export const InitialFilters = {
  equipments: [],
  noteType: undefined,
  startTime: "",
  endTime: "",
};

export const ShiftReportHeader = {
  HEADER : "BRL-HST-STD-006.01"
}
