
import RequestedTable from "./RequestedTable/RequestedTable";
import Header from "./Header/Header";
import { RequestedProvider } from "./Context/Context";

const Requested = () => {
  return (
    <>

      <RequestedProvider>
        <Header />
        <RequestedTable />
      </RequestedProvider>

    </>
  );
};

export default Requested;
