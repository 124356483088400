
import { useState } from "react";
import FilterComponent from "./FilterComponent";
import { FLEET_FLYER, FleetInitialFilters } from "pages/Fleet/utils/Constant";
import { useFleetContext } from "pages/Fleet/Context/Context";
import Flyer from "pages/Fleet/Component/Flyers/Flyer";


const Filter = () => {
  const {
    onApplyFilter,
    openFlyer,
    setOpenFlyer,
    filters: originalFilters,
    //setFilters: setOriginalFilters,
  } = useFleetContext();


  const [filters, setFilters] = useState(originalFilters)

  const handleOnChange = (key, val) => {
    const tempFilters = { ...filters };
    tempFilters[key] = val;
    setFilters(tempFilters);
  };

  return (
    <>
      {openFlyer === FLEET_FLYER.FILTER && (
        <Flyer
          isOpen={openFlyer === FLEET_FLYER.FILTER}
          onClose={() => setOpenFlyer("")}
          title={"Filters"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 10.2rem)",
          }}
          hideBorderBottom={true}
          headerClassName={"pt-3"}
          headerHeight={'60px'}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Apply",
                  onClick: () => {
                    onApplyFilter(filters);
                    //setOriginalFilters(filters);
                    setOpenFlyer("");
                  },
                },
              ],
              leftActionButtons: [
                {

                  label: "Clear All",
                  onClick: () => {
                    //setOriginalFilters(FleetInitialFilters);
                    onApplyFilter(FleetInitialFilters, true);
                    setOpenFlyer("");
                  },
                  outline: true,
                },
              ],
              component: (
                <FilterComponent
                  filters={filters}
                  onChange={handleOnChange}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default Filter;
