import React, { useEffect, useRef, useState } from "react";
import menuIcon from "../../../../../assets/svgs/kpi-dashboard/menu.svg";
import styles from "./styles.module.css";
import { GroupingOptions } from "../helpers";
import tick from '../../../../../assets/svgs/kpi-dashboard/tick.svg'

const GroupBy = ({ selectedOption, onChange }) => {
  const [isVisible, setIsVisible] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [menuRef]);

  const OPTIONS = [
    {
      label: "Asset Categories",
      value: GroupingOptions.AssetCategories,
    },
    {
      label: "Asset Sub-Categories",
      value: GroupingOptions.AssetSubCategories,
    },
    {
      label: "Individual Assets",
      value: GroupingOptions.IndividualAssets,
    },
  ];

  return (
    <>
      <div
        className="d-flex flex-column align-items-end justify-content-start position-relative"
        style={{ gap: "6px" }}
        ref={menuRef}
      >
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            border: isVisible ? "1px solid #0D7FFA" : "1px solid #E3E3E3",
            backgroundColor: "white",
            cursor: "pointer",
            padding: "7px 6px",
            borderRadius: "1000px",
          }}
          onClick={() => setIsVisible(true)}
        >
          <img width={14} src={menuIcon} alt="" />
        </div>

        {isVisible && (
          <div className={`${styles["container"]}`}>
            <p>Group data by:</p>

            <div className="d-flex flex-column">
              {OPTIONS.map((option, index) => (
                <div
                  className="d-flex flex-row justify-content-between align-items-center"
                  style={{
                    borderRadius: "15px",
                    backgroundColor:
                      selectedOption === option.value ? "#E8F2FD" : "white",
                    padding: "0px 16px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    onChange(option.value);
                    setIsVisible(false);
                  }}
                >
                  <p
                    style={{
                      fontFamily: "Open Sans",
                      fontSize: "11px",
                      fontWeight: 500,
                    }}
                  >
                    {option.label}
                  </p>
                  {selectedOption === option.value && <img src={tick} alt="" />}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default GroupBy;
