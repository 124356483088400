export const messages = {
    error: {
      ENTER_EMAIL: 'Please enter your registered email address',
      INVALID_EMAIL: 'Please enter a valid email address',
      ENTER_PASSWORD: 'Please enter password',
      PASSWORD_TOO_SHORT: 'Password must be at least 6 characters',
      ENTER_CONFIRM_PASSWORD: 'Please enter confirm password',
      PASSWORD_NOT_MATCH: 'Passwords do not match',
      DEPARTMENTS_NOT_FOUND: 'Department not found',
      LICENSE_NOT_FOUND: 'License not found',
    },
    prestartCheck: {
      pieChartDescription: 'Submitted Pre-start Checks’ Results',
    },
  };