import React, { useEffect, useState } from "react";
import styles from "./SelectAsset.module.css";
import SelectAssetTable from "./SelectAssetTable";
import { DateTimeFormats, SelectAssetTypes } from "../../utils/contants";
import Search from "../../components/GeneralFields/Search";
import { isUndefined, toLower } from "lodash";
import SelectField from "../../components/GeneralFields/SelectField";
import { CallWithAuth } from "../../../../action/apiActions";
import moment from "moment";
import { IoCloseSharp } from "react-icons/io5";

const SelectAsset = ({
  timeRange,
  title,
  type,
  onClose,
  alreadySelectedAssets,
  handleAddAsset,
}) => {
  const [selected, setSelected] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [subCat, setsubCat] = useState("");
  const [subCategoriesOptions, setSubCategoriesOptions] = useState([]);
  const [allAssets, setAllAssets] = useState([]);
  const [fetchedData, setFetchedData] = useState([]);

  const checkUnavailability = (currShiftStart, currShiftEnd, shifts) => {
    const unavailablePeriods = [];
    const startCurrentShift = moment(currShiftStart, DateTimeFormats.DATE_TIME_U);
    const endCurrentShift = moment(currShiftEnd, DateTimeFormats.DATE_TIME_U);

    shifts.forEach(shift => {
      const shiftStart = moment(shift.startTime);
      const shiftEnd = moment(shift.endTime);

      if (!(shiftStart.isBefore(startCurrentShift) && shiftEnd.isSameOrBefore(startCurrentShift) ||
        shiftStart.isAfter(endCurrentShift) && shiftEnd.isSameOrAfter(endCurrentShift))) {

        const unavailableStart = shiftStart.isBefore(startCurrentShift) ? startCurrentShift : shiftStart;
        const unavailableEnd = shiftEnd.isAfter(endCurrentShift) ? endCurrentShift : shiftEnd;
        unavailablePeriods.push({
          reportNo: shift?.shift?.reportNo,
          startTime: unavailableStart,
          endTime: unavailableEnd,
          crew: shift?.shift?.crew?.CrewType
        });
      }
    });

    return unavailablePeriods;
  };

  const filterAssets = (assets) => {
    return assets.filter((asset) =>
      alreadySelectedAssets.findIndex(
        (as) => as.equipment === asset._id || as.value == asset._id
      ) === -1
    );
  };

  const handleClose = () => {
    onClose()
  };

  const handleAdd = () => {
    handleAddAsset(selected);
    handleClose();
  };

  const handleSearch = (value) => {
    if (!isUndefined(value)) {
      setSearchString(value);
    }
  };

  const handleSubcategoryChange = (val) => {
    setsubCat(val);
  };

  useEffect(() => {

    const fetchData = async () => {
      try {
        let endpoint = "";

        if (type === SelectAssetTypes.EXCAVATORS) {
          endpoint = "api/equipments?equipmentType=Excavator";
        } else if (type === SelectAssetTypes.TRUCKS) {
          endpoint = "api/equipments?equipmentType=Truck";
        }

        const { res } = await CallWithAuth("GET", endpoint);

        if (res.data.status === 200) {
          const { data } = res.data;

          const processAssets = (assets, type) => {
            return assets.map(asset => {
              const unavailabilityArray = checkUnavailability(timeRange.startTime, timeRange.endTime, asset.assignedInShifts);
              const isCompletelyUnavailable = unavailabilityArray[0]?.startTime.format(DateTimeFormats.DATE_TIME_U) === timeRange.startTime &&
                unavailabilityArray[0]?.endTime.format(DateTimeFormats.DATE_TIME_U) === timeRange.endTime || asset.assetStatus == "Out of Order";
              const unavailablePeriods = unavailabilityArray.length
                ? unavailabilityArray.map(period => `${period.startTime.format('hh:mm a')} - ${period.endTime.format('hh:mm a')}`).join(', ')
                : '';
              let reasons = unavailabilityArray.length
                ? [...new Set(unavailabilityArray.map(period => `${period.reportNo} (Crew ${period.crew})`))].join(', ')
                : '';
              if (asset.assetStatus == "Out of Order")
                if (Array.isArray(reasons))
                  reasons?.unshift('Out of Order')
                else
                  reasons = 'Out of Order'

              return {
                ...asset,
                completelyUnavailable: isCompletelyUnavailable,
                unavailable: unavailablePeriods,
                reason: reasons
              };
            });
          };

          const updateOptions = (filteredData) => {
            const options = filteredData.map(asset => ({
              name: asset.model,
              category: asset.model
            }));
            setSubCategoriesOptions(options);
          };

          if (type === SelectAssetTypes.EXCAVATORS) {
            const filteredData = filterAssets(data.excavators);
            setFetchedData(processAssets(filteredData, type));
            updateOptions(filteredData);
          } else if (type === SelectAssetTypes.TRUCKS) {
            const filteredData = filterAssets(data.trucks);
            setFetchedData(processAssets(filteredData, type));
            updateOptions(filteredData);
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {

    const filteredAssets = fetchedData.filter((asset) => {
      const matchesSearch = searchString
        ? asset.Unit.toLowerCase().includes(searchString.toLowerCase())
        : true;
      const matchesSubCat = subCat ? asset.model === subCat : true;

      return matchesSearch && matchesSubCat;
    });

    setAllAssets(filteredAssets);
  }, [searchString, subCat, fetchedData]);

  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.top}>
          <div className={styles.title}>
            {title}
          </div>
          <span onClick={handleClose} className={styles.close}>
            <IoCloseSharp color="black" fontSize={17.5} />
          </span>
        </div>
        <div className={styles.mid}>
          <div className={styles.selected}>
            <div className={styles.subtitle}>
              <div>Selected Assets ({selected?.length ?? 0})</div>
              <div
                className={styles.addBtn}
                style={
                  selected?.length == 0
                    ? { color: "#8190AD", background: "#E1E4EA" }
                    : {}
                }
                onClick={handleAdd}
              >
                Add Assets
              </div>
            </div>
            <div className={styles.body}>
              <SelectAssetTable
                data={selected}
                type={type}
                selected={selected}
                setSelected={setSelected}
              />
            </div>
          </div>
          <div className={styles.all}>
            <div className={styles.subtitle}>
              <span>All Assets ({allAssets?.filter((item) => !selected.find((e) => item._id?.toString() == e._id?.toString()))?.length ?? 0})</span>
              <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
                <Search query={searchString} containerStyle={{ height: 30 }} onChange={handleSearch} />
                <SelectField
                  placeholder={"Subcategories"}
                  options={subCategoriesOptions.map((e) => ({
                    value: e.category,
                    label: e.name,
                  }))}
                  value={subCat}
                  onChange={handleSubcategoryChange}
                  style={{
                    height: "30px",
                    width: "160px",
                    marginTop: 2.5,
                    borderRadius: "15px",
                    backgroundColor: "white",
                    paddingLeft: "10px",
                    border: "1px solid #E1E8F4",
                    color: "#1A1A1A",
                    fontSize: "12px",
                    fontWeight: "600",
                  }}
                  mainContainerStyle={{ marginBottom: "0px" }}
                />
              </div>
            </div>
            <div className={styles.body}>
              <SelectAssetTable
                data={allAssets}
                type={type}
                selected={selected}
                setSelected={setSelected}
                allAssets={true}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectAsset;
