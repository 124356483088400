import React, { useEffect, useState } from "react";
import styles from "../ViewDetails/ViewDetails.module.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import GeneralInfo from "./Sections/GeneralInfo";
import MechanicSummary from "./Sections/MechanicSummary";
import SupervisorSummary from "./Sections/SupervisorSummary";
import { JOB_STATUS } from "pages/ShiftMaintenance/utils/Constant";
import Collapsible from "pages/ShiftMaintenance/Component/Collapsible/Collapsible";
import { Riple } from "react-loading-indicators";


const ViewDetailsComponent = ({ isAllExpanded, data, isLoading }) => {
  
  const sections = [
    // Conditionally include General Info
    ...(data?.generalInfo
      ? [
        {
          title: "General Info",
          content: <GeneralInfo data={data} />,
        },
      ]
      : []),

    // Conditionally include Mechanic's Service Summary
    ...(data?.generalInfo?.jobStatus === JOB_STATUS.CLOSED
      ? [
        {
          title: "Mechanic's Service Summary",
          content: <MechanicSummary summaryData={data?.mechanicServiceSummary} />,
        },
      ]
      : []),

    // Conditionally include Supervisor's Summary
    ...(data?.generalInfo?.jobStatus === JOB_STATUS.CLOSED
      ? [
        {
          title: "Maintenance Supervisor's Summary",
          content: <SupervisorSummary supervisorData={data?.supervisorInfo} />,
        },
      ]
      : []),
  ];

  const [openSections, setOpenSections] = useState([]);

  useEffect(() => {
    if (isAllExpanded) {
      setOpenSections(sections.map((_, index) => index)); // Expand all
    } else {
      setOpenSections([]); // Collapse all
    }
  }, [isAllExpanded]);

  const toggleSection = (index) => {
    if (openSections.includes(index)) {
      setOpenSections(openSections.filter((i) => i !== index));
    } else {
      setOpenSections([...openSections, index]);
    }
  };
  return (
    <div className={`px-4 mr-2 ml-3 ${styles.container} h-100`}>
      {isLoading ? <div
        className={`d-flex align-items-center justify-content-center h-100`}
      >
        <Riple color="#317bcc" size="medium" text="" textColor="" />
      </div> : <div className={`pb-5 ${styles.collapsibleContainer}`}>
        {sections.map((section, index) => (
          <Collapsible key={index}
            title={section.title}
            content={section.content}
            isOpen={openSections.includes(index)}
            toggle={() => toggleSection(index)} />
        ))}
      </div>}
    </div>
  );
};


export default ViewDetailsComponent;
