import RadioButton from "pages/ShiftMaintenance/Component/RadioButton/RadioButton";
import DynamicSelectField from "pages/ShiftMaintenance/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import TextAreaField from "pages/ShiftMaintenance/Component/GeneralFields/TextAreaField";
import {
  addJobIssueType,
  assetStatus,
  issueTypes,
} from "pages/ShiftMaintenance/utils/DropDownMasters";
import moment from "moment";
import {
  DateTimeFormats,
  ISSUE_TYPE,
} from "pages/ShiftMaintenance/utils/Constant";
import DateAndTimeSelect from "pages/ShiftMaintenance/Component/GeneralFields/DateAndTimeSelect/DateAndTimeSelect";
import { MdCircle } from "react-icons/md";

const AddJobComponent = ({ showError, equipmentList, formData, handleOnChange }) => {


  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  const handleDateChange = (v) => {
    const isoDate = moment(v).toISOString(); // Convert to ISO string if needed
    handleOnChange("scheduledFor", isoDate);
  };

  return (
    <div className="px-5">
      <DynamicSelectField
        required={true}
        label={"Select Asset"}
        placeholder={"Select Asset"}
        options={equipmentList}
        value={formData?.equipment}
        onChange={(val) => handleOnChange("equipment", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        wrapperStyle={{ marginBottom: "0" }}
        error={showError && !formData?.equipment}
        errorMessage="Please select asset"
      />

      <DynamicSelectField
        required={true}
        label={"Issue/Job Type"}
        placeholder={"Issue/Job Type"}
        options={addJobIssueType?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={formData?.issueType}
        onChange={(val) => handleOnChange("issueType", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        wrapperStyle={{ marginBottom: "0", marginTop: "10px" }}
        error={showError && !formData?.issueType}
        errorMessage="Please select job type"
      />
      {formData?.issueType?.label === ISSUE_TYPE.PLANNED_MAINTENANCE && (
        <DateAndTimeSelect
          // required
          label="Scheduled For"
          value={
            formData?.scheduledFor ? new Date(formData?.scheduledFor) : null
          }
          minDate={new Date(moment().toISOString())}

          maxDate={new Date(moment().add(1, 'year').toISOString())}

          labelStyle={labelStyle}
          onChange={handleDateChange}
          // error={showError && !formData?.startTime}
          // errorMessage="Please select shift start time"
          id="scheduledFor"
          wrapperStyle={{ marginTop: "1rem" }}
        // error={showError && !formData?.scheduledFor}
        // errorMessage="Please select date"
        />
      )}

      <RadioButton
        radioLabel={"Asset Status"}
        options={assetStatus}
        value={formData?.assetStatus}
        onChange={(v) => {
          handleOnChange("assetStatus", v);
        }}
        isDisabled={false}
        id="assetStatus"
        radioLabelStyle={labelStyle}
        className={"px-5 py-4"}
        optionMainContainer={{
          marginLeft: "102px",
          paddingTop: "5px",
        }}
        styleClass={"pt-3"}
      />

      <div
        style={{ marginTop: "12px" }}
        className="d-flex align-content-center pt-3"
      >
        <div
          className="mb-2"
          style={{
            width: "20%",
            ...labelStyle,
          }}
        >
          Notes & Comments
        </div>
        <TextAreaField
          wrapperStyle={{ paddingLeft: "83px", flexGrow: "1" }}
          style={{
            height: "100%",
            border: "1px solid #E1E8F4",
            borderRadius: "4px",
          }}
          value={formData.notesAndComments}
          onChange={(v) => handleOnChange("notesAndComments", v)}
        />
      </div>
    </div>
  );
};

export default AddJobComponent;
