import DynamicTable from "pages/ShiftMaintenance/Component/Table/Table";
import {
  ARCHIVED_FLYER,
  ArchivedHeader,
} from "pages/ShiftMaintenance/utils/Constant";
import { useState } from "react";
import ViewDetails from "../ViewDetails/ViewDetails";
import ConfirmAlert from "pages/ShiftMaintenance/Component/ConfirmAlert/ConfirmAlert";
import { useArchivedContext } from "../Context/Context";
import { GoDotFill } from "react-icons/go";
import {
  dotColors,
  formatDateTime,
  showToast,
} from "pages/ShiftMaintenance/utils/helper";
import { convertSecondsToTime } from "../../../utils/helper";
import { JobViewDetails, RevertJob } from "pages/ShiftMaintenance/utils/ApiServices";

const ArchivedTable = () => {
  const { openFlyer, setOpenFlyer, filteredResults, archivedData, setRefreshPage, loading } = useArchivedContext();
  const [viewDetailsData, setViewDetailsData] = useState({});
  const [selectedJobId, setSelectedJobId] = useState("");

  const [isModalLoading, setIsModalLoading] = useState(false)
  const headerStyle = {
    paddingLeft: 12,
    font: "normal normal bold 11px/18px Open Sans",
    color: "#1A1A1A",
  };

  const headers = [
    {
      label: ArchivedHeader.JOBS_REFS,
      key: "jobRefs",
      style: {
        width: "8%",
        ...headerStyle,
      },
    },
    {
      label: ArchivedHeader.ARCHIVED_ON,
      key: "archivedOn",
      style: {
        width: "13%",
        ...headerStyle,
      },
    },
    {
      label: ArchivedHeader.ASSET_ID,
      key: "assetId",
      style: {
        width: "7%",
        ...headerStyle,
      },
    },
    {
      label: ArchivedHeader.JOB_TYPE,
      key: "jobType",
      style: {
        width: "15%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: ArchivedHeader.JOB_STATUS,
      key: "jobStatus",
      style: {
        width: "9%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: ArchivedHeader.ISSUE_STATUS,
      key: "issueStatus",
      style: {
        width: "10%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: ArchivedHeader.MECHANIC,
      key: "mechanic",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: ArchivedHeader.DELAY_DURATION,
      key: "delayDuration",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: ArchivedHeader.JOB_DURATION,
      key: "jobDuration",
      style: {
        width: "9%",
        ...headerStyle,
      },
    },
    {
      label: ArchivedHeader.REVIEWED_BY,
      key: "reviewedBy",
      style: {
        width: "8%",
        ...headerStyle,
      },
    },
    {
      label: ArchivedHeader.ACTIONS,
      key: "actionMenu",
      style: {
        width: "0%",
        ...headerStyle,
      },
    },
  ];

  const totalFilteredArchivedJobs = filteredResults?.length ?
    filteredResults?.reduce((total, entry) => total + (entry.jobs?.length || 0), 0) : 0;


  const totalArchivedJobs = archivedData?.length ?
    archivedData?.reduce((total, entry) => total + (entry.jobs?.length || 0), 0) : 0;

  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: "",
    message: "",
    jid: "",
  });

  const confirmRevertJobMessage = () => (
    <div
      className="d-flex flex-column"
      style={{
        font: "normal normal normal 14px/20px Open Sans",
        color: "#000000",
      }}
    >
      <span>
        Are you sure you want to move this job back to the approved jobs table?
      </span>
    </div>
  );

  const handleViewDetails = async (jobId) => {
    setIsModalLoading(true)
    const viewDetailResponse = await JobViewDetails(jobId)
    if (viewDetailResponse) {
      setViewDetailsData(viewDetailResponse);
    }
    setSelectedJobId(jobId)
    //setViewDetailsData(viewDetailsDummyData);
    setOpenFlyer(ARCHIVED_FLYER.VIEW_DETAILS);
    setIsModalLoading(false)
  };

  const handleDownload = () => { };

  const handleRevertJob = async (jobId) => {
    const approveJobResponse = await RevertJob(jobId);
    if (approveJobResponse) {
      showToast(true, "Job revert successfully");
      setOpenFlyer("")
      setRefreshPage(true)

    } else {
      showToast(false, "An error occurred while reverting job");
    }
  };

  const processedData = filteredResults.length ?
    filteredResults?.map((group) => ({
      groupName: group?.date, // Use `name` as the group name
      groupData: group?.jobs?.map((job) => ({
        rowHeaderStyle: {
          font: "normal normal bold 12px/17px Open Sans",
          color: "#1A1A1A",
          background: "#EFF3FA 0% 0% no-repeat padding-box",
          borderRadius: "15px",
          padding: "6px 10px 6px 10px",
        },
        rowHeader: group?.date,
        // Assign group name as the row header
        rows: [
          {
            value: {
              jobRefs: job?.refNo || "-",
              archivedOn: job?.archivedAt ? formatDateTime(job?.archivedAt) : "-",
              assetId: job?.assetId || "-",
              jobType: job?.issueType ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(job?.issueType),
                      paddingBottom: "3px",
                    }}
                  />
                  {job?.issueType}
                </span>
              ) : (
                "-"
              ),
              jobStatus: job?.jobStatus ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(job?.jobStatus),
                      paddingBottom: "3px",
                    }}
                  />
                  {job?.jobStatus}
                </span>
              ) : (
                "-"
              ),
              issueStatus: job?.issueStatus ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(job?.issueStatus),
                      paddingBottom: "3px",
                    }}
                  />
                  {job?.issueStatus}
                </span>
              ) : (
                "-"
              ),
              mechanic: job?.mechanic || "-",

              delayDuration: job?.delayType
                ? `${job?.delayType} - ${convertSecondsToTime(
                  job?.delayDuration
                )}`
                : "-",
              jobDuration: job?.jobDuration
                ? convertSecondsToTime(job?.jobDuration)
                : "-",
              reviewedBy: job?.reviewedBY || "-",
            },
            rowStyle: {
              font: "normal normal normal 13px/19px Open Sans",
              color: "#1A1A1A",
            },
            onClick: () => handleViewDetails(job?.jobId),
            actionMenu: {
              options: [
                {
                  name: "View Details",
                  icon: "",
                  onClick: () => handleViewDetails(job?.jobId),
                },
                {
                  name: "Download",
                  icon: "",
                  onClick: () => handleDownload(),
                },
                {
                  name: "Revert",
                  icon: "",
                  onClick: () =>
                    setConfirmModal({
                      show: true,
                      title: "Revert Job",
                      message: confirmRevertJobMessage(),
                      wantToDelete: true,
                      jid: job?.jobId,
                    }),
                },
              ],
            },
          },
        ],
        actionMenu: {
          options: [],
        },
      })),
    })) : [];

  return (
    <>
      {openFlyer === ARCHIVED_FLYER.VIEW_DETAILS && (
        <ViewDetails
          isLoading={isModalLoading}
          setConfirmModal={setConfirmModal}
          revertJobMessage={confirmRevertJobMessage}
          setOpenFlyer={setOpenFlyer}
          openFlyer={openFlyer}
          data={viewDetailsData}
          jobId={selectedJobId}
        />
      )}

      {confirmModal.show && (
        <ConfirmAlert
          title={confirmModal.title}
          message={confirmModal.message}
          onClose={() => {
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              jid: "",
            });
          }}
          onConfirm={() => {
            handleRevertJob(confirmModal.jid);
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              jid: "",
            });
          }}
        />
      )}

      {/*
      {openFlyer === REQUESTED_FLYER.JOB_APPROVE && (
        <ApproveJob setOpenFlyer={setOpenFlyer} openFlyer={openFlyer} />
      )}

      {openFlyer === REQUESTED_FLYER.JOB_REJECT && (
        <RejectJob setOpenFlyer={setOpenFlyer} openFlyer={openFlyer} />
      )} */}

      <DynamicTable
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={true}
        rowHeader={false}
        footer={`Displaying ${totalFilteredArchivedJobs}/${totalArchivedJobs} Archived Jobs`}
      />
    </>
  );
};

export default ArchivedTable;
