import DateTimeSelect from "pages/ShiftMaintenance/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import DynamicSelectField from "pages/ShiftMaintenance/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import { assetCategory, delayTypes, issueStatus, issueTypes, jobStatus, jobTypesForArchived } from "pages/ShiftMaintenance/utils/DropDownMasters";
import { MdCircle } from "react-icons/md";


const FilterComponent = ({ filters, onChange }) => {
  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  return (
    <div className="px-5">
      <DynamicSelectField
        label={"Asset Category"}
        placeholder={"Asset Category"}
        options={assetCategory}
        value={filters.assetCategory}
        onChange={(val) => onChange("assetCategory", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        wrapperStyle={{ marginBottom: "0" }}
        isMulti={true}
        selectAll={true}
      />

      <div className="d-flex align-items-center justify-content-between" >
        <div style={{ width: "20%", ...labelStyle }}>Date</div>
        <div className="d-flex align-items-center justify-content-between" style={{ width: "80%" }}>
          <DynamicSelectField
            label={""}
            placeholder={"Job Type"}
            options={jobTypesForArchived}
            value={filters?.jobType}
            onChange={(val) => onChange("dateType", val)}
            style={commonFieldStyles}
            labelStyle={labelStyle}
            valueStyle={{ width: "165px", ...valueStyle }}
            wrapperStyle={{ marginBottom: "0", marginLeft: "68px" }}
          />
          <DateTimeSelect
            label=""
            value={filters?.date}
            onDateChange={(v) => {
              onChange("date", v);
            }}
            iconColor="#6B7B8E"
            containerStyle={{
              border: "1px solid #E1E8F4",
              borderRadius: "4px",
              height: "max-content",
              minHeight: "3.4rem",
              width: "175px",
              marginTop: "10px",
              marginBottom: "10px",
            }}
            labelStyle={labelStyle}
          />
        </div>
      </div>



      <DynamicSelectField

        label={"Delay Type"}
        placeholder={"Delay Type"}
        options={delayTypes}
        value={filters?.delayType}
        onChange={(val) => onChange("delayType", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
      <DynamicSelectField

        label={"Issue/Job Type"}
        placeholder={"Issue/Job Type"}
        options={
          issueTypes?.map(({ value, label, color }) => ({
            value,
            label,
            icon: (
              <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
            ),
          }))}
        value={filters?.issueType}
        onChange={(val) => onChange("issueType", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}


      />

      <DynamicSelectField

        label={"Job Status"}
        placeholder={"Job Status"}
        options={
          jobStatus?.map(({ value, label, color }) => ({
            value,
            label,
            icon: (
              <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
            ),
          }))}
        value={filters?.jobStatus}
        onChange={(val) => onChange("jobStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}

      />

      <DynamicSelectField

        label={"Issue Status"}
        placeholder={"Issue Status"}
        options={
          issueStatus?.map(({ value, label, color }) => ({
            value,
            label,
            icon: (
              <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
            ),
          }))}
        value={filters?.issueStatus}
        onChange={(val) => onChange("issueStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}

      />
    </div>
  );
};

export default FilterComponent;
