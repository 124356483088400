// src/components/DisplaySummaryPieChart.tsx
import { FunctionComponent } from 'react';


import { entries, values } from 'lodash';
import {
  DefectColor,
  prestartcheckLabelsWithoutIncomplete,
} from './constant'; // Adjust the path as necessary
import { DisplaySummaryPieChartProps } from 'pages/Pre-StartCheck/utils/NZMine/types';
import PieChartGraph from 'pages/Pre-StartCheck/Component/PieChart';
import { PreCheckEvaluationResult } from 'pages/Pre-StartCheck/utils/NZMine/enums';
import { messages } from 'pages/Pre-StartCheck/utils/NZMine/messages';

const DisplaySummaryPieChart: FunctionComponent<DisplaySummaryPieChartProps> = (
  props,
) => {
  return (
    <div className="d-flex flex-row">
      <div className="d-flex flex-column" style={{ minWidth: '240px', position: "relative" }}>
        <PieChartGraph
          key={1}
          showLabel={props.showLabel ?? true}
          data={values(props.summary)}
          chartData={[
            {
              name: PreCheckEvaluationResult.CATEGORY_A_FAILURE,
              value: props.summary[PreCheckEvaluationResult.CATEGORY_A_FAILURE],
            },
            {
              name: PreCheckEvaluationResult.CATEGORY_B_FAILURE,
              value: props.summary[PreCheckEvaluationResult.CATEGORY_B_FAILURE],
            },
            {
              name: PreCheckEvaluationResult.CATEGORY_C_FAILURE,
              value: props.summary[PreCheckEvaluationResult.CATEGORY_C_FAILURE],
            },
            {
              name: PreCheckEvaluationResult.PASS,
              value: props.summary[PreCheckEvaluationResult.PASS],
            },
            {
              name: PreCheckEvaluationResult.INCOMPLETE,
              value: props.summary[PreCheckEvaluationResult.INCOMPLETE],
            },
          ]}
        />
        <div className="text-size text-center" style={{ color: '#707070', position: "relative", bottom: "8px" }}>
          {messages.prestartCheck.pieChartDescription}
        </div>

      </div>
      {props.showKey ? (
        <div style={{ marginBottom: 15 }} className="align-self-end">
          {entries(prestartcheckLabelsWithoutIncomplete).map(
            ([defectType, label]) => (
              <div
                style={{ lineHeight: '1.3rem' }}
                className="align-self-end d-flex flex-row align-items-center gap-2"
                key={defectType}
              >
                <div
                  className="rounded-circle"
                  style={{
                    width: '8px',
                    height: '8px',
                    backgroundColor:
                      DefectColor[defectType as PreCheckEvaluationResult],
                  }}
                ></div>
                <div style={{ fontSize: '10px', paddingLeft: '4px' }}>{label as string}</div>
              </div>
            ),
          )}
        </div>
      ) : null}
    </div>
  );
};

export default DisplaySummaryPieChart;
