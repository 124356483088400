import DynamicTable from "pages/ShiftMaintenance/Component/Table/Table";

import {
  REQUESTED_FLYER,
  RequestedHeader,
} from "pages/ShiftMaintenance/utils/Constant";
import ViewDetails from "../ViewDetails/ViewDetails";
import ApproveJob from "../ApproveJob/ApproveJob";
import RejectJob from "../RejectJob/RejectJob";
import { GoDotFill } from "react-icons/go";
import { dotColors, formatDateTime } from "pages/ShiftMaintenance/utils/helper";
import { convertSecondsToTime } from "../../../utils/helper";
import { useRequestedContext } from "../Context/Context";
import { useState } from "react";
import { JobViewDetails } from "pages/ShiftMaintenance/utils/ApiServices";
import InfiniteScroll from "react-infinite-scroll-component";
import { Riple } from "react-loading-indicators";

const RequestedTable = () => {
  const { openFlyer, setOpenFlyer, requestedData, filteredResults, loading } =
    useRequestedContext();

  const [viewDetailsData, setViewDetailsData] = useState({});

  const headerStyle = {
    paddingLeft: 12,
    font: "normal normal bold 11px/18px Open Sans",
    color: "#1A1A1A",
  };
  const headers = [
    {
      label: RequestedHeader.JOBS_REFS,
      key: "jobRefs",
      style: {
        width: "8%",
        ...headerStyle,
      },
    },
    {
      label: RequestedHeader.ISSUE_REPORTED_ON,
      key: "issueReportedOn",
      style: {
        width: "13%",
        ...headerStyle,
      },
    },
    {
      label: RequestedHeader.ASSET_ID,
      key: "assetId",
      style: {
        width: "7%",
        ...headerStyle,
      },
    },
    {
      label: RequestedHeader.JOB_TYPE,
      key: "jobType",
      style: {
        width: "15%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: RequestedHeader.DELAY_DURATION,
      key: "delayDuration",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: RequestedHeader.REPORTED_BY,
      key: "reportedBy",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: RequestedHeader.PRE_START_REPORT,
      key: "preStartReport",
      style: {
        width: "9%",
        ...headerStyle,
      },
    },
    {
      label: RequestedHeader.SHIFT,
      key: "shift",
      style: {
        width: "9%",
        ...headerStyle,
      },
    },
    {
      label: RequestedHeader.ASSET_STATUS,
      key: "assetStatus",
      style: {
        width: "10%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: RequestedHeader.ASSET_ACTIVITY,
      key: "assetActivity",
      style: {
        width: "9%",
        ...headerStyle,
      },
    },
    {
      label: RequestedHeader.ACTIONS,
      key: "actionMenu",
      style: {
        width: "5%",
        ...headerStyle,
      },
    },
  ];

  const rowHeaderBadgeStyle = {
    font: "normal normal bold 12px/17px Open Sans",
    color: "#1A1A1A",
    background: "#EFF3FA 0% 0% no-repeat padding-box",
    borderRadius: "15px",
    padding: "6px 10px 6px 10px",
  };

  const totalFilteredRequestedJobs = filteredResults?.length ? filteredResults?.reduce(
    (total, entry) => total + (entry.jobs?.length || 0),
    0
  ) : 0;

  const totalRequestedJobs = requestedData?.length ?
    requestedData?.reduce(
      (total, entry) => total + (entry.jobs?.length || 0),
      0
    ) : 0;

  const handleViewDetails = async (jobData) => {
    //const viewDetailResponse = await JobViewDetails(jobId);
    // if (viewDetailResponse) {
    //   setViewDetailsData(viewDetailResponse);
    // }
    setViewDetailsData(jobData);
    setOpenFlyer(REQUESTED_FLYER.VIEW_DETAILS);
  };

  const handleApproveJobModal = async (jobId) => {
    const viewDetailResponse = await JobViewDetails(jobId);
    if (viewDetailResponse) {
      setViewDetailsData(viewDetailResponse);
    }
    setOpenFlyer(REQUESTED_FLYER.JOB_APPROVE);
  };

  const handleRejectJobModal = async (jobId) => {
    const viewDetailResponse = await JobViewDetails(jobId);
    if (viewDetailResponse) {
      setViewDetailsData(viewDetailResponse);
    }
    setOpenFlyer(REQUESTED_FLYER.JOB_REJECT);
  };

  const processedData = filteredResults.length ?
    filteredResults?.map((group) => ({
      groupName: group?.date, // Use `name` as the group name
      groupData: group?.jobs?.map((job) => ({
        rowHeaderStyle: { rowHeaderBadgeStyle },
        rowHeader: group?.date,
        // Assign group name as the row header
        rows: [
          {
            value: {
              jobRefs: job?.refNo || "-",
              issueReportedOn: job?.reportedAt
                ? formatDateTime(job?.reportedAt)
                : "-",
              assetId: job?.assetId || "-",
              jobType: job?.issueType ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(job?.issueType),
                      paddingBottom: "3px",
                    }}
                  />{" "}
                  {job?.issueType}
                </span>
              ) : (
                "-"
              ),
              delayDuration: job?.delayType
                ? `${job?.delayType} - ${convertSecondsToTime(
                  job?.delayDuration
                )}`
                : "-",
              reportedBy: job?.reportedBy || "-",
              preStartReport: (
                <span
                  style={{
                    color: "#186FD9",
                    textDecoration: job?.preStartCheck?.refNo ? "underline" : "",
                  }}
                >
                  {job?.preStartCheck?.refNo || "-"}
                </span>
              ),
              shift: job?.shift
                ? `${job?.shift?.reportNo}, ${job?.shift?.shiftType}`
                : "-",
              assetStatus: job?.assetStatus ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(job?.assetStatus),
                      paddingBottom: "3px",
                    }}
                  />
                  {job?.assetStatus}
                </span>
              ) : (
                "-"
              ),
              assetActivity: job?.assetActivity ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(job?.assetActivity),
                      paddingBottom: "3px",
                    }}
                  />
                  {job?.assetActivity}
                </span>
              ) : (
                "-"
              ),
            },
            rowStyle: {
              font: "normal normal normal 13px/19px Open Sans",
              color: "#1A1A1A",
            },
            onClick: () => handleViewDetails(job),
            actionMenu: {
              options: [
                {
                  name: "View Details",
                  icon: "",
                  onClick: () => handleViewDetails(job),
                },
                {
                  name: "Approve Job",
                  icon: "",
                  onClick: () => handleApproveJobModal(job?.jobId),
                },
                {
                  name: "Reject Job",
                  icon: "",
                  onClick: () => handleRejectJobModal(job?.jobId),
                },
                {
                  name: "Download",
                  icon: "",
                  onClick: () => { },
                },
              ],
            },
          },
        ],
        actionMenu: {
          options: [],
        },
      })),
    })) : [];

  return (
    <>
      {openFlyer === REQUESTED_FLYER.VIEW_DETAILS && (
        <ViewDetails
          data={viewDetailsData}
          handleApproveJobModal={handleApproveJobModal}
          handleRejectJobModal={handleRejectJobModal}
        />
      )}

      {openFlyer === REQUESTED_FLYER.JOB_APPROVE && (
        <ApproveJob data={viewDetailsData} />
      )}

      {openFlyer === REQUESTED_FLYER.JOB_REJECT && (
        <RejectJob data={viewDetailsData} />
      )}

      {/* <InfiniteScroll
        className="shift-listing-infinite-scroll"
        dataLength={totalFilteredRequestedJobs}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={
          loading && (
            <div
              style={{ height: "100%", width: "100%" }}
              className="d-flex align-items-center justify-content-center"
            >
              <Riple color="#317bcc" size="medium" text="" textColor="" />
            </div>
          )
        }
        height={"100%"}
      > */}
      <DynamicTable
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={true}
        rowHeader={false}
        footer={`Displaying ${totalFilteredRequestedJobs}/${totalRequestedJobs} Jobs Pending Approval`}
      />
      {/* </InfiniteScroll> */}
    </>
  );
};

export default RequestedTable;
