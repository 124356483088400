import React from 'react'
import ToggleButtons from 'pages/KPIDashboard/Component/ToggleButton/ToggleButton'
import { ToggleOptions } from '../helpers'

const Switch = ({ value, onClick }) => {
  const OPTIONS = [
    {
      name: 'Owned',
      value: ToggleOptions.Owned
    },
    {
      name: 'Hired',
      value: ToggleOptions.Hired
    },
  ]
  return (
    <ToggleButtons selectedValue={value} onOptionClick={onClick} options={OPTIONS} activeBackgroundColor='#2B2D2F' activeColor='#FFFFFF' style={{height: "24.5px",}} />
  )
}

export default Switch;
