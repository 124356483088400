export const FLEET_FLYER = {
  VIEW_DETAILS: "FLEET_VIEW_DETAILS",
  // UPDATE_ASSET_STATUS: "FLEET_UPDATE_STATUS",
  FILTER: "FLEET_FILTER",
};


export const FleetInitialFilters = {
  assetCategory: "",
  shiftAssignment: "",
  preStartStatus: "",
  preStartResult: "",
  assetStatus: "",
  assetActivity: "",
};

export const fleetSortOptions = [
  { value: "assetCategory", label: "Asset Category" },
  { value: "assetStatus", label: "Asset Status" },
  { value: "assetActivity", label: "Asset Activity" },
  { value: "shiftAssignment", label: "Shift Assignment" },
];

export const assetStatus = [
  { value: "Out of Order", label: "Out of Order" },
  { value: "Operational", label: "Operational" },
]

export const FleetHeader = {
  ASSET_ID: "Asset ID",
  ASSET_STATUS: "Asset Status",
  ASSET_ACTIVITY: "Asset Activity",
  OPERATOR: "Operator",
  LAST_UPDATED: "Engine Hours (hr) | Last Updated",
  PRE_START_RESULT: "Pre-Start Result",
  ASSET_CATEGORY: "Asset Category",
  SHIFT_ASSIGNMENT: "Shift Assignment",
  ACTIONS: "Actions",
};

export const ASSET_STATUS = {
  OUT_OF_ORDER: "Out of Order",
  OPERABLE: "Operational",
};

export const ASSET_ACTIVITY = {
  ACTIVE: "Active",
  PARKED: "Parked",
  BATTERY_DISCONNECTED: "Battery Disconnected",
  DISCONNECTED: "Disconnected"
};

export const PRE_START_RESULT = {
  PASSED: "Passed",
  CAT_A_FAILURE: "Cat A Failure",
  CAT_B_FAILURE: "Cat B Failure",
  CAT_C_FAILURE: "Cat C Failure",
};

export const ASSET_CATEGORY = {
  EXCAVATORS: "Excavators",
  HAUL_TRUCKS: "Haul Trucks"
}

export const resultMap = {
  "passed": "Passed",
  "cat a failure": "Cat A Failure",
  "cat b failure": "Cat B Failure",
  "cat c failure": "Cat C Failure",
};


