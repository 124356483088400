import React from 'react'
import downloadIcon from '../../../../../assets/svgs/kpi-dashboard/download.svg';

const Download = ({ onClick }) => {
  return (
    <div className='d-flex align-item-center' style={{ cursor: 'not-allowed' }}>
      <img src={downloadIcon} alt="" />
    </div>
  )
}

export default Download