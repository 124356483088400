// @ts-nocheck
import { useState, FunctionComponent } from 'react';
import DisplaySummaryPieChart from './displaySummaryPieChart';
import { categoryDefects } from 'pages/Pre-StartCheck/utils/NZMine/displayCategoryDefectsSummary';
import { includesPassOrIncomplete } from 'pages/Pre-StartCheck/utils/NZMine/helper';
import { DefectColor, PreCheckEvaluationResultLabels } from './constant';



const SummaryWidget: FunctionComponent<SummaryWidgetProps> = (props) => {
  const [isCategoryViewOpen, setIsCategoryViewOpen] = useState<boolean>(true);

  const toggleCategoryView = (): void => {
    setIsCategoryViewOpen((prevState: Boolean) => !prevState);
  };

  return (
    <div className="prestartcheck-summary-container triangle-div p-3 d-flex flex-column ">
      <div className="header d-flex justify-content-between align-items-start py-0 ">
       <strong> <span className="fw-bold ">SUMMARY</span></strong>
        <div type="button" onClick={toggleCategoryView} className="fw-bold">
          {isCategoryViewOpen ? '>' : '<'}
        </div>
      </div>
      <div className="body d-flex justify-content-between  h-100 gap-lg-5 gap-md-3 gap-sm-2 ">
        {isCategoryViewOpen ? (
          <>
            <div style={{ display: "flex", alignItems: 'center', height: '100%' }}>
              {categoryDefects.map(({ Icon, name }) => (
                <div style={{ height: '100%', justifyContent: 'space-evenly' }} className="px-4 d-flex flex-column  align-items-center">
                  {includesPassOrIncomplete(name) ? (
                    <Icon size={'50'} />
                  ) : (
                    <Icon size={'30.82'} />
                  )}
                  <div
                    style={{
                      ...(includesPassOrIncomplete(name) && {
                        marginBottom: '0.5rem',
                      }),
                      alignItems: 'center'
                    }}
                  >
                    <h1
                      className="fw-bold "
                      style={{
                        color: DefectColor[name],
                        textAlign: 'center',
                        fontWeight: 'bolder',
                        fontSize: '40px',
                      }}
                    >
                      {props.summary[name]}
                    </h1>
                    <div style={{ lineHeight: '17.5px' }} >
                      <span style={{ fontWeight: '700' }} className=" text-size text-capitalize">
                        {PreCheckEvaluationResultLabels[name]}
                      </span>
                      <div className="text-size">
                        {includesPassOrIncomplete(name) ? 'Checks' : 'Failures'}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <DisplaySummaryPieChart
              showLabel={props.showLabel}
              summary={props.summary}
            />
          </>
        ) : (
          <DisplaySummaryPieChart
            showKey={true}
            showLabel={true}
            summary={props.summary}
          />
        )}
      </div>
    </div>
  );
};

export default SummaryWidget;
