import { GoDotFill } from "react-icons/go";
import styles from "../../ViewDetails/ViewDetails.module.css";
import { dotColors } from "pages/ShiftMaintenance/utils/helper";

const FailedCheckStatus = ({ failedData }) => {
  return (
    <div className="d-flex flex-column">
      {failedData?.failedCheckPoints?.map((checkPointData, index) => (
        <div
          key={index}
          id={`cat${String.fromCharCode(65 + index)}`}
          className="d-flex flex-column"
          style={{ gap: "12px", marginTop: index !==0 ? "25px" : "" }}

        >
          <div className="py- d-flex align-items-center">
            <div className={styles.categoryLabel}>
              {`${checkPointData?.category || ""} ${checkPointData?.category ? "," : ""} Check No: ${checkPointData?.checkpointNo || ""}`}
            </div>
            <div
              style={{
                flexGrow: "1",
                borderBottom: "1px solid #E1E8F4",
                marginLeft: "5px",
                paddingBottom: "11px",
                opacity: "0.5",
              }}
            ></div>
          </div>

          {/* Check Point */}
          <div className="d-flex align-items-center justify-content-between">
            <div className={styles.label}>Check Point</div>
            <div className={styles.value}>
              {checkPointData?.checkPoint || "-"}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <div className={styles.label}>Operator’s Notes</div>
            <div className={styles.value}>
              {checkPointData?.operatorNotes || "-"}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <div className={styles.label}>Issue Status</div>
            <div className={styles.value} style={{ marginRight: checkPointData?.issueStatus ? "21px" : "" }}>
              {checkPointData?.issueStatus && (
                <GoDotFill
                  fontSize={19.5}
                  style={{
                    color: dotColors(checkPointData?.issueStatus),
                    paddingBottom: "2px",
                  }}
                />
              )}
              {checkPointData?.issueStatus || "-"}
            </div>
          </div>

          <div className="d-flex align-items-center justify-content-between">
            <div className={styles.label}>Corrective Action/Notes</div>
            <div className={styles.value}>
              {checkPointData?.correctiveActions || "-"}
            </div>
          </div>

          {/* <div className="d-flex align-items-center justify-content-between">
            <div className={styles.label}>Mechanic’s Photos</div>
            <div className={styles.value}>{checkPointData?.mechanicImagePaths[0]}</div>
          </div> */}

          {/*         
          <div className="d-flex align-items-center justify-content-between">
            <div className={styles.label}>Operator’s Photos</div>
            <div className={styles.value}>{checkPointData?.operatorImagePaths[0]}</div>
          </div> */}
        </div>
      ))}
    </div>
  );
};

export default FailedCheckStatus;
