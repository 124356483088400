import Flyer from "pages/ShiftMaintenance/Component/Flyers/Flyer";
import { REQUESTED_FLYER } from "pages/ShiftMaintenance/utils/Constant";
import ApproveJobComponent from "../ApproveJob/ApproveJobComponent";
import { useState } from "react";
import { formateDataForSaving } from "pages/ShiftPlanning/utils/helpers/PublishShift";
import { ApproveJobData } from "pages/ShiftMaintenance/utils/ApiServices";
import { showToast } from "pages/ShiftMaintenance/utils/helper";
import { useRequestedContext } from "../Context/Context";

const ApproveJob = ({
  data,
}) => {
  const { openFlyer, setOpenFlyer, setRefreshPage } =
    useRequestedContext();

  const initialFormData = {
    equipment: data?.generalInfo?.equipment,
    refNo: data?.generalInfo?.refNo,
    issueType: data?.generalInfo?.issueType,
    assetStatus: data?.generalInfo?.assetStatus,
    scheduledFor: "",
    notesAndComments: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleApproveJob = async () => {
    const formattedData = {
      ...formateDataForSaving({ ...formData }),
    };
    // const formattedData = {
    //   ...(formData?.scheduledFor && { scheduledFor: formData.scheduledFor }),
    //   notesAndComments: formData?.notesAndComments,
    // };

    const approveJobResponse = await ApproveJobData(formattedData, data?.jobId);
    if (approveJobResponse) {
      showToast(true, "Job approved successfully");
      setOpenFlyer("");
      setRefreshPage(true);
    } else {
      showToast(false, "An error occurred while approving job");
    }
  };

  return (
    <>
      {openFlyer === REQUESTED_FLYER.JOB_APPROVE && (
        <Flyer
          isOpen={openFlyer === REQUESTED_FLYER.JOB_APPROVE}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={"Job Approval Form"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 9.25rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Submit",
                  onClick: async () => handleApproveJob(),
                },
              ],
              component: (
                <ApproveJobComponent
                  formData={formData}
                  setFormData={setFormData}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default ApproveJob;
