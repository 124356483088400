export const dayShiftData = [
  { quarter: "6 AM", earnings: 0 },
  { quarter: "7 AM", earnings: 0 },
  { quarter: "8 AM", earnings: 0 },
  { quarter: "9 AM", earnings: 0 },
  { quarter: "10 AM", earnings: 0 },
  { quarter: "11 AM", earnings: 0 },
  { quarter: "12 PM", earnings: 0 },
  { quarter: "1 PM", earnings: 0 },
  { quarter: "2 PM", earnings: 0 },
  { quarter: "3 PM", earnings: 0 },
  { quarter: "4 PM", earnings: 0 },
];

export const nightShiftData = [
  { quarter: "5 PM", earnings: 0 },
  { quarter: "6 PM", earnings: 0 },
  { quarter: "7 PM", earnings: 0 },
  { quarter: "8 PM", earnings: 0 },
  { quarter: "9 PM", earnings: 0 },
  { quarter: "10 PM", earnings: 0 },
  { quarter: "11 PM", earnings: 0 },
  { quarter: "12 AM", earnings: 0 },
  { quarter: "1 AM", earnings: 0 },
  { quarter: "2 AM", earnings: 0 },
  { quarter: "3 AM", earnings: 0 },
];
