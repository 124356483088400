export const assetCategory = [
  { value: "Excavator", label: "Excavator" },
  { value: "Truck", label: "Haul Trucks" },
]

export const shiftAssignment = [
  { value: "assigned", label: "Assigned-Ongoing" },
  { value: "Unassigned", label: "Unassigned" },
]

export const preStartStatus = [
  { value: "Complete", label: "Complete" },
  { value: "Incomplete", label: "Incomplete" },
]

export const preStartResult = [
  { value: "Cat A Failure", label: "Cat A Failure", color: "#E03E3E" },
  { value: "Cat B Failure", label: "Cat B Failure", color: "#FFBA00" },
  { value: "Cat C Failure", label: "Cat C Failure", color: "#6C35E6" },
  { value: "Passed", label: "Passed", color: "#06AF7B" },
  { value: "Incomplete", label: "Incomplete", color: "#939FB7" },
]

export const assetStatus = [
  { value: "Out of Order", label: "Out of Order" },
  { value: "Operational", label: "Operational" },
];

export const pscAssetStatus = [
  { value: "Operational", label: "Operational" },
  { value: "Out of Order", label: "Out of Order" }
];

export const assetActivity = [
  { value: "Active", label: "Active", color: "#06AF7B" },
  { value: "Parked", label: "Parked", color: "#939FB7" },
  { value: "Battery Disconnected", label: "Battery Disconnected", color: "#FFA701" },
  { value: "Disconnected", label: "Disconnected", color: "#E03E3E" },
]

export const jobStatus = [
  { value: "Requested", label: "Requested", color: "#A2A0A0" },
  { value: "Pending", label: "Pending", color: "#E03E3E" },
  { value: "In Progress", label: "In Progress", color: "#2291FF" },
  { value: "Paused", label: "Paused", color: "#FFBA01" },
  { value: "Ended", label: "Ended", color: "#06AF7B" },
  { value: "Closed", label: "Closed", color: "#A2A0A0" },
  { value: "Rejected", label: "Rejected", color: "#C18152" },
  { value: "Deleted", label: "Deleted", color: "#494750" },



]

export const operatorNotification = [
  { value: "Pending", label: "Pending", color: "#E03E3E" },
  { value: "Sent", label: "Sent", color: "#06AF7B" },
]

