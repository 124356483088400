import moment from "moment";
import Logo from "../../../../../assets/images/logo.png";
import "../../PreStartReport/PreStartReport.css";
import { formatDateTime2 } from "pages/Pre-StartCheck/utils/Helper";
import { MdCircle } from "react-icons/md";
import {  statusMapColor } from "pages/Pre-StartCheck/utils/Constant";
const ReportHeader = ({ data }) => {
 


  return (
    <div className={` px-5 py-4 d-flex flex-column `}>
      <div
        className={`pb-5 d-flex align-items-center justify-content-between w-100 `}
      >
        <div className="logoImos">
          <img src={Logo} alt="iMOS" />
        </div>
        <div className="dateLabel">
          {formatDateTime2(data?.checkTime)}
        </div>
      </div>
      <div className="pt-2 d-flex align-items-center justify-content-between w-100">
        <div className="checkSummary">
          {data?.job ?  data?.job?.WONumber ? data?.job?.WONumber : data?.job?.refNo : "0000000"}_{data?.assetID}_Pre-Start Check Summary
        </div>
        <div className="checkSummary">
          {statusMapColor[data?.status] || "-"}
        </div>
      </div>
    </div>
  
  );
};

export default ReportHeader;
