import { Category, PreCheckEvaluationResult } from "./enums";

export const CategoryMapping = {
  [Category.CATEGORY_A]: "A",
  [Category.CATEGORY_B]: "B",
  [Category.CATEGORY_C]: "C",
};
export const initialPrestartcheckDefectCount = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: 0,
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: 0,
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: 0,
  [PreCheckEvaluationResult.PASS]: 0,
  [PreCheckEvaluationResult.INCOMPLETE]: 0,
};

export type InitialPrestartcheckDefectCountType =
  typeof initialPrestartcheckDefectCount;