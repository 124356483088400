export enum UtilisationTabs {
    OperationalUtilisation = 'OPERATIONAL_UTILISATION',
    SpryCalendarAvailability = 'SPRY_CALENDAR_AVAILABILITY',
    SpryCalendarUtilisation = 'SPRY_CALENDAR_UTILISATION'
}

export enum ToggleOptions {
    Owned = 'OWNED',
    Hired = 'HIRED'
}

export enum SelectOptions {
    ShowAllCategories = 'SHOW_ALL_CATEGORIES'
}

export enum GroupingOptions {
    AssetCategories = 'ASSET_CATEGORIES',
    AssetSubCategories = 'ASSET_SUB_CATEGORIES',
    IndividualAssets = 'INDIVIDUAL_ASSETS',
}