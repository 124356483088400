import styles from "../AddEditShiftReport/AddEditShiftReport.module.css";
import close from "../../../../../assets/svgs/roundClose.svg";
import bathurstResource from "../../../../../assets/svgs/BathurstResource.svg";
import TextInputField from "pages/ShiftNotes/Component/GeneralFields/TextInputField";
import { useState } from "react";
import {
  addDailyReport,
  editDailyReport,
} from "pages/ShiftNotes/utils/apiService";
import { showToast } from "pages/ShiftNotes/utils/helper";
import { useShiftReportContext } from "../Context/Context";
import moment from "moment";
import SelectField from "pages/ShiftNotes/Component/GeneralFields/SelectField/SelectField";
import SafetySection from "./SafetySection/SafetySection";
import ProductionSection from "./ProductionSection/ProductionSection";

const AddEditShiftReport = ({
  handleCloseModal,
  shiftReportData = {},
  isModalEdit = false,
}) => {
  const {
    selectedDate,
    setRefreshPage,
    shiftType,
    shiftOptions,
    selectedShift,
    supervisorList,
    loading,
    refreshPage,
    shiftSuperVisor,
  } = useShiftReportContext();

  const initialFormData = {
    shift: selectedShift[shiftType],
    date: moment(selectedDate).format("YYYY-MM-DD"),
    day: moment(selectedDate).format("dddd"),
    shiftType: shiftType == 'day' ? "Day" : "Night",
    shiftSupervisor: shiftReportData?.shiftSupervisor?.value
      ? shiftReportData?.shiftSupervisor?.value
      : shiftSuperVisor[shiftType],
    safety: {
      injuryFreeDays: shiftReportData?.safety?.injuryFreeDays || "",
      previousBest: shiftReportData?.safety?.previousBest || "",
      incidents: shiftReportData?.safety?.incidents || "",
      communicationTopics: shiftReportData?.safety?.communicationTopics || "",
      trafficChanges: shiftReportData?.safety?.trafficChanges || "",
      barricades: shiftReportData?.safety?.barricades || "",
      safeShift: shiftReportData?.safety?.safeShift || true,
      safetyRanking: shiftReportData?.safety?.safetyRanking || "Green",
      exclusionZones: shiftReportData?.safety?.exclusionZones || "",
      hazard: shiftReportData?.safety?.hazard || "",
      crewIssues: shiftReportData?.safety?.crewIssues || "",
      trapLevelChanges: shiftReportData?.safety?.trapLevelChanges || "",
    },
    production: {
      shift: shiftReportData?.production?.shift || "",
      otherComments: shiftReportData?.production?.otherComments || "",
      maintenanceIssues: shiftReportData?.production?.maintenanceIssues || "",
      environment: shiftReportData?.production?.environment || "",
      geoTechnicalIssues: shiftReportData?.production?.geoTechnicalIssues || "",
      recordedBy: shiftReportData?.production?.recordedBy || "",
      reviewedByFirst: shiftReportData?.production?.reviewedByFirst || "",
      reviewedBySecond: shiftReportData?.production?.reviewedBySecond || "",
      recordedByDate: moment(
        shiftReportData?.production?.recordedByDate
      ).format("YYYY-MM-DD"),
      shiftSupervisorDate: moment(
        shiftReportData?.production?.shiftSupervisorDate
      ).format("YYYY-MM-DD"),
      shiftSupervisor: shiftReportData?.production?.shiftSupervisor || "",
      oncomingShiftSupervisor:
        shiftReportData?.production?.oncomingShiftSupervisor || "",
      mineManager: shiftReportData?.production?.mineManager || "",
      commences: shiftReportData?.production?.commences || "",
    },
  };

  const [formData, setFormData] = useState(initialFormData);
  const [showError, setShowError] = useState(false);

  const handleChange = (field, value) => {
    setFormData((prevState) => {
      // Check if the field is within the `safety` object
      if (field.startsWith("safety.")) {
        const safetyField = field.split(".")[1];
        return {
          ...prevState,
          safety: {
            ...prevState.safety,
            [safetyField]: value,
          },
        };
      }
      // Check if the field is within the `production` object
      else if (field.startsWith("production.")) {
        const productionField = field.split(".")[1];
        return {
          ...prevState,
          production: {
            ...prevState.production,
            [productionField]: value,
          },
        };
      }
      // Handle top-level fields
      return {
        ...prevState,
        [field]: value,
      };
    });
  };

  // const validateSafetyData = (safety) => {
  //   for (const key in safety) {
  //     if (
  //       safety[key] === undefined ||
  //       safety[key] === null ||
  //       safety[key] === ""
  //     ) {
  //       return false;
  //     }
  //   }
  //   return true;
  // };

  const validateData = (data) => {

    const isFormValid = (
      data.date &&
      data.day &&
      data.shiftType &&
      data.shiftSupervisor
    ) ? true : false;

    if (!isFormValid) {
      return false;
    }
    return true
  }

  const saveDailyReport = async () => {
    //validation safety data
    if (!validateData(formData)) {
      setShowError(true);
      return;
    }
    if (!isModalEdit) {
      await addReport();
    } else {
      await editReport();
    }
  };

  const addReport = async () => {
    try {
      //  const formattedData = {
      //       ...formateDataForSaving({ ...formData })
      //     };
      const response = await addDailyReport(formData);

      if (response) {
        showToast(true, "Daily shift report added successfully!");
        handleCloseModal();
        setFormData(initialFormData);
        setRefreshPage(!refreshPage);
      } else {
        throw new Error("Failed to add shift daily report");
      }
    } catch (error) {
      showToast(
        false,
        "An error occurred while adding shift daily report. Please try again."
      );
    }
  };

  const editReport = async () => {
    try {
      const reqBody = {
        dailyReportId: shiftReportData?.id,
        day: moment(selectedDate).format("dddd"),
        safety: formData.safety,
        production: formData.production,
      };

      // const formattedData = {
      //   ...formateDataForSaving({ ...reqBody })
      // };
      // const reqBody = formattedData;
      const response = await editDailyReport(reqBody);

      if (response) {
        showToast(true, "Daily shift report edited successfully!");
        handleCloseModal();
        setFormData(initialFormData);
        setRefreshPage(!refreshPage);
      } else {
        throw new Error("Failed to edit shift daily report");
      }
    } catch (error) {
      showToast(
        false,
        "An error occurred while editing shift daily report. Please try again."
      );
    }
  };

  return (
    <>
      <div>
        <div id="reportIcon" className={`${styles.iconContainer}`}>
          <div
            className={`${styles.iconButton} ${styles.save}`}
            style={{ background: "#3880F5" }}
            onClick={saveDailyReport}
          >
            Save
          </div>
          <div className={styles.iconButton} onClick={handleCloseModal}>
            <img className={styles.reportTopIcon} src={close} alt="preview" />
          </div>
        </div>

        <div
          id="add-report"
          className={`modal fade show ${styles.modalTop}`}
          tabIndex="-1"
          role="dialog"
          style={{ zIndex: "9998" }}
        >
          <div
            id="modalDialogTop"
            className={`modal-dialog modal-dialog-centered ${styles.modalTopArea}`}
            role="document"
          >
            <div className={`modal-content px-5 ${styles.modalContent}`}>
              <div
                className={`modal-header d-flex align-items-center justify-content-between ${styles.modalHeader}`}
              >
                {/* <div className={styles.alias}>{ShiftReportHeader.HEADER}</div> */}
                <div className={styles.headerTitle}>
                  Daily Shift Report Form{" "}
                  <span
                    className={styles.reportNo}
                  >{`(#${shiftOptions[shiftType]?.[0]?.label})`}</span>
                </div>
                <div>
                  <img src={bathurstResource} alt="logo" />
                </div>
              </div>
              <div className={`modal-body p-0 pt-3 pb-5 ${styles.modalBody}`}>
                <div>
                  <div
                    className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    <div style={{ width: "25%" }}>Date</div>
                    <div style={{ width: "25%" }}>Day</div>
                    <div style={{ width: "25%" }}>Shift Type</div>
                    <div style={{ width: "25%" }}>Shift Supervisor</div>
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div style={{ width: "25%" }}>
                      {" "}
                      <TextInputField
                        label=""
                        required={true}
                        placeholder="DD/MM/YYYY"
                        isDisabled={true}
                        style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                          paddingLeft: "8px",
                        }}
                        value={formData.date}
                        onChange={(v) =>
                          handleChange("date", moment(v).format("YYYY-MM-DD"))
                        }
                      />
                    </div>
                    <div style={{ width: "25%" }}>
                      {" "}
                      <TextInputField
                        label=""
                        required={true}
                        placeholder="Day"
                        viewOnly={false}
                        isDisabled={true}
                        style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                          paddingLeft: "8px",
                        }}
                        value={formData.day}
                        onChange={(v) => handleChange("day", v)}
                      />
                    </div>
                    <div style={{ width: "25%", paddingBottom: "10px" }}>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="day"
                          id="day"
                          value="day"
                          checked={formData.shiftType === "Day"}
                          disabled={true}
                          style={{ width: "16px", height: "16px" }}
                          onChange={(v) => handleChange("shiftType", v)}
                        />
                        <div
                          className={`form-check-label ${styles.dayLabel}`}
                          htmlFor="day"
                        >
                          Day
                        </div>
                      </div>
                      <div class="form-check form-check-inline">
                        <input
                          class="form-check-input"
                          type="radio"
                          name="night"
                          id="night"
                          value="night"
                          checked={formData.shiftType === "Night"}
                          onChange={(v) => handleChange("shiftType", v)}
                          disabled={true}
                          style={{ width: "16px", height: "16px" }}
                        />
                        <div
                          className={`form-check-label ${styles.dayLabel}`}
                          htmlFor="night"
                        >
                          Night
                        </div>
                      </div>
                    </div>
                    <div style={{ width: "25%" }}>
                      <SelectField
                        noPlaceHolder={true}
                        options={supervisorList?.map((supervisor) => ({
                          value: supervisor._id,
                          label: `${supervisor.firstName} ${supervisor.lastName || ""
                            }`.trim(),
                        }))}
                        value={formData.shiftSupervisor}
                        className=""
                        isDisabled={
                          !shiftOptions?.[shiftType]?.length || loading
                        }
                        style={getSelectFieldStyle(
                          loading,
                          shiftOptions?.[shiftType]
                        )}
                        onChange={(v) => {
                          handleChange("shiftSupervisor", v);
                        }}
                        mainContainerStyle={{
                          marginBottom: "0px",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <SafetySection
                  showError={showError}
                  formData={formData}
                  handleChange={handleChange}
                />

                <ProductionSection
                  formData={formData}
                  handleChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const getSelectFieldStyle = (isLoading, options) => ({
  width: "220px",
  height: "35px",
  marginTop: 2.5,
  border: "1px solid #EBEBEB",
  borderRadius: "4px",
  paddingLeft: "10px",
  font: !options?.length
    ? "normal normal normal 11px/14px Open Sans"
    : "normal normal normal 9px/13px Open Sans",
  color: !options?.length ? "#888888" : "#050505",
  opacity: 1,
  cursor: !options?.length || isLoading ? "not-allowed" : "pointer",
});

export default AddEditShiftReport;
