
import Flyer from "pages/Fleet/Component/Flyers/Flyer";
import { FLEET_FLYER } from "pages/Fleet/utils/Constant";
import ViewDetailsComponent from "./ViewDetailsComponent";

const ViewDetails = ({ setOpenFlyer, data, openFlyer, openUpdateAssetStatusModal }) => {
  return (
    <>
      {openFlyer === FLEET_FLYER.VIEW_DETAILS && (
        <Flyer
          isOpen={openFlyer === FLEET_FLYER.VIEW_DETAILS}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={`${data?.equipment?.label} - Details`}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 10.2rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}
          tabs={[
            {
              title: "",
              leftActionButtons: [
                {
                  label: "Update Asset Status",
                  onClick: () => openUpdateAssetStatusModal(data),
                  style: {
                    background: "#FFFFFF",
                    border: "1px solid #E1E8F4",
                    borderRadius: "15px",
                    font: "normal normal bold 12px/17px Open Sans",
                    color: "#186FD9",
                  },
                },
              ],

              component: <ViewDetailsComponent data={data} />,
            },
          ]}
        />
      )}
    </>
  );
};

export default ViewDetails;
