import React from "react";
import { UtilisationTabs } from "../helpers";

const Tab = ({ isSelected, label, onClick }) => {
  return (
    <div
      className="ml-4"
      style={{
        paddingBottom: "4px",
        borderBottom: isSelected
          ? "1.75px solid #386DFF"
          : "1.75px solid transparent",
        cursor: "pointer",
        font: "normal normal 600 14px/19px Open Sans"
      }}
      onClick={onClick}
    >
      <p
        style={{
          fontFamily: "Open Sans",
          fontSize: "14px",
          color: isSelected ? "#386DFF" : "#707070",
        }}
      >
        {label}
      </p>
    </div>
  );
};

const Tabs = ({ selectedTab, onTabChange }) => {
  const TABS = [
    {
      label: "Operational Utilisation",
      id: UtilisationTabs.OperationalUtilisation,
    },
    {
      label: "Spry Calendar Availability",
      id: UtilisationTabs.SpryCalendarAvailability,
    },
    {
      label: "Spry Calendar Utilisation",
      id: UtilisationTabs.SpryCalendarUtilisation,
    },
  ];
  return (
    <div
      className="flex-grow"
      style={{ borderBottom: "1px solid #F2F2F2", flexGrow: 1 }}
    >
      <div
        className="d-flex align-items-center justify-content-start"
        style={{ gap: "12px" }}
      >
        {TABS.map((e, idx) => (
          <Tab
            isSelected={selectedTab === e.id}
            label={e.label}
            onClick={() => onTabChange(e.id)}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
};

export default Tabs;
