import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { formatDate } from "../Table/helpers";

export const generatePdf = (mainContainer, truck) => {
  const input = mainContainer.current;
  html2canvas(input).then((canvas) => {
    const pdf = new jsPDF({
      unit: "px",
      format: [canvas.width, Math.max(canvas.height, 1800)],
    });
    const imgData = canvas.toDataURL("image/png");

    pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
    pdf.save("Dump_Cycle_Tracker_Data " + truck?.truckName + ".pdf");
  });
};

function getHourMinuteDifference(start, end) {
  const diffInMs = Math.abs(end - start);
  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  const formattedTime = `${hours.toString().padStart(2, "0")}: ${minutes
    .toString()
    .padStart(2, "0")} hrs`;
  return formattedTime;
}

export const getCSVData = (truck) => {
  let index = 1;

  return truck
    ? truck.loads?.map((load) => ({
        Load: `Load ${index++}`,
        "Cycle Duration": `${formatDate(load?.startTime)} - ${formatDate(
          load?.endTime,
          "00:00 AM"
        )} (${
          load?.startTime && load?.endTime
            ? getHourMinuteDifference(
                new Date(load?.startTime),
                new Date(load?.endTime)
              )
            : "00:00 hrs"
        })`,
        Source: load.sourceArea ? `Area ${load.sourceArea}` : "-",
        Excavator: load.excavatorName || "-",
        Material: load.material || "-",
        Dump: load.dumpArea || "-",
        Operator: load.operatorName || "-",
      }))
    : [];
};
