import { FaChevronRight } from "react-icons/fa";
import styles from './Collapsible.module.css'
import { useEffect, useState } from "react";

const Collapsible = ({ title, content, isOpen, toggle, isDisabled = false }) => {

    const [showContent, setShowContent] = useState(isOpen);

    useEffect(() => {
        setShowContent(isOpen);
    }, [isOpen]);

    const toggleContent = () => {
        setShowContent(prev => !prev);
    };

    return (
        <div className={`pr-2 mb-1 ${styles.waterfallModal} `}>
            <div onClick={toggleContent} style={isDisabled ? { backgroundColor: "#bcbcbc" } : {}} className={`d-flex align-items-center ${styles.trigger} ${showContent ? styles.showContent : ""}`}>
                <div style={{ userSelect: 'none' }} className="d-flex align-items-center w-100">
                    {title}
                </div>
                <FaChevronRight size={12} style={{ transition: "transform 0.5s ease", transform: `rotateZ(${showContent ? 90 : 0}deg)` }} />
            </div>
            <div className={`${!showContent ? "d-none" : "px-3 mb-2 mt-4"}`}>{content}</div>
        </div>
    );
};

export default Collapsible;