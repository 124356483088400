
import styles from "../RadioButton/RadioButton.module.css"
const RadioButton = ({radioLabel, options = [], value, onChange, isDisabled = false, id, radioLabelStyle={}, styleClass="" , optionMainContainer={},optionStyle={} }) => {
  return (
    <div className={`d-flex ${styleClass}`}>
      <div className="form-check form-check-inline">
        <div className={`form-check-label ${styles.radioLabel}`} htmlFor={id} style={{...radioLabelStyle}}>
         {radioLabel}
        </div>
      </div>
      <div className="d-flex" style={{...optionMainContainer}}>

     
      {options.map((option) => (
        <div className="pl-2 form-check form-check-inline" key={option.value}>
          <input
            className={`form-check-input ${styles.radioInput} `}
            type="radio"
            value={option.value}
            checked={value === option.value}
            name={id}
            disabled={isDisabled}
            onChange={() => onChange(option.value)}
          />
          <div className={`form-check-label ${styles.optionLabel}`} htmlFor={`${id}-${option.value}`} style={{...optionStyle}}>
            {option.label}
          </div>
        </div>
      ))}
      </div>
    </div>
  );
};

export default RadioButton;
