import TextInputField from "pages/ShiftMaintenance/Component/TextInputField";
import TextAreaField from "pages/ShiftMaintenance/Component/GeneralFields/TextAreaField";
import DynamicSelectField from "pages/ShiftMaintenance/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import { jobStatus } from "pages/ShiftMaintenance/utils/DropDownMasters";

const RejectJobComponent = ({ formData, setFormData }) => {

  const handleOnChange = (key, val) => {
    const tempFormData = { ...formData };
    tempFormData[key] = val;
    setFormData(tempFormData);
  };

  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  return (
    <div className="px-5">
    <DynamicSelectField
                  label={"Asset ID"}
                  placeholder={""}
                  options={jobStatus}
                  value={formData?.equipment}
                  // onChange={(val) => handleOnChange("generalInfo.jobStatus", val)}
                  style={commonFieldStyles}
                  labelStyle={labelStyle}
                  valueStyle={valueStyle}
                  wrapperStyle={{ marginTop: "10px"  }}
                  isDisabled={true}
                />

      <TextInputField
        label="Job Ref No"
        required={true}
        placeholder=""
        style={{
          background: "#E1E4EA",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          width: "350px",
          height: "40px",
          font: "normal normal 600 14px/19px Open Sans",
          color: "#5F6672",
          opacity: 1,
        }}
        isDisabled={true}
        value={formData.refNo}
        // onChange={(v) => handleChange("jobRefNo", v)}
      />
      <TextInputField
        label="Issue/Job Type"
        required={true}
        placeholder=""
        style={{
          background: "#E1E4EA",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          width: "350px",
          height: "40px",
          font: "normal normal 600 14px/19px Open Sans",
          color: "#5F6672",
          opacity: 1,
        }}
        isDisabled={true}
        dotIcon={true}
        value={formData.issueType}
        // onChange={(v) => handleChange("jobType", v)}
      />

      <div style={{}} className="d-flex align-content-center pt-4">
        <div
          className="mb-2"
          style={{
            font: "normal normal normal 14px/17px Open Sans",
            color: "#1A1A1A",
            width: "20%",
          }}
        >
          Reason for Rejection
        </div>
        <TextAreaField
          wrapperStyle={{ paddingLeft: "83px",flexGrow:"1" }}
          style={{
            height: "100%",
            border: "1px solid #E1E8F4",
            borderRadius: "4px",
          }}
          value={formData.notesAndComments}
          onChange={(v) => handleOnChange("notesAndComments", v)}
        />
      </div>
    </div>
  );
};

export default RejectJobComponent;
