import { useState } from "react";
import styles from "../Archived.module.css";
import RadioButton from "pages/ShiftMaintenance/Component/RadioButton/RadioButton";
import {
  ARCHIVED_FLYER,
  archivedSortOptions,
  sortOptions,
} from "pages/ShiftMaintenance/utils/Constant";
import Search from "pages/ShiftMaintenance/Component/GeneralFields/Search/Search";
import filterIcon from "../../../../../assets/images/filter.png";
import download from "../../../../../assets/svgs/shift-maintenance/download.svg";
import Filter from "../Filter/Filter";
import { useArchivedContext } from "../Context/Context";

const Header = () => {

  const {
    searchQuery,
    sortBy,
    setSortBy,
    openFlyer,
    setOpenFlyer,
    searchArchivedData
  } = useArchivedContext();

  const handleFilter = () => {
    setOpenFlyer(ARCHIVED_FLYER.FILTER);
  };

  return (
    <>
      <div className="mx-5 py-4 border-bottom d-flex align-items-center justify-content-between">
        <RadioButton
          radioLabel={"Sort By:"}
          options={archivedSortOptions}
          value={sortBy}
          onChange={(v) => {
            setSortBy(v);
          }}
          isDisabled={false}
          optionStyle={{ marginLeft: "2px" }}
          id="sortOptions_archived"
        />

        <div
          className="d-flex justify-content-between align-items-center"
          style={{ gap: "10px" }}
        >
          <Search
            query={searchQuery}
            onChange={(value) => searchArchivedData(value)}
          />

          <div
            style={{
              userSelect: "none",
              backgroundColor:
                openFlyer === ARCHIVED_FLYER.FILTER
                  ? // ||
                  // filters.noteType ||
                  // filters.equipments.length > 0 ||
                  // filters.startTime ||
                  // filters.endTime
                  "#E2ECFC"
                  : "transparent",
            }}
            className={styles.filterBtn}
            onClick={() => handleFilter()}
          >
            <img src={filterIcon} alt="filter" />
            <span>Filter</span>
          </div>

          {openFlyer === ARCHIVED_FLYER.FILTER && (
            <Filter openFlyer={openFlyer} setOpenFlyer={setOpenFlyer} />
          )}

          <img className={styles.downloadIcon} src={download} alt="download" />
        </div>
      </div>
    </>
  );
};

export default Header;
