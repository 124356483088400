export const sortOptions = [
  { value: "issueType", label: "Issue Type" },
  { value: "assetStatus", label: "Asset Status" },
  { value: "reportedOn", label: "Reported On" },
];

export const archivedSortOptions = [
  { value: "jobStatus", label: "Job Status" },
  { value: "issueType", label: "Issue Type" },
  { value: "archivedOn", label: "Archived On" },
];

export const approvedSortOptions = [
  { value: "jobStatus", label: "Job Status" },
  { value: "issueType", label: "Issue Type" },
  { value: "addedOn", label: "Added On" },
];

export const RequestedHeader = {
  JOBS_REFS: "Job Refs No",
  ISSUE_REPORTED_ON: "Issue Reported On",
  ASSET_ID: "Asset ID",
  JOB_TYPE: "Issue/Job Type",
  DELAY_DURATION: "Delay Duration(hr)",
  REPORTED_BY: "Reported By",
  PRE_START_REPORT: "Pre-Start Report",
  SHIFT: "Shift",
  ASSET_STATUS: "Asset Status",
  ASSET_ACTIVITY: "Asset Activity",
  ACTIONS: "Actions",
};

export const ArchivedHeader = {
  JOBS_REFS: "Job Refs No",
  ARCHIVED_ON: "Archived On",
  ASSET_ID: "Asset ID",
  JOB_TYPE: "Issue/Job Type",
  JOB_STATUS: "Job Status",
  ISSUE_STATUS: "Issue Status",
  MECHANIC: "Mechanic",
  DELAY_DURATION: "Delay Duration(hr)",
  JOB_DURATION: "Job Duration (hr)",
  REVIEWED_BY: "Reviewed By",
  ACTIONS: "Actions",
};

export const ApprovedHeader = {
  JOBS_REFS: "Job Refs No",
  ADDED_ON: "Approved/Added On",
  ASSET_ID: "Asset ID",
  JOB_STATUS: "Job Status",
  JOB_TYPE: "Issue/Job Type",
  MECHANIC: "Mechanic",
  DELAY_DURATION: "Delay Duration(hr)",
  SCHEDULED_FOR: "Scheduled For",
  ISSUE_STATUS: "Issue Status",
  ASSET_STATUS: "Asset Status",
  ASSET_ACTIVITY: "Asset Activity",
  ACTIONS: "Actions",
};

export const REQUESTED_FLYER = {
  VIEW_DETAILS: "REQUESTED_VIEW_DETAILS",
  JOB_APPROVE: "REQUESTED_JOB_APPROVE",
  JOB_REJECT: "REQUESTED_JOB_REJECT",
  FILTER: "REQUESTED_JOB_FILTER",
};

export const APPROVED_FLYER = {
  ADD_JOB: "APPROVED_ADD_JOB",
  VIEW_DETAILS: "APPROVED_VIEW_DETAILS",
  FILTER: "APPROVED_JOB_FILTER",
  EDIT_JOB: "APPROVED_EDIT_JOB",
};

export const ARCHIVED_FLYER = {
  FILTER: "ARCHIVED_JOB_FILTER",
  VIEW_DETAILS: "ARCHIVED_VIEW_DETAILS",
};

export const ISSUE_TYPE = {
  CAT_A_FAILURE: "Cat A Failure",
  CAT_B_FAILURE: "Cat B Failure",
  CAT_C_FAILURE: "Cat C Failure",
  BREAKDOWN: "Breakdown",
  PLANNED_MAINTENANCE: "Planned Maintenance",
  PASSED: "Passed",
  INCOMPLETE: "Incomplete"
};

export const JOB_STATUS = {
  PENDING: "Pending",
  INPROGRESS: "In Progress",
  PAUSED: "Paused",
  ENDED: "Ended",
  CLOSED: "Closed",
  REJECTED: "Rejected",
  DELETED: "Deleted"
};

export const ASSET_STATUS = {
  OUT_OF_ORDER: "Out of Order",
  OPERABLE: "Operational",
};

export const ASSET_ACTIVITY = {
  ACTIVE: "Active",
  PARKED: "Parked",
  BATTERY_DISCONNECTED: "Battery Disconnected",
  DISCONNECTED: "Disconnected"
};

export const ISSUE_STATUS = {
  RESOLVED: "Resolved",
  UNRESOLVED: "Unresolved",
};

export const RequestedInitialFilters = {
  assetCategory: "",
  date: "",
  shift: "",
  shiftRefNo: "",
  delayType: "",
  issueType: "",
  assetStatus: "",
  assetActivity: "",
};

export const ArchivedInitialFilters = {
  assetCategory: "",
  jobType: { value: "Job Archived", label: "Job Archived" },
  date: "",
  delayType: "",
  issueType: "",
  jobStatus: "",
  issueStatus: "",
};

export const ApprovedInitialFilters = {
  assetCategory: "",
  jobType: { value: "Job Added/Approved", label: "Job Added/Approved" },
  date: "",
  delayType: "",
  issueType: "",
  jobStatus: "",
  issueStatus: "",
  assetStatus: "",
  assetActivity: "",
};

export const DateTimeFormats = {
  DATE_TIME_U: "DD/MM/YYYY, LT",
};

export const EDIT_FORM_SECTION = {
  GENERAL_INFO: "General Info",
  MECHANIC_SUMMARY: "Mechanic's Service Summary",
  SUPERVISOR_SUMMARY: "Maintenance Supervisor's Summary",
  FAILED_CHECK: "Status of Failed Checks",

}

export const FORM_SECTION = {
  GENERAL_INFO: "General Info",
  MECHANIC_SUMMARY: "Mechanic's Service Summary",
  SUPERVISOR_SUMMARY: "Maintenance Supervisor's Summary",
  FAILED_CHECK: "Status of Failed Checks",

}
