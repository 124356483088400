import {
  convertSecondsToTime,
  dotColors,
  formatDateTime2,
} from "pages/Fleet/utils/Helper";
import styles from "../ViewDetails/ViewDetails.module.css";
import { GoDotFill } from "react-icons/go";
import { resultMap } from "pages/Fleet/utils/Constant";
const ViewDetailsComponent = ({ data }) => {
  return (
    <div className="px-5 d-flex flex-column" style={{ gap: "15px" }}>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Asset ID</div>
        <div className={`${styles.viewDetailValue}`}>
          {data?.equipment?.label || "-"}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Asset Status</div>
        <div
          className={styles.viewDetailValue}
          style={{ marginRight: data?.assetStatus ? "21px" : "" }}
        >
          <span>
            {data?.assetStatus && (
              <GoDotFill
                fontSize={19.5}
                style={{
                  color: dotColors(data?.assetStatus),
                  paddingBottom: "2px",
                }}
              />
            )}

            {data?.assetStatus || "-"}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Asset Activity</div>
        <div
          className={styles.viewDetailValue}
          style={{ marginRight: data?.assetActivity ? "21px" : "" }}
        >
          <span>
            {data?.assetActivity && (
              <GoDotFill
                fontSize={19.5}
                style={{
                  color: dotColors(data?.assetActivity),
                  paddingBottom: "2px",
                }}
              />
            )}
            {data?.assetActivity || "-"}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Operator</div>
        <div className={styles.viewDetailValue}>{data?.operator || "-"}</div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Engine Hours (hr)</div>
        <div className={styles.viewDetailValue}>
          {convertSecondsToTime(data?.engineHour)}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>
          Engine Hours Last Updated On
        </div>
        <div className={styles.viewDetailValue}>
          {" "}
          {formatDateTime2(data?.engineHourLastUpdatedAt)}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Pre-Start Result</div>
        <div
          className={styles.viewDetailValue}
          style={{ marginRight: data?.preStartResult ? "21px" : "" }}
        >
          <span>
            {data?.preStartResult && (
              <GoDotFill
                fontSize={19.5}
                style={{
                  color: dotColors(resultMap[data?.preStartResult] || "Unknown"),
                  paddingBottom: "2px",
                }}
              />
            )}
            {resultMap[data?.preStartResult] || "-"}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Pre-Start Report</div>
        <div
          className={styles.viewDetailValue}
          style={{
            color: "#186FD9",
            textDecoration: data?.preStartReport?.refNo ? "underline" : "",
          }}
        >
          {data?.preStartReport?.refNo || "-"}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Shift Assignment</div>
        <div className={styles.viewDetailValue}>
          {data?.shiftAssignment || "-"}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Open Jobs</div>
        <div className={styles.viewDetailValue} style={{textDecoration: data?.openJobs?.length > 0 ? "underline" : "",color:data?.openJobs?.length > 0 ? "#186FD9" : ""}}>
          {data?.openJobs?.length > 0
            ? data?.openJobs?.map((job) => job.refNo).join(", ")
            : "-"}
        </div>
      </div>
      <div className="d-flex align-items-center">
        <div className={styles.viewDetailLabel}>Asset Category</div>
        <div className={styles.viewDetailValue}>
          {data?.assetCategory || "-"}
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsComponent;
