import TextInputField from "pages/ShiftMaintenance/Component/TextInputField";
import RadioButton from "pages/ShiftMaintenance/Component/RadioButton/RadioButton";
import DynamicSelectField from "pages/ShiftMaintenance/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import TextAreaField from "pages/ShiftMaintenance/Component/GeneralFields/TextAreaField";
import DateAndTimeSelect from "pages/ShiftMaintenance/Component/GeneralFields/DateAndTimeSelect/DateAndTimeSelect";
import moment from "moment";
import {
  assetStatus,
  jobStatus,
} from "pages/ShiftMaintenance/utils/DropDownMasters";
import { MdCircle } from "react-icons/md";
import { ISSUE_TYPE } from "pages/ShiftMaintenance/utils/Constant";

const ApproveJobComponent = ({ formData, setFormData }) => {
  const handleOnChange = (key, val) => {
    const tempFormData = { ...formData };
    tempFormData[key] = val;
    setFormData(tempFormData);
  };
  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  const handleDateChange = (v) => {
    const isoDate = moment(v).toISOString();
    handleOnChange("scheduledFor", isoDate);
  };

  return (
    <div className="px-5">
      <DynamicSelectField
        label={"Asset ID"}
        placeholder={""}
        options={jobStatus?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={formData?.equipment}
        // onChange={(val) => handleOnChange("generalInfo.jobStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        wrapperStyle={{ marginTop: "10px" }}
        isDisabled={true}
      />

      <TextInputField
        label="Job Ref No"
        required={true}
        placeholder=""
        style={{
          background: "#E1E4EA",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          width: "350px",
          height: "40px",
          font: "normal normal 600 14px/19px Open Sans",
          color: "#5F6672",
          opacity: 1,
        }}
        isDisabled={true}
        value={formData.refNo}
      // onChange={(v) => handleOnChange("jobRefNo", v)}
      />
      <TextInputField
        label="Issue/Job Type"
        required={true}
        placeholder=""
        style={{
          background: "#E1E4EA",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          width: "350px",
          height: "40px",
          font: "normal normal 600 14px/19px Open Sans",
          color: "#5F6672",
          opacity: 1,
        }}
        dotIcon={true}
        isDisabled={true}
        value={formData.issueType}
      // onChange={(v) => handleOnChange("jobType", v)}
      />

      <RadioButton
        radioLabel={"Asset Status"}
        options={assetStatus}
        value={formData?.assetStatus}
        onChange={(v) => {
          handleOnChange("assetStatus", v);
        }}
        isDisabled={false}
        id="assetStatus"
        radioLabelStyle={{
          font: "normal normal normal 14px/17px Open Sans",
          color: "#1A1A1A",
        }}
        className={"px-5 py-4"}
        optionMainContainer={{
          marginLeft: "102px",
          paddingTop: "5px",
        }}
      />

      <DateAndTimeSelect
        // required
        label="Scheduled For"
        value={formData?.scheduledFor ? new Date(formData?.scheduledFor) : null}
        wrapperStyle={{
          marginBottom: "0rem",
          marginTop: "1.5rem",
        }}
        onChange={handleDateChange}
        isDisabled={formData?.issueType !== ISSUE_TYPE.CAT_C_FAILURE}
        // error={showError && !formData?.startTime}
        // errorMessage="Please select shift start time"
        id="scheduledFor"
      />
      <div style={{}} className="d-flex align-content-center pt-4">
        <div
          className="mb-2"
          style={{
            font: "normal normal normal 14px/17px Open Sans",
            color: "#1A1A1A",
            width: "20%",
          }}
        >
          Notes & Comments
        </div>
        <TextAreaField
          wrapperStyle={{ paddingLeft: "83px", flexGrow: "1" }}
          style={{
            height: "100%",
            border: "1px solid #E1E8F4",
            borderRadius: "4px",
          }}
          value={formData.notesAndComments}
          onChange={(v) => handleOnChange("notesAndComments", v)}
        />
      </div>
    </div>
  );
};

export default ApproveJobComponent;
