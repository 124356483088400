import { MdCircle } from "react-icons/md";

export const PreStartInitialFilters = {
  assetCategory: "",
  preStartStatus: "",
  preStartResult: "",
  jobStatus: "",
  assetActivity: "",
  supervisorReview: "",
  operatorNotification: "",
};

export const PreStartSortOptions = [
  { value: "result", label: "Result" },
  { value: "assetCategory", label: "Asset Category" },
  { value: "preStartStatus", label: "Pre-Start Status" },
];

export const PRE_START_FLYER = {
  VIEW_DETAILS: "PRE_START_VIEW_DETAILS",
  // UPDATE_ASSET_STATUS: "FLEET_UPDATE_STATUS",
  FILTER: "PRE_START_FILTER",
};

export const SHIFT_TYPES = [
  { name: "Day", value: 1 },
  { name: "Night", value: 2 },
];

export const PreStartHeader = {
  ASSET_ID: "Asset ID",
  RESULT: "Result",
  OPERATOR: "Operator",
  PRE_START_REF_NO: "Pre-Start Ref No",
  REVIEWED_BY: "Reviewed by",
  OPERATOR_NOTIFICATION: "Operator’s Notification",
  ENGINE_HOURS: "Engine Hours (hr)",
  ASSET_ACTIVITY: "Asset Activity",
  JOB_REF_NO: "Job Ref/WO No",
  JOB_STATUS: "Job Status",
  ACTIONS: "Actions",
};

export const ASSET_STATUS = {
  OUT_OF_ORDER: "Out of Order",
  OPERABLE: "Operational",
};

export const ISSUE_TYPE = {
  CAT_A_FAILURE: "Cat A Failure",
  CAT_B_FAILURE: "Cat B Failure",
  CAT_C_FAILURE: "Cat C Failure",
  BREAKDOWN: "Breakdown",
  PLANNED_MAINTENANCE: "Planned Maintenance",
  PASSED: "Passed",
};

export const ASSET_ACTIVITY = {
  ACTIVE: "Active",
  PARKED: "Parked",
  BATTERY_DISCONNECTED: "Battery Disconnected",
  DISCONNECTED: "Disconnected",
};

export const CategoryDefect = {
  CATEGORY_A_DEFECT: "Cat A faults",
  CATEGORY_B_DEFECT: "Cat B faults",
  CATEGORY_C_DEFECT: "Cat C faults",
};




export const ExtraNoteDefect = {
  EXTRA_NOTE_DEFECT: "Defects and other notes",
};

export const PreCheckAnswer = {
  OK: "ok",
  DEFECT_FOUND: "Defect found",
  NOT_APPLICABLE: "n/a",
};

export const statusMapColor = {
  "cat a failure": (
    <span>
      <MdCircle
        style={{ marginRight: "4px", marginBottom: "2px" }}
        size={12.5}
        color={"#E03E3E"}
      />
      Cat A Failure
    </span>
  ),
  "cat b failure": (
    <span>
      <MdCircle
        style={{ marginRight: "4px", marginBottom: "2px" }}
        size={12.5}
        color={"#FFBA01"}
      />
      Cat B Failure
    </span>
  ),
  "cat c failure": (
    <span>
      <MdCircle
        style={{ marginRight: "4px", marginBottom: "2px" }}
        size={12.5}
        color={"#6C35E6"}
      />
      Cat C Failure
    </span>
  ),
  passed: (
    <span>
      <MdCircle
        style={{ marginRight: "4px", marginBottom: "2px" }}
        size={12.5}
        color={"#06AF7B"}
      />
      Passed
    </span>
  ),
};
export const statusMap = {
  "cat a failure": "Cat A Failure",
  "cat b failure": "Cat B Failure",
  "cat c failure": "Cat C Failure",
  "incomplete": "Incomplete",
  passed: "Passed",
};

