
import { jobStatus, operatorNotification, preStartResult, preStartStatus,assetActivity } from "pages/Pre-StartCheck/utils/DropDownMasters";
import DynamicSelectField from "pages/ShiftMaintenance/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import { MdCircle } from "react-icons/md";


const FilterComponent = ({ filters, onChange  }) => {
  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  return (
    <div className="px-5">
      <DynamicSelectField
        label={"Asset Category"}
        placeholder={"Asset Category"}
        options={[
          { value: "Excavator", label: "Excavator" },
          { value: "Truck", label: "Haul Trucks" },
        ]}
        value={filters.assetCategory}
        onChange={(val) => onChange("assetCategory", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />

<DynamicSelectField
        label={"Pre-Start Status"}
        placeholder={"Pre-Start Status"}
        options={preStartStatus}
        value={filters.preStartStatus}
        onChange={(val) => onChange("preStartStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
      
      <DynamicSelectField
        isSearchable={true}
        label={"Pre-Start Result"}
        placeholder={"Pre-Start Result"}
        options={
          preStartResult?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={filters?.preStartResult}
        onChange={(val) => onChange("preStartResult", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
           
           <DynamicSelectField
        isSearchable={true}
        label={"Job Status"}
        placeholder={"Job Status"}
        options={
          jobStatus?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))
        }
        value={filters?.jobStatus}
        onChange={(val) => onChange("jobStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />

   
      <DynamicSelectField
        isSearchable={true}
        label={"Asset Activity"}
        placeholder={"Asset Activity"}
        options={
          assetActivity?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={filters?.assetActivity}
        onChange={(val) => onChange("assetActivity", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />

       
<DynamicSelectField
        isSearchable={true}
        label={"Supervisor’s Review"}
        placeholder={"Supervisor’s Review"}
        options={preStartStatus}
        value={filters?.supervisorReview}
        onChange={(val) => onChange("supervisorReview", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />

      <DynamicSelectField
        isSearchable={true}
        label={"Operator’s Notification"}
        placeholder={"Operator’s Notification"}
        options={
          operatorNotification?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={filters?.operatorNotification}
        onChange={(val) => onChange("operatorNotification", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
    </div>
  );
};

export default FilterComponent;
