import Flyer from "pages/ShiftMaintenance/Component/Flyers/Flyer";
import { APPROVED_FLYER } from "pages/ShiftMaintenance/utils/Constant";
import { useEffect, useState } from "react";
import EditJobComponent from "./EditJobComponent";
import { useApprovedContext } from "../Context/Context";
import { EditJobData, JobViewDetails } from "pages/ShiftMaintenance/utils/ApiServices";
import { bindInitialFormData, formateDataForSaving, showToast } from "pages/ShiftMaintenance/utils/helper";

const EditJob = ({
  jobId,
  editFormData,
  setEditFormData,
  handleOnChange,
  handleEditJobData,
  handleAddNotesSignature
}) => {
  const { openFlyer, setOpenFlyer } = useApprovedContext();
  const [isAllExpanded, setIsAllExpanded] = useState(false);
  const [loading, setLoading] = useState(false)
  const [viewDetailsData, setViewDetailsData] = useState({});

  const handleExpandAll = () => {
    setIsAllExpanded(!isAllExpanded);
  };

  useEffect(() => {
    const fetchViewDetails = async () => {
      setLoading(true)
      const viewDetailResponse = await JobViewDetails(jobId);
      if (viewDetailResponse) {
        setViewDetailsData(viewDetailResponse);
        const bindFormData = bindInitialFormData(viewDetailResponse)
        setEditFormData(bindFormData)
      }
      setLoading(false)
    };

    fetchViewDetails();
  }, [jobId]);


  return (
    <>
      {openFlyer === APPROVED_FLYER.EDIT_JOB && (
        <Flyer
          isOpen={openFlyer === APPROVED_FLYER.EDIT_JOB}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={"Edit Job"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 9.25rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}
          tabs={[
            {
              title: "",
              leftActionButtons: [
                {
                  label: isAllExpanded ? "Collapse All" : "Expand All",
                  onClick: async () => handleExpandAll(),
                  style: {
                    background: "#FFFFFF",
                    border: "1px solid #E1E8F4",
                    borderRadius: "15px",
                    font: "normal normal bold 12px/17px Open Sans",
                    color: "#186FD9",
                  },
                },
              ],
              title: "",
              rightActionButtons: [
                {
                  label: "Save Changes",
                  onClick: async () => handleEditJobData(viewDetailsData?.jobId),
                },
              ],
              component: (
                <EditJobComponent
                  isLoading={loading}
                  isAllExpanded={isAllExpanded}
                  editFormData={editFormData}
                  setEditFormData={setEditFormData}
                  detailData={viewDetailsData}
                  handleOnChange={handleOnChange}
                  handleAddNotesSignature={handleAddNotesSignature}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default EditJob;
