import Styles from "../Header/Header.module.css";
import DateTimeSelect from "pages/ShiftNotes/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import ToggleButtons from "pages/ShiftNotes/Component/ToggleButton/ToggleButton";
import Search from "pages/ShiftNotes/Component/GeneralFields/Search/Search";
import ActionMenu from "pages/ShiftNotes/Component/ActionMenu/ActionMenu";
import { useRef, useState } from "react";

import { LuHistory } from "react-icons/lu";
import { LiaDownloadSolid } from "react-icons/lia";
import { useShiftReportContext } from "../Context/Context";
import History from "../History/History";
import SelectShift from "../SelectShift/SelectShift";
import AddEditShiftReport from "../AddEditShiftReport/AddEditShiftReport";
import moment from "moment";
import { CSVLink } from "react-csv";

const SHIFT_TYPES = [
  { name: "Day", value: 1 },
  { name: "Night", value: 2 },
];

const Header = () => {
  const {
    selectedDate,
    setSelectedDate,
    shiftType,
    setShiftType,
    searchQuery,
    searchShiftReportData,
    shiftReportData,
    loading,
    shiftOptions,
    filteredResults,
  } = useShiftReportContext();

  const [openAddReport, setOpenAddReport] = useState(false);
  const [openSelectShift, setOpenSelectShift] = useState(false);

  const [historyData, setHistoryData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);

  const handleOpenSelectShiftModal = () => setOpenSelectShift(true);
  const handleCloseSelectShiftModal = () => setOpenSelectShift(false);

  const handleOnConfirmShift = () => {
    handleCloseSelectShiftModal();
    setOpenAddReport(true);
  };
  const handleCloseAddShiftReport = () => setOpenAddReport(false);

  const [csvData, setCSVData] = useState([]);

  const csvLinkRef = useRef(null); // Create a reference for the CSVLink

  const handleDownloadCSV = () => {
    const data = getCSVData(filteredResults);
    if (data.length === 0) return;

    setCSVData(data);
    // Trigger the download after the state has been updated
    setTimeout(() => {
      csvLinkRef.current.link.click();
    }, 0);
  };

  const getCSVData = (data) =>
    data.map((report) => ({
      shiftRefNumber: report?.reportNo, //report.refNumber,
      addedOn: moment(report.createdAt)
        .tz("Pacific/Auckland")
        .format("hh:mm A, DD/MM/YYYY"),
      shiftType: report.shiftType,
      createdBy: report.reportedBy,
    }));

  const optionMenu = [
    {
      name: "View History",
      icon: <LuHistory color={"#386DFF"} size={14} />,
      onClick: () =>
        handleHistory(
          shiftReportData?.history?.map((hist) => ({
            updatedAt: hist.actionDate,
            updatedBy: hist.actionBy,
            changes: [
              {
                key: `Daily shift report ${hist?.action} ${hist?.reportNo ? `(#${hist?.reportNo})` : ""
                  }`,
                to: "",
              },
            ],
          }))
        ),
    },
    {
      name: "Download Reports",
      icon: <LiaDownloadSolid color={"#386DFF"} size={16} />,
      onClick: () => handleDownloadCSV(),
    },
  ];

  const handleHistory = (data) => {
    setHistoryData(data);
    setShowHistory(true);
  };

  return (
    <div
      // style={{ paddingTop: 30 }}
      className={`d-flex justify-content-between align-items-center pt-5 mx-5 pb-3 ${Styles.header} border-bottom`}
    >
      <div className="d-flex justify-content-between align-items-center">
        <CSVLink
          ref={csvLinkRef}
          data={csvData}
          filename="Daily-Shift-Report.csv"
          style={{ display: "none" }}
        />

        <div
          className={`px-3 py-2 ${Styles.addShiftReportBtn} ${loading || !shiftOptions?.[shiftType]?.length ? Styles.disabled : ""
            }`}
          onClick={handleOpenSelectShiftModal}
        >
          Add Daily Shift Report
        </div>
        {openSelectShift && (
          <SelectShift
            closeSelectShift={handleCloseSelectShiftModal}
            onConfirmShift={handleOnConfirmShift}
          />
        )}
        {openAddReport && (
          <AddEditShiftReport handleCloseModal={handleCloseAddShiftReport} />
        )}

        <DateTimeSelect
          value={selectedDate}
          onDateChange={(date) => {
            setSelectedDate(date);
          }}
          className={`${Styles.dateTimeSelect} ml-3`}
          iconColor="#6B7B8E"
          containerStyle={{
            border: "1px solid #E1E8F4",
          }}
        />

        <ToggleButtons
          selectedValue={shiftType === "day" ? 1 : 2}
          onOptionClick={(v) => {
            setShiftType(v === 1 ? "day" : "night");
          }}
          style={{ height: 27.5 }}
          options={SHIFT_TYPES}
        />
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <Search
          query={searchQuery}
          onChange={(value) => searchShiftReportData(value)}
        />

        <ActionMenu
          options={optionMenu}
          optionsContainerStyle={{
            right: "55.5%",
            borderRadius: "15px",
            width: "159px",
            height: "77px",
            paddingTop: "6px",
            top: "20px",
          }}
          optionStyle={{
            font: "normal normal normal 11px/15px Open Sans",
            color: "#313131",
          }}
          className="ml-3"
        />
        {showHistory && (
          <History
            handleOnClose={() => setShowHistory(false)}
            historyData={historyData}
          />
        )}
      </div>
    </div>
  );
};

export default Header;
