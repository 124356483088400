import {
  calculateTimeDifference,
  dotColors,
  formatDateTime2,
  getFailedChecks,
} from "pages/ShiftMaintenance/utils/helper";
import styles from "../ViewDetails.module.css";
import { JOB_STATUS } from "pages/ShiftMaintenance/utils/Constant";
import { GoDotFill } from "react-icons/go";

const LabelValue = ({ label, value, dotColor, formatValue }) => (
  <div className="d-flex align-items-start justify-content-between">
    <div className={styles.label}>{label}</div>
    <div className={styles.value} style={{ marginRight: dotColor ? "12px" : "" }}>
      {dotColor && (
        <GoDotFill
          fontSize={19.5}
          style={{ color: dotColor, paddingBottom: "4px" }}
        />
      )}
      {formatValue ? formatValue(value) : value || "-"}
    </div>
  </div>
);

const GeneralInfo = ({ data }) => {
  const { generalInfo, preStartCheckInfo } = data || {};
  const {
    equipment,
    refNo,
    jobStatus,
    issueType,
    assetStatus,
    assetActivity,
    scheduledFor,
    delay,
    preStartCheck,
    shift,
    equipmentType,
    reportedBy,
    reportedAt,
    rejectedBy,
    rejectedAt,
    deletedBy,
    deletedAt,
    approvedBy,
    approvedAt,
    addedBy,
    addedAt
  } = generalInfo || {};

  return (
    <div className="d-flex flex-column" style={{ gap: 16 }}>
      <LabelValue label="Asset ID" value={equipment?.label} />
      <LabelValue label="Job Ref No" value={refNo} />
      <LabelValue
        label="Job Status"
        value={jobStatus}
        dotColor={dotColors(jobStatus)}
      />
      <LabelValue
        label="Issue/Job Type"
        value={issueType}
        dotColor={dotColors(issueType)}
      />
      <LabelValue
        label="Failed Checks"
        value={
          preStartCheckInfo?.preStartCheck
            ? getFailedChecks(preStartCheckInfo?.failedCheckPoints)
            : "-"
        }
      />
      <LabelValue
        label="Asset Status"
        value={assetStatus}
        dotColor={dotColors(assetStatus)}
      />
      <LabelValue
        label="Asset Activity"
        value={assetActivity}
        dotColor={dotColors(assetActivity)}
      />
      <LabelValue
        label="Scheduled For"
        value={scheduledFor}
        formatValue={formatDateTime2}
      />
      <LabelValue
        label="Delay Duration"
        value={
          delay?.delayType
            ? `${delay?.delayType} - ${calculateTimeDifference(
              delay?.startTime,
              delay?.endTime
            )}`
            : "-"
        }
      />
      <LabelValue
        label="Pre-Start Report"
        value={preStartCheck?.refNo}
      />
      <LabelValue
        label="Shift"
        value={shift?.reportNo ? `${shift?.reportNo} ${shift?.shiftType}` : "-"}
      />
      <LabelValue label="Asset Category" value={equipmentType} />
      <LabelValue
        label="Issue Reported By"
        value={
          reportedBy
            ? `${reportedBy?.firstName || ""} ${reportedBy?.lastName || ""
            }, ${formatDateTime2(reportedAt) || ""}`
            : `${formatDateTime2(reportedAt) || ""}`
        }
      />
      <LabelValue
        label={
          jobStatus === JOB_STATUS.REJECTED
            ? "Rejected By"
            : "Approved/Added By"
        }
        value={
          jobStatus === JOB_STATUS.REJECTED
            ? `${rejectedBy?.firstName || ""} ${rejectedBy?.lastName || ""
            }, ${formatDateTime2(rejectedAt)}`
            : (addedBy?.firstName ? `${addedBy?.firstName || ""} ${addedBy?.lastName || ""
              }, ${formatDateTime2(addedAt)}` : `${approvedBy?.firstName || ""} ${approvedBy?.lastName || ""
              }, ${formatDateTime2(approvedAt)}`)
        }
      />
      {jobStatus === JOB_STATUS.DELETED && <LabelValue
        label={"Deleted By"}
        value={
          `${deletedBy?.firstName || ""} ${deletedBy?.lastName || ""
          }, ${formatDateTime2(deletedAt)}`
        }
      />}
    </div>
  );
};

export default GeneralInfo;
