import { PRE_START_RESULT } from "pages/Fleet/utils/Constant";
import {
  ASSET_ACTIVITY,
  ASSET_STATUS,
  CategoryDefect,
  ExtraNoteDefect,
  PreCheckAnswer,
} from "./Constant";
import { ISSUE_TYPE } from "pages/ShiftMaintenance/utils/Constant";
import { toast } from "react-toastify";
import { forEach, includes, isNaN, values } from "lodash";
import moment from "moment";

export const convertDataToGroup = (data) => {
  if (!Array.isArray(data) || data.length <= 0) {
    return; 
  }

  return data.reduce((acc, currentItem) => {
    const equipmentType = currentItem?.equipment?.EquipmentType;

    if (!equipmentType) {
      return acc; 
    }

    const existingGroup = acc.find((group) => group.name === equipmentType);

    if (existingGroup) {
      existingGroup.data.push(currentItem);
    } else {
      acc.push({
        name: equipmentType,
        data: [currentItem],
      });
    }

    return acc;
  }, []);
};



export const getValuesFromObj = (data) => {
  if (!Array.isArray(data)) {
    return data?.value;
  }
  if (Array.isArray(data)) {
    return data?.length === 1
      ? data[0].value
      : data?.map((item) => item.value).join(",");
  }
};

export const formatQuery = (query) =>
  query.trim().toLowerCase().replace(/\s+/g, "");

export const dotColors = (type) => {
  switch (type) {
    case ASSET_STATUS.OUT_OF_ORDER:
    case PRE_START_RESULT.CAT_A_FAILURE:
    case ISSUE_TYPE.CAT_A_FAILURE:
      return "#E03E3E"; //red

    case ISSUE_TYPE.CAT_C_FAILURE:
    case ISSUE_TYPE.PLANNED_MAINTENANCE:
      return "#6C35E6"; //purple

    case ASSET_ACTIVITY.BATTERY_DISCONNECTED:
    case ISSUE_TYPE.CAT_B_FAILURE:
      return "#FFBA01"; //Yellow

    // case JOB_STATUS.INPROGRESS:
    //   return "#2291FF"; // Blue

    case ASSET_STATUS.OPERABLE:
    case ASSET_ACTIVITY.ACTIVE:
    case PRE_START_RESULT.PASSED:
    case ISSUE_TYPE.PASSED:
      return "#06AF7B"; //Green

    case ASSET_ACTIVITY.PARKED:
      return "#939FB7"; //Grey
    case ISSUE_TYPE.INCOMPLETE:
      return "#6f7070"; //dark grey

    // case JOB_STATUS.REJECTED:
    //   return "#C18152"; //Brown

    // case JOB_STATUS.DELETED:
    //   return "#1A1A1A"; //Black
    default:
      return "";
  }
};

export const showToast = (isSuccess, msg) => {
  const options = {
    position: "bottom-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  if (isSuccess) {
    toast.success(msg, options);
  } else {
    toast.error(msg, options);
  }
};

export const tempPreProcessing = async (obj) => {
  return {
    _id: obj._id,
    status: obj.status,
    truck: {
      _id: obj.equipment?._id,
      name: obj.equipment?.Unit,
      type:
        obj.equipment?.EquipmentType == "Excavator"
          ? 2
          : obj.equipment?.EquipmentType == "Truck"
          ? 6
          : 0,
    },
    operator: {
      _id: obj.operator?._id,
      first_name: obj.operator?.firstName,
      last_name: obj.operator?.lastName,
      signature: obj.operatorSignature,
    },
    loginTime: obj.loginTime,
    engineHours: obj.engineHours,
    odometers: obj.odometers,
    checkpoints: obj.checkpoints,
    checkTime: obj.checkTime,
    refNo: obj.refNo,
    extraNote: {
      question: obj.extraNote?.question || "",
      note: obj.extraNote?.note || "",
      imagePaths: obj.extraNote?.imagePaths || [],
    },
    job:obj.job,
    shift : obj.shift,
    supervisor: obj.supervisor,
    notificationBySupervisor : obj.notificationBySupervisor,
    __v: obj.__v,
  };
};

export const formatDate = (timestamp, defaultValue = "00:00 AM") => {
  if (isNaN(Date.parse(timestamp))) return defaultValue;
  const date = new Date(timestamp);
  return date.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
};

export const getCategoryDefectDescription = (category) => {
  const categoryPrefix = {
    "Category A": CategoryDefect.CATEGORY_A_DEFECT,
    "Category B": CategoryDefect.CATEGORY_B_DEFECT,
    "Category C": CategoryDefect.CATEGORY_C_DEFECT,
  };

  return categoryPrefix[category] || ExtraNoteDefect.EXTRA_NOTE_DEFECT;
};

export const countDefectsInCategory = (questions) => {
  return (
    questions?.reduce((count, question) => {
      return question.answer === PreCheckAnswer.DEFECT_FOUND
        ? count + 1
        : count;
    }, 0) || 0
  );
};

const Category = Object.freeze({
  CATEGORY_A: "Category A",
  CATEGORY_B: "Category B",
  CATEGORY_C: "Category C",
});

export const isCategoryValid = (category) => {
  return includes(values(Category), category);
};

export const generateCategoryHtml = (category, checkpoints) => {
  let categoryHtml = "";
  if (category) {
    const categoryText = getCategoryDefectDescription(category);
    const numberOfDefects = countDefectsInCategory(checkpoints);
    categoryHtml = `<div class="d-flex align-items-center categoryBg mb-2 text-uppercase " style="height: 32px">
  <div style="padding-left: 28px;"><span class="categoryLabel">${categoryText}</span> 
  ${isCategoryValid(category)
    ? `<span style="margin-bottom: 1px; margin-left: 3px;">
        (<span style="color: #9E2929; font: normal normal normal 12px/17px Open Sans;" class="font-weight-bold">${numberOfDefects}</span>/<span style="font: normal normal normal 12px/17px Open Sans; color: #000000;">${checkpoints.length}</span>)
      </span>`
    : ""
  } 
   </div></div>`;
  }
  return categoryHtml;
};

const generateCheckpointHtml = (checkpoint, currentHeight) => {
  const { checkList, answer, comments, media, key } = checkpoint;
  let temp = "";
  let tempHeight = 0;
  let temp2Height = currentHeight;

  temp += `<div class="d-flex flex-column px-3 my-4">`;
  currentHeight += 26;
  tempHeight += 26;

  temp += `<div class="d-flex" >`;
  temp += ` <div class="d-flex col-6 ">`;
  if (key)
    temp += `<div class="col-1 mr-2 p-0" style="font: normal normal normal 12px/17px Open Sans;
color: #000000;">${key}</div>`;
  if (checkList)
    temp += `<div class="col-11 pl-0"  style="font: normal normal 600 12px/17px Open Sans;
color: #000000;">${checkList}</div>`;
  temp += `</div>`;

  const containerWidth = 122; // in pixels 142
  const fontSize = 12; // in pixels
  const charsPerLine = Math.floor(containerWidth / fontSize);
  const numLines = Math.ceil(checkList?.length / charsPerLine);
  currentHeight += 13 * numLines;
  tempHeight += 13 * numLines;
  if (answer === PreCheckAnswer.DEFECT_FOUND) {
    temp += `<div class="col-6" style="font: normal normal bold 12px/17px Open Sans; color:#9E2929">${PreCheckAnswer.DEFECT_FOUND}</div>`;
  } else if (answer === PreCheckAnswer.OK) {
    temp += `<div class="col-12" style="font: normal normal bold 12px/17px Open Sans;color:#2A7B39">OK</div>`;
  } else {
    temp += `<div class="col-12" style="color:#00005B"><strong>${answer}</strong></div>`;
  }

  temp += "</div>";

  temp += `<div class="d-flex">`;
  if (comments?.length > 0) {
    temp += `<div style="font: normal normal normal 12px/17px Open Sans;
color: #707070;" class="col-6 mt-2 pl-5">Comments</div>`;
    temp += `<div style="font: normal normal normal 12px/17px Open Sans;
color: #000000;" class="col-6 mt-2">${comments}</div>`;
    const containerWidth = 151; // in pixels
    const fontSize = 11; // in pixels
    const charsPerLine = Math.floor(containerWidth / fontSize);
    const numLines = Math.ceil(comments?.length / charsPerLine);
    currentHeight += 11 * numLines;
    tempHeight += 11 * numLines;
  }

  temp += "</div>";

  if (media?.length > 0) {
    media.map((mediaInst, index) => {
      temp += `<div style="margin-top:10px" class="d-flex ">`;
      temp += `<div style="color:#707070" class="col-6 p-0">${
        index == 0 ? "Media" : ""
      }</div>`;
      temp += `<div class="col-6"><img src="${mediaInst}" alt="Image" style="max-height: 60px; height: 60px;cursor:pointer" class="modal-trigger" data-media="${mediaInst}"></div>`;
      currentHeight += 70;
      tempHeight += 70;
      temp += "</div>";
    });
  }

  temp += "</div>";
  return {
    temp,
    temp2Height,
    tempHeight,
    currentCheckpointheight: currentHeight,
  };
};

export const generateTable = (data, maxHeight, maxWidth, index) => {
  let tableHtml =
    '<div class="w-100">';
  let currentHeight = 0;
  let remainingData = [];

  try {
    forEach(data, (entry) => {
      const { category, checkpoints } = entry;
      const categoryHtml = generateCategoryHtml(category, checkpoints);
      if (currentHeight + 20 <= maxHeight) {
        // Check if there's enough space for the category title
        if (currentHeight + 26 + 20 <= maxHeight) {
          tableHtml += categoryHtml;
          currentHeight += 26;
        } else {
          remainingData.push({ category, checkpoints });
        }

        forEach(checkpoints, (checkpoint, checkpointIndex) => {
          const { temp, temp2Height, tempHeight, currentCheckpointheight } =
            generateCheckpointHtml(checkpoint, currentHeight);
          currentHeight = currentCheckpointheight;
          if (tempHeight <= maxHeight - temp2Height) {
            tableHtml += temp;
          } else {
            remainingData.push({
              checkpoints: checkpoints.slice(checkpointIndex),
            });
            return false;
          }
        });
      } else {
        remainingData.push(entry);
      }
    });
  } catch (error) {
    console.log(error);
  }

  tableHtml += "</div>";
  return { tableHtml, remaining: remainingData };
};

export const formatDateTime2 = (dateString) => {
  if (!dateString) return "";
  return moment(dateString)
    .tz("Pacific/Auckland")
    .format(" DD/MM/YYYY, hh:mm A");
};




