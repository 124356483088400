import { useEffect, useState } from "react";
import CloseIcon from "../../../../assets/svgs/roundClose.svg";
import DownloadIcon from "../../../../assets/svgs/roundDownload.svg";
import "../PreStartReport/PreStartReport.css";
import { toast } from "react-toastify";
import { CallWithAuth, CallWithAuthFormData } from "action/apiActions";
import {
  formatDate,
  formatDateTime2,
  generateTable,
  showToast,
  tempPreProcessing,
} from "pages/Pre-StartCheck/utils/Helper";
import { dummy_Licenses } from "pages/Pre-StartCheck/utils/DummyData";
import ReportHeader from "./ReportHeader/ReportHeader";
import DisplayFaultyChecks from "./helper/diplayFaultyChecks";
import { reportData } from "../../utils/DummyData";
import Logo from "../../../../assets/images/logo.png";
import { statusMap } from "pages/Pre-StartCheck/utils/Constant";
import Badge from "pages/Pre-StartCheck/Component/Badge/Badge";
import RadioButton from "pages/Pre-StartCheck/Component/RadioButton/RadioButton";
import {
  assetStatus,
  pscAssetStatus,
} from "pages/Pre-StartCheck/utils/DropDownMasters";
import AddSign from "../AddSign/AddSign";
import { SignatureActionDropdown } from "../../../preStartChecks/NZMine/materialMovements/components/SignatureActionDropdown";
import { generatePreStartReportPdf, NotifyOperator } from "pages/Pre-StartCheck/utils/ApiServices";
import { Riple } from "react-loading-indicators";
import { usePreStartContext } from "pages/Pre-StartCheck/Context/Context";
import moment from "moment";
import axios from "axios";

const color = {
  passed: "#06AF7B",
  "cat a failure": "#E03E3E",
  "cat b failure": "#FFBA01",
  "cat c failure": "#6C35E6",
  failed: "#E03E3E",
  incomplete: "#325D88",
};

const PreStartReport = ({ id, closeModal, date }) => {

    const { 
      shiftType,
      selectedDate,
      selectedShift,
    } = usePreStartContext();
  const licenses = dummy_Licenses;
  const departments = [];

  const [preStartCheck, setPreStartCheck] = useState({});
  const [signatureData, setSignatureData] = useState({
    firstName: "",
    lastName: "",
    comments: "",
    signature: "",
    file: "",
  });
  const [isEdit, setIsEdit] = useState(false);
  const [tables, setTables] = useState([]);
  const [sortBy, setSortBy] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [enableNotify, setEnableNotify] = useState(false);

  const handleEnableNotify = () => setEnableNotify(true);
  const handleDisableNotify = () => setEnableNotify(false);

  const [notificationFormData, setNotificationFormData] = useState({
    assetStatus: "",
    comments: "",
  });
  const search = new URLSearchParams(document.location.search);

  const handleOnChange = (key, val) => {
    const tempFormData = { ...notificationFormData };
    tempFormData[key] = val;
    setNotificationFormData(tempFormData);
  };


  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };
  const handleDropdownOptions = async (action, sign) => {
    if (action === "edit") {
      setShowModal(true);
    }
    if (action === "delete") {
      setSignatureData({
        ...signatureData,
        firstName: "",
        lastName: "",
        signature: "",
        file: "",
      });
    }
  };

  const getCategoryName = (truckType, licenses) => {
    const license = licenses.find((license) => license.code === truckType);

    if (license) {
      return license.name;
    } else {
      return "cat_not_found";
    }
  };

  const getDeptName = (truckType, departments, licenses) => {
    const license = licenses.find((license) => license.code === truckType);
    const dept = departments.find(
      (dept) => license.departmentCode === dept.code
    );
    if (dept) {
      return dept.name;
    } else {
      return "dept_not_found";
    }
  };

  const fetchPreStartData = async () => {
    try {
      const source = axios.CancelToken.source();
      setIsLoading(true);

      const queryParams = new URLSearchParams({
               ...(selectedShift[shiftType] !== "Unassigned" && { shiftId: selectedShift[shiftType] }),
               ...(selectedShift[shiftType] === "Unassigned" && { date: moment(selectedDate).format("YYYY-MM-DD") }),
             }).toString();
     
     
             const response = await CallWithAuth(
               "GET",
               `/api/prestart/?${queryParams}`,
               {},
               source.token
             );

      // const tempData = await Promise.all(
      //   response?.res.data?.map((d) => tempPreProcessing(d))
      // );
      if (!response?.res.status) {
        showToast(false, "An error occurred while getting data");
        return;
      }
      const reportResponse = response?.res?.data;

      const tempData = await Promise.all(
        reportResponse?.map((d) => tempPreProcessing(d))
      );

      const ID = search.get("id") ? search.get("id") : id;
      const d = tempData.find((obj) => obj._id === ID);
      let t_summary = [0, 0, 0];
      let catData = [];

      let fault = 0;
      function getStatusIndex(status) {
        return ["passed", "failed", "incomplete"].indexOf(status);
      }
      for (const status of ["failed", "passed", "incomplete"]) {
        if (d?.status === status) {
          t_summary[getStatusIndex(status)]++;

          // Check if the category exists in catData, otherwise add it
          const categoryName = getCategoryName(d?.truck?.type, licenses);
          const categoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName
          );

          if (categoryIndex === -1) {
            catData.push({ name: categoryName, data: [0, 0, 0] });
          }

          // Update data in catData
          const updatedCategoryIndex = catData.findIndex(
            (cat) => cat.name === categoryName
          );
          catData[updatedCategoryIndex].data[getStatusIndex(status)]++;
        }
      }
      d?.checkpoints?.map((c) => {
        if (c.answer === "faulty") fault++;
      });
      let temp = {
        ...d,
        assetID: d?.truck?.name,
        failedChecks: `${fault}/${d?.checkpoints?.length}`,
        supervisorSignature: `${d?.supervisor?.firstName} ${d?.supervisor?.lastName}`,
        department: getDeptName(d?.truck?.type, departments, licenses),
        category: getCategoryName(d?.truck?.type, licenses),
      };

      setPreStartCheck(temp);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      showToast(false, err?.message);
    }
  };

  useEffect(() => {
    fetchPreStartData();
  }, []);

  useEffect(() => {
    if (preStartCheck?._id) {
      //setIsLoading(true);
      fetchCheckpoints();
      setSignatureData({
        firstName: preStartCheck?.supervisor?.firstName,
        lastName: preStartCheck?.supervisor?.lastName,
        comments: preStartCheck?.supervisor?.comments,
        signature: preStartCheck?.supervisor?.signature,
        file: preStartCheck?.supervisor?.signature,
      });
      setNotificationFormData({
        assetStatus: preStartCheck?.notificationBySupervisor?.assetStatus,
        comments: preStartCheck?.notificationBySupervisor?.comments,
      });
      preStartCheck?.supervisor?._id ? setIsEdit(true) : console.log("");
      //setIsLoading(false);
    }
  }, [preStartCheck]);

  const fetchCheckpoints = async () => {
    try {
      const result = preStartCheck?.checkpoints?.map(
        ({ checkpoints, category }) => {
          return {
            category,
            checkpoints: checkpoints.map(
              (matchingCheckpoint, checkpointIndex) => ({
                key: checkpointIndex + 1,
                checkList: matchingCheckpoint.question,
                answer: matchingCheckpoint.answer,
                media: matchingCheckpoint.imagePaths,
                comments: matchingCheckpoint.note || "",
              })
            ),
          };
        }
      );

      // add the extranote in the result array so it can be displayed.
      const extraNote = preStartCheck?.extraNote;

      if (extraNote) {
        const { question, answer, imagePaths, note } = extraNote;
        result.push({
          category: question || "",
          checkpoints: [
            {
              checkList: "",
              answer: answer || "",
              media: imagePaths || [],
              comments: note || "",
            },
          ],
        });
      }

      const temp = [];
      let remainingData = result;
      while (remainingData?.length > 0) {
        const { tableHtml, remaining } = generateTable(
          remainingData,
          // temp?.length > 1 ? 925 : 600,
          925,
          200,
          temp?.length
        );
        temp.push(tableHtml);
        setTables(temp);
        remainingData = remaining;
      }
    } catch (err) {
      toast.error(err?.message);
    }
  };

  const handleSaveAndSign = async () => {
    const formData = new FormData();
    formData.append("firstName", signatureData.firstName);
    formData.append("lastName", signatureData.lastName);
    formData.append("prestartId", preStartCheck._id);
    if (typeof signatureData?.file == "string") {
      formData.append("signatureFileUrl", signatureData?.file);
    } else {
      formData.append("files", signatureData?.file);
    }

    formData.append("comments", signatureData.comments);

    try {
      const response = await CallWithAuthFormData(
        "POST",
        "api/prestart/sign",
        formData
      );

      if (response?.res?.status == 200) {
        toast.success("Added");
        fetchPreStartData();
      }
    } catch (error) {
      toast.error(error?.message);
    }

    //fetchParent();
  };

  const handleNotifyOperator = async () => {
    const reqBody = {
      prestartId: id,
      assetStatus: notificationFormData?.assetStatus,
      comments: notificationFormData?.comments,
    };
    const response = await NotifyOperator(reqBody);
    if (response) {
      handleDisableNotify();
      showToast(true, "Operator notified successfully");
      fetchPreStartData();
    } else {
      showToast(false, "Error occurred while notify operator");
    }
  };

  const ReportMenu = () => {
    return (
      <div className="d-flex">
        <div className="col-6 d-flex borderY pady-2 ">
          <div className="col-6 checkListLabel ">Checklist</div>
          <div className="col-6 checkListLabel">Answer</div>
        </div>
        <div className="col-6 d-flex borderY pady-2">
          <div className="col-6 checkListLabel ">Checklist</div>
          <div className="col-6 checkListLabel">Answer</div>
        </div>
      </div>
    );
  };

  const handlePreBtnVisibility = (btn) => {
    
    if (!enableNotify) {
      if (btn === "notify") {
        if (preStartCheck?.notificationBySupervisor?.notifiedBy) {
          return false;
        }
        else{
          return true
        }
      }
      if (btn === "edit") {
        if (preStartCheck?.notificationBySupervisor?.notifiedBy) {
          return true;
        }
        else{
          return false
        }
      }
    }
  };

  const handlePostBtnVisibility = (btn) => {
    if (enableNotify) {
      if (btn === "send") {
        if (preStartCheck?.notificationBySupervisor?.notifiedBy) {
          return false;
        } else {
          return true;
        }
      }

      if (btn === "resend") {
        if (preStartCheck?.notificationBySupervisor?.notifiedBy) {
          return true;
        } else {
          return false;
        }
      }
    } else {
      return false;
    }
  };

  const [pdfLoading, setPdfLoading] = useState(false);

     const generatePscReportPdf = async () => {
      console.log("preStartCheck?.id",  preStartCheck?._id);
      console.log("preStartCheck?.assetID",  preStartCheck?.assetID);
      
        setPdfLoading(true);
        try {
          const pdfResponse = await generatePreStartReportPdf(
            selectedDate,
            shiftType,
            preStartCheck?._id,
            preStartCheck?.assetID
          );
          if (pdfResponse) {
            closeModal();
            showToast(true, "Pdf generated successfully..!");
          }
        } catch (error) {
          showToast(false, error.message);
        }
        setPdfLoading(false);
      }
    

  return (
    <>
      {isLoading || pdfLoading ? (
        <div className="d-flex align-items-center justify-content-center ripple">
          <Riple color="#317bcc" size="medium" text="" textColor="" />
        </div>
      ) : (
        <div className="modal-view-report" id="pscReport">
          <div className="pdf-modal-content">
            <div className="report-outer-view">
              <div>
                <div style={{ lineHeight: "1.8rem" }}>
                  <div className="reportViewPdf p-0">
                    <div className={` px-5 py-4 d-flex flex-column `}>
                      <div
                        className={`pb-5 d-flex align-items-center justify-content-between w-100 `}
                      >
                        <div className="logoImos">
                          <img src={Logo} alt="iMOS" />
                        </div>
                      </div>
                      <div className="pt-2 d-flex align-items-center justify-content-between w-100">
                        <div>
                          <span className="assetIdLabel">
                            {preStartCheck?.assetID}
                          </span>{" "}
                          <span className="reportLabel">
                            - Pre-Start Check Report
                          </span>
                        </div>
                        <div className="dateLabel">
                          {formatDateTime2(preStartCheck?.checkTime)}
                        </div>
                      </div>
                    </div>

                    <div className="reportSection mb-3">
                      <div className=" px-5 py-4 d-flex align-items-center justify-content-between border-bottom">
                        <div className="d-flex align-items-center justify-content-between">
                          <div className="pr-3 jobWOLabel">Result:</div>
                          <div
                            className="py-2 resultBadge"
                            style={{
                              background: color[preStartCheck.status],
                              color:
                                statusMap[preStartCheck?.status] ===
                                "Cat B Failure"
                                  ? "#1A1A1A"
                                  : "#FFFFFF",
                            }}
                          >
                            {statusMap[preStartCheck?.status]}
                          </div>
                        </div>
                        <div
                          className="d-flex align-items-center justify-content-between"
                          style={{ gap: "16px" }}
                        >
                          <div className="pr-2">
                            <span className="jobWOLabel">Job Ref/WO No:</span>{" "}
                            <span className="jobWOData">
                              {preStartCheck?.job
                                ? preStartCheck?.job?.WONumber
                                  ? preStartCheck?.job?.WONumber
                                  : preStartCheck?.job?.refNo
                                : "0000000"}
                            </span>
                          </div>
                          <div>
                            <span className="jobWOLabel">
                              Pre-Start Ref No:
                            </span>{" "}
                            <span className="jobWOData">
                              {preStartCheck?.refNo}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex px-4 borderY">
                        <div
                          className=" col-5 py-4 d-flex flex-column"
                          style={{ gap: "10px" }}
                        >
                          <div className=" d-flex">
                            <div className="col-6 p-0 dataLabel">
                              Faulty Checks:
                            </div>
                            <div className="col-6 p-0 data">
                              <DisplayFaultyChecks
                                checkpoints={preStartCheck.checkpoints}
                              />
                            </div>
                          </div>
                          <div className=" d-flex">
                            <div className="col-6 p-0 dataLabel">
                              Asset category:{" "}
                            </div>
                            <div className="col-6 p-0 data">
                              {preStartCheck?.category}
                            </div>
                          </div>
                          <div className=" d-flex">
                            <div className="col-6 p-0 dataLabel">
                              Engine Hours:
                            </div>
                            <div className="col-6 p-0 data">
                              {preStartCheck?.engineHours}
                              {preStartCheck?._databaseEngineHours
                                ? preStartCheck?.engineHours !==
                                  preStartCheck?._databaseEngineHours
                                  ? " (Manual)"
                                  : " (Automatic)"
                                : " (Automatic)"}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid #E1E8F4",
                          }}
                        ></div>
                        <div
                          className=" col-4 py-4 d-flex flex-column"
                          style={{ gap: "10px" }}
                        >
                          <div className=" d-flex">
                            <div className="col-6 p-0 dataLabel">Shift:</div>
                            <div className="col-6 p-0 data">
                              {preStartCheck?.shift?.reportNo || "0000000"}
                              {preStartCheck?.shift?.reportNo ? "," : ""}{" "}
                              {preStartCheck?.shift?.shiftType || ""}
                            </div>
                          </div>

                          <div className=" d-flex">
                            <div className="col-6 p-0 dataLabel">
                              Login Time:
                            </div>
                            <div className="col-6 p-0 data">
                              {formatDate(preStartCheck?.loginTime)}
                            </div>
                          </div>
                          <div className=" d-flex">
                            <div className="col-6 p-0 dataLabel">Operator:</div>
                            <div className="col-6 p-0 data">
                              {(
                                preStartCheck?.operator?.first_name +
                                " " +
                                preStartCheck.operator?.last_name
                              ).length > 15
                                ? (
                                    preStartCheck?.operator?.first_name +
                                    " " +
                                    preStartCheck.operator?.last_name
                                  ).substring(0, 15) + "..."
                                : preStartCheck?.operator?.first_name +
                                  " " +
                                  preStartCheck.operator?.last_name}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            border: "1px solid #E1E8F4",
                          }}
                        ></div>
                        <div className="col-3 py-4 d-flex flex-column">
                          <div className="dataLabel">Operator's Signature</div>
                          <div>
                            {preStartCheck?.operator?.signature && (
                              <embed
                                style={{ width: "70px", height: "44px" }}
                                src={preStartCheck?.operator?.signature}
                                alt="sign"
                              />
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="d-flex px-4 py-4 align-items-center justify-content-center borderB">
                        <div
                          style={{ lineHeight: "2rem" }}
                          className="col-8 py-3 d-flex flex-column"
                        >
                          <div className="pb-2 supervisorUse">
                            Supervisor's use
                          </div>
                          <div className="pb-2 comments">Comments:</div>
                          <textarea
                            placeholder=""
                            value={signatureData?.comments}
                            onChange={(e) => {
                              setSignatureData({
                                ...signatureData,
                                comments: e.target.value,
                              });
                            }}
                            style={{
                              width: "100%",
                              height: "120px",
                              backgroundColor: "white",
                              border: "1px solid #E1E8F4",
                              borderRadius: "6px",
                              padding: "0.85rem",
                              marginBottom: "0.85rem",
                              resize: "vertical",
                            }}
                          />

                          <div className="d-flex justify-content-end">
                            <div
                              className={`saveBtn 
                            ${
                              !signatureData?.signature ? "disabledSaveBtn" : ""
                            }`}
                              onClick={handleSaveAndSign}
                            >
                              Save Comments
                            </div>
                          </div>
                        </div>
                        <div className="col-4" style={{ marginTop: "25px" }}>
                          <div className="d-flex justify-content-between align-items-start mb-2 ">
                            <div className="signature">
                              Supervisor’s Signature:
                            </div>

                            {showModal && (
                              <>
                                <div
                                  style={{
                                    position: "fixed",
                                    top: 290,
                                    left: 100,
                                    right: 0,
                                    bottom: 0,
                                    backgroundColor: "rgba(0,0,0,0)",
                                    zIndex: 1000,
                                  }}
                                >
                                  <AddSign
                                    closeModal={() => {
                                      setShowModal(false);
                                    }}
                                    onChange={(v) => {
                                      let temp = {
                                        ...signatureData,
                                        signature: v.file,
                                        firstName: v.firstName,
                                        lastName: v.lastName,
                                      };

                                      if (v.f) {
                                        temp["file"] = v.f;
                                      }
                                      setSignatureData(temp);
                                    }}
                                    props={{
                                      firstName: signatureData?.firstName,
                                      lastName: signatureData?.lastName,
                                      signature: signatureData?.signature,
                                    }}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                          <div
                            className="d-flex w-100 borderY"
                            style={{
                              paddingTop: "20px",
                              paddingBottom: "24px",
                            }}
                          >
                            {signatureData?.signature ? (
                              <div className="d-flex w-100 justify-content-between">
                                <div>
                                  <embed
                                    style={{
                                      maxHeight: "40px",
                                      maxWidth: "170px",
                                    }}
                                    // src={dataObj?.supervisor?.signature}
                                    src={signatureData?.signature}
                                    alt="sign"
                                  />
                                  <br />
                                  <i className="">
                                    {signatureData?.firstName}{" "}
                                    {signatureData?.lastName}
                                  </i>
                                </div>

                                {/* <SignatureActionDropdown
                                  handleDropdownOptions={handleDropdownOptions}
                                /> */}
                              </div>
                            ) : (
                              <i className="py-1" style={{ color: "#707070" }}>
                                No signature saved
                              </i>
                            )}
                          </div>
                          <div className="d-flex justify-content-end pt-3">
                            <div
                              className="addSignature"
                              onClick={handleShowModal}
                            >
                              {signatureData?.signature ? "Edit Signature ":"Add Signature"}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        id="notify-operator"
                        className="px-5 pt-4"
                        style={{
                          opacity:
                            statusMap[preStartCheck?.status] === "Cat B Failure"
                              ? "1"
                              : "0.7",
                        }}
                      >
                        <div
                          className="py-2 notifyOperator"
                          style={{
                            color:
                              statusMap[preStartCheck?.status] ===
                              "Cat B Failure"
                                ? "#000000"
                                : "#6A7C9A",
                          }}
                        >
                          Notify Operator
                        </div>
                        <div
                          className="py-2 notifyInfo"
                          style={{
                            color:
                              statusMap[preStartCheck?.status] ===
                              "Cat B Failure"
                                ? "#000000"
                                : "#6A7C9A",
                          }}
                        >
                          Operator’s are required to be notified whether an
                          asset is operational or not whenever a category B
                          failure is reported. Please notify the operator as
                          soon as possible in the event of a category B failure
                          .
                        </div>
                        <div className="py-4 d-flex align-items-center justify-content-between w-100">
                          <div style={{ width: "45%" }}>
                            <RadioButton
                              radioLabel={"Asset Status:"}
                              options={pscAssetStatus}
                              value={notificationFormData?.assetStatus}
                              onChange={(v) => {
                                handleOnChange("assetStatus", v);
                              }}
                              isDisabled={!enableNotify && !preStartCheck?.notificationBySupervisor?.notifiedBy ? true : false}
                              id="assetStatus"
                            />
                          </div>
                          <div
                            style={{ width: "40%" }}
                            className="d-flex align-items-center justify-content-between"
                          >
                            <div className="notifyInfo">Notified By:</div>
                            <div className="notifyInfo">
                              {
                                preStartCheck?.notificationBySupervisor
                                  ?.notifiedBy?.firstName
                              }{" "}
                              {
                                preStartCheck?.notificationBySupervisor
                                  ?.notifiedBy?.lastName
                              }{" "}
                              {formatDateTime2(
                                preStartCheck?.notificationBySupervisor
                                  ?.notifiedAt
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="pb-1 notifyInfo">Comments:</div>
                        <div>
                          <textarea
                            placeholder="Type..."
                            value={notificationFormData?.comments}
                            onChange={(v) => {
                              handleOnChange("comments", v.target.value);
                            }}
                            style={{
                              height: "8.5rem",
                              maxHeight: "10rem",
                              width: "100%",
                              backgroundColor: !enableNotify && !preStartCheck?.notificationBySupervisor?.notifiedBy
                                ? "#E1E4EA"
                                : "#ffff",
                              borderRadius: "6px",
                              border: "1px solid #EBEBEB",
                              padding: "0.85rem",
                              marginBottom: "0.85rem",
                              resize: "vertical",
                              font: "normal normal normal 12px/17px Open Sans",
                              color: "#000000",
                            }}
                            disabled={!enableNotify && !preStartCheck?.notificationBySupervisor?.notifiedBy  ? true : false}
                          />
                        </div>
                      </div>
                      <div
                        className="py-3 px-5 d-flex align-items-center  w-100"
                        style={{
                          justifyContent: enableNotify
                            ? "space-between"
                            : "flex-end",
                        }}
                      >
                        {enableNotify && (
                          <div
                            className="cancelBtn"
                            onClick={handleDisableNotify}
                          >
                            Cancel
                          </div>
                        )}

                        {handlePreBtnVisibility("notify") && (
                          <div
                            className={`${
                              statusMap[preStartCheck?.status] ===
                              "Cat B Failure"
                                ? "notifyBtn"
                                : "notifyBtnDisabled"
                            }`}
                            onClick={handleEnableNotify}
                          >
                            Notify Operator
                          </div>
                        )}

                        {handlePreBtnVisibility("edit") && (
                          <div
                            className={`${
                              statusMap[preStartCheck?.status] ===
                              "Cat B Failure"
                                ? "notifyBtn"
                                : "notifyBtnDisabled"
                            }`}
                            onClick={handleEnableNotify}
                          >
                            Edit Notification
                          </div>
                        )}

                        {handlePostBtnVisibility("send") && (
                          <div
                            className="sendNotificationBtn"
                            onClick={handleNotifyOperator}
                          >
                            Send Notification
                          </div>
                        )}

                        {handlePostBtnVisibility("resend") && (
                          <div
                            className="sendNotificationBtn"
                            onClick={handleNotifyOperator}
                          >
                            Resend Notification
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="reportViewPdf  p-0">
                    <ReportHeader data={preStartCheck} />
                    <ReportMenu />
                    <div className="d-flex">
                      <div
                        dangerouslySetInnerHTML={{ __html: tables[0] }}
                        className="col-6 d-flex border-r p-0"
                      ></div>

                      {tables?.length > 1 && (
                        <div
                          dangerouslySetInnerHTML={{ __html: tables[1] }}
                          className="col-6 d-flex p-0"
                        ></div>
                      )}
                    </div>
                  </div>

                  {tables?.length > 2 && (
                    <div className="reportViewPdf  p-0">
                      <ReportHeader data={preStartCheck} />

                      <ReportMenu />
                      <div className="d-flex">
                        <div
                          dangerouslySetInnerHTML={{ __html: tables[2] }}
                          className="col-6 d-flex border-r p-0"
                        ></div>

                        {tables?.length > 3 && (
                          <div
                            className="col-6 d-flex p-0"
                            dangerouslySetInnerHTML={{ __html: tables[3] }}
                          ></div>
                        )}
                      </div>
                    </div>
                  )}
                  {tables?.length > 4 && (
                    <div className="reportViewPdf  p-0">
                      <ReportHeader data={preStartCheck} />

                      <ReportMenu />
                      <div className="d-flex">
                        <div
                          dangerouslySetInnerHTML={{ __html: tables[4] }}
                          className="col-6 d-flex border-r p-0"
                        ></div>

                        {tables?.length > 3 && (
                          <div
                            className="col-6 d-flex p-0"
                            dangerouslySetInnerHTML={{ __html: tables[5] }}
                          ></div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
          id="pscReportIcon"
            className="download-icon-report"
            style={{
              gap: "5px",
            }}
          >
            <div
              onClick={generatePscReportPdf}
              className="cursor-pointer text-white "
              style={{ cursor: "pointer" }}
            >
              <img src={DownloadIcon} alt="download" />
            </div>

            <div
              className="cursor-pointer text-white"
              onClick={() => closeModal()}
              style={{ cursor: "pointer" }}
            >
              <img
                src={CloseIcon}
                alt="close"
                style={{ padding: "0 0.5rem" }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PreStartReport;
