import React, { useEffect, useState } from "react";
import styles from "../EditJob/EditJob.module.css";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";
import EditMechanicSummary from "./Sections/EditMechanicSummary";
import EditSupervisorSummary from "./Sections/EditSupervisorSummary";
import EditGeneralInfo from "./Sections/EditGeneralInfo";
import { FORM_SECTION } from "pages/ShiftMaintenance/utils/Constant";
import { viewDetailsCondition } from "pages/ShiftMaintenance/utils/helper"
import EditFailedCheckStatus from "./Sections/EditFailedCheckStatus";
import Collapsible from "pages/ShiftMaintenance/Component/Collapsible/Collapsible";
import { Riple } from "react-loading-indicators";

const EditJobComponent = ({ isAllExpanded, editFormData, setEditFormData, detailData, handleOnChange, isLoading,handleAddNotesSignature }) => {

  const sections = [

    ...(viewDetailsCondition(editFormData?.generalInfo?.jobStatus?.value, detailData?.generalInfo?.issueType, FORM_SECTION.GENERAL_INFO)
      ? [
        {
          title: FORM_SECTION.GENERAL_INFO,
          content: <EditGeneralInfo detailData={detailData} editFormData={editFormData} handleOnChange={handleOnChange} />
        },
      ]
      : []),


    ...(viewDetailsCondition(editFormData?.generalInfo?.jobStatus?.value, detailData?.generalInfo?.issueType, FORM_SECTION.MECHANIC_SUMMARY)
      ? [
        {
          title: FORM_SECTION.MECHANIC_SUMMARY,
          content: <EditMechanicSummary detailData={detailData} editFormData={editFormData} handleOnChange={handleOnChange} />
        },
      ]
      : []),

    ...(viewDetailsCondition(editFormData?.generalInfo?.jobStatus?.value, detailData?.generalInfo?.issueType, FORM_SECTION.FAILED_CHECK)
      ? [
        {
          title: FORM_SECTION.FAILED_CHECK,
          content: <EditFailedCheckStatus detailData={detailData} editFormData={editFormData} setEditFormData={setEditFormData} />
        },
      ]
      : []),
      // viewDetailsCondition(editFormData?.generalInfo?.jobStatus?.value, detailData?.generalInfo?.issueType, FORM_SECTION.SUPERVISOR_SUMMARY)
    ...(detailData?.generalInfo?.isReviewed
      ? [
        {
          title: FORM_SECTION.SUPERVISOR_SUMMARY,
          content: <EditSupervisorSummary editFormData={editFormData} handleOnChange={handleOnChange} handleAddNotesSignature={handleAddNotesSignature} />
        },
      ]
      : []),
  ];

  const [openSections, setOpenSections] = useState([]);

  useEffect(() => {
    if (isAllExpanded) {
      setOpenSections(sections.map((_, index) => index)); // Expand all
    } else {
      setOpenSections([]); // Collapse all
    }
  }, [isAllExpanded]);

  const toggleSection = (index) => {
    if (openSections.includes(index)) {
      setOpenSections(openSections.filter((i) => i !== index));
    } else {
      setOpenSections([...openSections, index]);
    }
  };
  return (
    <div className={`px-4 mr-2 ml-3 ${styles.container} h-100`}>
      {!isLoading ?
        <div className={`pb-5 ${styles.collapsibleContainer}`}>
          {sections.map((section, index) => (
            <Collapsible key={index}
              title={section.title}
              content={section.content}
              isOpen={openSections.includes(index)}
              toggle={() => toggleSection(index)} />
          ))}
        </div> : <div
          className={`d-flex align-items-center justify-content-center h-100`}
        >
          <Riple color="#317bcc" size="medium" text="" textColor="" />
        </div>}
    </div>

  );
};

export default EditJobComponent;
