import Header from "../SubComponent/DailyShiftReport/Header/Header" 
import ShiftReportTable from "./DailyShiftReport/ShiftReportTable/ShiftReportTable";

const ShiftReport = () => {
  return (
    <>
      <Header />
      <ShiftReportTable/>
    </>
  );
};

export default ShiftReport;
