import { useExcavatorLoadTrackerContext } from "../context/context";
import { generatePdf, getCSVData } from "./helpers";
import Searchbar from "pages/Maintenance/Component/Searchbar/Searchbar";
import Datepicker from "pages/Maintenance/Component/Datepicker/Datepicker";
import DayNightSwitch from "pages/Maintenance/Component/DayNightSwitch/DayNightSwitch";
import ExportButton from "pages/Maintenance/Component/ExportButton/ExportButton";
import Legend from "pages/Maintenance/Component/Legend/Legend";
import EquipmentButton from "pages/Maintenance/Component/EquipmentButton/EquipmentButton";
import SelectField from "pages/ShiftPlanning/components/GeneralFields/SelectField";

const Header = () => {
  const {
    searchQuery,
    setSearchQuery,
    selectedDate,
    setSelectedDate,
    shiftType,
    setShiftType,
    getExcavators,
    selectedExcavator,
    setSelectedExcavator,
    mainContainer,
    getExcavatorData,
    loading,
    shiftOptions,
    setSelectedShift,
  } = useExcavatorLoadTrackerContext();

  const excavators = getExcavators() ?? [],
    excavator = getExcavatorData();

  return (
    <div className="d-flex justify-content-between align-items-center p-5 border-bottom">
      <div className="d-flex justify-content-start align-items-center">
        {excavators.map((e, idx) => (
          <EquipmentButton
            isSelected={idx === selectedExcavator}
            label={e.excavatorName}
            onClick={() => setSelectedExcavator(idx)}
            key={idx}
          />
        ))}
      </div>

      <div className="d-flex justify-content-end align-items-center">
        <Searchbar search={searchQuery} onChange={(e) => setSearchQuery(e)} />
        <Datepicker date={selectedDate} onChange={(e) => setSelectedDate(e)} />
        <DayNightSwitch value={shiftType} onChange={(e) => setShiftType(e)} />
        <SelectField
          noPlaceHolder={true}
          options={shiftOptions?.[shiftType]}
          value="1"
          className={"mr-2"}
          isDisabled={!shiftOptions?.[shiftType]?.length || loading}
          style={{
            height: "30px",
            width: "160px",
            marginTop: 2.5,
            border: "1px solid #F1F1F1",
            borderRadius: "15px",
            paddingLeft: "10px",
            marginLeft: "10px",
            font: "normal normal 600 11px/14px Open Sans",
            color: "#000000",
            cursor:
              !shiftOptions?.[shiftType]?.length || loading
                ? "not-allowed"
                : "pointer",
          }}
          onChange={(v) => setSelectedShift(prev => ({ ...prev, [shiftType]: v}))}
          mainContainerStyle={{ marginBottom: "0px" }}
        />
        <ExportButton
          onClick={() => generatePdf(mainContainer, excavator)}
          fileName={"Dump_Cycle_Tracker_Data " + excavator?.excavatorName}
          data={excavator ? getCSVData(excavator) : []}
        />
        <Legend />
      </div>
    </div>
  );
};

export default Header;
