import { CallWithAuth } from "action/apiActions";
import axios from "axios";
import useAuth from "hooks/useAuth";
import { ApprovedInitialFilters } from "pages/ShiftMaintenance/utils/Constant";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatQuery, getValuesFromObj } from "pages/ShiftMaintenance/utils/helper";
import moment from "moment";
import { fetchEquipments } from "pages/ShiftMaintenance/utils/ApiServices";

const ApprovedContext = createContext();

export const ApprovedProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const timeoutRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [approvedData, setApprovedData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [openFlyer, setOpenFlyer] = useState("");
  const [filters, setFilters] = useState(ApprovedInitialFilters);
  //   const filtersRef = useRef(ApprovedInitialFilters);
  //   const searchQueryRef = useRef("");
  const [refreshPage, setRefreshPage] = useState(false);
  const [equipmentList, setEquipmentList] = useState([])
  const [sortBy, setSortBy] = useState("addedOn");

  useEffect(() => {
    queryParams.set("sortBy", sortBy);
    const sortByFromQuery = queryParams.get("sortBy");
    if (sortByFromQuery && sortBy !== sortByFromQuery) {
      setSortBy(sortByFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [equipmentList] = await Promise.all([
          fetchEquipments(),
        ]);
        setEquipmentList(equipmentList);
      } catch (error) {
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const source = axios.CancelToken.source();

    // Helper function to fetch data
    const fetchData = async (dontLoad = false) => {
      if (!dontLoad) setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          type: 'approved',
          sortBy,
          assetCategory: getValuesFromObj(filters?.assetCategory),
          date: filters?.date ? moment(filters?.date).format("DD/MM/YYYY") : "",
          dateType: getValuesFromObj(filters?.jobType),
          delayType: getValuesFromObj(filters?.delayType),
          issueType: getValuesFromObj(filters?.issueType),
          jobStatus: getValuesFromObj(filters?.jobStatus),
          issueStatus: getValuesFromObj(filters?.issueStatus),
          assetStatus: getValuesFromObj(filters?.assetStatus),
          assetActivity: getValuesFromObj(filters?.assetActivity),
          page:0
        }).toString();


        // Call the API with the constructed query string
        const jobResponse = await CallWithAuth(
          "GET",
          `/api/job?${queryParams}`,
          {},
          source.token
        );
        const data = jobResponse?.res?.data;
        //const data = approvedDummyData;
        if (!dontLoad && data) setLoading(false);
        if (jobResponse?.res?.status) {
          setApprovedData(data);
          setFilteredResults(data);
        }

        setAutoRefreshTimeout();
        // If successful, start the timeout for auto-refresh
      } catch (error) {
        handleError(error, dontLoad);
      }
    };

    // Helper function for error handling and retry logic
    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error("Error fetching job data:", error);

      // Set retry with timeout on error
      setAutoRefreshTimeout();
    };

    // Helper function to manage auto-refresh
    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        if (sortBy) fetchData(true);
      }, 5000);
    };

    // Initial Navigation
    if (sortBy) {
      queryParams.set("sortBy", sortBy);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }

    // Initial Data Fetch
    if (sortBy) fetchData();
    else {
      setApprovedData([]);
      setFilteredResults([]);
    }

    // Cleanup on unmount
    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [sortBy,
    filters,
    refreshPage]);
  // 

  const onApplyFilter = (filterObj, wantToClear = false) => {
    setFilters(filterObj);
  };


  const searchApprovedData = (searchQuery) => {
    setSearchQuery(searchQuery);
    if (!searchQuery.trim()) {
      setFilteredResults(approvedData);
      return;
    }
    const formattedSearchQuery = formatQuery(searchQuery);

    const filteredData = approvedData.map(day => ({
      ...day,
      jobs: day.jobs.filter(job => {
        // Define specific fields to search within
        const searchableFields = {
          assetId: job.assetId,
          jobType: job.issueType,
          addedBy: job.addedBy,
          jobRefNo: job?.jobRefNo,
          delayType: job?.delayType
        };

        // Check if any searchable field contains the formatted search query
        return Object.values(searchableFields).some(value =>
          value && formatQuery(String(value)).includes(formattedSearchQuery)
        );
      })
    })).filter(day => day.jobs.length > 0);

    setFilteredResults(filteredData);
  };




  return (
    <ApprovedContext.Provider
      value={{
        loading,
        setLoading,
        openFlyer,
        sortBy,
        setSortBy,
        setOpenFlyer,
        filters,
        setFilters,
        searchQuery,
        setSearchQuery,
        searchApprovedData,
        onApplyFilter,
        approvedData,
        filteredResults,
        equipmentList,
        refreshPage,
        setRefreshPage,
      }}
    >
      {children}
    </ApprovedContext.Provider>
  );
};

export const useApprovedContext = () => {
  return useContext(ApprovedContext);
};
