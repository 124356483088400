import { includes, isEmpty, values } from "lodash";
import { CategoryMapping } from "./constant";
import { PreCheckAnswer, PreCheckEvaluationResult } from "./enums";
import { messages } from "./messages";
import { CheckpointDetail, CheckpointQuestion, Department, License } from "./types";

export const getCategoryName = (
  truckType: number,
  licenses: License[]
): string => {
  const license = licenses.find((license) => license.code === truckType);
  if (license) {
    return license.name;
  } else {
    return messages.error.LICENSE_NOT_FOUND;
  }
};

export const getDeptname = (
  truckType: number,
  departments: Department[],
  licenses: License[]
): { name: string; code: number } => {
  const license: License | undefined = licenses.find(
    (license) => license.code === truckType
  );
  if (!license) return { name: "-", code: 0 };
  const dept: Department | undefined = departments.find(
    (dept) => license.departmentCode === dept.code
  );
  if (dept) {
    return { name: dept.name, code: dept.code };
  } else {
    return { name: "-", code: 0 };
  }
};
const hasDefectsInCheckpoints = (
  checkpoints: CheckpointQuestion[]
): boolean => {
  return checkpoints.some(
    (checkpoint) => checkpoint.answer === PreCheckAnswer.DEFECT_FOUND
  );
};

export const getFailedCategories = (checkpoints?: CheckpointDetail[]) => {
  const defectsCategories: string[] | undefined = checkpoints?.reduce(
    (acc: string[], categoryAndCheckpoints: CheckpointDetail) => {
      if (hasDefectsInCheckpoints(categoryAndCheckpoints.checkpoints)) {
        acc.push(CategoryMapping[categoryAndCheckpoints.category]);
      }
      return acc;
    },
    []
  );
  if (isEmpty(defectsCategories)) return "-";
  return defectsCategories!.join(", ");
};

export const arrayofPreCheckEvaluationResult: PreCheckEvaluationResult[] =
  values(PreCheckEvaluationResult);

  export const includesPassOrIncomplete = (
    name: PreCheckEvaluationResult
  ): boolean => {
    const validResults: PreCheckEvaluationResult[] = [
      PreCheckEvaluationResult.PASS,
      PreCheckEvaluationResult.INCOMPLETE,
    ];
    return includes(validResults, name);
  };