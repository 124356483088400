import React from "react";
import { GoDotFill } from "react-icons/go";
import { dotColors } from "../utils/helper";

export default function TextInputField({
  inputClass,
  wrapperClassName,
  labelStyle,
  placeholder,
  required=false,
  type = "text",
  label,
  value,
  isDisabled,
  id,
  style = {},
  error,
  errorMessage,
  className,
  onChange,
  viewOnly,
  containerStyle = {},
  textStyle = {},
  dotIcon=false
}) {
  return (
    <div
      style={{
        minHeight: "3.5rem",
        display: "flex",
        alignItems: "center",
        marginBottom: "1rem",
        ...containerStyle,
      }}
      className={`${wrapperClassName} row form-group`}
    >
      {label && (
        <div className="col-md-4">
          <div
            style={{
              fontSize: "1.25rem",
              color: "#4B515A",
              ...labelStyle,
            }}
          >
            {label} {required && <span style={{ color: "#4B515A" }}>*</span>}
          </div>
        </div>
      )}
      <div
        style={{ borderRadius: ".25rem" }}
        className={`col-md-${label ? 8 : 12} ` + (className || "")}
      >
        {viewOnly ? (
          <span
            className="pl-1"
            style={{
              color: "#1A1A1A",

              fontSize: "1.3rem",
              height: "3.25rem",
              fontWeight: "600",
              ...textStyle,
            }}
          >
            {value || "-"}
          </span>
        ) : (
          <div
            style={{
              position: "relative",
              display: "inline-block",
              width: "100%",
            }}
          >
            <input
              placeholder={placeholder}
              type={type}
              disabled={isDisabled}
              value={value}
              style={{
                paddingLeft: dotIcon ?  "2.5rem": "1rem",
                border: error ? "1px solid red" : "",
                fontWeight: "600",
                // fontFamily: 'Open Sans',
                fontSize: "1.3rem",
                height: "3.25rem",
                ...style,
              }}
              id={id}
              onChange={(e) => onChange(e.target.value, e)}
              className={inputClass}
            />
            { dotIcon && value && ( <span
              style={{
                position: "absolute",
                top: "50%",
                left: "0.75rem", // Position the dot
                transform: "translateY(-50%)",
              }}
            >
             <GoDotFill fontSize={19.5} style={{color: dotColors(value) ,paddingBottom: "4px"}} />
            </span>)}
          </div>
        )}
        <div
          className="d-flex align-items-center pt-1"
          style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}
        >
          {error ? errorMessage : ""}
        </div>
      </div>
    </div>
  );
}
