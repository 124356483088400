import Flyer from "pages/ShiftMaintenance/Component/Flyers/Flyer";
import ViewDetailsComponent from "./ViewDetailsComponent";
import { ARCHIVED_FLYER } from "pages/ShiftMaintenance/utils/Constant";
import { useState } from "react";
import { isWithin24Hours } from "pages/ShiftMaintenance/utils/helper";

const ViewDetails = ({
  isLoading,
  setOpenFlyer,
  openFlyer,
  revertJobMessage,
  setConfirmModal,
  data,
  jobId,
}) => {

  
  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const handleExpandAll = () => {
    setIsAllExpanded(!isAllExpanded);
  };

  return (
    <>
      {openFlyer === ARCHIVED_FLYER.VIEW_DETAILS && (
        <Flyer
          isOpen={openFlyer === ARCHIVED_FLYER.VIEW_DETAILS}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={`${
            isLoading ? "HT000" : data?.generalInfo?.equipment?.label
          } - Job Details`}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 9.25rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}
          downloadIcon={false}
          tabs={[
            {
              title: "",
              leftActionButtons: isWithin24Hours(data?.generalInfo?.rejectedAt)
                ? [
                    {
                      label: "Revert",
                      onClick: async () =>
                        setConfirmModal({
                          show: true,
                          title: "Revert Job",
                          message: revertJobMessage(),
                          wantToDelete: true,
                          jid: jobId,
                        }),
                    },
                  ]
                : undefined,

              rightActionButtons: [
                {
                  label: isAllExpanded ? "Collapse All" : "Expand All",
                  onClick: async () => handleExpandAll(),
                  style: {
                    background: "#FFFFFF",
                    border: "1px solid #E1E8F4",
                    borderRadius: "15px",
                    font: "normal normal bold 12px/17px Open Sans",
                    color: "#186FD9",
                  },
                },
              ],
              component: (
                <ViewDetailsComponent
                  isLoading={isLoading}
                  isAllExpanded={isAllExpanded}
                  data={data}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default ViewDetails;
