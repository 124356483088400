import moment from "moment";
import Flyer from "pages/ShiftNotes/Component/Flyers/Flyer";
import NoDataMessage from "pages/DelayAndActivity/Component/NoDataMessage/NoDataMessage";
import styles from './History.module.css'

const EditHistoryComponent = ({ history, loading = false, inBlock = [] }) => {

  return (
    <div className={styles.container + " px-5"}>
      {loading ? 'Loading...' : !history?.length ? <NoDataMessage /> : <div style={{ marginRight: -5, height: "100%", width: "100%" }}>
        {history.map((history, index) => {
          return (
            <div key={index} className={styles.card}>
              <div className={styles.date}>
                {moment(history.updatedAt).format('DD/MM/YYYY, hh:mm A')} - {history.updatedBy}
              </div>
              {history.changes.map((change, idx) => {
                return (
                  <div key={idx} className={styles.changesCard}>
                    <div className={styles.key}>
                      {change.key}
                    </div>
                    <div>
                      {change.from}{" "}
                      {change.from && <span
                        className={inBlock.includes(change.key) ? 'my-2' : ""}
                        style={{ color: "#6A7C9A", fontStyle: "italic", display: inBlock.includes(change.key) ? 'block' : 'inline' }}
                      >
                        to
                      </span>}{" "}
                      {change.to}
                    </div>
                  </div>
                );
              })}
            </div>
          )
        })
        }
      </div>
      }
    </div>
  );
};

const History = ({
  historyData,
  handleOnClose
}) => {
  return (
    <>
      <Flyer
        isOpen={true}
        onClose={handleOnClose}
        title={"History"}
        containerStyle={{
          zIndex: "100",
          right: 0,
          bottom: 0,
          top: "unset",
          height: "calc(100vh - 11.6rem)",
        }}
        headerClassName={"pt-3"}
        hideBottom={true}
        hideBorderBottom={true}
        //headerClassName"px-4"
        headerComponent={true}
        tabs={[
          {
            title: "",
            component: <EditHistoryComponent inBlock={['Notes']} history={historyData} />
          },
        ]}
      />
    </>
  );
};

export default History;
