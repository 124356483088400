export const PreStartDummyData = [
  {
    equipment: { value: "test", label: "test" },
    result: "Cat A Failure",
    operator: "yash",
    preSTartReport: {
      refNo: "sdcdscsdc",
      _id: "asxasxasx",
    },
    reviewedBy: "Yash",
    operatorNotification: "Pending",
    engineHour: 546454,
    assetActivity: "Parked",
    jobRefNo: "00000",
    jobStatus: "Requested",
    engineHourLastUpdatedAt: new Date().toISOString(),
    preStartResult: "Cat A Failure",

    shiftAssignment: "sdcsdcsdc",
    shiftStatus: "day",
    openJobs: [
      {
        refNo: "dsvsdcs",
        _id: "sdvsdcsdcsdc",
      },
    ],
    assetCategory: "Haul Truck",
  },
  {
    equipment: { value: "test", label: "test" },
    result: "Passed",
    operator: "prem",
    preSTartReport: {
      refNo: "sdcdscsdc",
      _id: "asxasxasx",
    },
    reviewedBy: "Yash",
    operatorNotification: "Pending",
    engineHour: 546454,
    assetActivity: "Parked",
    jobRefNo: "00000",
    jobStatus: "Requested",
    engineHourLastUpdatedAt: new Date().toISOString(),
    preStartResult: "Cat A Failure",

    shiftAssignment: "sdcsdcsdc",
    shiftStatus: "day",
    openJobs: [
      {
        refNo: "dsvsdcs",
        _id: "sdvsdcsdcsdc",
      },
    ],
    assetCategory: "Haul Truck",
  },
  {
    equipment: { value: "test", label: "test" },
    result: "Passed",
    operator: "yash",
    preSTartReport: {
      refNo: "sdcdscsdc",
      _id: "asxasxasx",
    },
    reviewedBy: "Yash",
    operatorNotification: "Pending",
    engineHour: 546454,
    assetActivity: "Parked",
    jobRefNo: "00000",
    jobStatus: "Requested",
    engineHourLastUpdatedAt: new Date().toISOString(),
    preStartResult: "Cat A Failure",

    shiftAssignment: "sdcsdcsdc",
    shiftStatus: "day",
    openJobs: [
      {
        refNo: "dsvsdcs",
        _id: "sdvsdcsdcsdc",
      },
    ],
    assetCategory: "Haul Truck",
  }
];

export const dummy_Licenses = [
  {
      "_id": "6491213291c6cf03df3c0b77",
      "name": "Excavator",
      "code": 2,
      "__v": 0,
      "departmentCode": 800
  },
  {
      "_id": "64d462eefba89e600cd101b3",
      "name": "Misc",
      "code": 0,
      "__v": 0,
      "departmentCode": 600
  },
  {
      "_id": "668b172735e826e4871b9422",
      "name": "Truck",
      "code": 6,
      "__v": 0,
      "departmentCode": 600
  },
]

export const reportData =[
  {
      "_id": "677dbc35ac9dbe45de1ea72c",
      "status": "passed",
      "equipment": {
          "_id": "62bbb3b125045c582c97ec1a",
          "EquipmentType": "Truck",
          "Unit": "HT 213"
      },
      "operator": {
          "_id": "6362aa71bb3d0d6f834d4aaf",
          "firstName": "Test",
          "lastName": "1"
      },
      "operatorSignature": "https://imos.s3.ap-southeast-2.amazonaws.com/1736293429953_signature.png",
      "loginTime": "2025-01-07T23:38:27.843Z",
      "engineHours": 1234,
      "checkpoints": [
          {
              "checkpoints": [
                  {
                      "question": "Isolation lock in place and working",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea72e"
                  },
                  {
                      "question": "Steering (incl. cylinders & tie rods)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea72f"
                  },
                  {
                      "question": "Service brakes",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea730"
                  },
                  {
                      "question": "Warning system functioning",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea731"
                  },
                  {
                      "question": "Fire extinguisher (Certified)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea732"
                  },
                  {
                      "question": "Fire suppression (if fitted)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea733"
                  },
                  {
                      "question": "Emergency steering",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea734"
                  },
                  {
                      "question": "Emergency brake",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea735"
                  },
                  {
                      "question": "Park brake",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea736"
                  },
                  {
                      "question": "ROPS and/or FOPS",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea737"
                  },
                  {
                      "question": "Brake system leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea738"
                  },
                  {
                      "question": "Horn and reverse alarm",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea739"
                  },
                  {
                      "question": "Seat belt and seat (safe)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea73a"
                  },
                  {
                      "question": "Lights (tail, turn, flashing)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea73b"
                  },
                  {
                      "question": "Suspension cylinders",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea73c"
                  },
                  {
                      "question": "Clear birds nests",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea73d"
                  }
              ],
              "category": "Category A",
              "_id": "677dbc35ac9dbe45de1ea72d"
          },
          {
              "checkpoints": [
                  {
                      "question": "Two-way radio (available)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea73f"
                  },
                  {
                      "question": "Fuel leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea740"
                  },
                  {
                      "question": "Main machine access, ladders, rails",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea741"
                  },
                  {
                      "question": "Grease lines",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea742"
                  },
                  {
                      "question": "New damage",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea743"
                  },
                  {
                      "question": "Mirrors & windows",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea744"
                  },
                  {
                      "question": "Coolant leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea745"
                  },
                  {
                      "question": "Gauges and warning system alarms",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea746"
                  },
                  {
                      "question": "Major oil leaks, coal fines buildup",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea747"
                  },
                  {
                      "question": "Tray up alarm",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea748"
                  },
                  {
                      "question": "Pin retainers and rock ejectors",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea749"
                  },
                  {
                      "question": "Tyres or tracks - cuts and damage",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea74a"
                  },
                  {
                      "question": "Wheel nuts and chocks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea74b"
                  },
                  {
                      "question": "Headlights",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea74c"
                  },
                  {
                      "question": "Fluid Levels Above Min: (Eng, hyd, trans, coolant) ",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea74d"
                  }
              ],
              "category": "Category B",
              "_id": "677dbc35ac9dbe45de1ea73e"
          },
          {
              "checkpoints": [
                  {
                      "question": "Minor oil leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea74f"
                  },
                  {
                      "question": "Wipers/washers",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea750"
                  },
                  {
                      "question": "Existing damage",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea751"
                  },
                  {
                      "question": "Fuel level",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea752"
                  },
                  {
                      "question": "Air leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea753"
                  },
                  {
                      "question": "Air conditioner ",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677dbc35ac9dbe45de1ea754"
                  }
              ],
              "category": "Category C",
              "_id": "677dbc35ac9dbe45de1ea74e"
          }
      ],
      "checkTime": "2025-01-07T23:43:49.945Z",
      "refNo": "PS 188",
      "__v": 0
  },
  {
      "_id": "677d6389ac9dbe45de12ed78",
      "status": "passed",
      "equipment": {
          "_id": "62bbb3b125045c582c97ebed",
          "EquipmentType": "Excavator",
          "Unit": "EX075"
      },
      "operator": {
          "_id": "66e0d5e590664b433d1aee83",
          "firstName": "Terry",
          "lastName": "Middleton"
      },
      "operatorSignature": "https://imos.s3.ap-southeast-2.amazonaws.com/1736270729752_signature.png",
      "loginTime": "2025-01-07T17:23:09.522Z",
      "engineHours": 62929.5,
      "checkpoints": [
          {
              "checkpoints": [
                  {
                      "question": "Isolation lock in place and working",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed7a"
                  },
                  {
                      "question": "Steering (incl. cylinders & tie rods)",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed7b"
                  },
                  {
                      "question": "Service brakes",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed7c"
                  },
                  {
                      "question": "Warning system functioning",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed7d"
                  },
                  {
                      "question": "Fire extinguisher (Certified)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed7e"
                  },
                  {
                      "question": "Fire suppression (if fitted)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed7f"
                  },
                  {
                      "question": "Emergency steering",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed80"
                  },
                  {
                      "question": "Emergency brake",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed81"
                  },
                  {
                      "question": "Park brake",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed82"
                  },
                  {
                      "question": "ROPS and/or FOPS",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed83"
                  },
                  {
                      "question": "Brake system leaks",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed84"
                  },
                  {
                      "question": "Horn and reverse alarm",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed85"
                  },
                  {
                      "question": "Seat belt and seat (safe)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed86"
                  },
                  {
                      "question": "Lights (tail, turn, flashing)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed87"
                  },
                  {
                      "question": "Suspension cylinders",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed88"
                  },
                  {
                      "question": "Clear birds nests",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed89"
                  }
              ],
              "category": "Category A",
              "_id": "677d6389ac9dbe45de12ed79"
          },
          {
              "checkpoints": [
                  {
                      "question": "Two-way radio (available)",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed8b"
                  },
                  {
                      "question": "Fuel leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed8c"
                  },
                  {
                      "question": "Main machine access, ladders, rails",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed8d"
                  },
                  {
                      "question": "Grease lines",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed8e"
                  },
                  {
                      "question": "New damage",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed8f"
                  },
                  {
                      "question": "Mirrors & windows",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed90"
                  },
                  {
                      "question": "Coolant leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed91"
                  },
                  {
                      "question": "Gauges and warning system alarms",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed92"
                  },
                  {
                      "question": "Major oil leaks, coal fines buildup",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed93"
                  },
                  {
                      "question": "Tray up alarm",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed94"
                  },
                  {
                      "question": "Pin retainers and rock ejectors",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed95"
                  },
                  {
                      "question": "Tyres or tracks - cuts and damage",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed96"
                  },
                  {
                      "question": "Wheel nuts and chocks",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed97"
                  },
                  {
                      "question": "Headlights",
                      "answer": "n/a",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed98"
                  },
                  {
                      "question": "Fluid Levels Above Min: (Eng, hyd, trans, coolant) ",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed99"
                  }
              ],
              "category": "Category B",
              "_id": "677d6389ac9dbe45de12ed8a"
          },
          {
              "checkpoints": [
                  {
                      "question": "Minor oil leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed9b"
                  },
                  {
                      "question": "Wipers/washers",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed9c"
                  },
                  {
                      "question": "Existing damage",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed9d"
                  },
                  {
                      "question": "Fuel level",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed9e"
                  },
                  {
                      "question": "Air leaks",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12ed9f"
                  },
                  {
                      "question": "Air conditioner ",
                      "answer": "ok",
                      "imagePaths": [],
                      "_id": "677d6389ac9dbe45de12eda0"
                  }
              ],
              "category": "Category C",
              "_id": "677d6389ac9dbe45de12ed9a"
          }
      ],
      "checkTime": "2025-01-07T17:25:29.728Z",
      "refNo": "PS 187",
      "__v": 0
  }
]