import { useState } from "react";
import Styles from "../../Component/NavBar/NavBar.module.css";
import clsx from "clsx";

const NavBar = ({ onTabChange, unreadCount }) => {
  console.log(unreadCount)
  const options = [
    {
      label: "Inbox",
      value: "INBOX",
    },
    {
      value: "ARCHIVE",
      label: "Archive"
    },
  ];

  const [activeOption, setActiveOption] = useState("INBOX");
  return (
    <nav>
      <ul className={Styles.menu}>
        {options.map((option, index) => (
          <li
            key={index}
            className={clsx(
              Styles.menuItem,
              option.value === activeOption && Styles.active
            )}
            onClick={() => {
              setActiveOption(option.value);
              if (onTabChange) onTabChange(option.value);
            }}
            style={{
              cursor: "pointer",
              width: "unset",
              height: "unset",
              borderRadius: "unset",
              marginLeft: "0rem",
              border: "none",
              background: "#ffff",
              paddingBottom: 2.5
            }}
          >
            {option.label}
            {unreadCount > 0 && option.label === "Inbox" && (
              <span className={`px-2 mx-1 ${Styles.unreadCount}`}>{unreadCount}</span>
            )}
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavBar;
