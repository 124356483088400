import TextInputField from "pages/ShiftNotes/Component/GeneralFields/TextInputField";
import styles from "../AddEditShiftReport.module.css"
import DateTimeSelect from "pages/ShiftNotes/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import moment from "moment";



const ProductionSection = ({formData , handleChange})=>{
    return (<>
  <div
                  className={` d-flex align-items-center justify-content-center ${styles.blackRow}`}
                >
                  Production
                </div>
                <div id="productionContainer">
                  <div
                    className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    Shift
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div class="form-floating" style={{ width: "100%" }}>
                      <textarea
                        class={`form-control ${styles.textArea}`}
                        placeholder="Type…"
                        id="floatingTextarea"
                        value={formData.production?.shift || ""}
                        onChange={(e) =>
                          handleChange("production.shift", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    Other Comments / Sketches
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div class="form-floating" style={{ width: "100%" }}>
                      <textarea
                        class={`form-control ${styles.textArea}`}
                        placeholder="Type…"
                        id="floatingTextarea"
                        value={formData.production?.otherComments || ""}
                        onChange={(e) =>
                          handleChange(
                            "production.otherComments",
                            e.target.value
                          )
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    Maintenance Issues
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div class="form-floating" style={{ width: "100%" }}>
                      <textarea
                        class={`form-control ${styles.textArea}`}
                        style={{ minHeight: "35px !important" }}
                        placeholder="Type…"
                        id="floatingTextarea"
                        value={formData.production?.maintenanceIssues || ""}
                        onChange={(e) =>
                          handleChange(
                            "production.maintenanceIssues",
                            e.target.value
                          )
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    Environment / Water Management / Dewatering
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div class="form-floating" style={{ width: "100%" }}>
                      <textarea
                        class={`form-control ${styles.textArea}`}
                        style={{ minHeight: "35px !important" }}
                        placeholder="Type…"
                        id="floatingTextarea"
                        value={formData.production?.environment || ""}
                        onChange={(e) =>
                          handleChange("production.environment", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    GeoTechnical issues
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div class="form-floating" style={{ width: "100%" }}>
                      <textarea
                        class={`form-control ${styles.textArea}`}
                        style={{ minHeight: "35px !important" }}
                        placeholder="Type…"
                        id="floatingTextarea"
                        value={formData.production?.geoTechnicalIssues || ""}
                        onChange={(e) =>
                          handleChange(
                            "production.geoTechnicalIssues",
                            e.target.value
                          )
                        }
                      ></textarea>
                    </div>
                  </div>
                  <div
                    className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    <div style={{ width: "25%" }}>Recorded by</div>
                    <div style={{ width: "25%" }}>Reviewed by</div>
                    <div style={{ width: "25%" }}>Reviewed by</div>
                    <div style={{ width: "25%" }}>Date</div>
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div style={{ width: "25%" }}>
                      {" "}
                      <TextInputField
                        label=""
                        required={true}
                        placeholder="Type…"
                        style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                          paddingLeft: "8px",
                        }}
                        value={formData.production?.recordedBy}
                        onChange={(v) =>
                          handleChange("production.recordedBy", v)
                        }
                      />
                    </div>
                    <div style={{ width: "25%" }}>
                      {" "}
                      <TextInputField
                        label=""
                        required={true}
                        placeholder="Type…"
                        viewOnly={false}
                        style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                          paddingLeft: "8px",
                        }}
                        value={formData.production?.reviewedByFirst}
                        onChange={(v) =>
                          handleChange("production.reviewedByFirst", v)
                        }
                      />
                    </div>
                    <div style={{ width: "25%" }}>
                      <TextInputField
                        label=""
                        required={true}
                        placeholder="Type…"
                        viewOnly={false}
                        style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                          paddingLeft: "8px",
                        }}
                        value={formData.production?.reviewedBySecond}
                        onChange={(v) =>
                          handleChange("production.reviewedBySecond", v)
                        }
                      />
                    </div>
                    <div style={{ width: "25%" }}>
                      <DateTimeSelect
                        value={moment
                          .tz(
                            formData.production?.recordedByDate,
                            "YYYY-MM-DD",
                            "Pacific/Auckland"
                          )
                          .toDate()}
                        onDateChange={(v) => {
                          handleChange(
                            "production.recordedByDate",
                            moment(v).format("YYYY-MM-DD")
                          );
                        }}
                        placeholder=""
                        iconColor="#6B7B8E"
                        containerStyle={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          width: "220px",
                          height: "35px",
                        }}
                        mainContainerStyle={{
                          paddingBottom: "15px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={` px-3  d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    <div style={{ width: "25%" }}>Shift Supervisor</div>
                    <div style={{ width: "25%" }}>
                      OnComing Shift Supervisor{" "}
                    </div>
                    <div style={{ width: "25%" }}>Mine Manager</div>
                    <div style={{ width: "25%" }}>Date</div>
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div style={{ width: "25%" }}>
                      {" "}
                      <TextInputField
                        label=""
                        required={true}
                        placeholder="Type…"
                        style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                          paddingLeft: "8px",
                        }}
                        value={formData.production?.shiftSupervisor}
                        onChange={(v) =>
                          handleChange("production.shiftSupervisor", v)
                        }
                      />
                    </div>
                    <div style={{ width: "25%" }}>
                      {" "}
                      <TextInputField
                        label=""
                        required={true}
                        placeholder="Type…"
                        viewOnly={false}
                        style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                          paddingLeft: "8px",
                        }}
                        value={formData.production?.oncomingShiftSupervisor}
                        onChange={(v) =>
                          handleChange("production.oncomingShiftSupervisor", v)
                        }
                      />
                    </div>
                    <div style={{ width: "25%" }}>
                      <TextInputField
                        label=""
                        required={true}
                        placeholder="Type…"
                        viewOnly={false}
                        style={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                          paddingLeft: "8px",
                        }}
                        value={formData.production?.mineManager}
                        onChange={(v) =>
                          handleChange("production.mineManager", v)
                        }
                      />
                    </div>
                    <div style={{ width: "25%" }}>
                      <DateTimeSelect
                        value={moment
                          .tz(
                            formData.production?.shiftSupervisorDate,
                            "YYYY-MM-DD",
                            "Pacific/Auckland"
                          )
                          .toDate()}
                        onDateChange={(v) => {
                          handleChange(
                            "production.shiftSupervisorDate",
                            moment(v).format("YYYY-MM-DD")
                          );
                        }}
                        className={` `}
                        iconColor="#6B7B8E"
                        containerStyle={{
                          border: "1px solid #EBEBEB",
                          borderRadius: "4px",
                          font: "normal normal normal 10px/10px Open Sans",
                          color: "#050505",
                          width: "220px",
                          height: "35px",
                        }}
                        mainContainerStyle={{
                          paddingBottom: "15px",
                        }}
                      />
                    </div>
                  </div>
                  <div
                    className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                  >
                    Crew Sign Off Below Or Attach Crew Attendance Form (Before
                    Shift Commences)
                  </div>
                  <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
                    <div class="form-floating" style={{ width: "100%" }}>
                      <textarea
                        class={`form-control ${styles.textArea}`}
                        placeholder="Type…"
                        id="floatingTextarea"
                        value={formData.production?.commences || ""}
                        onChange={(e) =>
                          handleChange("production.commences", e.target.value)
                        }
                      ></textarea>
                    </div>
                  </div>
                </div>
    </>)
}

export default ProductionSection