


import { GoDotFill } from "react-icons/go";
import { useFleetContext } from "pages/Fleet/Context/Context";
import { ASSET_CATEGORY, FLEET_FLYER, FleetHeader, resultMap } from "pages/Fleet/utils/Constant";
import DynamicTable from "pages/Fleet/Component/Table/Table";
import { dotColors, formatDateTime, convertSecondsToTime, convertDataToGroup } from "pages/Fleet/utils/Helper";
import ViewDetails from "../ViewDetails/ViewDetails";
import { useEffect, useState } from "react";
import UpdateAssetStatus from "../UpdateAssetStatus/UpdateAssetStatus"

const FleetTable = () => {

  const {
    loading,
    openFlyer,
    setOpenFlyer,
    fleetData,
    filteredResults,
  } = useFleetContext();

  const totalFleetData =
    fleetData?.reduce((total, entry) => total + (entry.data?.length || 0), 0);

  const totalFilteredFleetData =
    filteredResults?.reduce((total, entry) => total + (entry.data?.length || 0), 0);

  const headerStyle = {
    paddingLeft: 12,
    font: "normal normal bold 11px/18px Open Sans",
    color: "#1A1A1A",
  };
  const headers = [
    {
      label: FleetHeader.ASSET_ID,
      key: "assetId",
      style: {
        width: "4%",
        ...headerStyle,
      },
    },
    {
      label: FleetHeader.ASSET_STATUS,
      key: "assetStatus",
      style: {
        width: "11%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: FleetHeader.ASSET_ACTIVITY,
      key: "assetActivity",
      style: {
        width: "15%",
        ...headerStyle,
        paddingLeft: 21,
      },
    },
    {
      label: FleetHeader.OPERATOR,
      key: "operator",
      style: {
        width: "18%",
        ...headerStyle,
      },
    },
    {
      label: FleetHeader.LAST_UPDATED,
      key: "lastUpdated",
      style: {
        width: "13%",
        ...headerStyle,
      },
    },
    {
      label: FleetHeader.PRE_START_RESULT,
      key: "preStartResult",
      style: {
        width: "12%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: FleetHeader.ASSET_CATEGORY,
      key: "assetCategory",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: FleetHeader.SHIFT_ASSIGNMENT,
      key: "shiftAssignment",
      style: {
        width: "12%",
        ...headerStyle,
      },
    },
    {
      label: FleetHeader.ACTIONS,
      key: "actionMenu",
      style: {
        width: "0%",
        ...headerStyle,
      },
    },
  ];

  const rowHeaderBadgeStyle = {
    font: "normal normal bold 12px/17px Open Sans",
    color: "#1A1A1A",
    background: "#EFF3FA 0% 0% no-repeat padding-box",
    borderRadius: "15px",
    padding: "6px 10px 6px 10px",
  };

  const [updateAssetModal, setUpdateAssetModal] = useState(false)
  const [viewDetailData, setViewDetailsData] = useState({})
  const [assetData, setAssetData] = useState({})

  const handleViewDetails = (data) => {
    setViewDetailsData(data)
    setOpenFlyer(FLEET_FLYER.VIEW_DETAILS);
  };

  const openUpdateAssetStatusModal = (data) => {
    console.log("data_fleet", data);

    setAssetData({
      "assetId": data?.equipment?.value,
      "assetStatus": data?.assetStatus
    })
    setUpdateAssetModal(true)
  }

  const closeUpdateAssetStatusModal = () => setUpdateAssetModal(false)


  const processedData = filteredResults?.map((group) => ({
    groupName: group?.name, // Use `name` as the group name
    groupData: group?.data?.map((job) => ({
      rowHeaderStyle: { rowHeaderBadgeStyle },
      rowHeader: group?.name,
      // Assign group name as the row header
      rows: [
        {
          value: {
            assetId: job?.equipment?.label || "-",
            assetStatus: job?.assetStatus ? (
              <span>
                <GoDotFill
                  fontSize={19.5}
                  style={{
                    color: dotColors(job?.assetStatus),
                    paddingBottom: "2px",
                  }}
                />
                {job?.assetStatus}
              </span>
            ) : (
              "-"
            ),
            assetActivity: job?.assetActivity ? (
              <span>
                <GoDotFill
                  fontSize={19.5}
                  style={{
                    color: dotColors(job?.assetActivity),
                    paddingBottom: "2px",
                  }}
                />
                {job?.assetActivity}
              </span>
            ) : (
              "-"
            ),
            operator: job?.operator || "-",
            lastUpdated: job?.engineHour ? `${convertSecondsToTime(job?.engineHour)} | ${formatDateTime(job?.engineHourLastUpdatedAt)}` : "-",


            preStartResult: job?.preStartResult ? (
              <span>
                <GoDotFill
                  fontSize={19.5}
                  style={{
                    color: dotColors(resultMap[job?.preStartResult] || "Unknown"),
                    paddingBottom: "2px",
                  }}
                />
                {resultMap[job?.preStartResult]}
              </span>
            ) : (
              "-"
            ),
            assetCategory: job?.assetCategory || "-",
            shiftAssignment: job?.shiftAssignment || "-"

          },
          rowStyle: {
            font: "normal normal normal 13px/19px Open Sans",
            color: "#1A1A1A",
          },
          onClick: () => handleViewDetails(job),
          actionMenu: {
            options: [
              {
                name: "View Details",
                icon: "",
                onClick: () => handleViewDetails(job),
              },
              {
                name: "Update Asset Status",
                icon: "",
                onClick: () => openUpdateAssetStatusModal(job),
              },
              {
                name: "Download",
                icon: "",
                disabled: true,
                onClick: () => { },
              },
            ],
          },
        },
      ],
      actionMenu: {
        options: [],
      },
    })),
  }));

  return (
    <>
      {openFlyer === FLEET_FLYER.VIEW_DETAILS && (
        <ViewDetails data={viewDetailData} openUpdateAssetStatusModal={openUpdateAssetStatusModal} setOpenFlyer={setOpenFlyer} openFlyer={openFlyer} />
      )}

      {updateAssetModal && (
        <UpdateAssetStatus handleClose={closeUpdateAssetStatusModal} assetData={assetData} />
      )}

      <DynamicTable
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={true}
        rowHeader={false}
        footer={`Displaying ${totalFilteredFleetData}/${totalFleetData} Pre-Start Checks`}
      />
    </>
  );
};

export default FleetTable;
