export const NOTIFICATION_FLYER = {
    SHOW_NOTIFICATION : "Show_Notification_Summary"
  };

  export const notificationType = [
    { value: "Flagged", label: "Flagged" },
    { value: "Unread", label: "Unread" },
    { value: "Alerts", label: "Alerts" },
    { value: "Information", label: "Information" },
  ];

  export const modules = [
    { value: "Delays", label: "Delays" },
    { value: "Maintenance", label: "Maintenance" },
    { value: "Shift Planning", label: "Shift Planning" }
  ];

  export const duration = [
    { value: "Today", label: "Today" },
    { value: "Yesterday", label: "Yesterday" },
    { value: "Last 7 Days", label: "Last 7 Days" }
   
  ];

  export const Tab = {
    INBOX:"INBOX",
    ARCHIVE:"ARCHIVE"
  }
  