import moment from "moment";
import { ASSET_ACTIVITY, ASSET_STATUS, PRE_START_RESULT } from "./Constant";
import { toast } from "react-toastify";

export const dotColors = (type) => {
  switch (type) {
    case ASSET_STATUS.OUT_OF_ORDER:
    case PRE_START_RESULT.CAT_A_FAILURE:
   
      return "#E03E3E"; //red

    case PRE_START_RESULT.CAT_C_FAILURE:
     return "#6C35E6"; //purple

    case ASSET_ACTIVITY.BATTERY_DISCONNECTED:
      case PRE_START_RESULT.CAT_B_FAILURE:
      return "#FFBA01"; //Yellow

    // case JOB_STATUS.INPROGRESS:
    //   return "#2291FF"; // Blue

    case ASSET_STATUS.OPERABLE:
    case ASSET_ACTIVITY.ACTIVE:
    case PRE_START_RESULT.PASSED:
      return "#06AF7B"; //Green

    case ASSET_ACTIVITY.PARKED:
      return "#939FB7"; //Grey

    // case JOB_STATUS.REJECTED:
    //   return "#C18152"; //Brown

    // case JOB_STATUS.DELETED:
    //   return "#1A1A1A"; //Black
    default:
      return "";
  }
};

export const formatDateTime = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString)
    .tz("Pacific/Auckland")
    .format("hh:mm A, DD/MM/YYYY");
};

export const formatDateTime2 = (dateString) => {
  if (!dateString) return "-";
  return moment(dateString)
    .tz("Pacific/Auckland")
    .format(" DD/MM/YYYY, hh:mm A");
};

export const convertSecondsToTime = (seconds) => {
  if (!seconds) return "-";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let timeString = "";
  if (hours > 0) {
    timeString += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    timeString += `${minutes}m`;
  }

  return timeString;
};

export const convertDataToGroup = (data) => {
  return data?.reduce((acc, currentItem) => {
    const existingGroup = acc.find(
      (group) => group.name === currentItem.assetCategory
    );

    if (existingGroup) {
      existingGroup.data.push(currentItem);
    } else {
      acc.push({
        name: currentItem.assetCategory,
        data: [currentItem],
      });
    }

    return acc;
  }, []);
};

export const showToast = (isSuccess, msg) => {
  const options = {
    position: "bottom-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  if (isSuccess) {
    toast.success(msg, options);
  } else {
    toast.error(msg, options);
  }
};

export const getValuesFromObj = (data) => {
  if (typeof data === 'string') {
    return data;
  }
  
  if (!Array.isArray(data)) {
    return data?.value;
  }
  if (Array.isArray(data)) {
    return data?.length === 1
      ? data[0].value
      : data?.map((item) => item.value).join(",");
  }
};

export const formatQuery = (query) =>
  query.trim().toLowerCase().replace(/\s+/g, "");
