import { useState } from "react";
import AuthLayout from "../../components/AuthLayout";
import NavBar from "./Component/NavBar/NavBar";
import { ShiftNotesProvider } from "./Context/context";
import ShiftReport from "./SubComponent/shiftReport";
import Notes from "./SubComponent";
import { ShiftReportProvider } from "./SubComponent/DailyShiftReport/Context/Context";




const ShiftNotes = () => {
  const [selectedTab, setSelectedTab] = useState("shiftReport");

  return (
    <AuthLayout pageName="Shift Notes">
      <div
        className={`right_sidebar_body d-flex flex-column`}
        id="main"
        style={{backgroundColor: "#FFFFFF"}}
      >
       <div className={`mb-0 px-5  mt-5 border-bottom`}>
          <NavBar onTabChange={(type) => setSelectedTab(type)} />
        </div>

        {selectedTab === "shiftReport" ? (
          <ShiftReportProvider>
          <ShiftReport />
          </ShiftReportProvider>
          
        
        ) : (
          <ShiftNotesProvider>
            <Notes />
          </ShiftNotesProvider>
        )}

         
      </div>
    </AuthLayout>
  );
};

export default ShiftNotes;
