import React, { FunctionComponent } from 'react';

import RenderSeparator from './renderSeparator';
import { getSecondWord } from './getSecondWord';
import { countDefectsInCategory } from 'pages/Pre-StartCheck/utils/Helper';

 enum PreCheckAnswer {
  OK = 'ok',
  DEFECT_FOUND = 'Defect found',
  NOT_APPLICABLE = 'n/a',
}
type DisplayFaultyChecksProps = { checkpoints: CheckpointDetail[] };
type categoryOption = 'Category A' | 'Category B' | 'Category C';

 type CheckpointDetail = {
  checkpoints: CheckpointQuestion[];
  category: categoryOption;
};
 type CheckpointQuestion = {
  question: string;
  answer?: (typeof PreCheckAnswer)[keyof typeof PreCheckAnswer];
  note: string;
  imagePaths: string[];
};

const DisplayFaultyChecks: FunctionComponent<DisplayFaultyChecksProps> = (
  props,
) => {
  return (
    <>
      {props.checkpoints?.map(({ checkpoints, category }, index) => (
        <React.Fragment key={category}>
          {getSecondWord(category)}{' '}
          ({countDefectsInCategory(checkpoints)})
          <RenderSeparator
            arrayLength={props.checkpoints.length}
            index={index}
          />
        </React.Fragment>
      ))}
    </>
  );
};

export default DisplayFaultyChecks;
