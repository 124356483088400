

export const assetStatus = [
  { value: "Out of Order", label: "Out of Order", color: "#E03E3E" },
  { value: "Operational", label: "Operational", color: "#06AF7B" },
];

export const issueStatus = [
  { value: "Resolved", label: "Resolved", color: "#06AF7B" },
  { value: "Unresolved", label: "Unresolved", color: "#E03E3E" },
];

export const assetCategory = [
  { value: "Excavator", label: "Excavator" },
  { value: "Truck", label: "Haul Trucks" },
]

export const jobTypesForApprovedFilter = [
  { value: "Issue Reported", label: "Issue Reported" },
  { value: "Job Added/Approved", label: "Job Added/Approved" },
  { value: "Job Started", label: "Job Started" },
  { value: "Job Paused/Ended", label: "Job Paused/Ended" },
  { value: "PM Schedule", label: "Supervisor's Review" },
  { value: "PM Schedule", label: "PM Schedule" },
];

export const jobTypesForArchived = [
  { value: "Job Archived", label: "Job Archived" },
  { value: "Issue Reported", label: "Issue Reported" },
  { value: "Job Added/Approved", label: "Job Added/Approved" },
  { value: "Job Started", label: "Job Started" },
  { value: "Job Paused/Ended", label: "Job Paused/Ended" },
  { value: "PM Schedule", label: "Supervisor's Review" },
  { value: "PM Schedule", label: "PM Schedule" },
];

export const delayTypes = [
  { value: "M1", label: "M1" },
  { value: "M2", label: "M2" },
];

export const issueTypes = [
  { value: "Breakdown", label: "Breakdown", color: "#E03E3E" },
  { value: "Cat A Failure", label: "Cat A Failure", color: "#E03E3E" },
  { value: "Cat B Failure", label: "Cat B Failure", color: "#FFBA01" },
  { value: "Cat C Failure", label: "Cat C Failure", color: "#6C35E6" },
  { value: "Planned Maintenance", label: "Planned Maintenance", color: "#6C35E6" },

]

export const addJobIssueType = [
  { value: "Breakdown", label: "Breakdown", color: "#E03E3E" },
  { value: "Planned Maintenance", label: "Planned Maintenance", color: "#6C35E6" },
]

export const jobStatus = [
  // { value: "Closed", label: "Closed" ,color:"#A2A0A0" },
  // { value: "Rejected", label: "Rejected" ,color:"#C18152" },
  // { value: "Deleted", label: "Deleted",color:"#494750" },
  { value: "Pending", label: "Pending", color: "#E03E3E" },
  { value: "In Progress", label: "In Progress", color: "#2291FF" },
  { value: "Paused", label: "Paused", color: "#FFBA01" },
  { value: "Ended", label: "Ended", color: "#06AF7B" },
]

export const assetActivity = [
  { value: "Active", label: "Active", color: "#06AF7B" },
  { value: "Parked", label: "Parked", color: "#939FB7" },
  { value: "Battery Disconnected", label: "Battery Disconnected", color: "#FFA701" },
  { value: "Disconnected", label: "Disconnected", color: "#E03E3E" },
]

