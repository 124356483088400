import download from "../../../../../assets/svgs/roundDownload.svg";
import close from "../../../../../assets/svgs/roundClose.svg";
import styles from "../ViewShiftReport/ViewShiftReport.module.css";
import bathurstResource from "../../../../../assets/svgs/BathurstResource.svg";
import SignatureSection from "./SignatureSection/SignatureSection";
import moment from "moment";
import { showToast } from "pages/ShiftNotes/utils/helper";
import { useShiftReportContext } from "../Context/Context";
import { useState } from "react";
import { generateDailyReportPdf } from "pages/ShiftNotes/utils/apiService";
import { Riple } from "react-loading-indicators";
import { ShiftReportHeader } from "pages/ShiftNotes/utils/constant";

const ViewShiftReport = ({reportData, handleCloseViewModal }) => {
    const {
    selectedDate,
    shiftType
    } = useShiftReportContext();
  const [pdfLoading, setPdfLoading] = useState(false);

    const generateShiftDailyReportPdf = async () => {
      setPdfLoading(true);
      try {
        const pdfResponse = await generateDailyReportPdf(
          selectedDate,
          shiftType,
          reportData?.id,
          reportData?.reportNo
        );
        if (pdfResponse) {
          handleCloseViewModal();
          showToast(true, "Pdf generated successfully..!");
        }
      } catch (error) {
        showToast(false, error.message);
      }
      setPdfLoading(false);
    }
  
  return (
    <>
      {pdfLoading ? (
        <div
          className={`d-flex align-items-center justify-content-center ${styles.ripple}`}
        >
          <Riple color="#317bcc" size="medium" text="" textColor="" />
        </div>
      ) : (
      <div>
        <div id="reportIcon" className={`${styles.iconContainer}`}>
          <div className={`${styles.iconButton}`} onClick={generateShiftDailyReportPdf} >
            <img
              className={styles.reportTopIcon}
              src={download}
              alt="download"
            />
          </div>
          <div className={styles.iconButton} onClick={handleCloseViewModal}>
            <img className={styles.reportTopIcon} src={close} alt="close" />
          </div>
        </div>
        <div>
          <div
            id="shift-view-report"
            className={`modal fade show ${styles.modalTop}`}
            tabIndex="-1"
            role="dialog"
            style={{zIndex: "9998"}}
          >
            <div
              id="modalDialogTop"
              className={`modal-dialog modal-dialog-centered ${styles.modalTopArea}`}
              role="document"
            >
              <div className={`modal-content px-5 ${styles.modalContent}`}>
                <div
                  className={`modal-header d-flex align-items-center justify-content-between ${styles.modalHeader}`}
                >
                  {/* <div className={styles.alias}>{ShiftReportHeader.HEADER}</div> */}
                  <div className={styles.headerTitle}>
                    Daily Shift Report Form{" "}
                    <span className={styles.reportNo}>{`(#${reportData?.reportNo})`}</span>
                  </div>
                  <div>
                    <img src={bathurstResource} alt="logo" />
                  </div>
                </div>
                <div className={`modal-body p-0 pt-3 pb-5 ${styles.modalBody}`}>
                  <div>
                    <div
                      className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      <div style={{ width: "25%" }}>Date</div>
                      <div style={{ width: "25%" }}>Day</div>
                      <div style={{ width: "25%" }}>Shift Type</div>
                      <div style={{ width: "25%" }}>Shift Supervisor</div>
                    </div>
                    <div
                      className={`px-3 py-3 pt-2 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                      <div style={{ width: "25%" }}>{moment(reportData?.date).format("DD/MM/YYYY")}</div>
                      <div style={{ width: "25%" }}>{reportData?.day}</div>
                      <div style={{ width: "25%", paddingBottom: "10px" }}>
                      {reportData?.shiftType}
                      </div>
                      <div style={{ width: "25%" }}>{reportData?.shiftSupervisor?.label}</div>
                    </div>
                  </div>
                  <div
                    className={` d-flex align-items-center justify-content-center ${styles.blackRow}`}
                  >
                    Safety
                  </div>
                  <div id="safetyContainer">
                    <div>
                      <div
                        className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
                      >
                        <div style={{ width: "15%" }}>Injury-Free Days</div>
                        <div style={{ width: "15%" }}>Previous Best</div>
                        <div style={{ width: "35%" }}>
                          Incidents / Accidents / Hazards
                        </div>
                        <div style={{ width: "35%" }}>
                          Daily Communication Topics with Crew
                        </div>
                      </div>
                      <div
                        className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                      >
                        <div style={{ width: "15%" }}>{reportData?.safety?.injuryFreeDays || "-"}</div>
                        <div style={{ width: "15%" }}>{reportData?.safety?.previousBest || "-"}</div>
                        <div style={{ width: "35%" }}>{reportData?.safety?.incidents || "-"}</div>
                        <div style={{ width: "35%" }}>{reportData?.safety?.communicationTopics || "-"}</div>
                      </div>
                    </div>
                    <div>
                      <div
                        className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
                      >
                        <div style={{ width: "30%" }}>Traffic Changes</div>
                        <div style={{ width: "30%" }}>
                          Barricades / Roads Closed
                        </div>
                        <div style={{ width: "20%" }}>
                          Did we have a safe shift yesterday?
                        </div>
                        <div style={{ width: "20%" }}>
                          Previous Shift Safety Ranking
                        </div>
                      </div>
                      <div
                        className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                      >
                        <div style={{ width: "30%" }}>{reportData?.safety?.trafficChanges || "-"}</div>
                        <div style={{ width: "30%" }}>{reportData?.safety?.barricades || "-"}</div>
                        <div style={{ width: "20%" }}>{reportData?.safety?.safeShift ? "No" :"Yes" }</div>
                        <div style={{ width: "20%" }}>{reportData?.safety?.safetyRanking}</div>
                      </div>
                    </div>
                    <div
                      className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      <div style={{ width: "15%" }}>Exclusion Zones - Area</div>
                      <div style={{ width: "15%" }}>Hazard</div>
                      <div style={{ width: "35%" }}>Crew Issues</div>
                      <div style={{ width: "35%" }}>Tarp Level Changes</div>
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                      <div style={{ width: "15%" }}>{reportData?.safety?.exclusionZones || "-"}</div>
                      <div style={{ width: "15%" }}>{reportData?.safety?.hazard || "-"}</div>
                      <div style={{ width: "35%" }}>{reportData?.safety?.crewIssues || "-"}</div>
                      <div style={{ width: "35%" }}>{reportData?.safety?.trapLevelChanges || "-"}</div>
                    </div>
                  </div>
                  <div
                    className={` d-flex align-items-center justify-content-center ${styles.blackRow}`}
                  >
                    Production
                  </div>
                  <div id="productionContainer">
                    <div
                      className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      Shift
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                     {reportData?.production?.shift || "-"}
                    </div>
                    <div
                      className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      Other Comments / Sketches
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                     {reportData?.production?.otherComments || "-"}
                    </div>
                    <div
                      className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      Maintenance Issues
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                     {reportData?.production?.maintenanceIssues || "-"}
                    </div>
                    <div
                      className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      Environment / Water Management / Dewatering
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                    {reportData?.production?.environment || "-"}
                    </div>
                    <div
                      className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      GeoTechnical issues
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                       {reportData?.production?.geoTechnicalIssues || "-"}
                    </div>
                    <div
                      className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      <div style={{ width: "25%" }}>Recorded by</div>
                      <div style={{ width: "25%" }}>Reviewed by</div>
                      <div style={{ width: "25%" }}>Reviewed by</div>
                      <div style={{ width: "25%" }}>Date</div>
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                      <div style={{ width: "25%" }}>{reportData?.production?.recordedBy || "-"}</div>
                      <div style={{ width: "25%" }}>{reportData?.production?.reviewedByFirst || "-"}</div>
                      <div style={{ width: "25%" }}>{reportData?.production?.reviewedBySecond || "-"}</div>
                      <div style={{ width: "25%" }}>{moment(reportData?.production?.recordedByDate).format("DD/MM/YYYY")}</div>
                    </div>
                    <div
                      className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      <div style={{ width: "25%" }}>Shift Supervisor</div>
                      <div style={{ width: "25%" }}>
                        OnComing Shift Supervisor{" "}
                      </div>
                      <div style={{ width: "25%" }}>Mine Manager</div>
                      <div style={{ width: "25%" }}>Date</div>
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                      <div style={{ width: "25%" }}>{reportData?.production?.shiftSupervisor || "-"}</div>
                      <div style={{ width: "25%" }}>{reportData?.production?.oncomingShiftSupervisor || "-"}</div>
                      <div style={{ width: "25%" }}>{reportData?.production?.mineManager || "-"}</div>
                      <div style={{ width: "25%" }}>{moment(reportData?.production?.shiftSupervisorDate).format("DD/MM/YYYY")}</div>
                    </div>
                    <div
                      className={` px-3 mt-2 d-flex align-items-center justify-content-between ${styles.header}`}
                    >
                      Crew Sign Off Below Or Attach Crew Attendance Form (Before
                      Shift Commences)
                    </div>
                    <div
                      className={`px-3 py-3 d-flex align-items-center justify-content-between ${styles.data}`}
                    >
                      {reportData?.production?.commences || "-"}
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div
          id="shift-report"
          className={`modal fade show ${styles.modalTop}`}
          tabIndex="-1"
          role="dialog"
        >
          <div
            id="modalDialogTop"
            className={`modal-dialog modal-dialog-centered ${styles.modalTopArea}`}
            role="document"
          >
            <div className={`modal-content px-5 ${styles.modalContent}`}>
              <div
                className={`modal-header d-flex align-items-center justify-content-between ${styles.modalHeader}`}
              >
                <div className={styles.alias}>Pre-Shift Instruction</div>

                <div className="d-flex align-items-center justify-content-between">
                  <div className={`pr-3 ${styles.alias}`}>DD/MM/YYYY</div>
                  <img src={bathurstResource} alt="logo" />
                </div>
              </div>
              <div className={`py-1 ${styles.data}`}>
                Sign in to indicate attendance and understanding.
              </div>
              <div className={`modal-body p-0 pt-3 pb-5 ${styles.modalBody}`}>
                <div className="d-flex align-items-center justify-content-between">
                  <SignatureSection title={"Coal Crew"}/>
                  <SignatureSection title={"Coal A"}/>
                  <SignatureSection title={"Coal B"}/>
                </div>

                <div className=" mt-5 d-flex align-items-center justify-content-between">
                <SignatureSection title={"M-Stream Crew"}/>
                <SignatureSection title={"C Crew"}/>
                <SignatureSection title={"Health & Safety"}/>
                </div>

              </div>
            </div>
          </div>
        </div> */}
      </div>
    )}
    </>
  );
};

export default ViewShiftReport;
