


import ArchivedTable from "./ArchivedTable/ArchivedTable";
import { ArchivedProvider } from "./Context/Context";
import Header from "./Header/Header";

const Archived = () => {

  return (
    <>
      <ArchivedProvider>
        <Header />
        <ArchivedTable />
      </ArchivedProvider>
    </>
  );
};

export default Archived;
