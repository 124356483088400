import AuthLayout from "../../components/AuthLayout";
import { PreStartProvider } from "./Context/Context";
import Header from "./SubComponent/Header/Header";
import PreStartTable from "./SubComponent/PreStartTable/PreStartTable";

const PreStartCheck = () => {
  return (
    <AuthLayout pageName="Pre-Start Checks">
      <div
        className="right_sidebar_body d-flex flex-column"
        id="main"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <PreStartProvider>
          <Header />
          <PreStartTable />
        </PreStartProvider>
      </div>
    </AuthLayout>
  );
};

export default PreStartCheck;
