import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import pdf from "../../../../assets/svgs/pdf.svg";
import removeLogo from "../../../../assets/svgs/remove.svg";
import UploadImage from "../../../../assets/svgs/upload.svg";
import fileImage from "../../../../assets/svgs/shift-maintenance/file.svg";
import { IoCloseSharp } from "react-icons/io5";

function ImageUploader({ selectedImage, setSelectedImage, setFile , originalFileName="" }) {
  const [fileTypeError, setFileTypeError] = useState(null);
  const onDrop = useCallback(
    (acceptedFiles) => {
      setFileTypeError(null);
      // Do something with the files
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          file.originalname = originalFileName
          setFile(file);
          setSelectedImage(reader.result);
        };
        reader.readAsDataURL(file); // Read file as Data URL
      });
    },
    [setSelectedImage]
  );

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    onDropRejected: (rejectedFiles) => {
      const unsupportedFiles = rejectedFiles.filter(
        (file) => !["image/png", "image/jpeg", "image/jpg"].includes(file.type)
      );

      if (unsupportedFiles.length > 0) {
        setFileTypeError(
          "Unsupported file type. Please select a PNG, JPEG, or JPG file."
        );
      }
    },
    noClick: true,
    noKeyboard: true,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
    },
    maxSize: 1000 * 1024, // 1 MB
  });

  const removeImage = () => setSelectedImage(null); // Function to remove the image

  const handleCrossButtonClick = () => {
    setSelectedImage(false);
  };

  return (
    <div
      {...getRootProps()}
      style={{
        // marginTop: "20px",
        // height: "210px",
        width: "100%",
        // height: "100%",
        height: "248px",
        minWidth: 300,
        borderRadius: "10px",
        border: !selectedImage ? "1px dashed #E1E4EA" : "1px dashed #E1E4EA",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: !selectedImage ? "#F8FAFD" : "#F8FAFD",
        padding: "10px", // Add padding inside the dropzone
      }}
    >
      <input {...getInputProps()} />
      {selectedImage ? (
        <div>
          <div
            className="d-flex align-items-center justify-content-between"
            onClick={handleCrossButtonClick}
            style={{
              position: "absolute",
              top: "270px",
              right: "35px",
              cursor: "pointer",
            }}
          >
            <IoCloseSharp color="black" fontSize={19.5} />
          </div>
          <div className="d-flex flex-column justify-content-center align-items-center">
            <embed
              src={selectedImage}
              alt="preview"
              style={{ maxWidth: "100%", maxHeight: "175px" }} // Image will not overflow the container
            />
          </div>
        </div>
      ) : (
        <>
          {fileTypeError && <p style={{ color: "red" }}>{fileTypeError}</p>}

          <div
            style={{
              position: "relative",
              maxWidth: "80%",
              maxHeight: "50%",
              marginTop: "10px",
            }}
          >
            <img
              style={{ maxWidth: "50px", maxHeight: "50px" }}
              src={fileImage}
              alt="fileImage"
            />
          </div>
          <div
            style={{
              font: "normal normal bold 14px/19px Open Sans",
              color: "#576B8D",
              marginTop: "10px",
            }}
          >
            Drag and drop file
          </div>
          <div
            style={{
              font: "normal normal 600 14px/19px Open Sans",
              color: "#576B8D",
            }}
          >
            or
          </div>
          <div>
            <div
              name="Save"
              className="mt-3"
              style={{
                background: "#FFFFFF",
                border: "1px solid #E1E8F4",
                borderRadius: "15px",
                font: "normal normal 600 12px/18px Open Sans",
                color: "#386DFF",
                width: "84px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={open}
            >
              Browse
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ImageUploader;
