import Flyer from "pages/ShiftMaintenance/Component/Flyers/Flyer";
import ViewDetailsComponent from "./ViewDetailsComponent";
import { REQUESTED_FLYER } from "pages/ShiftMaintenance/utils/Constant";
import { useRequestedContext } from "../Context/Context";
// import ApproveJob from "../ApproveJob/ApproveJob";
// import RejectJob from "../RejectJob/RejectJob";

const ViewDetails = ({ data, handleApproveJobModal, handleRejectJobModal }) => {
  const { openFlyer, setOpenFlyer } = useRequestedContext();

  // const handleApproveJob = ()=>{
  //   setOpenFlyer(REQUESTED_FLYER.JOB_APPROVE);
  // }

  // const handleRejectJob = ()=>{
  //   setOpenFlyer(REQUESTED_FLYER.JOB_REJECT);
  // }

  return (
    <>
      {openFlyer === REQUESTED_FLYER.VIEW_DETAILS && (
        <Flyer
          isOpen={openFlyer === REQUESTED_FLYER.VIEW_DETAILS}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={`${data?.assetId} - Job Request`}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 9.25rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}
          tabs={[
            {
              title: "",
              leftActionButtons: [
                {
                  label: "Reject Job",
                  onClick: async () => handleRejectJobModal(data?.jobId),
                  style: {
                    background: "#FFFFFF",
                    border: "1px solid #E1E8F4",
                    borderRadius: "15px",
                    font: "normal normal bold 12px/17px Open Sans",
                    color: "#186FD9",
                  },
                },
              ],

              rightActionButtons: [
                {
                  label: "Approve Job",
                  onClick: async () => handleApproveJobModal(data?.jobId),
                },
              ],
              component: <ViewDetailsComponent data={data} />,
            },
          ]}
        />
      )}

      {/* {openFlyer === REQUESTED_FLYER.JOB_APPROVE && (
        <ApproveJob setOpenFlyer={setOpenFlyer} openFlyer={openFlyer} data={data} />
      )}

      {openFlyer === REQUESTED_FLYER.JOB_REJECT && (
        <RejectJob setOpenFlyer={setOpenFlyer} openFlyer={openFlyer} data={data} />
      )} */}
    </>
  );
};

export default ViewDetails;
