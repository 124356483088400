import "./Dashboard.css";
import AuthLayout from "../../components/AuthLayout";
import Summary from "./SubComponents/Summary/Summary";
import Weather from "./SubComponents/WeatherForeCast/WeatherCard/Weather";
import Header from "./SubComponents/Header/Header";
import { useEffect, useRef, useState } from "react";
import Search from "pages/ShiftPlanning/components/GeneralFields/Search";
import ExcavatorsCards from "./SubComponents/ExcavatorsCards/ExcavatorsCards";
import HourlyResult from "./SubComponents/HourlyResults/HourlyResult";
import { CallWithAuth, CallWithAuthFormData } from "action/apiActions";
import useAuth from "hooks/useAuth";
import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { SUPERVISOR_END_SHIFT } from "../../action/apiPath";
import { toast } from "react-toastify";

function AssetPerformance() {
  const [radioValue, setRadioValue] = useState(1);
  const [isExpanded, setIsExpanded] = useState(true); // Manage toggle state
  const [summaryData, setSummaryData] = useState({})
  const [excavatorData, setExcavatorData] = useState([])
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredResults, setFilteredResults] = useState([]);
  // State to manage the visibility of Weather component
  const [showWeather, setShowWeather] = useState(false);
  const [exHourlyResult, setExHourlyResult] = useState({})
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [shiftDashboardData, setShiftDashboardData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    queryParams.get("date")
      ? moment.tz(queryParams.get("date"), "YYYY-MM-DD", "Pacific/Auckland").toDate()
      : new Date()
  );
  const [shiftOptions, setShiftOptions] = useState([]);
  const [selectedShift, setSelectedShift] = useState({ day: undefined, night: undefined });
  const [shiftType, setShiftType] = useState(queryParams.get("type") ? queryParams.get("type") : 'day');
  const [loading, setLoading] = useState(true);
  const [weatherData, setWeatherData] = useState({});
  const { auth } = useAuth();
  const timeoutRef = useRef(null); // Create a ref to store the timeout ID


  const [showEndShiftModal, setEndShiftShowModal] = useState(false);
  const [refreshPage, setRefreshPage] = useState(false);
  const handleEndShiftOpenModal = () => setEndShiftShowModal(true);
  const handleShiftCloseModal = () => setEndShiftShowModal(false);


  useEffect(() => {
    const dateFromQuery = queryParams.get("date");
    const shiftTypeFromQuery = queryParams.get("type")
    const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

    if (dateFromQuery && formattedSelectedDate !== dateFromQuery) {
      const newSelectedDate = moment.tz(dateFromQuery, "YYYY-MM-DD", "Pacific/Auckland").toDate();
      setSelectedDate(newSelectedDate);
    }
    if (shiftTypeFromQuery && shiftType !== shiftTypeFromQuery) {
      setShiftType(shiftTypeFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await CallWithAuth("POST", `/api/fetchWeather`, { timestamp: Math.round(new Date().getTime() / 1000) });
        setWeatherData(data?.res?.data?.message)
      } catch (error) {
        console.log(error);
      }
    }
    fetchData()
  }, [])

  useEffect(() => {
    const source = axios.CancelToken.source()
    const cktkn = source.token

    const fetchShiftsData = async () => {
      try {
        setLoading(true);
        queryParams.set("date", moment(selectedDate).format("YYYY-MM-DD"));
        navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });

        const shiftResponse = await CallWithAuthFormData("POST", "/api/getShiftsForDashboard", {
          userId: auth?.userData?._id,
          date: moment(selectedDate).format("YYYY-MM-DD"),
        }, {}, cktkn);

        const shiftsData = shiftResponse?.res?.data?.data || {};
        console.log(shiftsData);

        setShiftOptions({
          day: shiftsData.day,
          night: shiftsData.night,
        });

        setSelectedShift({
          day: shiftsData?.day?.[0]?.value,
          night: shiftsData?.night?.[0]?.value
        });

        if (!shiftsData[shiftType]?.[0]?.value) {
          shiftDataTranslator({})
          setExHourlyResult([])
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching shift data:", error);
        setLoading(false);
      }
    };

    if (auth?.userData?._id && selectedDate) fetchShiftsData();
    return () => {
      source.cancel('Operation canceled by the user.')
    }
  }, [selectedDate, auth]);

  // Fetch performance data based on shift type and selected shift every 5 seconds

  useEffect(() => {
    const source = axios.CancelToken.source();

    const fetchPerformanceData = async (dontLoad = false) => {
      if (!dontLoad)
        setLoading(true);
      try {

        const shiftResponse = await CallWithAuth(
          "GET",
          `/api/dashboard/${selectedShift[shiftType]}`,
          {},
          source.token
        );

        const data = shiftResponse?.res?.data;
        if (!dontLoad && shiftResponse.status) setLoading(false);
        if (shiftResponse.status)
          shiftDataTranslator(data);

        timeoutRef.current = setTimeout(() => {
          if (selectedShift[shiftType]) {
            fetchPerformanceData(true);
          }
        }, 5000);

      } catch (error) {
        if (!dontLoad) setLoading(false);
        timeoutRef.current = setTimeout(() => {
          if (selectedShift[shiftType]) {
            fetchPerformanceData(true);
          }
        }, 5000);
        console.log("error")
        console.error("Error fetching performance data:", error);
      }
    };

    // Navigate with shift type parameter if available
    if (shiftType) {
      queryParams.set("type", shiftType);
      navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
    }

    if (selectedShift[shiftType]) {
      fetchPerformanceData(); // Initial call
    } else {
      shiftDataTranslator({});
      setExHourlyResult([]);
    }

    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [selectedShift, shiftType, refreshPage]);




  const shiftDataTranslator = (data) => {
    setShiftDashboardData(data);
    // Extract summary data
    const summary = {
      materialType: data?.materialType,
      shiftActualBCM: data?.shiftActualBCM,
      shiftTargetBCM: data?.shiftTargetBCM,
      previousShiftActualBCM: data?.previousShiftActualBCM,
      previousShiftTotalBCM: data?.previousShiftTotalBCM,
      shiftStatus: data?.shiftStatus,
      reportNo: data?.reportNo,
      shiftType: data?.shiftType,
      shiftStartTime: data?.shiftStartTime,
      shiftEndTime: data?.shiftEndTime,
      excavatorStatus: data?.assetData?.excavatorStatus,
      truckStatus: data?.assetData?.truckStatus,
      BCMGeofence: data?.BCMGeofence
    };
    setSummaryData(data ? summary : {});
    setExcavatorData(data?.assetData?.excavatorAssignments ?? []);
    setFilteredResults(data?.assetData?.excavatorAssignments ?? [])
  }

  const toggleSummary = () => {
    setIsExpanded((prev) => !prev); // Toggle the state
  };

  const toggleWeather = () => {
    setShowWeather((prev) => !prev);
  };

  const handleWeatherCloseModal = () => setShowWeather(false);


  //Search excavator data.
  const searchExcavator = (query) => {

    setSearchQuery(query);
    if (query.trim() === "" || query.trim() === undefined) {
      // If search query is empty, show all data
      setFilteredResults(excavatorData);
    }
    else {
      query = query.trim().toLowerCase().replace(/\s+/g, '');

      const results = excavatorData.filter((assignment) => {

        // Check excavatorName
        const matchesExcavator = assignment.excavatorName.trim().toLowerCase().replace(/\s+/g, '').includes(query);

        //Check truckAssignments for truckName
        const matchesTruck = assignment.truckAssignments.filter(truck =>
          truck.truckName.trim().toLowerCase().replace(/\s+/g, '').includes(query)
        )?.length > 0;

        // Return true if any of the conditions match
        return matchesExcavator || matchesTruck;
      });

      setFilteredResults(results);
    }
  }

  const onExHourlyResultChange = (exName) => {
    exName = exName.toLowerCase().replace(/\s+/g, '');
    var response = excavatorData.filter((assignment) => {
      return assignment.excavatorName.trim().toLowerCase().replace(/\s+/g, '').includes(exName);
    });

    const hourlyResult = {
      shiftActualBCM: shiftDashboardData.shiftActualBCM,
      shiftStartTime: shiftDashboardData.shiftStartTime,
      shiftEndTime: shiftDashboardData.shiftEndTime,
      shiftStatus: shiftDashboardData.shiftStatus,
      exHourlyResult: response
    }

    setExHourlyResult(hourlyResult)

  }


  const SupervisorEndShift = async (equipmentIds) => {
    if (!equipmentIds.length) return;

    const payload = {
      shiftId: shiftDashboardData?.newShiftId,
      equipmentIds: equipmentIds,
    };

    try {
      const reqBody = payload;
      const response = await CallWithAuth("PUT", SUPERVISOR_END_SHIFT, reqBody);

      if (response?.res?.status === 200) {
        toast.success("Shift ended successfully!");
        handleShiftCloseModal();
        setRefreshPage(!refreshPage);
      } else {
        throw new Error("Failed to end shift");
      }
    } catch (error) {
      toast.error(
        "An error occurred while ending the shift. Please try again."
      );
    }
  }

  return (
    <AuthLayout pageName="Shift Management">

      {showWeather && <Weather handleClose={handleWeatherCloseModal} weatherData={weatherData} />}

      <div style={{ backgroundColor: "#FFFFFF" }} className="right_sidebar_body d-flex flex-column" id="main">
        <div className=" mb-0 border-0" style={{
          borderRadius: "0px",
          width: "100%",
          boxShadow: "0px 8px 50px #a3a3a325",
          position: "relative",
          zIndex: 200
        }}>
          <Header
            selectedDate={selectedDate}
            isLoading={loading}
            isExpanded={isExpanded}
            toggleSummary={toggleSummary}
            tabIdx={radioValue}
            onTabChange={(v) => {
              setRadioValue(v)
            }}
            toggleWeather={toggleWeather}
            showWeather={showWeather}
            weatherData={weatherData}
            onShiftTypeChange={setShiftType}
            shiftType={shiftType}
            shiftOptions={shiftOptions}
            selectedShift={selectedShift}
            onSelectShift={(type, id) => setSelectedShift((prev) => ({ ...prev, [type]: id }))}
            onDateSelect={setSelectedDate}
            summaryData={summaryData}
            showEndShiftModal={showEndShiftModal}
            handleEndShiftOpenModal={handleEndShiftOpenModal}
            handleShiftCloseModal={handleShiftCloseModal}
            assetData={shiftDashboardData?.assetData}
            handleEndShift={(equipmentIds) => SupervisorEndShift(equipmentIds)}
          />
          <Summary
            isLoading={loading}
            tabIdx={radioValue}
            isExpanded={isExpanded}
            summaryData={summaryData}
            excavatorData={excavatorData}
            exHourlyResultChange={(v) => {
              onExHourlyResultChange(v)
            }}

          />

        </div>
        <div style={{ overflowY: 'scroll', flexGrow: 1, backgroundColor: "#FCFCFD" }}>
          {radioValue === 1 && (
            <div className="h-100 d-flex flex-column juisfy-content-center">
              <div style={{ height: 68 }} className="d-flex justify-content-between align-items-center px-5 py-4">
                <span style={{ font: 'normal normal normal 16px/22px Open Sans' }}>
                  <span style={{ font: "normal normal 600 16px/22px Open Sans" }}>Excavators </span>
                  ({summaryData?.materialType == "Coal" ? "Tonnes" : "BCM"})
                </span>
                <Search query={searchQuery} onChange={(value) => searchExcavator(value)} />
              </div>
              <ExcavatorsCards isLoading={loading} excavatorData={filteredResults} summaryData={summaryData} />
            </div>
          )}

          {radioValue === 2 && (
            <div className="h-100 d-flex flex-column juisfy-content-center">
              <div style={{ height: 68, }} className="d-flex justify-content-between align-items-center px-5 py-4">
                <span style={{ font: "normal normal 600 16px/22px Open Sans" }}>Hourly Results</span>
              </div>
              <HourlyResult materialType={summaryData?.materialType} shiftType={shiftType} isLoading={loading} exHourlyResultData={exHourlyResult} />
            </div>
          )}
        </div>
      </div>
    </AuthLayout>
  );
}

export default AssetPerformance;
