import { CallWithAuth } from "action/apiActions";
import axios from "axios";
import useAuth from "hooks/useAuth";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FleetInitialFilters } from "../utils/Constant";
import { convertDataToGroup, formatQuery, getValuesFromObj } from "../utils/Helper";


const FleetContext = createContext();

export const FleetProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const timeoutRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [fleetData, setFleetData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [openFlyer, setOpenFlyer] = useState("");
  const [filters, setFilters] = useState(FleetInitialFilters);
  //   const filtersRef = useRef(RequestedInitialFilters);
  //   const searchQueryRef = useRef("");
  const [refreshPage, setRefreshPage] = useState(false);
  const [sortBy, setSortBy] = useState("assetCategory");

  // const [assetCategoryFilter, setAssetCategoryFilter] = useState("")
  // const [shiftAssignmentFilter, setShiftAssignmentFilter] = useState("")
  // const [preStartStatusFilter, setPreStartStatusFilter] = useState("")
  // const [preStartResultFilter, setPreStartResultFilter] = useState("")
  // const [assetStatusFilter, setAssetStatusFilter] = useState("")
  // const [assetActivityFilter, setAssetActivity] = useState("")
  // const [reportedOnFilter, setReportedOnFilter] = useState("")

  useEffect(() => {
    const sortByFromQuery = queryParams.get("sortBy");
    if (sortByFromQuery && sortBy !== sortByFromQuery) {
      setSortBy(sortByFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    // Helper function to fetch data
    const fetchData = async (dontLoad = false) => {
      if (!dontLoad) setLoading(true);
      try {
          const queryParams = new URLSearchParams({
                  sortBy,
                  assetCategory: getValuesFromObj(filters?.assetCategory),
                  shiftAssignment: getValuesFromObj(filters?.shiftAssignment),
                  preStartStatus: getValuesFromObj(filters?.preStartStatus),
                  preStartResult: getValuesFromObj(filters?.preStartResult),
                  assetStatus: getValuesFromObj(filters?.assetStatus),
                  assetActivity: getValuesFromObj(filters?.assetActivity)
                  
                }).toString();

        const fleetResponse = await CallWithAuth(
          "GET",
          `/api/fleet?${queryParams}`,
          {},
          source.token
        );

        const data = fleetResponse?.res?.data;
        //const data = fleetDummyData;
        const processedData = convertDataToGroup(data?.data)

        if (!dontLoad && data) setLoading(false);
        if (fleetResponse?.res?.status) {
          setFleetData(processedData);
          setFilteredResults(processedData);
        }
        setAutoRefreshTimeout();
        // If successful, start the timeout for auto-refresh
      } catch (error) {
        handleError(error, dontLoad);
      }
    };

    // Helper function for error handling and retry logic
    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error("Error fetching job data:", error);

      // Set retry with timeout on error
      setAutoRefreshTimeout();
    };

    // Helper function to manage auto-refresh
    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        if (sortBy) fetchData(true);
      }, 5000);
    };

    // Initial Navigation
    if (sortBy) {
      queryParams.set("sortBy", sortBy);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }

    // Initial Data Fetch
    if (sortBy) fetchData();
    else {
      setFleetData([]);
      setFilteredResults([]);
    }

    // Cleanup on unmount
    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [sortBy, filters, refreshPage]);

  const onApplyFilter = (filterObj, wantToClear = false) => {
    // if (wantToClear) {
    //   setFilteredResults(fleetData);
    //   return;
    // }
    setFilters(filterObj);
    // setAssetCategoryFilter(getValuesFromObj(filterObj?.assetCategory));
    // setShiftAssignmentFilter(getValuesFromObj(filterObj?.shiftAssignment));
    // setPreStartStatusFilter(getValuesFromObj(filterObj?.preStartStatus));
    // setPreStartResultFilter(getValuesFromObj(filterObj?.preStartResult));
    // setAssetStatusFilter(getValuesFromObj(filterObj?.assetStatus))
    // setAssetActivity(getValuesFromObj(filterObj?.assetActivity))

  };


  const searchFleetData = (searchQuery) => {
    setSearchQuery(searchQuery);
    if (!searchQuery.trim()) {
      setFilteredResults(fleetData);
      return;
    }
    const formattedSearchQuery = formatQuery(searchQuery);

    const filteredData = fleetData.map(day => ({
      ...day,
      data: day.data.filter(job => {
        // Define specific fields to search within
        const searchableFields = {
          equipmentLabel: job.equipment?.label,
          operator: job.operator,
          assetCategory: job?.assetCategory,
          assetStatus: job?.assetStatus
        };

        // Check if any searchable field contains the formatted search query
        return Object.values(searchableFields).some(value =>
          value && formatQuery(String(value)).includes(formattedSearchQuery)
        );
      })
    })).filter(day => day.data.length > 0);

    setFilteredResults(filteredData);
  };




  return (
    <FleetContext.Provider
      value={{
        loading,
        setLoading,
        openFlyer,
        sortBy,
        setSortBy,
        setOpenFlyer,
        filters,
        setFilters,
        searchQuery,
        setSearchQuery,
        searchFleetData,
        onApplyFilter,
        fleetData,
        filteredResults,
        refreshPage,
        setRefreshPage,
      }}
    >
      {children}
    </FleetContext.Provider>
  );
};

export const useFleetContext = () => {
  return useContext(FleetContext);
};
