import ColorDot from "pages/KPIDashboard/Component/ColorDot/ColorDot";
import Style from "../MTDAchieved/MTDAchieved.module.css";
import { calculatePercentage } from "pages/KPIDashboard/Utils/helper";

const MTDAchieved = ({ coalData, overburnedData }) => {
  return (
    <div style={{ height: 357 }} className="d-flex justify-content-between flex-column">
      <div className={`px-4 py-4 ${Style.container}`}>
        <div className={Style.title}>MTD % Achieved</div>
        <div
          className="mt-3 mb-4"
          style={{ border: "1px solid #ebebeb", opacity: "0.4" }}
        ></div>
        <div className="d-flex flex-column" style={{ gap: "10px" }}>
          <div className="d-flex flex-column">
            <div className={Style.labelCoal}>Product Coal</div>
            <div>
              <span>
                <ColorDot
                  bgColor={
                    calculatePercentage(
                      coalData?.productCoalMTDActual,
                      coalData?.productCoalMTDTarget
                    ) > 100
                      ? "#85D813"
                      : "#FF6565"
                  }
                  containerStyle={{
                    opacity: "1",
                    boxShadow: "0px 0px 30px #85D8134D",
                    width: "14px",
                    height: "14px",
                  }}
                />
              </span>
              <span className={Style.valCoal}>{`${calculatePercentage(
                coalData?.productCoalMTDActual,
                coalData?.productCoalMTDTarget
              )} %`}</span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className={Style.labelCoal}>ROM Coal</div>
            <div>
              <span>
                <ColorDot
                  bgColor={
                    calculatePercentage(
                      coalData?.romCoalMTDActual,
                      coalData?.romCoalMTDTarget
                    ) > 100
                      ? "#85D813"
                      : "#FF6565"
                  }
                  containerStyle={{
                    opacity: "1",
                    boxShadow: "0px 0px 30px #85D8134D",
                    width: "14px",
                    height: "14px",
                  }}
                />
              </span>
              <span className={Style.valCoal}>{`${calculatePercentage(
                coalData?.romCoalMTDActual,
                coalData?.romCoalMTDTarget
              )} %`}</span>
            </div>
          </div>
          <div className="d-flex flex-column">
            <div className={Style.labelCoal}>OB-BCM</div>
            <div>
              <span>
                <ColorDot
                  bgColor={
                    calculatePercentage(
                      overburnedData?.actual,
                      overburnedData?.target
                    ) > 100
                      ? "#85D813"
                      : "#FF6565"
                  }
                  containerStyle={{
                    opacity: "1",
                    boxShadow: "0px 0px 30px #FF65654D",
                    width: "14px",
                    height: "14px",
                  }}
                />
              </span>
              <span className={Style.valCoal}>{`${calculatePercentage(
                overburnedData?.mtdActual,
                overburnedData?.mtdTarget
              )} %`}</span>
            </div>
          </div>
        </div>
      </div>

      <div className={`px-4 py-4 ${Style.bottomContainer}`}>
        <div className={Style.labelROM}>ROM Stockpile (T)</div>
        <div className={Style.valROM}>{overburnedData?.romStockpile || '-'}</div>
      </div>
    </div>
  );
};

export default MTDAchieved;
