import React, { useEffect, useState } from "react";
import styles from "./ViewDetails.module.css";
import { FaAngleDown, FaAngleUp, FaChevronRight } from "react-icons/fa";
import GeneralInfo from "./Sections/GeneralInfo";
import MechanicSummary from "./Sections/MechanicSummary";
import SupervisorSummary from "./Sections/SupervisorSummary";
import FailedCheckStatus from "./Sections/FailedCheckStatus";
import { validateViewDetails, viewDetailsCondition } from "pages/ShiftMaintenance/utils/helper";
import { FORM_SECTION, JOB_STATUS } from "pages/ShiftMaintenance/utils/Constant";
import Collapsible from "pages/ShiftMaintenance/Component/Collapsible/Collapsible";
import { Riple } from "react-loading-indicators";

const ViewDetailsComponent = ({ isAllExpanded, detailData, isLoading }) => {
  const sections = [
    // Conditionally include General Info

    {
      title: "General Info",
      content: <GeneralInfo data={detailData} />,
    },

    // Conditionally include Mechanic's Service Summary
    ...(viewDetailsCondition(detailData?.generalInfo?.jobStatus, detailData?.generalInfo?.issueType, FORM_SECTION.MECHANIC_SUMMARY)
      ? [
        {
          title: "Mechanic's Service Summary",
          content: <MechanicSummary summaryData={detailData?.mechanicServiceSummary} issueType={detailData?.generalInfo?.issueType} />,
        },
      ]
      : []),

    // Conditionally include Pre-Start Check Info
    ...(viewDetailsCondition(detailData?.generalInfo?.jobStatus, detailData?.generalInfo?.issueType, FORM_SECTION.FAILED_CHECK)
      ? [
        {
          title: "Pre-Start Check Info",
          content: <FailedCheckStatus failedData={detailData?.preStartCheckInfo} />,
        },
      ]
      : []),

      // viewDetailsCondition(detailData?.generalInfo?.jobStatus, detailData?.generalInfo?.issueType, FORM_SECTION.SUPERVISOR_SUMMARY)
    ...(detailData?.generalInfo?.isReviewed
      ? [
        {
          title: "Maintenance Supervisor's Summary",
          content: <SupervisorSummary supervisorData={detailData?.supervisorInfo} />,
        },
      ]
      : []),
  ];


  const [openSections, setOpenSections] = useState([]);

  useEffect(() => {
    if (isAllExpanded) {
      setOpenSections(sections.map((_, index) => index)); // Expand all
    } else {
      setOpenSections([]); // Collapse all
    }
  }, [isAllExpanded]);

  const toggleSection = (index) => {
    if (openSections.includes(index)) {
      setOpenSections(openSections.filter((i) => i !== index));
    } else {
      setOpenSections([...openSections, index]);
    }
  };
  return (
    <div className={`px-4 mr-2 ml-3 ${styles.container} h-100`}>
      {!isLoading ?
        <div className={`pb-5 ${styles.collapsibleContainer}`}>
          {sections.map((section, index) => (
            <Collapsible key={index}
              title={section.title}
              content={section.content}
              isOpen={openSections.includes(index)}
              toggle={() => toggleSection(index)} />
          ))}
        </div> : <div
          className={`d-flex align-items-center justify-content-center h-100`}
        >
          <Riple color="#317bcc" size="medium" text="" textColor="" />
        </div>}
    </div>
  );
};


export default ViewDetailsComponent;
