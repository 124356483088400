import { CallWithAuth, CallWithAuthFormData } from "action/apiActions";
import axios from "axios";
import useAuth from "hooks/useAuth";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PreStartDummyData } from "pages/Pre-StartCheck/utils/DummyData";

import { PreStartInitialFilters } from "../utils/Constant";
import {
  convertDataToGroup,
  formatQuery,
  getValuesFromObj,
} from "../utils/Helper";
import moment from "moment";

const PreStartContext = createContext();

export const PreStartProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [selectedDate, setSelectedDate] = useState(
    queryParams.get("date")
      ? moment
        .tz(queryParams.get("date"), "YYYY-MM-DD", "Pacific/Auckland")
        .toDate()
      : new Date()
  );

  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const timeoutRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [preStartData, setPreStartData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [openFlyer, setOpenFlyer] = useState("");
  const [filters, setFilters] = useState(PreStartInitialFilters);
  //   const filtersRef = useRef(RequestedInitialFilters);
  //   const searchQueryRef = useRef("");
  const [selectedShift, setSelectedShift] = useState({
    day: undefined,
    night: undefined,
  });
  const [shiftType, setShiftType] = useState(
    queryParams.get("type") ? queryParams.get("type") : "day"
  );
  const [shiftOptions, setShiftOptions] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [sortBy, setSortBy] = useState("assetCategory");
  const [shiftData, setShiftData] = useState(shiftOptions[shiftType] ? shiftOptions[shiftType][0] : [])
  const [showCharts, setShowCharts] = useState(true);

  useEffect(() => {
    const dateFromQuery = queryParams.get("date");
    const shiftTypeFromQuery = queryParams.get("type");
    const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

    if (dateFromQuery && formattedSelectedDate !== dateFromQuery) {
      const newSelectedDate = moment
        .tz(dateFromQuery, "YYYY-MM-DD", "Pacific/Auckland")
        .toDate();
      setSelectedDate(newSelectedDate);
    }
    if (shiftTypeFromQuery && shiftType !== shiftTypeFromQuery) {
      setShiftType(shiftTypeFromQuery);
    }
  }, [location.search]);




  useEffect(() => {
    const source = axios.CancelToken.source();
    const cktkn = source.token;

    // Helper function to navigate with query parameters
    const navigateWithDate = () => {
      queryParams.set("date", moment(selectedDate).format("YYYY-MM-DD"));
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    };

    // Helper function to fetch shifts data
    const fetchShiftsData = async () => {
      try {
        setLoading(true);
        navigateWithDate(); // Update the navigation with the selected date

        const shiftResponse = await CallWithAuthFormData(
          "POST",
          "/api/getShiftsForDashboard?new=true",
          {
            userId: auth?.userData?._id,
            date: moment(selectedDate).format("YYYY-MM-DD"),
          },
          {},
          cktkn
        );

        const shiftsData = shiftResponse?.res?.data?.data || {};
        if (shiftsData) {
          // Push the manual object into both day and night arrays
          shiftsData.day.push({ value: "Unassigned", label: "Unassigned" });
          shiftsData.night.push({ value: "Unassigned", label: "Unassigned" });
        }

        processShiftData(shiftsData);
      } catch (error) {
        handleFetchError(error);
      }
    };

    // Helper function to process fetched shift data
    const processShiftData = (shiftsData) => {
      setShiftOptions({
        day: shiftsData.day,
        night: shiftsData.night,
      });

      setSelectedShift({
        day: shiftsData?.day?.[0]?.value,
        night: shiftsData?.night?.[0]?.value,
      });

      // Check if the selected shift type is valid
      if (!shiftsData[shiftType]?.[0]?.value) {
        setPreStartData([]);
        setFilteredResults([]);
      }

      setLoading(false); // Ensure loading is false after processing
    };

    // Helper function to handle fetch errors
    const handleFetchError = (error) => {
      console.error("Error fetching shift data:", error);
      setLoading(false); // Ensure loading is false on error
    };

    // Fetch shifts data if user is authenticated and a date is selected
    if (auth?.userData?._id && selectedDate) {
      fetchShiftsData();
    }

    // Cleanup function
    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [selectedDate, auth]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    // Helper function to fetch data
    const fetchData = async (dontLoad = false) => {
      if (!dontLoad) setLoading(true);
      try {

        const queryParams = new URLSearchParams({
          ...(selectedShift[shiftType] !== "Unassigned" && { shiftId: selectedShift[shiftType] }),
          ...(selectedShift[shiftType] === "Unassigned" && { date: moment(selectedDate).format("YYYY-MM-DD") }),
        }).toString();


        const response = await CallWithAuth(
          "GET",
          `/api/prestart/?${queryParams}`,
          {},
          source.token
        );
        //const data = PreStartDummyData;
        const data = response?.res?.data;
        if (!dontLoad && data) setLoading(false);
        if (response?.res?.status) {

          setPreStartData(data);
          setFilteredResults(data);
        }

        //setAutoRefreshTimeout();
        // If successful, start the timeout for auto-refresh
      } catch (error) {
        handleError(error, dontLoad);
      }
    };

    // Helper function for error handling and retry logic
    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error("Error fetching job data:", error);

      // Set retry with timeout on error
      //setAutoRefreshTimeout();
    };

    // Helper function to manage auto-refresh
    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        if (sortBy) fetchData(true);
      }, 5000);
    };

    // Initial Navigation
    if (shiftType) {
      queryParams.set("type", shiftType);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }

    // Initial Data Fetch
    if (selectedShift[shiftType]) fetchData();
    else {
      setPreStartData([]);
      setFilteredResults([]);
    }

    // Cleanup on unmount
    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [selectedDate, selectedShift, shiftType, refreshPage]);

  //Sort data
  useEffect(() => {
    sortData(sortBy);
  }, [sortBy]);


  const sortData = (key) => {
    if (!filteredResults) {
      return;
    }

    const statusOrder = ['cat a failure', 'cat b failure', 'cat c failure', 'passed', 'incomplete'];

    const sortedResults = filteredResults.slice().sort((a, b) => {
      if (key === 'result' || key === 'preStartStatus') {
        const statusA = (a?.status || '').toLowerCase();
        const statusB = (b?.status || '').toLowerCase();

        const statusIndexA = statusOrder.indexOf(statusA);
        const statusIndexB = statusOrder.indexOf(statusB);

        return (statusIndexA === -1 ? Infinity : statusIndexA) - (statusIndexB === -1 ? Infinity : statusIndexB);
      }

      if (key === 'assetCategory') {
        const unitA = a?.equipment?.Unit || '';
        const unitB = b?.equipment?.Unit || '';

        return unitA.localeCompare(unitB, undefined, { numeric: true, sensitivity: 'base' });
      }

      return 0;
    });

    console.log("sortedResults", sortedResults);

    setFilteredResults(sortedResults);
  };



  const formatObject = (obj) => {
    return Object.keys(obj).reduce((acc, key) => {
      // Ensure the value is an array before using .map
      const values = Array.isArray(obj[key]) ? obj[key] : [];
      acc[key] = values.map(item => item.value).join(', ') || null; // Join values with commas
      return acc;
    }, {});
  };
  const onApplyFilter = (filterObj, wantToClear = false) => {

    if (wantToClear) {
      setFilteredResults(preStartData);
      return;
    }
    const formattedFilters = formatObject(filterObj);

    const filteredData = preStartData.filter(item => {
      const filterCategory = formattedFilters.assetCategory
        ? formattedFilters.assetCategory.toLowerCase().split(', ')
        : [];
      const filterPreStartResult = formattedFilters.preStartResult
        ? formattedFilters.preStartResult.toLowerCase().split(', ')
        : [];
      const filterAssetActivity = formattedFilters.assetActivity
        ? formattedFilters.assetActivity.toLowerCase().split(', ')
        : [];
      const filterJobStatus = formattedFilters.jobStatus
        ? formattedFilters.jobStatus.toLowerCase().split(', ')
        : [];
      const filterPreStartStatus = formattedFilters.preStartStatus
        ? formattedFilters.preStartStatus.toLowerCase().split(', ')
        : [];
      const filterSupervisorReview = formattedFilters.supervisorReview
        ? formattedFilters.supervisorReview.toLowerCase().split(', ')
        : [];
      const filterOperatorNotification = formattedFilters.operatorNotification
        ? formattedFilters.operatorNotification.toLowerCase().split(', ')
        : [];

      const itemCategory = item.equipment.EquipmentType.toLowerCase();
      const itemJobStatus = item?.job?.jobStatus ? item.job.jobStatus.toLowerCase() : '';
      const itemStatus = item?.status ? item.status.toLowerCase() : '';
      const itemAssetActivity = item?.assetActivity ? item.assetActivity.toLowerCase() : '';
      const supervisorSignature = item?.supervisor?.signature;
      const operatorNotification = item?.notificationBySupervisor?.notifiedBy;
      const itemPreStartStatus = item?.status ? item.status.toLowerCase() : '';

      const isCategoryMatch = filterCategory.length === 0 || filterCategory.includes(itemCategory);
      const isJobStatusMatch = filterJobStatus.length === 0 || filterJobStatus.includes(itemJobStatus);
      const isStatusMatch = filterPreStartResult.length === 0 || filterPreStartResult.includes(itemStatus);
      const isAssetActivityMatch = filterAssetActivity.length === 0 || filterAssetActivity.includes(itemAssetActivity);

      const isSupervisorReviewMatch =
        filterSupervisorReview.length === 0 ||
        filterSupervisorReview.includes("complete") && supervisorSignature ||
        filterSupervisorReview.includes("incomplete") && !supervisorSignature;

      const isOperatorNotificationMatch =
        filterOperatorNotification.length === 0 ||
        filterOperatorNotification.includes("sent") && operatorNotification ||
        filterOperatorNotification.includes("pending") && !operatorNotification;


      const isPreStartStatusMatch =
        filterPreStartStatus.length === 0 ||
        (filterPreStartStatus.includes("complete") && itemPreStartStatus !== "incomplete") ||
        (filterPreStartStatus.includes("incomplete") && itemPreStartStatus === "incomplete");

      return (
        isCategoryMatch &&
        isJobStatusMatch &&
        isStatusMatch &&
        isAssetActivityMatch &&
        isSupervisorReviewMatch &&
        isOperatorNotificationMatch &&
        isPreStartStatusMatch
      );
    });

    setFilteredResults(filteredData);
  };





  const searchPreStartData = (searchQuery) => {

    setSearchQuery(searchQuery);
    if (!searchQuery.trim()) {
      setFilteredResults(preStartData);
      return;
    }
    const formattedSearchQuery = formatQuery(searchQuery);
    const filteredData = preStartData?.filter((item) => {
      return (
        String(item?.equipment?.Unit || "")
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(formattedSearchQuery) ||
        String(item?.operator || "")
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(formattedSearchQuery) ||
        String(item?.jobRefNo || "")
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(formattedSearchQuery) ||
        String(item?.preSTartReport?.refNo || "")
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(formattedSearchQuery) ||
        String(item?.shiftAssignment || "")
          .trim()
          .toLowerCase()
          .replace(/\s+/g, "")
          .includes(formattedSearchQuery)
      );
    });

    setFilteredResults(filteredData);
  };

  return (
    <PreStartContext.Provider
      value={{
        loading,
        setLoading,
        openFlyer,
        selectedDate,
        setSelectedDate,
        selectedShift,
        setSelectedShift,
        shiftType,
        setShiftType,
        shiftData,
        setShiftData,
        shiftOptions,
        sortBy,
        setSortBy,
        setOpenFlyer,
        filters,
        setFilters,
        searchQuery,
        setSearchQuery,
        searchPreStartData,
        onApplyFilter,
        preStartData,
        filteredResults,
        refreshPage,
        setRefreshPage,
        showCharts,
        setShowCharts
      }}
    >
      {children}
    </PreStartContext.Provider>
  );
};

export const usePreStartContext = () => {
  return useContext(PreStartContext);
};
