import Flyer from "pages/ShiftMaintenance/Component/Flyers/Flyer";
import ViewDetailsComponent from "./ViewDetailsComponent";
import {
  APPROVED_FLYER,
  ISSUE_TYPE,
  JOB_STATUS,
} from "pages/ShiftMaintenance/utils/Constant";
import { useEffect, useState } from "react";
import Signature from "../Signature/Signature";
import { useApprovedContext } from "../Context/Context";
import { JobViewDetails } from "pages/ShiftMaintenance/utils/ApiServices";
import { bindInitialFormData } from "pages/ShiftMaintenance/utils/helper";

const ViewDetails = ({
  jobId,
  handleEditJobModal,
  handleDeleteJob,
  setCloseConfirmModal,
  closeJobMessage,
  handleAddNotesSignature
}) => {
  const { openFlyer, setOpenFlyer } = useApprovedContext();

  const [loading, setLoading] = useState(false)

  const [isAllExpanded, setIsAllExpanded] = useState(false);

  const [viewDetailsData, setViewDetailsData] = useState({});

  const handleExpandAll = () => {
    setIsAllExpanded(!isAllExpanded);
  };


  useEffect(() => {
    const fetchViewDetails = async () => {
      setLoading(true)
      const viewDetailResponse = await JobViewDetails(jobId);
      if (viewDetailResponse) {
        setViewDetailsData(viewDetailResponse);
      }
      setLoading(false)
    };

    fetchViewDetails();
  }, [jobId]);

  const FlyerOptionMenu = [
    {
      name: "Edit",
      icon: "",
      onClick: () => handleEditJobModal(viewDetailsData?.jobId),
    },
    viewDetailsData?.generalInfo?.jobStatus === JOB_STATUS.PENDING && {
      name: "Delete",
      icon: "",
      onClick: () => handleDeleteJob(viewDetailsData?.jobId),
    },
  ].filter(Boolean);

  return (
    <>
      {openFlyer === APPROVED_FLYER.VIEW_DETAILS && (
        <Flyer
          isOpen={openFlyer === APPROVED_FLYER.VIEW_DETAILS}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={`${loading ? "HT000" : viewDetailsData?.generalInfo?.equipment?.label} - Job Details`}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 9.25rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}
          downloadIcon={false}
          actionIcon={true}
          actonOptions={FlyerOptionMenu}
          tabs={[
            {
              title: "",
              leftActionButtons: [
                {
                  label: isAllExpanded ? "Collapse All" : "Expand All",
                  onClick: async () => handleExpandAll(),
                  style: {
                    background: "#FFFFFF",
                    border: "1px solid #E1E8F4",
                    borderRadius: "15px",
                    font: "normal normal bold 12px/17px Open Sans",
                    color: "#186FD9",
                  },
                },
              ],

              rightActionButtons: [
                {
                  label:
                    viewDetailsData?.generalInfo?.isReviewed &&
                      viewDetailsData?.generalInfo?.jobStatus === JOB_STATUS.ENDED
                      ? "Close Job"
                      : "Add Notes & Signature",
                  onClick: async () =>
                    viewDetailsData?.generalInfo?.isReviewed &&
                      viewDetailsData?.generalInfo?.jobStatus === JOB_STATUS.ENDED
                      ? setCloseConfirmModal({
                        show: true,
                        title: "Close Job",
                        message: closeJobMessage(),
                        wantToDelete: true,
                        jid: viewDetailsData?.jobId,
                      })
                      : handleAddNotesSignature(),
                  isDisabled:
                    viewDetailsData?.generalInfo?.jobStatus ===
                      JOB_STATUS.PENDING ||
                      viewDetailsData?.generalInfo?.jobStatus ===
                      JOB_STATUS.INPROGRESS
                      ? true
                      : false,
                },
              ],
              component: <ViewDetailsComponent isLoading={loading} isAllExpanded={isAllExpanded} detailData={viewDetailsData} />,
            },
          ]}
        />
      )}


    </>
  );
};

export default ViewDetails;
