import React, { useEffect, useRef, useState } from "react";
import { Riple } from "react-loading-indicators";
import styles from "../styles.module.css";
import axios from "axios";
import { GroupingOptions, UtilisationTabs } from "../helpers";
import { CallWithAuth } from "action/apiActions";
import { KPI_DASHBOARD_OPERATIONAL_UTILISATION } from "action/apiPath";
import { useKPIDashboardContext } from "pages/KPIDashboard/Context/Context";
import moment from "moment";

const TABLE_HEADERS = {
  [UtilisationTabs.OperationalUtilisation]: [
    "Machine",
    "Shift",
    "Available Time (AT)",
    "Productive Time (PT)",
    "Actual Percent",
    "Target Percent",
    "Variance Percent",
  ],
  [UtilisationTabs.SpryCalendarAvailability]: [
    "Machine",
    "Shift",
    "Scheduled Time (ST)",
    "Breakdown Maintenance (BM)",
    "Calendar Time (CT)",
    "Actual Percent",
    "Target Percent",
    "Variance Percent",
  ],
  [UtilisationTabs.SpryCalendarUtilisation]: [
    "Machine",
    "Shift",
    "Productive Time (PT)",
    "Scheduled Time (ST)",
    "Breakdown Maintenance (BM)",
    "Actual Percent",
    "Target Percent",
    "Variance Percent",
  ],
};

const Table = ({ tab, groupBy, toggle }) => {
  const { selectedDate } = useKPIDashboardContext();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    setLoading(true);
    const source = axios.CancelToken.source();

    const fetch = async (dontLoad = false) => {
      try {
        if (!dontLoad) setLoading(true);
        const response = await CallWithAuth(
          "GET",
          KPI_DASHBOARD_OPERATIONAL_UTILISATION(
            moment(selectedDate).format("YYYY-MM-DD"),
            groupBy === GroupingOptions.AssetCategories ? 'CAT' : groupBy === GroupingOptions.AssetSubCategories ? 'SUBCAT' : 'SINGLE'
          ),
          {},
          source.token
        );

        const data = response?.res?.data;

        if (!dontLoad && data) setLoading(false);
        if (data && response?.res.status && response.status) {
          setData(data);
        }

        setAutoRefreshTimeout();
      } catch (error) {
        handleError();
      } finally {
        setLoading(false);
      }
    };

    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error("Error fetching performance data:", error);

      // Set retry with timeout on error
      setAutoRefreshTimeout();
    };

    //Helper function to manage auto-refresh
    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        fetch(true);
      }, 5000);
    };

    fetch();

    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [selectedDate, groupBy]);

  const calculatePercent = (num, den) => {
    if (den === 0) return 0;

    return (num / den) * 100;
  };

  const renderRow = (row) => {
    if (tab === UtilisationTabs.OperationalUtilisation) {
      const actual = calculatePercent(
        Number(row.PT || 0),
        row.AT || 0
      );
      const target = (row.operationalTargetPercent / (row.count || 1)) || 0
      return (
        <>
          <td>{row.unit}</td>
          <td>{row.shiftType}</td>
          <td>{row.AT}</td>
          <td>{row.PT}</td>
          <td>{actual.toFixed(2)} %</td>
          <td>{target.toFixed(2)} %</td>
          <td>{(actual - target).toFixed()} %</td>
        </>
      );
    }
    if (tab === UtilisationTabs.SpryCalendarAvailability) {
      const actual = calculatePercent(
        Number(row.ST || 0) - Number(row.BM || 0),
        row.CT || 0
      );
      const target = (row.spryAvailabilityTargetPercent / (row.count || 1)) || 0
      return (
        <>
          <td>{row.unit}</td>
          <td>{row.shiftType}</td>
          <td>{row.ST}</td>
          <td>{row.BM}</td>
          <td>{row.CT}</td>
          <td>{actual.toFixed(2)} %</td>
          <td>{target.toFixed(2)} %</td>
          <td>{(actual - target).toFixed()} %</td>
        </>
      );
    }
    if (tab === UtilisationTabs.SpryCalendarUtilisation) {
      const actual = calculatePercent(
        row.PT || 0,
        Number(row.ST || 0) - Number(row.BM || 0)
      );
      const target = (row.spryUtilisationTargetPercent / (row.count || 1)) || 0
      return (
        <>
          <td>{row.unit}</td>
          <td>{row.shiftType}</td>
          <td>{row.PT}</td>
          <td>{row.ST}</td>
          <td>{row.BM}</td>
          <td>{actual.toFixed(2)} %</td>
          <td>{target.toFixed(2)} %</td>
          <td>{(actual - target).toFixed()} %</td>
        </>
      );
    }
  };

  return loading ? (
    <div
      style={{ height: "300px", width: "100%", left: 0, zIndex: 1000 }}
      className="d-flex align-items-center justify-content-center"
    >
      <Riple color="#317bcc" size="medium" text="" textColor="" />
    </div>
  ) : (
    <>
      <table className={styles["table"]}>
        <thead>
          <tr className={styles["table-header"]}>
            {TABLE_HEADERS[tab].map((e, idx) => (
              <th key={idx} style={idx === 0 || idx === 1 ? { width: '6%' } : {}}>{e}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {!data || data[toggle.toLowerCase()].length === 0 ? (
            <tr className={styles["table-row"]} style={{ height: '300px' }}>
              <td colSpan={7} className="h-100 w-100">
                <div className="d-flex justify-content-center align-items-center">
                  <p className={styles["no-data"]}>No Data to Display</p>
                </div>
              </td>
            </tr>
          ) : (
            data[toggle.toLowerCase()].map((row, index) => (
              <tr className={styles["table-row"]} key={index}>
                {renderRow(row)}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </>
  );
};

export default Table;
