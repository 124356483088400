import TextAreaField from "pages/ShiftMaintenance/Component/GeneralFields/TextAreaField";

const EditSupervisorSummary = ({ editFormData, handleOnChange,handleAddNotesSignature }) => {
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };

  return (
    <div>
      <div
        style={{ marginTop: "12px" }}
        className="d-flex align-content-center pt-3"
      >
        <div
          className="mb-2"
          style={{
            width: "20%",
            ...labelStyle,
          }}
        >
          Corrective Action/ Notes
        </div>
        <TextAreaField
          wrapperStyle={{ paddingLeft: "83px", flexGrow: "1" }}
          style={{
            height: "100%",
            border: "1px solid #E1E8F4",
            borderRadius: "4px",
          }}
          value={editFormData?.supervisorInfo.notes}
          onChange={(v) => handleOnChange("supervisorInfo.notes", v)}
        />
      </div>
      <div className="d-flex align-content-center justify-content-between w-100">
        <div style={{ width: "20%", labelStyle }}>Signature</div>
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            width: "345px",
            height: "100px",
            background: "#E1E4EA",
            border: "1px solid #E1E8F4",
            borderRadius: "4px",
          }}
        >
          <div
            className="py-3 px-5 d-flex flex-column justify-content-center"
            style={{ width: "70%" }}
          >
            <embed
              style={{ maxHeight: "70px", maxWidth: "170px" }}
              src={editFormData?.supervisorInfo?.signature}
              alt="sign"
            />
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-center"
            style={{
              width: "64px",
              marginBottom: "55px",
              height: "30px",
              background: "#FFFFFF",
              border: "1px solid #E1E8F4",
              borderRadius: "15px",
              font: "normal normal bold 12px/17px Open Sans",
              color: "#186FD9",
              cursor:"pointer"
            }}
            onClick={handleAddNotesSignature}
          >
            Edit
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSupervisorSummary;
