import TextInputField from "pages/ShiftNotes/Component/GeneralFields/TextInputField";
import styles from "../AddEditShiftReport.module.css";
const SafetySection = ({ showError, formData, handleChange }) => {
  return (
    <>
      <div
        className={` d-flex align-items-center justify-content-center ${styles.blackRow}`}
      >
        Safety
      </div>
      <div id="safetyContainer">
        <div>
          <div
            className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
          >
            <div style={{ width: "15%" }}>Injury-Free Days</div>
            <div style={{ width: "15%" }}>Previous Best</div>
            <div style={{ width: "35%" }}>Incidents / Accidents / Hazards</div>
            <div style={{ width: "35%" }}>
              Daily Communication Topics with Crew
            </div>
          </div>
          <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
            <div style={{ width: "15%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "150px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.injuryFreeDays}
                onChange={(v) => handleChange("safety.injuryFreeDays", v)}
                error={showError && !formData?.safety?.injuryFreeDays}
                errorMessage="Please enter Injury-Free Days"
              />
            </div>
            <div style={{ width: "15%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                viewOnly={false}
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "150px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.previousBest}
                onChange={(v) => handleChange("safety.previousBest", v)}
                error={showError && !formData?.safety?.previousBest}
                errorMessage="Please enter Previous Best"
              />
            </div>
            <div style={{ width: "35%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                viewOnly={false}
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "370px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.incidents}
                onChange={(v) => handleChange("safety.incidents", v)}
                error={showError && !formData?.safety?.incidents}
                errorMessage="Please enter Incidents / Accidents / Hazards"
              />
            </div>
            <div style={{ width: "35%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "370px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.communicationTopics}
                onChange={(v) => handleChange("safety.communicationTopics", v)}
                error={showError && !formData?.safety?.communicationTopics}
                errorMessage="Please enter Daily Communication Topics with Crew"
              />
            </div>
          </div>
        </div>
        <div>
          <div
            className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
          >
            <div style={{ width: "30%" }}>Traffic Changes</div>
            <div style={{ width: "33%" }}>Barricades / Roads Closed</div>
            <div style={{ width: "20%" }}>
              Did we have a safe shift yesterday?
            </div>
            <div style={{ width: "17%" }}>Previous Shift Safety Ranking</div>
          </div>
          <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
            <div style={{ width: "30%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "325px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.trafficChanges}
                onChange={(v) => handleChange("safety.trafficChanges", v)}
                error={showError && !formData?.safety?.trafficChanges}
                errorMessage="Please enter Traffic Changes"
              />
            </div>
            <div style={{ width: "33%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                viewOnly={false}
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "325px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.barricades}
                onChange={(v) => handleChange("safety.barricades", v)}
                error={showError && !formData?.safety?.barricades}
                errorMessage="Please enter Barricades / Roads Closed"
              />
            </div>
            <div style={{ width: "20%", paddingBottom: "13px" }}>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="Yes"
                  id="Yes"
                  value={"1"}
                  checked={formData.safety?.safeShift}
                  style={{ width: "16px", height: "16px" }}
                  onChange={(v) => handleChange("safety.safeShift", true)}
                />
                <div
                  className={`form-check-label ${styles.dayLabel}`}
                  htmlFor="Yes"
                >
                  Yes
                </div>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="No"
                  id="No"
                  value="0"
                  checked={formData.safety?.safeShift === false}
                  style={{ width: "16px", height: "16px" }}
                  onChange={(v) => handleChange("safety.safeShift", false)}
                />
                <div
                  className={`form-check-label ${styles.dayLabel}`}
                  htmlFor="No"
                >
                  No
                </div>
              </div>
            </div>
            <div style={{ width: "17%", paddingBottom: "13px" }}>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="safetyRankingGreen"
                  id="safetyRankingGreen"
                  value="Green"
                  checked={formData.safety?.safetyRanking === "Green"}
                  style={{ width: "16px", height: "16px" }}
                  onChange={(v) => {
                    handleChange("safety.safetyRanking", "Green");
                  }}
                />
                <div
                  className={`form-check-label ${styles.dayLabel}`}
                  htmlFor="safetyRankingGreen"
                >
                  Green
                </div>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="safetyRankingYellow"
                  id="safetyRankingYellow"
                  value="Yellow"
                  checked={formData.safety?.safetyRanking === "Yellow"}
                  style={{ width: "16px", height: "16px" }}
                  onChange={(v) =>
                    handleChange("safety.safetyRanking", "Yellow")
                  }
                />
                <div
                  className={`form-check-label ${styles.dayLabel}`}
                  htmlFor="safetyRankingYellow"
                >
                  Yellow
                </div>
              </div>
              <div class="form-check form-check-inline">
                <input
                  class="form-check-input"
                  type="radio"
                  name="safetyRankingRed"
                  id="safetyRankingRed"
                  value="Red"
                  checked={formData.safety?.safetyRanking === "Red"}
                  style={{ width: "16px", height: "16px" }}
                  onChange={(v) => handleChange("safety.safetyRanking", "Red")}
                />
                <div
                  className={`form-check-label ${styles.dayLabel}`}
                  htmlFor="safetyRankingRed"
                >
                  Red
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div
            className={` px-3 d-flex align-items-center justify-content-between ${styles.header}`}
          >
            <div style={{ width: "15%" }}>Exclusion Zones - Area</div>
            <div style={{ width: "15%" }}>Hazard</div>
            <div style={{ width: "35%" }}>Crew Issues</div>
            <div style={{ width: "35%" }}>Tarp Level Changes</div>
          </div>
          <div className="px-3 pt-2 d-flex align-items-center justify-content-between">
            <div style={{ width: "15%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "150px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.exclusionZones}
                onChange={(v) => handleChange("safety.exclusionZones", v)}
                error={showError && !formData?.safety?.exclusionZones}
                errorMessage="Please enter Exclusion Zones"
              />
            </div>
            <div style={{ width: "15%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                viewOnly={false}
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "150px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.hazard}
                onChange={(v) => handleChange("safety.hazard", v)}
                error={showError && !formData?.safety?.hazard}
                errorMessage="Please enter Hazard"
              />
            </div>
            <div style={{ width: "35%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                viewOnly={false}
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "370px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.crewIssues}
                onChange={(v) => handleChange("safety.crewIssues", v)}
                error={showError && !formData?.safety?.crewIssues}
                errorMessage="Please enter Crew Issues"
              />
            </div>
            <div style={{ width: "35%" }}>
              <TextInputField
                label=""
                required={true}
                placeholder="Type…"
                style={{
                  border: "1px solid #EBEBEB",
                  borderRadius: "4px",
                  font: "normal normal normal 10px/10px Open Sans",
                  color: "#050505",
                  width: "370px",
                  height: "35px",
                  paddingLeft: "8px",
                }}
                value={formData.safety?.trapLevelChanges}
                onChange={(v) => handleChange("safety.trapLevelChanges", v)}
                error={showError && !formData?.safety?.trapLevelChanges}
                errorMessage="Please enter Tarp Level Changes"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SafetySection;
