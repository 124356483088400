import Flyer from "pages/ShiftMaintenance/Component/Flyers/Flyer";
import { APPROVED_FLYER, ISSUE_TYPE } from "pages/ShiftMaintenance/utils/Constant";
import { useState } from "react";
import AddJobComponent from "./AddJobComponent";
import { formateDataForSaving, showToast } from "pages/ShiftMaintenance/utils/helper";
import { useApprovedContext } from "../Context/Context";
import { AddJobData } from "pages/ShiftMaintenance/utils/ApiServices";

const AddJob = () => {
  const { equipmentList, openFlyer, setOpenFlyer, refreshPage, setRefreshPage } = useApprovedContext();

  const handleOnChange = (key, val) => {
    const tempFormData = { ...formData };
    tempFormData[key] = val;
    setFormData(tempFormData);
  };

  const initialFormData = {
    equipment: "",
    issueType: "",
    scheduledFor: "",
    assetStatus: "Operational",
    notesAndComments: "",
    "createdByType": "Supervisor"
  };
  const [formData, setFormData] = useState(initialFormData);
  const [showError, setShowError] = useState(false);

  const handleAddJob = async () => {
    if (!formData?.equipment || !formData?.issueType) {
      setShowError(true)
      return
    }

    const formattedData = {
      ...formateDataForSaving({ ...formData })
    };
    const addJobResponse = await AddJobData(formattedData);
    if (addJobResponse) {
      showToast(true, "Job added successfully");
      setOpenFlyer("");
      setRefreshPage(!refreshPage)
    } else {
      showToast(false, "An error occurred while adding job");
    }
  };

  return (
    <>
      {openFlyer === APPROVED_FLYER.ADD_JOB && (
        <Flyer
          isOpen={openFlyer === APPROVED_FLYER.ADD_JOB}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={"Add Job"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 9.25rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Submit",
                  onClick: async () => handleAddJob(),
                },
              ],
              component: (
                <AddJobComponent
                  showError={showError}
                  equipmentList={equipmentList}
                  formData={formData}
                  setFormData={setFormData}
                  handleOnChange={handleOnChange}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default AddJob;
