import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from 'moment-timezone';
import useAuth from "hooks/useAuth";
import { CallWithAuth, CallWithAuthFormData } from "action/apiActions";
import axios from "axios";

const ExcavatorLoadTrackerContext = createContext();

export const ExcavatorLoadTrackerProvider = ({ children }) => {
    const { auth } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    const mainContainer = useRef();

    const [selectedDate, setSelectedDate] = useState(
        queryParams.get("date") || moment().tz("Pacific/Auckland").format("YYYY-MM-DD")
    );
    const [shiftType, setShiftType] = useState(queryParams.get("type") ? queryParams.get("type") : 'day');
    const [selectedShift, setSelectedShift] = useState({ day: undefined, night: undefined });
    const [shiftOptions, setShiftOptions] = useState({ day: [], night: [] });
    const [loading, setLoading] = useState(true);
    const [searchQuery, setSearchQuery] = useState('')
    const [refreshPage, setRefreshPage] = useState(false);
    const [selectedExcavator, setSelectedExcavator] = useState(0);
    const [data, setData] = useState(null);
    const timeoutRef = useRef(null);

    useEffect(() => {
        const dateFromQuery = queryParams.get("date");
        // const shiftTypeFromQuery = queryParams.get("type")
        // const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

        if (dateFromQuery && selectedDate !== dateFromQuery) {
            // const newSelectedDate = moment.tz(dateFromQuery, "YYYY-MM-DD", "Pacific/Auckland").toDate();
            setSelectedDate(dateFromQuery);
        }
        // if (shiftTypeFromQuery && shiftType !== shiftTypeFromQuery) {
        //     setShiftType(shiftTypeFromQuery);
        // }
    }, [location.search]);

    useEffect(() => {
        const source = axios.CancelToken.source();
        const cktkn = source.token;

        const navigateWithDate = () => {
            queryParams.set("date", selectedDate);
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        };

        const fetch = async () => {
            try {
                setLoading(true);
                navigateWithDate();

                const shiftResponse = await CallWithAuthFormData(
                    "POST",
                    "/api/getShiftsForDashboard?new=true",
                    {
                        userId: auth?.userData?._id,
                        date: moment(selectedDate).format("YYYY-MM-DD"),
                    },
                    {},
                    cktkn
                );

                const shiftsData = shiftResponse?.res?.data?.data || { day: [], night: [] };
                setShiftOptions(shiftsData);

                setSelectedShift({
                    day: shiftsData?.day?.[0]?.value,
                    night: shiftsData?.night?.[0]?.value,
                });

                if (!shiftsData[shiftType]?.[0]?.value) {
                    setData(null);
                }

            } catch (error) {
                console.error("Error fetching shift data:", error);
            } finally {
                setLoading(false);
            }
        }

        if (auth?.userData?._id && selectedDate) {
            fetch();
        }

        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, [selectedDate]);

    useEffect(() => {
        const source = axios.CancelToken.source();

        // Helper function to fetch data
        const fetchData = async (dontLoad = false) => {
            if (!dontLoad) setLoading(true);
            try {
                const loadTrackerResponse = await CallWithAuth(
                    "GET",
                    `/api/excavator-load-cycle?shiftId=${selectedShift[shiftType]}`,
                    {},
                    source.token
                );
                const data = loadTrackerResponse?.res?.data.data;

                if (!dontLoad && data) setLoading(false);
                if (loadTrackerResponse?.res?.status) {
                    setData(data);
                    if (selectedExcavator >= data.excavators.length) {
                        setSelectedExcavator(0);
                    }
                }
                setAutoRefreshTimeout();

            } catch (error) {
                handleError(error, dontLoad);
            }
        };

        // Helper function for error handling and retry logic
        const handleError = (error, dontLoad) => {
            if (!dontLoad) setLoading(false);
            console.error("Error fetching performance data:", error);

            // Set retry with timeout on error
            setAutoRefreshTimeout();
        };

        // Helper function to manage auto-refresh
        const setAutoRefreshTimeout = () => {
            timeoutRef.current = setTimeout(() => {
                if (selectedShift[shiftType]) fetchData(true);
            }, 5000);
        };

        // Initial Navigation
        // if (shiftType) {
        //     queryParams.set("type", shiftType);
        //     navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        // }

        // Initial Data Fetch
        if (selectedShift[shiftType]) fetchData();
        else {
            setData(null);
        }

        // Cleanup on unmount
        return () => {
            source.cancel("Operation canceled by the user.");
            clearTimeout(timeoutRef.current);
        };
    }, [selectedShift, shiftType, refreshPage]);

    const getExcavatorData = () => {
        if (selectedExcavator === null || !data || selectedExcavator >= data.excavators.length) {
            return null;
        }
        return data.excavators[selectedExcavator];
    }

    const getExcavators = () => {
        if (!data) return [];

        return data.excavators;
    }

    return (
        <ExcavatorLoadTrackerContext.Provider value={{
            loading,
            setLoading,
            selectedDate,
            setSelectedDate,
            shiftType,
            setShiftType,
            searchQuery,
            setSearchQuery,
            refreshPage,
            setRefreshPage,
            data,
            selectedExcavator,
            setSelectedExcavator,
            getExcavatorData,
            getExcavators,
            mainContainer,
            shiftOptions,
        }}>
            {children}
        </ExcavatorLoadTrackerContext.Provider>
    );
}

export const useExcavatorLoadTrackerContext = () => {
    return useContext(ExcavatorLoadTrackerContext);
};