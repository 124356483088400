import {
  calculateTimeDifference,
  dotColors,
  formatDateTime2,
  getFailedChecks,
} from "pages/ShiftMaintenance/utils/helper";
import styles from "../../ViewDetails/ViewDetails.module.css";
import { GoDotFill } from "react-icons/go";

const LabelValue = ({ label, value, dotColor, isDate, format }) => (
  <div className="d-flex align-items-start justify-content-between">
    <div className={styles.label}>{label}</div>
    <div className={styles.value} style={{ marginRight: dotColor ? "12px" : "" }}>
      {dotColor && (
        <GoDotFill
          fontSize={19.5}
          style={{ color: dotColor, paddingBottom: "2px" }}
        />
      )}
      {value ? isDate ? (format ? format(value) : formatDateTime2(value)) : value : "-"}
    </div>
  </div>
);

const GeneralInfo = ({ data }) => {
  const { generalInfo, preStartCheckInfo } = data || {};

  return (
    <div className="d-flex flex-column" style={{ gap: "16px" }}>
      <LabelValue label="Asset ID" value={generalInfo?.equipment?.label} />
      <LabelValue label="Job Ref No" value={generalInfo?.refNo} />
      <LabelValue
        label="Job Status"
        value={generalInfo?.jobStatus}
        dotColor={dotColors(generalInfo?.jobStatus)}
      />
      <LabelValue
        label="Issue/Job Type"
        value={generalInfo?.issueType}
        dotColor={dotColors(generalInfo?.issueType)}
      />
      <LabelValue
        label="Failed Checks"
        value={
          preStartCheckInfo?.preStartCheck
            ? getFailedChecks(preStartCheckInfo?.failedCheckPoints)
            : "-"
        }
      />
      <LabelValue
        label="Asset Status"
        value={generalInfo?.assetStatus}
        dotColor={dotColors(generalInfo?.assetStatus)}
      />
      <LabelValue
        label="Asset Activity"
        value={generalInfo?.assetActivity}
        dotColor={dotColors(generalInfo?.assetActivity)}
      />
      <LabelValue
        label="Scheduled For"
        value={generalInfo?.scheduledFor}
        isDate
      />
      <LabelValue
        label="Delay Duration"
        value={
          generalInfo?.delay
            ? `${generalInfo?.delay?.delayType} - ${calculateTimeDifference(
              generalInfo?.delay?.startTime,
              generalInfo?.delay?.endTime
            )} ${generalInfo?.delay?.startTime
              ? `(${formatDateTime2(generalInfo?.delay?.startTime)} - ${generalInfo?.delay?.endTime
                ? formatDateTime2(generalInfo?.delay?.endTime)
                : "Ongoing"
              })`
              : ""
            }`
            : "-"
        }
      />
      <LabelValue
        label="Pre-Start Report"
        value={generalInfo?.preStartCheck?.refNo}
      />
      <LabelValue
        label="Shift"
        value={`${generalInfo?.shift?.reportNo || ""} ${generalInfo?.shift?.shiftType || ""}` || '-'}
      />
      <LabelValue label="Asset Category" value={generalInfo?.assetCategory} />
      <LabelValue
        label="Issue Reported By"
        value={
          generalInfo?.reportedBy?.firstName
            ? `${generalInfo?.reportedBy?.firstName} ${generalInfo?.reportedBy?.lastName}, ${formatDateTime2(
              generalInfo?.reportedAt
            )}`
            : formatDateTime2(generalInfo?.reportedAt)
        }
      />
      <LabelValue
        label="Approved/Added By"
        value={
          generalInfo?.approvedBy?.firstName
            ? `${generalInfo?.approvedBy?.firstName} ${generalInfo?.approvedBy?.lastName}, ${formatDateTime2(
              generalInfo?.approvedAt
            )}`
            : generalInfo?.addedBy?.firstName ? (`${generalInfo?.addedBy?.firstName} ${generalInfo?.addedBy?.lastName}, ${formatDateTime2(
              generalInfo?.addedAt
            )}`) : '-'
        }
      />
    </div>
  );
};

export default GeneralInfo;
