import ImageUploader from "pages/ShiftHistory/Component/ImageUploader/ImageUploader";
import React, { useState, useEffect } from "react";
import styles from "../AddSign/AddSign.module.css";

const AddSign = ({
  closeModal = () => {},
  onChange = () => {},
  props = {},
}) => {
  const [data, setData] = useState({
    firstName: props?.firstName,
    lastName: props?.lastName,
    file: props?.signature,
    f: "",
  });
  const [file, setFile] = useState("");
  const handleCancelClick = () => {
    closeModal(false);
  };

  const handleSaveClick = () => {
    onChange({ ...data, f: file });
    closeModal(false);
  };

  return (
    <div
      style={{
        marginTop: "50px",
        borderRadius: "10px",
        width: "500px",
        boxShadow: "0 0 10px rgba(0,0,0,0.15)",
      }}
      className="pdf-view d-flex justify-content-center p-4 align-items-start flex-column overflow-hidden "
    >
      <div className=" d-flex w-100 justify-content-start align-items-start flex-column">
        <div className="mb-3 ">
          <div
            style={{
              font: "normal normal bold 18px/24px Open Sans",
              color: "#000000",
            }}
          >
            Add a Signature
          </div>
        </div>
        <div className=" pt-3 d-flex align-items-center justify-content-between w-100">
          <div className="d-flex flex-column" style={{width:"49%"}}>
            <div
              style={{
                font: "normal normal normal 14px/17px Open Sans",
                color: "#1A1A1A",
              }}
              className="mb-2"
            >
              First Name*
            </div>
            <input
              type="text"
              className=" px-3 rounded"
              style={{
                background: "#FFFFFF",
                border: "1px solid #E1E8F4",
                borderRadius: "4px",
                width: "95%",
                height: "40px",
              }}
              value={data?.firstName}
              onChange={(e) => {
                setData({ ...data, firstName: e.target.value });
              }}
            />
          </div>
          <div className="d-flex flex-column" style={{width:"49%"}}>
            <div
              style={{
                font: "normal normal normal 14px/17px Open Sans",
                color: "#1A1A1A",
              }}
              className="mb-2"
            >
              Last Name
            </div>
            <input
              type="text"
              className="px-3 rounded"
              style={{
                background: "#FFFFFF",
                border: "1px solid #E1E8F4",
                borderRadius: "4px",
                width: "95%",
                height: "40px",
              }}
              value={data?.lastName}
              onChange={(e) => {
                setData({ ...data, lastName: e.target.value });
              }}
            />
          </div>
        </div>
      </div>
      <div className="w-100">
        <ImageUploader
          selectedImage={data?.file}
          setSelectedImage={(imageData) =>
            setData({ ...data, file: imageData })
          }
          setFile={(file) => {
            setFile(file);
          }}
        />
      </div>
      <div className="mt-3 d-flex w-100 justify-content-between align-items-center ">
        <div
          name="Cancel"
          className={`mr-3 ${styles.cancel}`}
          onClick={handleCancelClick}
        >
          Cancel
        </div>
        <div
          name="Save"
          className={`${styles.save} ${
            !(data?.firstName && data?.file) ? styles.disabled : ""
          }`}
          style={{}}
          onClick={handleSaveClick}
        >
          Save
        </div>
      </div>
    </div>
  );
};

export default AddSign;
