import moment from "moment";

export const getTimeFromDate = (date) => {
  if (!date) {
    return "-";
  }
  return moment.tz(date, "Pacific/Auckland").format("hh:mm a");
};

export const formateDate = (date) => {
  if (!date) {
    return "-";
  }
  return moment(date).format("DD/MM/YYYY");
};
