import {
  calculateTimeDifference,
  dotColors,
  formatDateTime2,
} from "pages/ShiftMaintenance/utils/helper";
import styles from "../ViewDetails.module.css";
import { ISSUE_TYPE } from "pages/ShiftMaintenance/utils/Constant";
import { GoDotFill } from "react-icons/go";

const LabelValue = ({ label, value, valueStyle = {} }) => (
  <div className="d-flex align-items-start justify-content-between">
    <div className={styles.label}>{label}</div>
    <div className={styles.value} style={valueStyle}>{value || "-"}</div>
  </div>
);

const DurationValue = ({ label, duration }) => {
  const { startTime, endTime
  } = duration || {};
  const formattedDuration = startTime
    ? calculateTimeDifference(startTime, endTime || new Date())
    : "-";

  return (
    <LabelValue
      label={label}
      value={
        <>
          {formattedDuration === "INVALID" ? (
            `Invalid ${label}`
          ) : (
            <>
              {formattedDuration}
              {startTime && (
                <>
                  <br /> ({formatDateTime2(startTime)} -{" "}
                  {endTime && (
                    <>
                      <br />
                    </>
                  )}
                  {endTime ? formatDateTime2(endTime) : "Ongoing"})
                </>
              )}
            </>
          )}
        </>
      }
    />
  );
};

const MechanicSummary = ({ summaryData, issueType }) => {
  return (
    <div className="d-flex flex-column" style={{ gap: "16px" }}>
      <LabelValue label="WO Number" value={summaryData?.WONumber} />
      <LabelValue
        label="Mechanic"
        value={`${summaryData?.mechanic?.firstName || ""} ${summaryData?.mechanic?.lastName || ""
          }`.trim()}
      />
      <LabelValue
        label="Issue Status"
        valueStyle={{ marginRight: summaryData?.issueStatus ? "12px" : "" }}
        value={
          <span>
            {summaryData?.issueStatus && (
              <GoDotFill
                fontSize={19.5}
                style={{
                  color: dotColors(summaryData?.issueStatus),
                  paddingBottom: "2px",
                }}
              />
            )}
            {summaryData?.issueStatus || "-"}
          </span>
        }
      />
      <LabelValue
        label="Corrective Action/Notes"
        value={summaryData?.correctiveActions}
      />
      <DurationValue
        label="Estimated Job Duration"
        duration={summaryData?.estimatedJobDuration}
      />
      <DurationValue
        label="Actual Job Duration"
        duration={summaryData?.actualJobDuration}
      />
      <LabelValue
        label="Notes & Comments"
        value={summaryData?.notesAndComments}
      />
      {issueType === ISSUE_TYPE.PLANNED_MAINTENANCE && (
        <LabelValue
          label="PMSheet"
          value={
            summaryData?.PMSheet ? (
              <embed
                style={{ maxHeight: "40px", maxWidth: "170px" }}
                src={summaryData?.PMSheet}
                alt="sign"
              />
            ) : (
              "-"
            )
          }
        />
      )}

      <LabelValue
        label="Mechanic’s Signature"
        value={
          <embed
            style={{ maxHeight: "40px", maxWidth: "170px" }}
            src={summaryData?.mechanicSignature}
            alt="sign"
          />
        }
      />
    </div>
  );
};

export default MechanicSummary;
