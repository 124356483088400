import RadioButton from "pages/ShiftMaintenance/Component/RadioButton/RadioButton";
import styles from "../Requested.module.css";
import {
  REQUESTED_FLYER,
  sortOptions,
} from "pages/ShiftMaintenance/utils/Constant";
import Search from "pages/ShiftMaintenance/Component/GeneralFields/Search/Search";
import { useState } from "react";
import Filter from "../Filter/Filter";
import filter from "../../../../../assets/images/filter.png";
import download from "../../../../../assets/svgs/shift-maintenance/download.svg";
import { useRequestedContext } from "../Context/Context";

const Header = () => {
  const {
    searchQuery,
    sortBy,
    setSortBy,
    openFlyer,
    setOpenFlyer,
    searchRequestedData
  } = useRequestedContext();


  const handleFilter = () => {
    setOpenFlyer(REQUESTED_FLYER.FILTER);
  };

  return (
    <div className="mx-5 py-4 border-bottom d-flex align-items-center justify-content-between">
      <RadioButton
        radioLabel={"Sort By:"}
        options={sortOptions}
        value={sortBy}
        onChange={(v) => {
          setSortBy(v);
        }}
        isDisabled={false}
        optionStyle={{ marginLeft: "2px" }}
        id="sortOptions_requested"
      />

      <div
        className="d-flex justify-content-between align-items-center"
        style={{ gap: "10px" }}
      >
        <Search
          query={searchQuery}
          onChange={(value) => searchRequestedData(value)}
        />

        <div
          style={{
            userSelect: "none",
            backgroundColor:
              openFlyer === REQUESTED_FLYER.FILTER
                ? // ||
                // filters.noteType ||
                // filters.equipments.length > 0 ||
                // filters.startTime ||
                // filters.endTime
                "#E2ECFC"
                : "transparent",
          }}
          className={styles.filterBtn}
          onClick={() => handleFilter()}
        >
          <img src={filter} alt="filter" />
          <span>Filter</span>
        </div>

        {openFlyer === REQUESTED_FLYER.FILTER && (
          <Filter openFlyer={openFlyer} setOpenFlyer={setOpenFlyer} />
        )}

        <img className={styles.downloadIcon} src={download} alt="download" />
      </div>
    </div>
  );
};

export default Header;
