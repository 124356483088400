import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '../DateAndTimeSelect/DateAndTimeSelect.css'
import moment from 'moment';
import { DateTimeFormats } from '../../../utils/Constant';

export default function DateAndTimeSelect({ required, maxDate, minDate, maxTime, minTime, label, value, onChange, isDisabled, viewOnly, error, errorMessage, className, containerClass, dateOnly = false, wrapperStyle = {}, labelStyle = {} }) {
    return (
        <div style={{ minHeight: '3.5rem', marginBottom: '1rem', ...wrapperStyle }} className="row ">
            <div className="col-md-4 d-flex align-items-center">
                <div style={{
                    fontSize: "1.25rem",
                    fontWeight: '500',
                    color: "#4B515A",
                    ...labelStyle
                }}>
                    {label} {required && <span style={{ color: "#6A7C9A" }}>*</span>}
                </div>
            </div>
            <div className={"col-md-8 " + className}>
                <div style={{
                    fontWeight: '600',
                    fontFamily: 'Open Sans',
                    fontSize: "1.25rem",
                    // height: "3.25rem",
                    width: "100%"
                }}>
                    {viewOnly ? <span style={{ fontWeight: '600', fontSize: "1.3rem", color: "#1A1A1A" }}>{moment(value).format(DateTimeFormats.DATE_TIME_U)}</span> : <DatePicker
                        placeholderText={dateOnly ? "DD/MM/YYYY" : "DD/MM/YYYY, 00:00 AM/PM"}
                        minDate={minDate}
                        maxDate={maxDate}
                        maxTime={maxTime}
                        minTime={minTime}
                        selected={value}
                        className={error ? 'custom-date-time-picker-error' : "custom-date-time-picker-normal"}
                        onChange={onChange}
                        showTimeSelect={!dateOnly}
                        timeIntervals={5}
                        timeCaption="Time"
                        dateFormat={dateOnly ? 'dd/MM/yyyy' : "dd-MMM-yy h:mm aa"}
                        disabled={isDisabled}
                    />}
                </div>
                <div
                    className="d-flex align-items-center pt-1"
                    style={{ fontSize: 12, color: "#BA3938", height: error ? 15 : 0 }}
                >
                    {error ? errorMessage : ""}
                </div>
            </div>
        </div>
    );
}
