import { useState } from "react";
import alertIcon from "../../../../assets/svgs/notifications/alert.svg";
import InfoIcon from "../../../../assets/svgs/notifications/infoIcon.svg";
import closeIcon from "../../../../assets/svgs/notifications/roundClose.svg";
import moment from "moment-timezone";
import useNotificationContext from "hooks/useNotificationContext";
import { RxCross2 } from "react-icons/rx";
import styles from "./NotificationToast.module.css";
const NotificationToast = ({
  data,
  isAlert,
  cardStyle = {},
  isCloseVisible = true,
  isOnClickDisabled = false,
  isCancelable = true
}) => {
  const {
    removeNotification,
    setShowNotificationSummary,
    clearAllNotifications,
  } = useNotificationContext();

  const getTimeDifference = (notificationTime) => {
    const now = moment.tz("Pacific/Auckland");
    const time = moment.tz(notificationTime, "Pacific/Auckland");

    const diffInMinutes = now.diff(time, "minutes");
    const diffInHours = now.diff(time, "hours");

    if (diffInMinutes < 1) {
      return "1m ago";
    } else if (diffInMinutes < 60) {
      return `${diffInMinutes}m ago`;
    } else if (diffInHours < 24) {
      return `${diffInHours}h ago`;
    } else {
      const diffInDays = now.diff(time, "days");
      return `${diffInDays}d ago`;
    }
  };

  return (
    <div style={{ zIndex: "9999", position: "relative", ...cardStyle }}>
      {data.length > 0 &&
        data?.map((item) => (
          <div
            className={(isCancelable ? styles.toast : "") + " my-3 d-flex align-items-center justify-content-between"}
            key={item._id}
            style={{
              background: isAlert ? "#FFF5F4" : "#E5EFFD",
              border: isAlert ? "1px solid #FAE4E0" : "1px solid #CFE4F8",
              borderRadius: "10px",
              boxShadow: "0px 6px 20px rgba(0, 0, 0, 0.15)",
              height: "80px",
              width: "400px",
              boxShadow: "0px 6px 20px #00000029"
            }}
          >
            <div
              className={"pl-4 d-flex align-items-center justify-content-between w-100"}
              onClick={() => {
                if (!isOnClickDisabled) {
                  clearAllNotifications();
                  setShowNotificationSummary(true);
                }
              }}
            >
              <div
                style={{
                  width: "15%",
                }}
              >
                {isAlert ? (
                  <img
                    src={alertIcon}
                    alt="alertIcon"
                    style={{ marginRight: "10px" }}
                  />
                ) : (
                  <img
                    src={InfoIcon}
                    alt="infoIcon"
                    style={{ marginRight: "10px" }}
                  />
                )}
              </div>
              <div style={{ width: "85%" }}>
                <div
                  style={{
                    font: "normal normal bold 14px/20px Open Sans",
                    color: "#1A1A1A",
                  }}
                >
                  {item.title}
                </div>
                <div className="d-flex align-items-center justify-content-between">
                  <div
                    style={{
                      font: "normal normal normal 14px/20px Open Sans",
                      color: "#1A1A1A",
                      width: "60%",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {item.description}
                  </div>
                  <div
                    style={{
                      font: "normal normal normal 12px/20px Open Sans",
                      color: "#1A1A1A",
                      float: "right",
                      marginRight: "20px",
                    }}
                  >
                    {getTimeDifference(item.createdAt)}
                  </div>
                </div>
              </div>
            </div>
            <div
              onClick={(e) => {
                e.stopPropagation();
                removeNotification(item?._id)
              }}
              className={
                styles.cross +
                " align-items-center justify-content-center"
              }
              style={{
                cursor: "pointer",
                boxShadow: "0px 3px 6px #00000029",
                backgroundColor: "white",
                borderRadius: "50%",
                minHeight: 25,
                minWidth: 25,
                position: "absolute",
                right: -12.5,
                top: -12.5,
              }}
            >
              <RxCross2 size={15} />
            </div>
          </div>
        ))
      }
    </div >
  );
};

export default NotificationToast;
