import {
  calculateTimeDifference,
  dotColors,
  formatDateTime2,
  getFailedChecks,
} from "pages/ShiftMaintenance/utils/helper";
import styles from "../../Requested/ViewDetails/ViewDetails.module.css";
import { GoDotFill } from "react-icons/go";
const ViewDetailsComponent = ({ data }) => {

  return (
    <div className="px-5 d-flex flex-column" style={{ gap: "15px" }}>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Asset ID</div>
        <div className={`${styles.viewDetailValue}`}>
          {data?.assetId || "-"}
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Job Ref No</div>
        <div className={styles.viewDetailValue}>
          {data?.refNo || "-"}
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Issue/Job Type</div>
        <div className={styles.viewDetailValue} style={{ marginRight: data?.issueType ? "21px" : "" }}>
          <span>
            {data?.issueType && (
              <GoDotFill
                fontSize={19.5}
                style={{
                  color: dotColors(data?.issueType),
                  paddingBottom: "2px",
                }}
              />
            )}
            {data?.issueType || "-"}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Failed Checks</div>
        <div className={styles.viewDetailValue}>
          {getFailedChecks(data?.failedCheckPoints)}
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Asset Status</div>
        <div className={styles.viewDetailValue} style={{ marginRight: data?.assetStatus ? "21px" : "" }}>
          <span>
            {data?.assetStatus && (
              <GoDotFill
                fontSize={19.5}
                style={{
                  color: dotColors(data?.assetStatus),
                  paddingBottom: "2px",
                }}
              />
            )}
            {data?.assetStatus || "-"}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Asset Activity</div>
        <div className={styles.viewDetailValue} style={{ marginRight: data?.assetActivity ? "21px" : "" }}>
          <span>
            {data?.assetActivity && (
              <GoDotFill
                fontSize={19.5}
                style={{
                  color: dotColors(data?.assetActivity),
                  paddingBottom: "2px"
                }}
              />
            )}
            {data?.assetActivity || "-"}
          </span>
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Delay Duration</div>
        <div className={styles.viewDetailValue}>
          {data?.delay?.delayType} -{" "}
          {calculateTimeDifference(
            data?.delay?.startTime,
            data?.delay?.endTime
          )}
        </div>
      </div>
      {data?.delay?.startTime && (
        <div className="d-flex align-items-start">
          <div className={styles.viewDetailLabel}></div>
          <div className={styles.viewDetailValue}>
            ({formatDateTime2(data?.delay?.startTime)} -{" "}
            {data?.delay?.endTime
              ? formatDateTime2(data?.delay?.endTime)
              : "Ongoing"}
            )
          </div>
        </div>
      )}

      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Pre-Start Report</div>
        <div
          className={styles.viewDetailValue}
          style={data?.preStartCheck?.refNo ? { color: "#186FD9", textDecoration: "underline" } : {}}
        >
          {data?.preStartCheck?.refNo || '-'}
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Shift</div>
        <div className={styles.viewDetailValue}>
          {data?.shift ? (
            <>
              {data.shift.reportNo}, {data.shift.shiftType}
            </>
          ) : (
            "-"
          )}
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Asset Category</div>
        <div className={styles.viewDetailValue}>
          {data?.assetCategory || "-"}
        </div>
      </div>
      <div className="d-flex align-items-start">
        <div className={styles.viewDetailLabel}>Issue Reported By</div>
        <div className={styles.viewDetailValue}>
          {data?.reportedBy || "-"}
          , {formatDateTime2(data?.reportedAt)}
        </div>
      </div>
    </div>
  );
};

export default ViewDetailsComponent;
