import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useRef,
} from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import { CallWithAuth, CallWithAuthFormData } from "action/apiActions";
import { GET_DUMP_CYCLE_DATA } from "action/apiPath";
import useAuth from "hooks/useAuth";

const DumpCycleTrackerContext = createContext();

export function DumpCycleTrackerProvider({ children }) {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);

  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();

  const mainContainer = useRef();

  const [shift, setShift] = useState(
    queryParams.get("type") ? queryParams.get("type") : "day"
  );
  const [date, setDate] = useState(
    queryParams.get("date")
      ? moment
          .tz(queryParams.get("date"), "YYYY-MM-DD", "Pacific/Auckland")
          .toDate()
      : new Date()
  );
  const [selectedExcavator, setSelectedExcavator] = useState(0);
  const [selectedTruck, setSelectedTruck] = useState(0);
  const [search, setSearch] = useState("");
  const [selectedShift, setSelectedShift] = useState({
    day: undefined,
    night: undefined,
  });
  const [shiftOptions, setShiftOptions] = useState({ day: [], night: [] });
  const [data, setData] = useState(null);

  const timeoutRef = useRef(null);

  useEffect(() => {
    const dateFromQuery = queryParams.get("date");
    const shiftTypeFromQuery = queryParams.get("type");
    const formattedSelectedDate = moment(date).format("YYYY-MM-DD");

    if (dateFromQuery && formattedSelectedDate !== dateFromQuery) {
      const newSelectedDate = moment
        .tz(dateFromQuery, "YYYY-MM-DD", "Pacific/Auckland")
        .toDate();
      setDate(newSelectedDate);
    }
    if (shiftTypeFromQuery && shift !== shiftTypeFromQuery) {
      setShift(shiftTypeFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const source = axios.CancelToken.source();
    const cktkn = source.token;

    const navigateWithDate = () => {
      queryParams.set("date", moment(date).format("YYYY-MM-DD"));
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    };

    const fetch = async () => {
      try {
        setLoading(true);
        navigateWithDate();

        const shiftResponse = await CallWithAuthFormData(
          "POST",
          "/api/getShiftsForDashboard?new=true",
          {
            userId: auth?.userData?._id,
            date: moment(date).format("YYYY-MM-DD"),
          },
          {},
          cktkn
        );

        const shiftsData = shiftResponse?.res?.data?.data || {
          day: [],
          night: [],
        };
        setShiftOptions(shiftsData);

        setSelectedShift({
          day: shiftsData?.day?.[0]?.value,
          night: shiftsData?.night?.[0]?.value,
        });

        if (!shiftsData[shift]?.[0]?.value) {
          setData(null);
        }
      } catch (error) {
        console.error("Error fetching shift data:", error);
      } finally {
        setLoading(false);
      }
    };

    if (auth?.userData?._id && date) {
      fetch();
    }

    return () => {
      source.cancel("Operation canceled by the user.");
    };
  }, [date]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    // Helper function to fetch data
    const fetchData = async (dontLoad = false) => {
      if (!dontLoad) setLoading(true);
      try {
        const loadTrackerResponse = await CallWithAuth(
          "GET",
          `${GET_DUMP_CYCLE_DATA}?shiftId=${selectedShift[shift]}`,
          {},
          source.token
        );

        const data = loadTrackerResponse?.res?.data.data;

        if (!dontLoad && data) setLoading(false);
        if (loadTrackerResponse?.res?.status) {
          setData(data);
          if (selectedExcavator >= data.excavators.length) {
            setSelectedExcavator(0);
            setSelectedTruck(0);
          }
        }
        setAutoRefreshTimeout();
      } catch (error) {
        handleError(error, dontLoad);
      }
    };

    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error("Error fetching performance data:", error);

      setAutoRefreshTimeout();
    };

    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        if (selectedShift[shift]) fetchData(true);
      }, 5000);
    };

    if (shift) {
      queryParams.set("type", shift);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }

    if (selectedShift[shift]) fetchData();
    else {
      setData(null);
    }

    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [selectedShift, shift]);

  const getExcavators = () => {
    if (!data) return [];

    return data.excavators;
  };

  const getTrucks = () => {
    if (!data) return [];

    return data.excavators[selectedExcavator].trucks;
  };

  const getTruckData = () => {
    if (
      selectedExcavator === null ||
      selectedTruck === null ||
      !data ||
      selectedExcavator >= data.excavators.length ||
      selectedTruck >= data.excavators[selectedExcavator].trucks.length
    ) {
      return null;
    }
    return data.excavators[selectedExcavator].trucks[selectedTruck];
  };

  const getExcavatorData = () => {
    if (
      selectedExcavator === null ||
      !data ||
      selectedExcavator >= data.excavators.length
    ) {
      return null;
    }
    return data.excavators[selectedExcavator];
  };

  return (
    <DumpCycleTrackerContext.Provider
      value={{
        loading,
        data,
        shift,
        setShift,
        date,
        setDate,
        search,
        setSearch,
        selectedExcavator,
        setSelectedExcavator,
        selectedTruck,
        setSelectedTruck,
        getTruckData,
        getExcavators,
        getTrucks,
        mainContainer,
        getExcavatorData,
        shiftOptions,
      }}
    >
      {children}
    </DumpCycleTrackerContext.Provider>
  );
}

export const useDumpCycleTrackerContext = () => {
  return useContext(DumpCycleTrackerContext);
};
