import { CallWithAuth } from "action/apiActions";
import axios from "axios";

export const GetNotifications = async (queryParams) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "GET",
        `/api/notification?${queryParams}`,
        {},
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
};

export const GetUnreadNotificationsCount = async (type) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "GET",
        `/api/notification/unread-count?type=${type}`,
        {},
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
};

export const MarkAllNotificationAsRead = async () => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "PUT",
        `/api/notification/mark-read`,
        {
          type: 'MARK_ALL_READ'
        },
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const markNotificationAsUnread = async (notificationId) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "PUT",
        `/api/notification/mark-read`,
        {
          type: 'MARK_UNREAD',
          notificationId
        },
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const MarkNotificationAsRead = async (notificationId) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "PUT",
        `/api/notification/mark-read`,
        {
          type: 'MARK_READ',
          notificationId
        },
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const MoveNotificationToInbox = async (notificationId) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "PUT",
        `/api/notification/change-inbox-type`,
        {
          notificationId,
          status: 'UNARCHIVE'
        },
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const MoveNotificationToArchive = async (notificationId) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "PUT",
        `/api/notification/change-inbox-type`,
        {
          notificationId,
          status: 'ARCHIVE'
        },
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const deleteAllNotification = async () => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "DELETE",
        `/api/notification?type=ALL`,
        {},
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const deleteAllNotificationExceptFlagged = async () => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "DELETE",
        `/api/notification?type=ALL_EXCEPT_FLAGGED`,
        {},
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const deleteNotification = async (notificationId) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "DELETE",
        `/api/notification?type=SINGLE&notificationId=${notificationId}`,
        {},
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const changeFlatStatus = async (notificationId, status) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "PUT",
        `/api/notification/change-flag-status`,
        {
          notificationId,
          status
        },
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const remindLater = async (notificationId, time) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "PUT",
        `/api/notification/remind-later`,
        {
          notificationId,
          time
        },
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}

export const muteNotifications = async (time) => {
  try {
    const source = axios.CancelToken.source();

    const response = await CallWithAuth(
        "PUT",
        `/api/notification/mute`,
        {
          time
        },
        source.token
      );

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      return undefined
    }
  } catch (error) {
    console.log("catch",error.message);
    throw(error)
  }
}