import SelectField from "pages/ShiftNotes/Component/GeneralFields/SelectField/SelectField";
import styles from "../SelectShift/SelectShift.module.css";
import { useShiftReportContext } from "../Context/Context";
import { IoCloseSharp } from "react-icons/io5";

const SelectShift = ({ closeSelectShift, onConfirmShift }) => {
  const { shiftType, shiftOptions, setSelectedShift, loading, selectedShift } =
    useShiftReportContext();

  return (
    <div
      id="select-shift"
      className={`modal fade show ${styles.modalTop}`}
      tabIndex="-1"
      role="dialog"
      style={{ zIndex: 9999 }}
    >
      <div
        id="modalDialogTop"
        className={`modal-dialog modal-dialog-centered ${styles.modalTopArea}`}
        role="document"
        style={{ zIndex: 10000 }}
      >
        <div className={`modal-content px-5 ${styles.modalContent}`}>
          <div
            className={`py-4 modal-header d-flex align-items-center justify-content-between ${styles.modalHeader}`}
          >
            <span className={styles.title} style={{}}>
              Select Shift
            </span>
            <div
              onClick={closeSelectShift}
              style={{ cursor: "pointer" }}
              className={styles.closeIcon}
            >
              <IoCloseSharp color="black" fontSize={17.5} />
            </div>
          </div>
          <div className={styles.border}></div>
          <div className={`modal-body p-0 pt-4  ${styles.modalBody}`}>
            <SelectField
              noPlaceHolder={true}
              options={shiftOptions?.[shiftType]}
              value={selectedShift?.[shiftType]}
              className=""
              isDisabled={!shiftOptions?.[shiftType]?.length || loading}
              style={getSelectFieldStyle(loading, shiftOptions?.[shiftType])}
              onChange={(v) => {
                setSelectedShift((prev) => ({ ...prev, [shiftType]: v }));
              }}
              mainContainerStyle={{
                marginBottom: "0px",
              }}
            />
            <div
              className={`mt-5 d-flex align-items-center justify-content-center ${styles.continue}`}
              style={{
                cursor: !shiftOptions?.[shiftType]?.length ? "not-allowed" : "pointer",
                opacity: !shiftOptions?.[shiftType]?.length ? "0.5" : "1",
                pointerEvents: !shiftOptions?.[shiftType]?.length
                  ? "none"
                  : "auto",
              }}
              onClick={() => {
                if (shiftOptions?.[shiftType]?.length) {
                  onConfirmShift(); // Only call handler when shiftOptions exist
                }
              }}
            >
              Continue
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const getSelectFieldStyle = (isLoading, options) => ({
  width: "399px",
  height: "30px",
  marginTop: 2.5,
  border: "1px solid #F1F1F1",
  borderRadius: "15px",
  paddingLeft: "10px",
  font: !options?.length ? "normal normal normal 11px/14px Open Sans" : "normal normal 600 11px/14px Open Sans",
  color: !options?.length ? "#888888" : "#000000",
  opacity: 1,
  cursor: !options?.length || isLoading ? "not-allowed" : "pointer",
});

export default SelectShift;
