import styles from "../NotificationSummary/Summary.module.css";
import Search from "pages/Pre-StartCheck/Component/GeneralFields/Search/Search";
import ActionMenu from "pages/Notifications/Component/ActionMenu/ActionMenu";
import Filter from "../Filter/Filter";
import { useEffect, useState } from "react";
import NavBar from "pages/Notifications/Component/NavBar/NavBar";
import { deleteAllNotification, deleteAllNotificationExceptFlagged, GetNotifications, GetUnreadNotificationsCount, MarkAllNotificationAsRead, muteNotifications } from "pages/Notifications/utils/ApiServices";
import NotificationTab from "../NotificationTab/NotificationTab";
import { Tab } from "pages/Notifications/utils/Constant";
import { RiFilter3Fill } from "react-icons/ri";
import { AiFillCloseCircle } from "react-icons/ai";
import useNotificationContext from "hooks/useNotificationContext";
import { isEqual } from "lodash";

const INITIAL_FILTERS = {
  type: "",
  module: "",
  duration: "",
};

const Summary = () => {
  const { setUnreadNotifications } = useNotificationContext();
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTab, setSelectedTab] = useState("INBOX");
  const [loading, setLoading] = useState(true);
  const [notificationData, setNotificationData] = useState([]);
  const [refreshPage, setRefreshPage] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);

  const handleOpenFilter = () => setOpenFilter(true);
  const handleCloseFilter = () => setOpenFilter(false);

  const [filters, setFilters] = useState(INITIAL_FILTERS);

  const handleChange = (field, value) => {
    setFilters((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  useEffect(() => {
    // Helper function to fetch data
    const fetchData = async () => {
      setLoading(true);
      try {
        setNotificationData([]);
        // Construct the query parameters
        const queryParams = new URLSearchParams({
          inboxType: selectedTab,
        }).toString();

        const notifications = await GetNotifications(queryParams);
        const unreadNotifications = await GetUnreadNotificationsCount("INBOX");

        if (unreadNotifications !== undefined) {
          setUnreadNotificationsCount(unreadNotifications);
        }

        setLoading(false);

        if (notifications) {
          setNotificationData(notifications);
        }
      } catch (error) {
        handleError(error);
      }
    };

    // Helper function for error handling and retry logic
    const handleError = (error) => {
      setLoading(false);
      console.error("Failed to get notifications", error);
    };

    fetchData();
  }, [filters, refreshPage, selectedTab]);

  // sortBy,

  const onApplyFilter = (filterObj, wantToClear = false) => {
    setFilters(filterObj);
  };

  const optionMenu = [
    selectedTab === Tab.INBOX && {
      name: "Mark All as Read",
      onClick: async () => {
        try {
          await MarkAllNotificationAsRead();
          setUnreadNotifications(0);
          setRefreshPage(prev => !prev);
        } catch (e) {
          console.log(e);
        }
      },
    },
    selectedTab === Tab.INBOX && {
      name: "Mute Notifications (15 min)",
      onClick: async () => {
        try {
          await muteNotifications('15_MINUTES');
        } catch (e) {
          console.log(e);
        }
      },
    },
    selectedTab === Tab.INBOX && {
      name: "Mute Notifications (1 hour)",
      onClick: async () => {
        try {
          await muteNotifications('1_HOUR');
        } catch (e) {
          console.log(e);
        }
      },
    },
    selectedTab === Tab.ARCHIVE && {
      name: "Delete All",
      onClick: async () => {
        try {
          await deleteAllNotification();
          setUnreadNotifications(0);
          setRefreshPage(prev => !prev);
        } catch (e) {
          console.log(e);
        }
      },
    },
    selectedTab === Tab.ARCHIVE && {
      name: "Delete All Except Flagged",
      onClick: async () => {
        try {
          await deleteAllNotificationExceptFlagged();
          setRefreshPage(prev => !prev);
        } catch (e) {
          console.log(e);
        }
      },
    },
  ].filter(Boolean);

  const isEmptyFilter = () => {
    if (!filters?.type && !filters?.module && !filters?.duration) {
      return true;
    }
    return false;
  };

  const clearFilters = () => {
    setFilters(INITIAL_FILTERS);
  };

  return (
    <>
      <div className={styles.mainContainer}>
        <div className="">
          <div
            id="header"
            className="px-5 py-5 d-flex align-items-center justify-content-between"
          >
            <div className={styles.title}>Notifications</div>
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ gap: "10px" }}
            >
              <div>
                <Search
                  query={searchQuery}
                  onChange={(value) => setSearchQuery(value)}
                />
              </div>

              <div style={{ position: "relative" }}>
                <span
                  className={styles.filterBtn}
                  style={{
                    background: !isEmptyFilter() ? "#DDE5F3" : "#FAFBFD",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <RiFilter3Fill />
                </span>

                {!isEqual(filters, INITIAL_FILTERS) && <span className={styles.crossIcon} onClick={clearFilters}>
                  <AiFillCloseCircle color="#D54444" />
                </span>}
              </div>

              <div>
                <ActionMenu
                  options={optionMenu}
                  optionsContainerStyle={{
                    font: "normal normal normal 12px/18px Open Sans",
                    color: "#1A1A1A",
                    right: "8.5%",
                    width: "196px",
                    top: "35px",
                  }}
                />
              </div>
            </div>
          </div>

          {openFilter && (
            <Filter
              filters={filters}
              onChange={handleChange}
              handleClose={handleCloseFilter}
              isEmptyFilter={isEmptyFilter}
              clearFilters={clearFilters}
            />
          )}

          <div className={`mb-0 px-5 mt-3 border-bottom`}>
            <NavBar
              unreadCount={unreadNotificationsCount}
              onTabChange={(type) => setSelectedTab(type)}
            />
          </div>
          <NotificationTab data={notificationData} selectedTab={selectedTab} loading={loading} setRefreshPage={setRefreshPage} searchQuery={searchQuery} />
        </div>
      </div>
    </>
  );
};

export default Summary;
