import jsPDF from "jspdf";
import { useRef } from "react";
import { FiDownload } from "react-icons/fi";
import { IoMdClose } from "react-icons/io";
import { Dropdown } from "react-bootstrap";
import { Dot, ShiftHistoryTimeline } from "./Timeline";
import html2canvas from "html2canvas";
import { PieChart } from "react-minimal-pie-chart";

function getHourMinuteDifference(start, end, hrs) {
  const diffInMs = Math.abs(end - start);
  const hours = Math.floor(diffInMs / (1000 * 60 * 60));
  const minutes = Math.floor((diffInMs / (1000 * 60)) % 60);
  const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${hrs ? "hrs" : "h"}`;
  return formattedTime;
}

export default function ShiftHistoryModal({ selectedUnit, setSelectedUnit }) {
  const modalContainer = useRef();
  const timeline = useRef();

  const generatePDF = () => {
    const input = modalContainer.current;
    timeline.current.style.height = "max-content";
    input.style.height = "max-content";
    html2canvas(input).then((canvas) => {
      timeline.current.style.height = "calc(100% - 100px)";
      input.style.height = "100%";
      const pdf = new jsPDF({
        unit: "px",
        format: [canvas.width, canvas.height],
      });
      const imgData = canvas.toDataURL("image/png");
      pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
      pdf.save(
        "Dump_Cycle_Tracker_Data_Timeline_" +
          selectedUnit?.excavatorName +
          "_" +
          selectedUnit?.truckName +
          ".pdf"
      );
    });
  };

  return (
    <aside
      ref={modalContainer}
      style={{
        backgroundColor: "white",
        boxShadow: "lightgray 0px 0px 0.85rem 0px",
        paddingBottom: "20px",
        maxHeight: "calc(100% - 20.7rem)",
        overflowY: "scroll",
        width: "46.6rem",
        position: "fixed",
        bottom: "0",
        right: "0",
        zIndex: "2",
      }}
    >
      <header
        style={{
          alignItems: "center",
          display: "flex",
          position: "fixed",
          flexDirection: "row",
          gap: "0.85rem",
          padding: "1rem 2rem 1rem 2rem",
          width: "46.6rem",
          backgroundColor: "white",
          zIndex: "999",
          borderBottom: "1px solid #E1E8F4",
          backgroundColor: "white",
        }}
      >
        <h5 style={{ fontWeight: "bold", flexGrow: 1 }}>
          {selectedUnit?.truckName} - {selectedUnit.id} Load (
          {selectedUnit?.startTime && selectedUnit?.endTime
            ? getHourMinuteDifference(
                new Date(selectedUnit?.startTime),
                new Date(selectedUnit?.endTime),
                true
              )
            : "00:00 hrs"}
          )
        </h5>

        <FiDownload
          onClick={generatePDF}
          size={"1.3rem"}
          style={{ cursor: "pointer" }}
        />
        <IoMdClose
          onClick={() => setSelectedUnit(null)}
          size={"1.3rem"}
          style={{ cursor: "pointer" }}
        />
      </header>
      <hr />

      <div
        style={{ backgroundColor: "white" }}
        className="d-flex align-items-center justify-content-center"
      >
        <div style={{ width: "35%", position: "relative" }}>
          <PieChart
            center={[50, 50]}
            data={[
              {
                title: "1",
                value:
                  selectedUnit.sourceExitTime && selectedUnit.sourceArivalTime
                    ? new Date(selectedUnit.sourceExitTime).getTime() -
                      new Date(selectedUnit.sourceArivalTime).getTime()
                    : 0,
                color: "#47519D",
              },
              {
                title: "2",
                value:
                  selectedUnit.dumpPointEntryTime && selectedUnit.sourceExitTime
                    ? new Date(selectedUnit.dumpPointEntryTime).getTime() -
                      new Date(selectedUnit.sourceExitTime).getTime()
                    : 0,
                color: "#54BA9A",
              },
              {
                title: "3",
                value:
                  selectedUnit.dumpPointEntryTime && selectedUnit.dumpPointExitTime
                    ? new Date(selectedUnit.dumpPointExitTime).getTime() -
                      new Date(selectedUnit.dumpPointEntryTime).getTime()
                    : 0,
                color: "#E09C2F",
              },
              {
                title: "4",
                value:
                  selectedUnit.sourceReturningTime && selectedUnit.dumpPointExitTime
                    ? new Date(selectedUnit.sourceReturningTime).getTime() -
                      new Date(selectedUnit.dumpPointExitTime).getTime()
                    : 0,
                color: "#AC4694",
              },
            ]}
            lengthAngle={360}
            paddingAngle={0}
            radius={30}
            startAngle={0}
          />
          <div
            style={{
              height: "6.2rem",
              width: "6.2rem",
              borderRadius: "50%",
              position: "absolute",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              top: "4.6rem",
              left: "4.6rem",
              color: "black",
              fontWeight: "800",
              fontSize: "0.9rem",
              border: "4px solid rgba(0,0,0,0.15)",
              boxShadow: "0px 0px 5px black",
              backgroundColor: "white",
            }}
          >
            {selectedUnit?.sourceArivalTime && selectedUnit?.sourceReturningTime
              ? getHourMinuteDifference(
                  new Date(selectedUnit?.sourceArivalTime),
                  new Date(selectedUnit?.sourceReturningTime)
                )
              : "00:00 h"}
          </div>
        </div>
        <div
          className="mb-4"
          style={{ backgroundColor: "white", width: "55%" }}
        >
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "19rem" }}
          >
            <div
              style={{
                backgroundColor: "#47519D",
                borderRadius: "50%",
                width: "0.85rem",
                height: "0.85rem",
              }}
            ></div>
            <span
              style={{
                color:
                selectedUnit.sourceExitTime && selectedUnit.sourceArivalTime
                    ? "#6A6A6A"
                    : "#9D9C9C",
                fontWeight: "600",
                fontSize: "0.9rem",
                width: "14rem",
              }}
            >
              Loading (Area {selectedUnit.sourceAtStart})
            </span>
            <span
              style={{ fontSize: "0.85rem", color: "black", fontWeight: "700" }}
            >
              {selectedUnit.sourceArivalTime && selectedUnit.sourceExitTime
                ? getHourMinuteDifference(
                    new Date(selectedUnit.sourceArivalTime).getTime(),
                    new Date(selectedUnit.sourceExitTime).getTime()
                  )
                : "00:00 h"}
            </span>
          </div>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "19rem", height: "0.8rem" }}
          >
            <div
              style={{
                backgroundColor: "#54BA9A",
                borderRadius: "50%",
                width: "0.85rem",
                height: "0.85rem",
              }}
            ></div>
            <span
              style={{
                color:
                selectedUnit.dumpPointEntryTime && selectedUnit.sourceExitTime
                    ? "#6A6A6A"
                    : "#9D9C9C",
                fontWeight: "600",
                fontSize: "0.9rem",
                width: "14rem",
              }}
            >
              Travelling to {selectedUnit.dumpArea}
            </span>
            <span
              style={{ fontSize: "0.85rem", color: "black", fontWeight: "700" }}
            >
              {selectedUnit.sourceExitTime && selectedUnit.dumpPointEntryTime
                ? getHourMinuteDifference(
                    new Date(selectedUnit.sourceExitTime).getTime(),
                    new Date(selectedUnit.dumpPointEntryTime).getTime()
                  )
                : "00:00 h"}
            </span>
          </div>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "19rem" }}
          >
            <div
              style={{
                backgroundColor: "#E09C2F",
                borderRadius: "50%",
                width: "0.85rem",
                height: "0.85rem",
              }}
            ></div>
            <span
              style={{
                color:
                  selectedUnit.dumpPointEntryTime &&
                  selectedUnit.dumpPointExitTime
                    ? "#6A6A6A"
                    : "#9D9C9C",
                fontWeight: "600",
                fontSize: "0.9rem",
                width: "14rem",
              }}
            >
              Dumping ({selectedUnit.dumpArea})
            </span>
            <span
              style={{ fontSize: "0.85rem", color: "black", fontWeight: "700" }}
            >
              {selectedUnit.dumpPointExitTime && selectedUnit.dumpPointEntryTime
                ? getHourMinuteDifference(
                    new Date(selectedUnit.dumpPointEntryTime).getTime(),
                    new Date(selectedUnit.dumpPointExitTime).getTime()
                  )
                : "00:00 h"}
            </span>
          </div>
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ width: "19rem", height: "0.85rem" }}
          >
            <div
              style={{
                backgroundColor: "#AC4694",
                borderRadius: "50%",
                width: "0.85rem",
                height: "0.85rem",
              }}
            ></div>
            <span
              style={{
                color:
                  selectedUnit.sourceReturningTime &&
                  selectedUnit.dumpPointExitTime
                    ? "#6A6A6A"
                    : "#9D9C9C",
                fontWeight: "600",
                fontSize: "0.9rem",
                width: "14rem",
              }}
            >
              Tramming Empty
            </span>
            <span
              style={{ fontSize: "0.85rem", color: "black", fontWeight: "700" }}
            >
              {selectedUnit.sourceReturningTime &&
              selectedUnit.dumpPointExitTime
                ? getHourMinuteDifference(
                    new Date(selectedUnit.dumpPointExitTime).getTime(),
                    new Date(selectedUnit.sourceReturningTime).getTime()
                  )
                : "00:00 h"}
            </span>
          </div>
        </div>
        <div
          style={{
            alignSelf: "start",
            marginRight: "2rem",
            marginTop: "3.2rem",
            display: "flex",
            width: "10%",
            backgroundColor: "white",
          }}
        >
          <Dropdown className="d-inline">
            <Dropdown.Toggle
              style={{ height: "2.5rem", width: "6rem" }}
              bsPrefix="custom-toggle"
              id=""
            >
              Legend
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{
                width: "250px",
                left: "-20% !important",
                top: "17% !important",
              }}
              bsPrefix="dropdown-menu dropdown-menu-m"
            >
              <Dropdown.Item
                className="d-flex align-items-center justif justify-content-betweeny-content-start"
                disabled
              >
                <Dot
                  Dotstyles={{ marginLeft: -4, marginRight: "0.85rem" }}
                  active={true}
                  type={"basic"}
                  status={false}
                />
                <div
                  style={{ width: "180px", whiteSpace: "normal" }}
                  className="dashboard__header__bar__label"
                >
                  Completed Activity
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                className="d-flex align-items-center justif justify-content-betweeny-content-start"
                disabled
              >
                <Dot
                  Dotstyles={{ marginLeft: -4, marginRight: "0.85rem" }}
                  active={false}
                  type={"delay"}
                  status={false}
                />
                <div className="dashboard__header__bar__label">
                  Upcoming/Incomplete
                </div>
              </Dropdown.Item>
              <Dropdown.Item
                className="d-flex align-items-center justif justify-content-betweeny-content-start"
                disabled
              >
                <Dot
                  Dotstyles={{ marginLeft: -6.5, marginRight: "8px" }}
                  active={true}
                  type={"delay"}
                  status={true}
                />
                <div className="dashboard__header__bar__label">
                  Ongoing Activity
                </div>
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <section
        ref={timeline}
        style={{
          position: "relative",
          top: "-45px",
          backgroundColor: "white",
        }}
      >
        <ShiftHistoryTimeline selectedUnit={selectedUnit} />
      </section>
    </aside>
  );
}
