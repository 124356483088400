// export const REACT_APP_API_ENDPOINT = "https://bathurst-shift-api.imos.co.nz";
// export const BASE_URL = "https://bathurst-shift-api.imos.co.nz";
// const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

// export const REACT_APP_API_ENDPOINT = "/";
export const BASE_URL = "/api";

export const REACT_APP_API_ENDPOINT = "/";

// export const REACT_APP_API_ENDPOINT = "http://bathurst-shift-api.imos.co.nz:3000";
// export const BASE_URL = "http://bathurst-shift-api.imos.co.nz:3000";

export const SEND_OTP = `${BASE_URL}/send-otp`; // POST
export const VERIFY_OTP = `${BASE_URL}/verify-otp`; // POST
export const LOGIN_API_URL = `${BASE_URL}/signin`; //POST

export const GET_USER_DETAILS = (id) => `${BASE_URL}/viewsingleuser?id=${id}`; //GET
export const GEt_ALL_USER = `${BASE_URL}/viewalluser`; // GET query param ?NUMBER_OF_ITEMS=20&PAGE_NUMBER=1

function generateQueryString(params) {
  const queryParams = Object.entries(params)
    .filter(
      ([_, value]) => value !== undefined && value !== null && value !== ""
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value
          .filter((item) => item !== undefined && item !== null && item !== "")
          .map((item) => `${key}=${encodeURIComponent(item)}`)
          .join("&");
      }
      return `${key}=${encodeURIComponent(value)}`;
    })
    .join("&");

  return queryParams ? `?${queryParams}` : "";
}

export const GEt_ALL_USER_LIST = (params) => {
  return `${BASE_URL}/listusers${generateQueryString(params)}`;
};

export const GEt_ALL_USER_WITH_FILTER = `${BASE_URL}/searchuser`; // POST

export const GET_PIN = `${BASE_URL}/generatepin`; //GET
export const GET_KPI_DELAYS_DATA = `${BASE_URL}/get-kpi-delay-data`; //POST
export const GET_KPI_SPEEDING_DATA = `${BASE_URL}/assets/analytics/speeding`; //POST
export const GET_KPI_MONTHLY_YEARLY_DELAYS_DATA = `${BASE_URL}/get-kpi-monthly-yearly-delay-data`; //POST
export const GET_KPI_DUMP_CYCLE_DATA = `${BASE_URL}/get-kpi-dump-cycle-duration`;

//delays new apis
export const GET_KPI_DELAYS_DATA_V1 = `${BASE_URL}/v1/get-kpi-delay-data`; //POST
export const GET_KPI_MONTHLY_YEARLY_DELAYS_DATA_V1 = `${BASE_URL}/v1/get-kpi-monthly-yearly-delay-data`; //POST

export const ADD_USER = `${BASE_URL}/saveUser`; //POST
// export const ADD_SUPER_ADMIN = `${BASE_URL}/addsuperadmin`; //POST

export const UPDATE_USER = (id) => `${BASE_URL}/edituser/${id}`; //POST
// export const UPDATE_SUPER_ADMIN = `${BASE_URL}/addsuperadmin`; //POST

export const GET_USER_ACCOUNT_DETAILS = `${BASE_URL}/getmyaccount`; //GET

export const DELETE_MULTIPLE_USERS = `${BASE_URL}/deleteusers`; //POST
export const CHANGE_MULTIPLE_USER_STATUS = `${BASE_URL}/changeuserstatus`; //POST
export const ACTIVATE_MULTIPLE_USER = `${BASE_URL}/activateuser`; //POST

export const DELETE_USER = (id) => `${BASE_URL}/deletesingleuser?id=${id}`; //POST
export const PIN_GENERATE = `${BASE_URL}/generatepin`; //POST
export const CHANGE_PASSWORD = `${BASE_URL}/adminchangepassword`; //POST
export const EDIT_PIN = `${BASE_URL}/adminEditpin`; //POST

export const CHANGE_PASSWORD_ADMIN = `${BASE_URL}/updatePassword`; //POST

export const FORGOT_PASSWORD = `${BASE_URL}/forgotpassword`; //POST
export const EDIT_ADMIN_USER = `${BASE_URL}/editadminuser`; //PUT

// export const SHIFT_LISTING_DETAILS = `${BASE_URL}/shift-plans`; //POST

export const SHIFT_LISTING_DETAILS = `${BASE_URL}/getshiftdetail`; //POST
export const GET_REPORT_CODE = `${BASE_URL}/generaterepotcode`; //GET
// export const SHIFT_LISTING_DETAILS = `${BASE_URL}/shift-plans`; //POST
// export const GET_REPORT_CODE = `${BASE_URL}/generate-report-number`; //GET
export const GET_MINES = `${BASE_URL}/getmines`; //GET
export const GET_DEPARTMENTS = `${BASE_URL}/getdepartments`; //GET
export const GET_CREW = `${BASE_URL}/getcrewtypes`; //GET
export const GET_EQUIMENTS = `${BASE_URL}/geteuipments`; //POST
export const GET_OPERATOR_SHIFT_ASSIGNMENTS = `${BASE_URL}/operator/assigned-shifts`; //POST

export const GET_HULK_TRUCK = `${BASE_URL}/getequipmenttype`; //POST
export const GET_AREA = `${BASE_URL}/getarea`; //GET
export const GET_MATERIALS = `${BASE_URL}/getmaterialdetails`; //GET
export const GET_ACTIVITY_DESCRIPTION = `${BASE_URL}/activity-description`; //GET
export const GET_MINE_PRIORITY = `${BASE_URL}/getmines`; //GET
export const GET_OPERATORS = `${BASE_URL}/getoperators`; //GET
export const GET_OPERATORS_SHIFT_DETAILS = `${BASE_URL}/getoperatorshiftdetails`; //GET

//breakdownEquipments
export const BREAKDOWN_EQUIPMENTS = `${BASE_URL}/breakDownEquipments`;
export const GET_BREAKDOWN_EQUIMENTS = (id) => {
  console.log(id);
  return `${BASE_URL}/getbreakDownEquipments/${id}`;
};

export const SAVE_AND_PUBLISH = `${BASE_URL}/publishshiftplan`; //POST
export const EDIT_AND_PUBLISH = `${BASE_URL}/editdraftplan`; //POST
export const GET_SHIFT_DETAILS = (id) =>
  `${BASE_URL}/getsingleshiftdetail/${id}`; //GET

export const GET_MINE_PRIORITYS = `${BASE_URL}/getminepriorities`; //GET
export const DUPLICATE_SHIFT_PLAN = `${BASE_URL}/duplicateshiftplan`; //POST
export const DELETE_SHIFT_PLAN = `${BASE_URL}/deleteshiftplan`; //POST
export const PUBLISH_SHIFT_PLAN = `${BASE_URL}/changestatus`; //POST
export const SHIFT_DASHBOARD_LISTING_DETAILS = `${BASE_URL}/getShiftDetailDashboard`; //POST
export const GET_ASSET_PERFORMANCE = `${BASE_URL}/getAssetPerformance`; //POST
export const GET_DUMP_CYCLE_DATA = `${BASE_URL}/getDumpCycleData`; //POST
export const SHIFT_HOUR_DATA = `${BASE_URL}/getHourShiftData`; //POST
export const SHIFT_DATA = `${BASE_URL}/getShiftData`; //POST

export const SAVE_LOGIN_LOGOUT_TIME = `${BASE_URL}/availabletime`; //POST
export const GET_PRESENT_TOMMOROW_DATA = `${BASE_URL}/openweather/get-current-weather`; //GET
export const GET_PRESENT_FORECAST_DATA = `${BASE_URL}/openweather/forecast`; //GET

// Data from Webhook
export const GET_WEBHOOK_DATA = `${BASE_URL}/fetchWeatherData`; //GET

// Generate PDF
export const GENERATE_PDF = `${BASE_URL}/generatepdf`; //POST

//dumPoints
export const SAVE_SOURCE_DUMP = `${BASE_URL}/source-dump/save`; //POST
export const GET_SOURCE_DUMPS = `${BASE_URL}/getsourcedumps`; //GET
export const EDIT_SOURCE_DUMP = `${BASE_URL}/source-dump/edit`; //POST
export const DELETE_SOURCE_DUMP = `${BASE_URL}/source-dump/delete`; //POST

// dumpEvents
export const GET_DUMP_EVENTS = `${BASE_URL}/get-all-dumps`; // GET
export const GET_VEHICLE_EVENTS = `${BASE_URL}/get-vehicle-dumps`; // GET
export const GET_ALL_OPERATOR_FOR_SHIFTS = `${BASE_URL}/get-operator-for-shifts`; // GET

// shift history
export const GET_SHIFT_HISTORY = `${BASE_URL}/shift-history`; // GET
// export const END_ALL_SHIFTS = `${BASE_URL}/end-shifts`; // PUT
export const END_SHIFT = `${BASE_URL}/end-shift`; // PUT

//get config productive hours
export const GET_CONFIG_PRODUCTIVE_HOURS = `${BASE_URL}/getConfig_productiveHours`; //GET
//asset-tracking
export const TRIP_HISTORY_DATA = `${BASE_URL}/assets/trip/history`; // PUT
export const TRIP_HISTORY_PATH = `${BASE_URL}/assets/trip/path`; // PUT
export const TRUCK_EVENTS = `${BASE_URL}/get/events/truck/time`; // PUT
export const SAVE_HISTORY_DATA_SETTINGS = `${BASE_URL}/user/settings/save`; // POST
export const GET_HISTORY_DATA_SETTINGS = `${BASE_URL}/user/settings/get`; // GET
//user types
export const GET_USER_TYPES = `${BASE_URL}/getUserTypes`;

// getMyRole
export const GET_CURRENT_USER_USER_TYPE = `${BASE_URL}/myroles`;
//asset-tracking
export const ALL_EQUIPMENTS = `${BASE_URL}/get/equipment/all`;
export const REPORT_EVENTS = `${BASE_URL}/get/events/report`; // PUT

export const SHIFT_LISTING_LOAD_COUNT = (shift_id) =>
  `${BASE_URL}/get/shift/${shift_id}/load/count`; //POST

export const SUPERVISOR_END_SHIFT = `${BASE_URL}/operator/supervisor-end-shift`;

//Delay and Activity
export const GET_DELAY_TYPE = `${BASE_URL}/delay/types`;

export const GET_DELAY_SUB_TYPE = (type_id) =>
  `${BASE_URL}/delay/subTypes?type=${type_id}`;

export const DELETE_DELAY = (delay_id) =>
  `${BASE_URL}/delay?delayId=${delay_id}`;

export const DELETE_ACTIVITY = (activity_id) =>
  `${BASE_URL}/activity?activityId=${activity_id}`;

export const GET_ACTIVITY_TYPE = `${BASE_URL}/activity/types?equipmentType=`;

//Shift notes
export const DELETE_SHIFT_NOTES = (note_id) =>
  `${BASE_URL}/shiftNotes?noteId=${note_id}`;

export const SHIFT_SUMMARY_REPORT = (shiftPlanId, equipmentId) =>
  `${BASE_URL}/operator/shift-summary?shiftPlanId=${shiftPlanId}&equipmentId=${equipmentId}`;

export const SHIFT_SUMMARY_REPORT_PDF = `${BASE_URL}/operator/generate-shift-summary-pdf`;

export const SIGN_SHIFT_SUMMARY = `${BASE_URL}/operator/sign-shift-summary`;

export const KPI_DASHBOARD_DATA = (date) =>
  `${BASE_URL}/summary/kpiDashboardData?dateString=${date}`;

export const ADD_DAILY_SHIFT_REPORT = `${BASE_URL}/daily-Report`

export const DELETE_DAILY_SHIFT_REPORT = (dailyReportId) => `${BASE_URL}/daily-Report?dailyReportId=${dailyReportId}`

export const SHIFT_DAILY_REPORT_PDF = `${BASE_URL}/daily-Report/generate-daily-report-pdf`


export const KPI_DASHBOARD_DELAY = (
  selectedData,
  type,
  shiftType,
  equipmentType
) =>
  `${BASE_URL}/delay/summary?date=${selectedData}&type=${type}&shiftType=${shiftType}&equipmentType=${equipmentType}`;

export const PRE_START_CHECK_REPORT_PDF = `${BASE_URL}/prestart/pre-start-report-pdf`
export const KPI_DASHBOARD_OPERATIONAL_UTILISATION = (date, groupBy) => `${BASE_URL}/delay/utilisation?date=${date}&groupBy=${groupBy}`;
