import { CallWithAuth } from "action/apiActions";
import { toast } from "react-toastify";


const cache = {};
const CACHE_DURATION = 5 * 60 * 1000;

const getFromCache = (key) => {
  const cachedData = cache[key];
  if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
    return cachedData.data;
  }
  return null;
};

const setCache = (key, data) => {
  cache[key] = {
    data,
    timestamp: Date.now(),
  };
};

export const AddJobData = async (reqBody) => {

  try {
    const response = await CallWithAuth("POST", "/api/job", reqBody);

    if (response?.res?.status === 201) {
      return true;
    } else {
      toast.error("Failed to add job");
    }
  } catch (error) {
    toast.error(error.message);
  }
};


export const fetchEquipments = async () => {
  const cacheKey = 'equipments';
  const cachedData = getFromCache(cacheKey);
  if (cachedData) return cachedData;

  try {
    const response = await CallWithAuth("GET", "api/equipments");
    if (response.res.status === 201) {
      const data = response.res.data.data;
      const equipmentList = Object.keys(data).reduce((prev, curr) =>
        [...prev, ...(data[curr] || [])],
        []
      ).map(d => ({ ...d, value: d._id, label: d.Unit })) || [];
      if (equipmentList)
        setCache(cacheKey, equipmentList);
      return equipmentList;
    } else {
      toast.error('Error fetching equipments');
    }
  } catch (error) {
    toast.error(`Error fetching equipments: ${error.message}`);
  }
};

export const ApproveJobData = async (reqBody, jobId) => {

  try {
    const response = await CallWithAuth("PUT", `/api/job/approve/${jobId}`, reqBody);

    if (response?.res?.status === 200) {
      return true;
    } else {
      toast.error("Failed to approve job");
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const RejectJobData = async (reqBody, jobId) => {

  try {
    const response = await CallWithAuth("PUT", `/api/job/reject/${jobId}`, reqBody);

    if (response?.res?.status === 200) {
      return true;
    } else {
      toast.error("Failed to reject job");
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const EditJobData = async (reqBody, jobId) => {

  try {
    const response = await CallWithAuth("PUT", `/api/job/edit/${jobId}`, reqBody);

    if (response?.res?.status === 200) {
      return true;
    } else {
      toast.error("Failed to edit job data");
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const RevertJob = async (jobId) => {

  try {
    const response = await CallWithAuth("PUT", `/api/job/revert/${jobId}`);

    if (response?.res?.status === 200) {
      return true;
    } else {
      toast.error("Failed to revert job");
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const CloseJob = async (jobId) => {

  try {
    const response = await CallWithAuth("PUT", `/api/job/close/${jobId}`);

    if (response?.res?.status === 200) {
      return true;
    } else {
      return false
    }
  } catch (error) {
    return false
  }
};

export const JobViewDetails = async (jobId) => {

  try {
    const response = await CallWithAuth("GET", `/api/job/${jobId}`);

    if (response?.res?.status === 200) {
      return response?.res?.data;
    } else {
      toast.error("Failed to revert job");
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const DeleteJobData = async (jobId) => {

  try {
    const response = await CallWithAuth("DELETE", `/api/job/${jobId}`);

    if (response?.res?.status === 200) {
      return true;
    } else {
      toast.error("Failed to delete job");
    }
  } catch (error) {
    toast.error(error.message);
  }
};

export const ReviewJob = async (formData, jobId) => {

  try {
    const response = await CallWithAuth("PUT", `/api/job/review/${jobId}`,formData);

    if (response?.res?.status === 200) {
      return true;
    } else {
      return false
    }
  } catch (error) {
    return false
  }
};