import { CallWithAuth } from "action/apiActions";
import { PRE_START_CHECK_REPORT_PDF } from "action/apiPath";
import axios from "axios";
import moment from "moment";

  export const NotifyOperator = async (reqBody) => {
    
    try {
      const response = await CallWithAuth("PUT", `/api/prestart/notify-operator`, reqBody);

      if (response?.res?.status === 200) {
       return true;
      } else {
        throw new Error("Failed to notify operator");
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };

  export const generatePreStartReportPdf = async (
    selectedDate,
    shiftType,
    id,
    assetId
  ) => {
    try {
      const requestData = {
        date: moment(selectedDate).format("YYYY-MM-DD"),
        shiftType: shiftType,
        id: id,
      };
  
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "multipart/form-data",
      };
  
      const responseType = "arraybuffer"; // Ensure response type is arraybuffer for PDF
  
      const pdfResponse = await axios.post(
        PRE_START_CHECK_REPORT_PDF,
        requestData,
        { headers, responseType }
      );
  
      if (pdfResponse.status === 200) {
        const blob = new Blob([pdfResponse?.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
  
        // Create a link element for download
        const link = document.createElement("a");
        link.href = url;
        link.download = `Pre-start-check-Report_${selectedDate}_${assetId}.pdf`;
  
        document.body.appendChild(link);
        link.click();
        link.remove();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 1000);
      } else {
        throw new Error("An error occurred while generating pre start check report pdf");
      }
    } catch (error) {
      throw new Error("An error occurred while generating pre start check report pdf",error.message);
    }
  };