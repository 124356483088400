import "react-datepicker/dist/react-datepicker.css";
import React, { useEffect, useState } from "react";
import Tabs from "./Tabs";
import Search from "../GeneralFields/Search/Search";
import styles from "./Flyer.module.css";
import { IoCloseSharp } from "react-icons/io5";
import { RiDownloadLine } from "react-icons/ri";
import { HiDotsHorizontal } from "react-icons/hi";
import ActionMenu from "../ActionMenu/ActionMenu";
//!classes needs to be changed

const Flyer = ({
  title,
  onClose,
  isOpen,
  tabs,
  search = false,
  searchString = "",
  searchCount = 0,
  onSearchInput,
  handleOnClear,
  style = {},
  removeBorderRadius = false,
  containerStyle = {},
  changeTab = 0,
  showVerticalJumpTo = false,
  wantSingleTab = false,
  setChangeTab = () => { },
  titleClass,
  tabStyle = {},
  tabOptionsClassName = "",
  hideBorderBottom = false,
  hideBottom = false,
  headerClassName = "",
  headerHeight = "",
  downloadIcon = false,
  actionIcon = false,
  actonOptions=[]
}) => {
  const [activeTab, setActiveTab] = useState(0);
  useEffect(() => {
    if (changeTab != activeTab)
      setActiveTab(changeTab);
  }, [changeTab])

  useEffect(() => {
    if (changeTab != activeTab)
      setChangeTab(activeTab);
  }, [activeTab])

  const handleClose = () => {
    onClose();
  };

  if (!isOpen) return <></>;
  return (
    <div
      style={{
        position: "fixed",
        height: "calc(100vh - 11.40em)",
        width: "620px",
        top: "8.5em",
        right: "1.45em",
        zIndex: "2",

        ...containerStyle,
      }}
      id="delay_activity"
    >
      <div
        className={` flyer add_new_user_box new_daily_plan_popup ${isOpen ? "active_user generalPopup" : ""
          } pb-0 px-0`}
        style={{
          boxShadow: "-1px 1px 6px #0000001A",
          borderRadius: !removeBorderRadius ? "0px 10px 10px 0px" : 0,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          ...style,
        }}
      >
        <div
          style={{ height: `calc(100% - ${headerHeight || "50px"})` }}
          className="add_new_user_boxInner "
        >
          <div className={"add_new_user_box_top px-5 " + headerClassName}>
            <div style={{ height: 32.5 }} className={`add_new_user_box_top_heading d-flex align-items-center ${titleClass ? titleClass : ""}`}>
              <h3>{title}</h3>
            </div>
            <div style={{ display: "flex", gap: "10px", alignItems: 'center' }}>
              {/* Had to Hardcode it for history as we need search only in history */}
              {search && tabs[activeTab].title === "History" && (
                <Search
                  query={searchString}
                  onChange={onSearchInput}
                  count={searchCount}
                  onClear={handleOnClear}
                  showCount={true}
                />
              )}

              {downloadIcon && (
                <div onClick={handleClose} className={styles.close} >
              <RiDownloadLine  fontSize={17.5} />
              </div>
              )}
            
              {actionIcon && (
                <div onClick={handleClose} className={styles.close} >
              {/* <HiDotsHorizontal  fontSize={17.5} /> */}
              <ActionMenu
              icon={<HiDotsHorizontal />}
                          options={actonOptions}
                          optionsContainerStyle={{
                            font: "normal normal normal 12px/18px Open Sans",
                            color: "#1A1A1A",
                            right: "102.5%",
                          }}
                        />
              </div>
              )}

              <div onClick={handleClose} className={styles.close} >
                <IoCloseSharp color="black" fontSize={17.5} />
              </div>
            </div>


          </div>
          <div
            div
            style={{
              height: `calc(100% - ${(tabs?.length > 1 || wantSingleTab) ? 5.2 : 2.5}em)`,
              marginTop: "0.5em",
              display: "flex",
              flexDirection: 'column'
            }}
            className="add_new_user_box_middle"
          >
            <Tabs
              hideBorderBottom={hideBorderBottom}
              tabOptionsClassName={tabOptionsClassName}
              showVerticalJumpTo={showVerticalJumpTo}
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              tabs={(tabs?.length > 1 || wantSingleTab) ? tabs : []}
            />
            <div className={`modal_scroll pt-4 pr-0  ${styles.flyer_modal}`} style={{ flexGrow: 1, ...tabStyle }}>
              {tabs?.map((tab, index) =>
                index === activeTab ? tab.component : null
              )}
            </div>
          </div>

        </div>
        {!hideBottom && <div
          className="px-3"
          style={{
            borderBottomRightRadius: removeBorderRadius ? 0 : 10,
            boxShadow: "0px -2px 4px #0000000A",
            height: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div>
            {tabs[activeTab]?.leftActionButtons?.map((btn) => (
              <span
                style={{ userSelect: "none", ...(btn?.style || {}) }}
                onClick={() => {
                  if (!btn.isDisabled) btn?.onClick();
                }}
                className={`modalDone py-2 ${styles.btn} ${btn.outline ? styles.outline : ""
                  } ${btn.isDisabled ? styles.disabled : ""}`}
              >
                {btn?.label}
              </span>
            ))}
          </div>
          <div>
            {tabs[activeTab]?.rightActionButtons?.map((btn) => (
              <span
                style={{ userSelect: "none", ...(btn?.style || {}) }}
                onClick={() => {
                  if (!btn.isDisabled) btn?.onClick();
                }}
                className={`modalDone py-2 ${styles.btn} ${btn.outline ? styles.outline : ""
                  } ${btn.isDisabled ? styles.disabled : ""}`}
              >
                {btn?.label}
              </span>
            ))}
          </div>
        </div>}
      </div>
    </div>
  );
};
export default Flyer;

