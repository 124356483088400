import React, { useState } from "react";
import styles from "./styles.module.css";
import Table from "./Table/Table";
import {
  GroupingOptions,
  SelectOptions,
  ToggleOptions,
  UtilisationTabs,
} from "./helpers";
import Tabs from "./Tabs/Tabs";
import Switch from "./Switch/Switch";
import Select from "./Select/Select";
import GroupBy from "./GroupBy/GroupBy";
import Download from "./Download/Download";

const OperationalUtilisation = ({ date }) => {
  const [tab, setTab] = useState(UtilisationTabs.OperationalUtilisation);
  const [toggle, setToggle] = useState(ToggleOptions.Owned);
  const [select, setSelect] = useState(SelectOptions.ShowAllCategories);
  const [groupBy, setGroupBy] = useState(GroupingOptions.AssetCategories);

  return (
    <div
      className={`d-flex flex-column p-4 position-relative ${styles.container}`}
    >
      <div className="d-flex align-items-center justify-content-between">
        <Tabs selectedTab={tab} onTabChange={(e) => setTab(e)} />
        <div
          className="d-flex flex-row justify-content-end align-items-center"
          style={{ gap: "8px" }}
        >
          {/* <Select value={select} onChange={(e) => setSelect(e)} /> */}
          <Switch value={toggle} onClick={(e) => setToggle(e)} />
          <GroupBy selectedOption={groupBy} onChange={(e) => setGroupBy(e)} />
          <Download />
        </div>
      </div>

      <Table tab={tab} groupBy={groupBy} toggle={toggle} />
    </div>
  );
};

export default OperationalUtilisation;
