import Flyer from "pages/ShiftMaintenance/Component/Flyers/Flyer";
import { useState } from "react";
import {
  APPROVED_FLYER,
  ApprovedInitialFilters,
} from "../../../utils/Constant";
import FilterComponent from "./FilterComponent";
import { useApprovedContext } from "../Context/Context";

const Filter = () => {
  const {
    onApplyFilter,
    openFlyer,
    setOpenFlyer,
    filters: originalFilters,
  } = useApprovedContext();

  const [filters, setFilters] = useState(originalFilters);

  const handleOnChange = (key, val) => {
    const tempFilters = { ...filters };
    tempFilters[key] = val;
    setFilters(tempFilters);
  };

  return (
    <>
      {openFlyer === APPROVED_FLYER.FILTER && (
        <Flyer
          isOpen={openFlyer === APPROVED_FLYER.FILTER}
          onClose={() => setOpenFlyer("")}
          title={"Filters"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 9.25rem)",
          }}
          hideBorderBottom={true}
          headerClassName={"pt-3"}
          headerHeight={"60px"}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Apply",
                  onClick: () => {
                    onApplyFilter(filters);

                    setOpenFlyer("");
                  },
                },
              ],
              leftActionButtons: [
                {
                  label: "Clear All",
                  onClick: () => {
                    // setOriginalFilters(ApprovedInitialFilters);
                    onApplyFilter(ApprovedInitialFilters, true);
                    setOpenFlyer("");
                  },
                  outline: true,
                },
              ],
              component: (
                <FilterComponent filters={filters} onChange={handleOnChange} />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default Filter;
