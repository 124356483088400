import React, { useEffect, useState } from "react";
import styles from "../EditJob.module.css";
import TextAreaField from "pages/ShiftMaintenance/Component/GeneralFields/TextAreaField";
import RadioButton from "pages/ShiftMaintenance/Component/RadioButton/RadioButton";
import TextInputField from "pages/ShiftMaintenance/Component/TextInputField";
import { issueStatus } from "pages/ShiftMaintenance/utils/DropDownMasters";

const EditFailedCheckStatus = ({
  detailData,
  editFormData,
  setEditFormData,
}) => {
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };

  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  // Temporary state for holding form values
  const [localState, setLocalState] = useState(editFormData);

  useEffect(() => {
    console.log("editFormData_edit",editFormData);
    
    setLocalState(editFormData);
    console.log("localState",localState);
    
  }, [editFormData]);

  const handleOnChange = (key, value) => {
    const keyParts = key.split(".");
    const parentKey = keyParts[0];
    const arrayKey = keyParts[1];
    const index = parseInt(keyParts[2], 10);
    const field = keyParts[3];

    const updatedLocalState = {
      ...localState,
      [parentKey]: {
        ...localState[parentKey],
        [arrayKey]: localState[parentKey]?.[arrayKey]?.map((item, idx) => {
          if (idx === index) {
            return {
              ...item,
              [field]: value,
            };
          }
          return item;
        }),
      },
    };

    setLocalState(updatedLocalState);
    setEditFormData(updatedLocalState);


  };

  return (
    <div>
      {localState?.preStartCheckInfo?.failedCheckPoints?.map(
        (failedCheckPoint, index) => (
          <div key={index} id={index} >
            <div className="py-2 d-flex align-items-center">
              <div className={styles.categoryLabel}>
                {/* {failedCheckPoint?.category
                  ? `${failedCheckPoint?.category}, Check No: ${
                      failedCheckPoint?.checkpointNo || ""
                    }`
                  : `Check No: ${failedCheckPoint?.checkpointNo || ""}`} */}
                  {`${failedCheckPoint?.category || ""} ${failedCheckPoint?.category ? "," : ""} Check No: ${failedCheckPoint?.checkpointNo ||"" }`}
              </div>
              <div
                style={{
                  flexGrow: "1",
                  borderBottom: "1px solid #E1E8F4",
                  marginLeft: "5px",
                  paddingBottom: "11px",
                  opacity: "0.5",
                }}
              ></div>
            </div>

            <TextInputField
              label="Check Point"
              placeholder=""
              style={{
                background: "#E1E4EA",
                border: "1px solid #E1E8F4",
                borderRadius: "4px",
                width: "335px",
                height: "40px",
                font: "normal normal 600 14px/19px Open Sans",
                color: "#5F6672",
                opacity: 1,
              }}
              isDisabled={true}
              value={failedCheckPoint?.checkPoint}
              onChange={(v) =>
                handleOnChange(
                  `preStartCheckInfo.failedCheckPoints.${index}.checkPoint`,
                  v
                )
              }
            />

            {/* Operator's Notes */}
            {failedCheckPoint?.operatorNotes && (
              <div
                style={{ marginTop: "12px" }}
                className="d-flex align-content-center pt-3"
              >
                <div className="mb-2" style={{ width: "23%", ...labelStyle }}>
                  Operator’s Notes
                </div>
                <TextAreaField
                  wrapperStyle={{ paddingLeft: "65px", flexGrow: "1" }}
                  style={{
                    height: "100%",
                    border: "1px solid #E1E8F4",
                    borderRadius: "4px",
                    background: "#E1E4EA",
                  }}
                  isDisabled={false}
                  value={failedCheckPoint.operatorNotes}
                  onChange={(v) =>
                    handleOnChange(
                      `preStartCheckInfo.failedCheckPoints.${index}.operatorNotes`,
                      v
                    )
                  }
                />
              </div>
            )}

            {/* Issue Status */}
            {failedCheckPoint?.issueStatus && (
              <RadioButton
                radioLabel="Issue Status"
                options={issueStatus}
                value={failedCheckPoint.issueStatus}
                onChange={(v) => {
                  handleOnChange(
                    `preStartCheckInfo.failedCheckPoints.${index}.issueStatus`,
                    v
                  );
                }}
                isDisabled={false}
                id={`issueStatus_${index}`}
                radioLabelStyle={labelStyle}
                className="px-5 py-4"
                optionMainContainer={{
                  marginLeft: "102px",
                  paddingTop: "5px",
                }}
                optionStyle={valueStyle}
                styleClass="pt-3"
              />
            )}

            {/* Corrective Action */}
            <div
              style={{ marginTop: "12px" }}
              className="d-flex align-content-center pt-3"
            >
              <div className="mb-2" style={{ width: "23%", ...labelStyle }}>
                Corrective Action
              </div>
              <TextAreaField
                wrapperStyle={{ paddingLeft: "65px", flexGrow: "1" }}
                style={{
                  height: "100%",
                  border: "1px solid #E1E8F4",
                  borderRadius: "4px",
                }}
                value={failedCheckPoint.correctiveActions}
                onChange={(v) =>
                  handleOnChange(
                    `preStartCheckInfo.failedCheckPoints.${index}.correctiveActions`,
                    v
                  )
                }
              />
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default EditFailedCheckStatus;
