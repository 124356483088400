import { CallWithAuth } from "action/apiActions";


  export const UpdateAssetStatusData = async (assetId,reqBody) => {
    
    try {
      const response = await CallWithAuth("PUT", `/api/update-asset-status/${assetId}`,reqBody);

      if (response?.res?.status === 200) {
       return true;
      } else {
        throw new Error("Failed to update asset status");
      }
    } catch (error) {
      throw new Error(error.message);
    }
  };