import { CallWithAuth } from "action/apiActions";
import { KPI_DASHBOARD_DELAY } from "action/apiPath";
import { TYPES } from "./constant";

export const fetchDashboardDelays = async (
  selectedDate,
  type,
  shiftType,
  equipmentType,
  source
) => {
  try {
    const equipment = Number(equipmentType) === 1 ? "truck" : "excavator";
    const typeName = type === TYPES.DAILY ? "day" : "month";
    const response = await CallWithAuth(
      "GET",
      KPI_DASHBOARD_DELAY(selectedDate, typeName, shiftType, equipment),
      {},
      source.token
    );

    if (response.res.status === 200) {
      const data = response?.res?.data;

      if (data) {
        return data;
      } else {
        throw new Error("Error fetching equipments");
      }
    } else {
      throw new Error("Error fetching equipments");
    }
  } catch (error) {
    throw new Error(`Error fetching equipments: ${error.message}`);
  }
};
