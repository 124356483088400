import moment from "moment";
import DateAndTimeSelect from "pages/ShiftMaintenance/Component/GeneralFields/DateAndTimeSelect/DateAndTimeSelect";
import TextAreaField from "pages/ShiftMaintenance/Component/GeneralFields/TextAreaField";
import RadioButton from "pages/ShiftMaintenance/Component/RadioButton/RadioButton";
import TextInputField from "pages/ShiftMaintenance/Component/TextInputField";
import {
  ISSUE_TYPE,
  JOB_STATUS,
} from "pages/ShiftMaintenance/utils/Constant";
import { issueStatus } from "pages/ShiftMaintenance/utils/DropDownMasters";

const EditMechanicSummary = ({ detailData, editFormData, handleOnChange }) => {
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  const handleDateChange = (v) => {
    const isoDate = moment(v).toISOString(); // Convert to ISO string if needed
    handleOnChange(
      "mechanicServiceSummary.estimatedJobDuration.endTime",
      isoDate
    );
  };

  return (
    <div>
      <TextInputField
        label="WO Number"
        placeholder=""
        style={{
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          width: "335px",
          height: "40px",
          font: "normal normal 600 14px/19px Open Sans",
          color: "#5F6672",
          opacity: 1,
        }}
        labelStyle={labelStyle}
        value={editFormData?.mechanicServiceSummary?.WONumber}
        onChange={(v) => handleOnChange("mechanicServiceSummary.WONumber", v)}
      />

      <DateAndTimeSelect
       
        label="Estimated Job Completion"
        value={
          editFormData?.mechanicServiceSummary?.estimatedJobDuration?.endTime
            ? new Date(
                editFormData?.mechanicServiceSummary?.estimatedJobDuration?.endTime
              )
            : null
        }
        onChange={handleDateChange}
        // error={showError && !formData?.startTime}
        // errorMessage="Please select shift start time"
        id="scheduledFor"
        labelStyle={labelStyle}
      />

      <RadioButton
        radioLabel={"Issue Status"}
        options={issueStatus}
        value={editFormData?.mechanicServiceSummary?.issueStatus}
        onChange={(v) => {
          handleOnChange("mechanicServiceSummary.issueStatus", v);
        }}
        isDisabled={
          detailData?.generalInfo?.jobStatus === JOB_STATUS.INPROGRESS
            ? true
            : false
        }
        id="assetStatus_mechanic"
        radioLabelStyle={labelStyle}
        className={"px-5 py-4"}
        optionMainContainer={{
          marginLeft: "102px",
          paddingTop: "5px",
        }}
        optionStyle={valueStyle}
        labelStyle={labelStyle}
        
      />
      {(detailData?.generalInfo?.issueType === ISSUE_TYPE.BREAKDOWN ||
        detailData?.generalInfo?.issueType ===
          ISSUE_TYPE.PLANNED_MAINTENANCE) && (
        <div
          style={{ marginTop: "12px" }}
          className="d-flex align-content-center pt-3"
        >
          <div
            className="mb-2"
            style={{
              width: "20%",
              ...labelStyle,
            }}
          >
            Corrective Action/ Notes
          </div>
          <TextAreaField
            wrapperStyle={{ paddingLeft: "83px", flexGrow: "1" }}
            style={{
              height: "100%",
              border: "1px solid #E1E8F4",
              borderRadius: "4px",
            }}
            isDisabled={
              detailData?.generalInfo?.jobStatus === JOB_STATUS.INPROGRESS
                ? true
                : false
            }
            value={editFormData?.mechanicServiceSummary?.correctiveActions}
            onChange={(v) =>
              handleOnChange("mechanicServiceSummary.correctiveActions", v)
            }
          />
        </div>
      )}
    </div>
  );
};

export default EditMechanicSummary;
