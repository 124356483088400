import React, { useState } from "react";
import styles from "./Table.module.css";
import ActionMenu from "../ActionMenu/ActionMenu";
import { Riple } from "react-loading-indicators";
// Dynamic Table Component
const DynamicTable = ({
  id="",
  footer,
  loading,
  data,
  headers,
  groupBadge,
  rowHeader,
  actionMenuContainerStyle={},
  actionMenuStyle={}
}) => (
  <>
    <div id={id} className={`${styles.tableContainer} px-5 pt-4`}>
      {loading 
      ? 
      <div style={{ height: '100%', width: '100%' }} className="d-flex align-items-center justify-content-center">
        <Riple color="#317bcc" size="medium" text="" textColor="" />
      </div> 
      :
        <table className={styles.customTable}>
          <TableHeader headers={headers} />
          <TableBody
            data={data}
            headers={headers}
            groupBadge={groupBadge}
            rowHeader={rowHeader}
            actionMenuContainerStyle={actionMenuContainerStyle}
            actionMenuStyle={actionMenuStyle}
          />
        </table>}
    </div>
    <div className={styles.tableFooter}>{footer}</div>
  </>
);

const TableHeader = ({ headers }) => (
  <thead className={styles.tableHeader}>
    <tr>
      {headers.map((header, index) => (
        <th style={header.style} key={index}>
          {header.label}
        </th>
      ))}
    </tr>
  </thead>
);

const GroupBadge = ({ groupKey }) => (
  <tr
    style={{ backgroundColor: "transparent", cursor: "initial" }}
    className={styles.groupBadgeRow + " pt-4 pb-3 mt-2"}
  >
    <td className={styles.borderNone}>
      <div className={`${styles.groupBadge} px-3 py-2`}>
        {groupKey.charAt(0).toUpperCase() + groupKey.slice(1)}
      </div>
    </td>
  </tr>
);

const RowHeader = ({ item, headerCount,actionMenuContainerStyle }) => (
  <tr
    style={{ backgroundColor: "transparent", cursor: "initial" }}
    className={styles.itemHeader}
  >
    <td className="pt-4 pb-3" colSpan={headerCount}>
      <span className="item-badge">{item.rowHeader}</span>
    </td>
    <td className="pt-4 pb-3">
      {item.actionMenu && (
        <ActionMenu
          options={item.actionMenu.options}
          optionsContainerStyle={{
            font: "normal normal normal 12px/18px Open Sans",
            color: "#1A1A1A",
            right: "102.5%",
            ...actionMenuContainerStyle
          }}

        />
      )}{" "}
    </td>
  </tr>
);

const TableBody = ({
  data,
  headers,
  groupBadge,
  rowHeader,
  actionMenuContainerStyle,
  actionMenuStyle
}) => {
  return (
    <tbody>
      {data.map((group, groupIdx) => (
        <React.Fragment key={groupIdx}>
          {groupBadge &&
            group?.groupData?.length > 0 &&
            <GroupBadge groupKey={group.groupName} />}

          {group?.groupData?.map((clubbedRows, itemIdx) => (
            <React.Fragment key={itemIdx}>
              {rowHeader &&
                // clubbedRows.rows?.length > 0 &&
                (
                  <RowHeader
                    item={clubbedRows}
                    headerCount={headers?.length - 1}
                    actionMenuContainerStyle={actionMenuContainerStyle}
                  />
                )}
              {clubbedRows?.rows?.map((row, childIdx) => (
                <tr 
                 id={`report_${row.value.id}`}
                  onClick={() => {
                    if (row.onClick)
                      row.onClick()
                  }}
                  style={row.style ?? {}}
                  className={styles.customRow}
                  key={childIdx}
                >
                  {headers?.map((header) =>
                    header.key !== "actionMenu" ? (
                      <td>{row.value[header.key]}</td>
                    ) : (
                      <td>
                        <ActionMenu
                          options={row.actionMenu.options}
                          optionsContainerStyle={{
                            font: "normal normal normal 12px/18px Open Sans",
                            color: "#1A1A1A",
                            right: "70%",
                            ...actionMenuContainerStyle
                          }}
                          optionStyle={{...actionMenuStyle}}
                        />
                      </td>
                    )
                  )}
                </tr>
              ))}
            </React.Fragment>
          ))}
        </React.Fragment>
      ))}
    </tbody>
  );
};

export default DynamicTable;
