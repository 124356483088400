import { IoCloseSharp } from "react-icons/io5";
import styles from "../DelaysSectionViewDetails/DelaysSectionViewDetails.module.css";
import Badge from "pages/KPIDashboard/Component/Badge/Badge";
import {
  convertSecondsToTime,
  mergeEquipmentData,
  showToast,
} from "pages/KPIDashboard/Utils/helper";
import moment from "moment";
import { useEffect, useState } from "react";
import {
  DELAY_TYPE,
  DELAY_TYPE_ALIAS_HEADER,
  EQUIPMENT_TYPE,
  SHIFT_TYPE,
  TYPES,
} from "pages/KPIDashboard/Utils/constant";
import { fetchDashboardDelays } from "pages/KPIDashboard/Utils/apiService";
import axios from "axios";
import { Riple } from "react-loading-indicators";

const DelaysSectionViewDetails = ({
  handleClose,
  selectedDate,
  detailData,
  type,
  equipmentType,
}) => {
  const [loading, setLoading] = useState(true);
  const [equipmentData, setEquipmentData] = useState([]);
  const [shiftType, setShiftType] = useState(SHIFT_TYPE.DAY);

  const handleBadgeClick = (badgeType) => {
    setShiftType(badgeType); // Update the selected badge
  };

  useEffect(() => {
    setEquipmentData([]);
    if (type === TYPES.DAILY) {
      setLoading(false);
      setEquipmentData(detailData?.equipmentSpecific);
    } else {
      const fetchData = async () => {
        const source = axios.CancelToken.source();
        try {
          setLoading(true);
          const delayResponse = await fetchDashboardDelays(
            moment(selectedDate).format("YYYY-MM-DD"),
            TYPES.DAILY,
            shiftType,
            equipmentType,
            source
          );
          if (!delayResponse) return;
          const mergedEquipmentData = mergeEquipmentData(
            delayResponse?.hourlyData
          );
          setEquipmentData(mergedEquipmentData);
        } catch {
          showToast("An error occurred while getting daily delays");
        } finally {
          setLoading(false);
        }
      };

      if (selectedDate && shiftType && type && equipmentType) fetchData();
    }
  }, [shiftType]);

  return (
    <div>
      <div
        id="summary-report"
        className={`modal fade show ${styles.modalTop}`}
        tabIndex="-1"
        role="dialog"
      >
        <div
          id="modalDialogTop"
          className={`modal-dialog modal-dialog-centered ${styles.modalTopArea}`}
          role="document"
        >
          <div className={`modal-content px-5 ${styles.modalContent}`}>
            <div
              className={`modal-header d-flex flex-column ${styles.modalHeader}`}
            >
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ width: "100%" }}
              >
                <div>
                  {type === TYPES.DAILY
                    ? moment(selectedDate).format("DD-MM-YYYY")
                    : moment(detailData?.time).format("DD-MM-YYYY")}
                </div>
                <div
                  onClick={handleClose}
                  style={{ cursor: "pointer" }}
                  className={styles.closeIcon}
                >
                  <IoCloseSharp color="black" fontSize={17.5} />
                </div>
              </div>
              <div className={styles.totalDelayLabel}>
                Total Delay Duration —{" "}
                <span className={styles.totalDelayValue}>
                  {convertSecondsToTime(detailData?.totalDelay)}
                </span>{" "}
              </div>
              <div className={styles.title}>
                {equipmentType === 1
                  ? EQUIPMENT_TYPE.TRUCK
                  : EQUIPMENT_TYPE.EXCAVATOR}
              </div>
            </div>
            <div className={`modal-body p-0 pt-3 pb-5 ${styles.modalBody}`}>
              <div className="d-flex align-items-center ">
                {type === TYPES.DAILY && (
                  <div className="pr-2">
                    <Badge
                      value={shiftType === SHIFT_TYPE.DAY ? "Day" : "Night"}
                      containerStyle={{
                        font: "normal normal 600 11px/15px Open Sans",
                        color: "#000000",
                        borderRadius: "15px",
                        background: "transparent",
                        border: "1px solid #D6D6D6",
                        width: "113px",
                        height: "24px",
                      }}
                    />
                  </div>
                )}

                {type === TYPES.MONTHLY && (
                  <>
                    <div
                      className="pr-2"
                      onClick={() => handleBadgeClick(SHIFT_TYPE.DAY)}
                    >
                      <Badge
                        value={`Day — ${
                          shiftType === SHIFT_TYPE.DAY
                            ? convertSecondsToTime(
                                equipmentData?.reduce(
                                  (acc, curr) => acc + curr.totalDelay,
                                  0
                                )
                              )
                            : convertSecondsToTime(
                                detailData && equipmentData
                                  ? detailData.totalDelay -
                                      equipmentData.reduce(
                                        (acc, curr) => acc + curr.totalDelay,
                                        0
                                      )
                                  : 0
                              )
                        }`}
                        containerStyle={{
                          font: "normal normal 600 11px/15px Open Sans",
                          color:
                            shiftType === SHIFT_TYPE.DAY
                              ? "#FFFFFF "
                              : "#000000",
                          background:
                            shiftType === SHIFT_TYPE.DAY
                              ? "#000000"
                              : "transparent",
                          borderRadius: "15px",
                          border: "1px solid #D6D6D6",
                          width: "113px",
                          height: "24px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                    <div onClick={() => handleBadgeClick(SHIFT_TYPE.NIGHT)}>
                      <Badge
                        value={`Night — ${
                          shiftType === SHIFT_TYPE.NIGHT
                            ? convertSecondsToTime(
                                equipmentData?.reduce(
                                  (acc, curr) => acc + curr.totalDelay,
                                  0
                                )
                              )
                            : convertSecondsToTime(
                                detailData && equipmentData
                                  ? detailData.totalDelay -
                                      equipmentData.reduce(
                                        (acc, curr) => acc + curr.totalDelay,
                                        0
                                      )
                                  : 0
                              )
                        }`}
                        containerStyle={{
                          font: "normal normal 600 11px/15px Open Sans",
                          color:
                            shiftType === SHIFT_TYPE.NIGHT
                              ? "#FFFFFF"
                              : "#000000",
                          background:
                            shiftType === SHIFT_TYPE.NIGHT
                              ? "#000000"
                              : "transparent",
                          borderRadius: "15px",
                          width: "113px",
                          border: "1px solid #D6D6D6",
                          height: "24px",
                          cursor: "pointer",
                        }}
                      />
                    </div>
                  </>
                )}
              </div>
              <div className={`mt-4 ${styles.typeContainer}`}>
                <div className="px-4 py-3 d-flex align-items-center justify-content-between">
                  <div
                    className="d-flex flex-column"
                    style={{ width: "45%", gap: "5px" }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.types}>{DELAY_TYPE.RI}</div>
                      <div className={styles.typesValue}>
                        {convertSecondsToTime(
                          detailData?.totalByDelayTypes?.RI
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.types}>{DELAY_TYPE.PI}</div>
                      <div className={styles.typesValue}>
                        {convertSecondsToTime(
                          detailData?.totalByDelayTypes?.PI
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.types}>{DELAY_TYPE.PM}</div>
                      <div className={styles.typesValue}>
                        {convertSecondsToTime(
                          detailData?.totalByDelayTypes?.PM
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.types}>{DELAY_TYPE.BM}</div>
                      <div className={styles.typesValue}>
                        {convertSecondsToTime(
                          detailData?.totalByDelayTypes?.BM
                        )}
                      </div>
                    </div>
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ width: "45%", gap: "5px" }}
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.types}>{DELAY_TYPE.OM}</div>
                      <div className={styles.typesValue}>
                        {convertSecondsToTime(
                          detailData?.totalByDelayTypes?.OM
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.types}>{DELAY_TYPE.AD}</div>
                      <div className={styles.typesValue}>
                        {convertSecondsToTime(
                          detailData?.totalByDelayTypes?.AD
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.types}>{DELAY_TYPE.WD}</div>
                      <div className={styles.typesValue}>
                        {convertSecondsToTime(
                          detailData?.totalByDelayTypes?.WD
                        )}
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className={styles.types}>{DELAY_TYPE.PNP}</div>
                      <div className={styles.typesValue}>
                        {convertSecondsToTime(
                          detailData?.totalByDelayTypes?.PN
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className={`mt-4 d-flex align-items-center justify-content-between ${styles.tableHeader}`}
              >
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.ASSET}
                </div>
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.RI}
                </div>
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.PI}
                </div>
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.PM}
                </div>
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.BM}
                </div>
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.OM}
                </div>
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.AD}
                </div>
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.WD}
                </div>
                <div className={styles.width10}>
                  {DELAY_TYPE_ALIAS_HEADER.PNP}
                </div>
                <div style={{ width: "13%" }}>Total Delays (h)</div>
              </div>
              {loading ? (
                <div
                  style={{ height: "100px" }}
                  className="d-flex align-items-center justify-content-center"
                >
                  <Riple color="#317bcc" size="medium" text="" textColor="" />
                </div>
              ) : (
                equipmentData?.map((equipment, index) => (
                  <div
                    key={index}
                    className={`d-flex align-items-center justify-content-between ${styles.tableValue}`}
                  >
                    <div className={styles.width10}>
                      {equipment.equipmentName}
                    </div>
                    <div className={styles.width10}>
                      {convertSecondsToTime(equipment?.totalByDelayTypes.RI)}
                    </div>
                    <div className={styles.width10}>
                      {convertSecondsToTime(equipment?.totalByDelayTypes.PI)}
                    </div>
                    <div className={styles.width10}>
                      {convertSecondsToTime(equipment?.totalByDelayTypes.PM)}
                    </div>
                    <div className={styles.width10}>
                      {convertSecondsToTime(equipment?.totalByDelayTypes.BM)}
                    </div>
                    <div className={styles.width10}>
                      {convertSecondsToTime(equipment?.totalByDelayTypes.OM)}
                    </div>
                    <div className={styles.width10}>
                      {convertSecondsToTime(equipment?.totalByDelayTypes.AD)}
                    </div>
                    <div className={styles.width10}>
                      {convertSecondsToTime(equipment?.totalByDelayTypes.WD)}
                    </div>
                    <div className={styles.width10}>
                      {convertSecondsToTime(equipment?.totalByDelayTypes.PN)}
                    </div>
                    <div style={{ width: "13%" }}>
                      {convertSecondsToTime(equipment.totalDelay)}
                    </div>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DelaysSectionViewDetails;
