import DynamicTable from "pages/ShiftMaintenance/Component/Table/Table";
import {
  APPROVED_FLYER,
  ApprovedHeader,
  JOB_STATUS,
} from "pages/ShiftMaintenance/utils/Constant";
import { useState } from "react";
import ViewDetails from "../ViewDetails/ViewDetails";
import {
  bindInitialFormData,
  dotColors,
  formatDateTime,
  formatDateTime2,
  formateDataForSaving,
  showToast,
} from "pages/ShiftMaintenance/utils/helper";
import { convertSecondsToTime } from "../../../utils/helper";
import { useApprovedContext } from "../Context/Context";
import { GoDotFill } from "react-icons/go";
import {
  CloseJob,
  DeleteJobData,
  EditJobData,
  JobViewDetails,
} from "pages/ShiftMaintenance/utils/ApiServices";
import ConfirmAlert from "pages/ShiftMaintenance/Component/ConfirmAlert/ConfirmAlert";
import EditJob from "../EditJob/EditJob";
import Signature from "../Signature/Signature";

const ApprovedTable = () => {
  const {
    openFlyer,
    setOpenFlyer,
    approvedData,
    filteredResults,
    refreshPage,
    setRefreshPage,
    loading,
  } = useApprovedContext();

  const [selectedJobId, setSelectedJobId] = useState("");
  const [editFormData, setEditFormData] = useState("");
  const [signature, setSignature] = useState(false);


  const handleCloseSignature = () => setSignature(false);

  const handleAddNotesSignature = async () => {
    const viewDetailResponse = await JobViewDetails(selectedJobId);
    if (viewDetailResponse) {
      const bindFormData = bindInitialFormData(viewDetailResponse);
      setEditFormData(bindFormData);
      setSignature(true);
    }
  };

  const headerStyle = {
    paddingLeft: 12,
    font: "normal normal bold 11px/18px Open Sans",
    color: "#1A1A1A",
  };

  const headers = [
    {
      label: ApprovedHeader.JOBS_REFS,
      key: "jobRefs",
      style: {
        width: "1%",
        ...headerStyle,
      },
    },
    {
      label: ApprovedHeader.ADDED_ON,
      key: "addedOn",
      style: {
        width: "11%",
        ...headerStyle,
      },
    },
    {
      label: ApprovedHeader.ASSET_ID,
      key: "assetId",
      style: {
        width: "5%",
        ...headerStyle,
      },
    },
    {
      label: ApprovedHeader.JOB_STATUS,
      key: "jobStatus",
      style: {
        width: "8%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: ApprovedHeader.JOB_TYPE,
      key: "jobType",
      style: {
        width: "13%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: ApprovedHeader.MECHANIC,
      key: "mechanic",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: ApprovedHeader.DELAY_DURATION,
      key: "delayDuration",
      style: {
        width: "9%",
        ...headerStyle,
      },
    },
    {
      label: ApprovedHeader.SCHEDULED_FOR,
      key: "scheduledFor",
      style: {
        width: "11%",
        ...headerStyle,
      },
    },
    {
      label: ApprovedHeader.ISSUE_STATUS,
      key: "issueStatus",
      style: {
        width: "10%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },

    {
      label: ApprovedHeader.ASSET_STATUS,
      key: "assetStatus",
      style: {
        width: "11%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: ApprovedHeader.ASSET_ACTIVITY,
      key: "assetActivity",
      style: {
        width: "10%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: ApprovedHeader.ACTIONS,
      key: "actionMenu",
      style: {
        // width: "5%",
        ...headerStyle,
      },
    },
  ];

  const totalFilteredApprovedJobs = filteredResults?.length
    ? filteredResults?.reduce(
      (total, entry) => total + (entry.jobs?.length || 0),
      0
    )
    : 0;

  const totalApprovedJobs = approvedData?.length
    ? approvedData?.reduce(
      (total, entry) => total + (entry.jobs?.length || 0),
      0
    )
    : 0;

  const handleViewDetails = async (jobId) => {
    setSelectedJobId(jobId);
    setOpenFlyer(APPROVED_FLYER.VIEW_DETAILS);
  };

  const handleEditJobModal = async (jobId) => {
    setSelectedJobId(jobId);
    setOpenFlyer(APPROVED_FLYER.EDIT_JOB);
  };

  const handleDeleteJob = async (jobId) => {
    const deleteResponse = await DeleteJobData(jobId);
    if (deleteResponse) {
      showToast(true, "Job deleted successfully");
      setOpenFlyer("");
      setRefreshPage(!refreshPage);
    } else {
      showToast(false, "An error occurred while deleting job");
    }
  };

  const handleCloseJob = async (jobId) => {
    const deleteResponse = await CloseJob(jobId);
    if (deleteResponse) {
      showToast(true, "Job closed successfully");
      setOpenFlyer("");
      setRefreshPage(!refreshPage);
    } else {
      showToast(false, "An error occurred while closing job");
    }
  };

  const handleOnChange = (path, value) => {
    const keys = path.split(".");
    const lastKey = keys.pop();
    let obj = { ...editFormData };
    keys.forEach((key) => {
      if (!obj[key]) {
        obj[key] = {};
      }
      obj = obj[key];
    });
    obj[lastKey] = value;
    setEditFormData({ ...editFormData });
  };

  const [closeConfirmModal, setCloseConfirmModal] = useState({
    show: false,
    title: "",
    message: "",
    jid: "",
  });


  const confirmCloseJobMessage = () => (
    <div
      className="d-flex flex-column"
      style={{
        font: "normal normal normal 14px/20px Open Sans",
        color: "#000000",
      }}
    >
      <span>
        Signature was added successfully. Would you like to close this job?
        Note, closed jobs will move to the archives section.
      </span>
    </div>
  );



  const handleEditJobData = async (jobId, returnFlow = false) => {
    const formattedData = {};
    const keys = [
      "generalInfo",
      "mechanicServiceSummary",
      "preStartCheckInfo",
      "supervisorInfo",
    ];

    keys.forEach((key) => {
      if (editFormData?.[key]) {
        formattedData[key] = formateDataForSaving(editFormData[key]);
      }
    });

    formattedData["mechanicServiceSummary"] =
      editFormData["mechanicServiceSummary"];

    const editJobResponse = await EditJobData(formattedData, jobId);
    if (editJobResponse) {
      if (returnFlow) {
        return true;
      }
      showToast(true, "Job edited successfully");
      setOpenFlyer("");
      setRefreshPage(!refreshPage);
      handleCloseSignature(false);
    } else {
      showToast(false, "An error occurred while editing job");
    }
  };


  const processedData = filteredResults?.length
    ? filteredResults?.map((group) => ({
      groupName: group?.date, // Use `name` as the group name
      groupData: group?.jobs?.map((job) => ({
        rowHeaderStyle: {
          font: "normal normal bold 12px/17px Open Sans",
          color: "#1A1A1A",
          background: "#EFF3FA 0% 0% no-repeat padding-box",
          borderRadius: "15px",
          padding: "6px 10px 6px 10px",
        },
        rowHeader: group?.date,
        // Assign group name as the row header
        rows: [
          {
            value: {
              jobId: job?.jobId,
              jobRefs: job?.refNo || "-",
              addedOn: job?.addedAt || job?.reportedAt
                ? formatDateTime(job?.addedAt || job?.reportedAt)
                : "-",
              assetId: job?.assetId || "-",
              jobStatus: job?.jobStatus ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(job?.jobStatus),
                      paddingBottom: "3px",
                    }}
                  />
                  {job?.jobStatus || "-"}
                </span>
              ) : (
                "-"
              ),
              jobType: job?.issueType ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(job?.issueType),
                      paddingBottom: "3px",
                    }}
                  />
                  {job?.issueType || "-"}
                </span>
              ) : (
                "-"
              ),
              mechanic: job?.mechanic || "-",
              delayDuration: job?.delayType
                ? `${job?.delayType} - ${convertSecondsToTime(
                  job?.delayDuration
                )}`
                : "-",
              scheduledFor: job?.scheduledFor
                ? formatDateTime2(job?.scheduledFor)
                : "-",
              issueStatus: job?.issueStatus ? (
                <span>
                  {job?.issueStatus && (
                    <GoDotFill
                      fontSize={19.5}
                      style={{
                        color: dotColors(job?.issueStatus),
                        paddingBottom: "3px",
                        paddingRight: 0,
                      }}
                    />
                  )}
                  {job?.issueStatus || "-"}
                </span>
              ) : (
                "-"
              ),
              assetStatus: job?.assetStatus ? (
                <span>
                  {job?.assetStatus && (
                    <GoDotFill
                      fontSize={19.5}
                      style={{
                        color: dotColors(job?.assetStatus),
                        paddingBottom: "3px",
                        paddingRight: 0,
                      }}
                    />
                  )}
                  {job?.assetStatus || "-"}
                </span>
              ) : (
                "-"
              ),
              assetActivity: job?.assetActivity ? (
                <span>
                  {job?.assetActivity && (
                    <GoDotFill
                      fontSize={19.5}
                      style={{
                        color: dotColors(job?.assetActivity),
                        paddingBottom: "3px",
                        paddingRight: 0,
                      }}
                    />
                  )}
                  {job?.assetActivity || "-"}
                </span>
              ) : (
                "-"
              ),
            },
            rowStyle: {
              font: "normal normal normal 13px/19px Open Sans",
              color: "#1A1A1A",
            },
            onClick: () => handleViewDetails(job?.jobId),
            actionMenu: {
              options: [
                {
                  name: "View Details",
                  icon: "",
                  onClick: () => handleViewDetails(job?.jobId),
                },
                {
                  name: "Edit",
                  icon: "",
                  onClick: () => handleEditJobModal(job?.jobId),
                },
                {
                  name: "Download",
                  icon: "",
                  onClick: () => { },
                },

                job?.jobStatus === JOB_STATUS.PENDING && {
                  name: "Delete",
                  icon: "",
                  onClick: () => handleDeleteJob(job?.jobId),
                },

                job?.jobStatus === JOB_STATUS.ENDED &&
                job?.isReviewed && {
                  name: "Close Job",
                  icon: "",
                  onClick: () =>
                    setCloseConfirmModal({
                      show: true,
                      title: "Close Job",
                      message: confirmCloseJobMessage(),
                      wantToDelete: true,
                      jid: job?.jobId,
                    }),
                },
              ].filter(Boolean),
            },
          },
        ],
        actionMenu: {
          options: [],
        },
      })),
    }))
    : [];

  return (
    <>
      {openFlyer === APPROVED_FLYER.VIEW_DETAILS && (
        <ViewDetails
          jobId={selectedJobId}
          handleEditJobModal={handleEditJobModal}
          handleDeleteJob={handleDeleteJob}
          setCloseConfirmModal={setCloseConfirmModal}
          closeJobMessage={confirmCloseJobMessage}
          handleAddNotesSignature={handleAddNotesSignature}
        />
      )}

      {openFlyer === APPROVED_FLYER.EDIT_JOB && (
        <EditJob
          jobId={selectedJobId}
          editFormData={editFormData}
          setEditFormData={setEditFormData}
          handleOnChange={handleOnChange}
          handleEditJobData={handleEditJobData}
          handleAddNotesSignature={handleAddNotesSignature}
        />
      )}

      {closeConfirmModal.show && (
        <ConfirmAlert
          title={closeConfirmModal.title}
          message={closeConfirmModal.message}
          onClose={() => {
            setCloseConfirmModal({
              show: false,
              title: "",
              message: "",
              jid: "",
            });
          }}
          onConfirm={() => {
            handleCloseJob(closeConfirmModal.jid);
            setCloseConfirmModal({
              show: false,
              title: "",
              message: "",
              jid: "",
            });
          }}
        />
      )}



      {signature && (
        <Signature
          jobId={selectedJobId}
          editFormData={editFormData}
          handleClose={handleCloseSignature}
          handleCloseJob={handleCloseJob}
        />
      )}

      <DynamicTable
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={true}
        rowHeader={false}
        footer={`Displaying ${totalFilteredApprovedJobs}/${totalApprovedJobs} Approved Jobs`}
      />
    </>
  );
};

export default ApprovedTable;
