import useAuth from "hooks/useAuth";
import { GetUnreadNotificationsCount } from "pages/Notifications/utils/ApiServices";
import { createContext, useEffect, useRef, useState } from "react";

export const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
  const { auth } = useAuth();
  const [showNotificationSummary, setShowNotificationSummary] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState(0);
  const [refetchNotifications, setRefetchNotifications] = useState(false);
  const ws = useRef(null);
  const reconnectInterval = useRef(null);

  const addNotification = (notification) => {
    setNotifications((prev) => [...prev, notification]);
    setUnreadNotifications((prev) => prev + 1);
  };

  const removeNotification = (id) => {
    setNotifications((prev) =>
      prev.filter((notification) => notification._id !== id)
    );
  };

  const clearAllNotifications = () => {
    setNotifications([]);
  };

  const clearAllAlerts = () => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.type !== "alert")
    );
  };

  const clearAllInformation = () => {
    setNotifications((prev) =>
      prev.filter((notification) => notification.type !== "info")
    );
  };

  const getNotifications = () => notifications;

  const getAlerts = () => notifications.filter((e) => e.type === "alert");

  const getInformation = () => notifications.filter((e) => e.type === "info");

  useEffect(() => {
    const fetchUnreadNotifications = async () => {
      try {
        const count = await GetUnreadNotificationsCount("");
        setUnreadNotifications(count || 0);
      } catch (error) {
        console.error("Error fetching unread notifications:", error);
        setUnreadNotifications(0);
      }
    };

    fetchUnreadNotifications();
  }, [auth]);

  useEffect(() => {
    const connectWebSocket = () => {
      try {
        console.log("Attempting to connect to WebSocket...");
        ws.current = new WebSocket(`${process.env.REACT_APP_WEB_API_SOCKET}`);

        ws.current.onopen = () => {
          console.log("WebSocket connection opened");
          clearInterval(reconnectInterval.current); // Clear reconnection attempts on success
          ws.current.send(
            JSON.stringify({
              type: "register",
              authToken: localStorage.getItem("accessToken"),
            })
          );
        };

        ws.current.onmessage = (event) => {
          try {
            const message = JSON.parse(event.data);

            switch (message.type) {
              case "notification":
                if (message.message) {
                  addNotification(message.message);
                }
                setRefetchNotifications((prev) => !prev);
                break;
              default:
                console.warn("Unhandled message type:", message.type);
            }
          } catch (error) {
            console.error("Error parsing WebSocket message:", error);
          }
        };

        ws.current.onerror = (error) => {
          console.error("WebSocket encountered an error:", error);
          ws.current.close(); // Ensure the connection is closed
          ws.current = null;
          initiateReconnection();
        };

        ws.current.onclose = () => {
          console.warn("WebSocket connection closed");
          ws.current = null;
          initiateReconnection();
        };

        // Send periodic pings to keep the connection alive
        const pingInterval = setInterval(() => {
          if (ws.current?.readyState === WebSocket.OPEN) {
            ws.current.send(JSON.stringify({ type: 'ping' }));
            // console.log('Ping sent to server');
          }
        }, 5000); // 5 seconds

        return () => {
          clearInterval(pingInterval);
        };
      } catch (error) {
        console.log(error);
      }
    };

    const initiateReconnection = () => {
      if (!reconnectInterval.current) {
        console.log("Starting WebSocket reconnection attempts...");
        reconnectInterval.current = setInterval(() => {
          console.log("Reconnecting to WebSocket...");
          connectWebSocket();
        }, 5000); // Try reconnecting every 5 seconds
      }
    };

    if (localStorage.getItem("accessToken"))
      connectWebSocket();

    return () => {
      console.log("Cleaning up WebSocket connection...");
      if (ws.current) {
        ws.current.close();
        ws.current = null;
      }
      if (reconnectInterval.current) {
        clearInterval(reconnectInterval.current);
        reconnectInterval.current = null;
      }
    };
  }, [auth]);

  return (
    <NotificationContext.Provider
      value={{
        addNotification,
        removeNotification,
        clearAllAlerts,
        clearAllInformation,
        getNotifications,
        getAlerts,
        getInformation,
        showNotificationSummary,
        setShowNotificationSummary,
        clearAllNotifications,
        unreadNotifications,
        setUnreadNotifications,
        refetchNotifications,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
