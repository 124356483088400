
import DateTimeSelect from "pages/ShiftMaintenance/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import DynamicSelectField from "pages/ShiftMaintenance/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import { assetActivity, assetStatus, delayTypes, issueTypes } from "pages/ShiftMaintenance/utils/DropDownMasters";
import { dotColors } from "pages/ShiftMaintenance/utils/helper";
import { GoDotFill } from "react-icons/go";
import { MdCircle } from "react-icons/md";


const FilterComponent = ({ filters, onChange }) => {
  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  return (
    <div className="px-5">
      <DynamicSelectField
        label={"Asset Category"}
        placeholder={"Asset Category"}
        options={[
          { value: "Excavator", label: "Excavator" },
          { value: "Truck", label: "Haul Trucks" },
        ]}
        value={filters.assetCategory}
        onChange={(val) => onChange("assetCategory", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        wrapperStyle={{ marginBottom: "0" }}
        isMulti={true}
        selectAll={true}
      />

      <DateTimeSelect
        label="Reported On"
        value={filters?.date}
        onDateChange={(v) => {
          onChange("date", v);
        }}
        iconColor="#6B7B8E"
        containerStyle={{
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          height: "40px",
          width: "354px",
          marginLeft: "83px",
          marginTop: "10px",
          marginBottom: "10px"

        }}
        valueStyle={valueStyle}
        labelStyle={labelStyle}
      />

      <DynamicSelectField

        label={"Shift"}
        placeholder={"Select Shift"}
        options={[{ value: "Day", label: "Day" },
        { value: "Night", label: "Night" },]}
        value={filters?.shift}
        onChange={(val) => onChange("shift", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />

      {/* <DynamicSelectField
       
        label={"Shift Ref No"}
        placeholder={"Shift Ref No"}
        options={[]}
        value={""}
        onChange={(val) => onChange("equipments", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
      /> */}

      <DynamicSelectField

        label={"Delay Type"}
        placeholder={"Delay Type"}
        options={delayTypes}
        value={filters?.delayType}
        onChange={(val) => onChange("delayType", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
      <DynamicSelectField

        label={"Issue/Job Type"}
        placeholder={"Issue/Job Type"}
        options={
          issueTypes?.map(({ value, label, color }) => ({
            value,
            label,
            icon: (
              <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
            ),
          }))}
        value={filters?.issueType}
        onChange={(val) => onChange("issueType", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}

      />
      <DynamicSelectField

        label={"Asset Status"}
        placeholder={"Asset Status"}
        options={
          assetStatus?.map(({ value, label, color }) => ({
            value,
            label,
            icon: (
              <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
            ),
          }))}
        value={filters?.assetStatus}
        onChange={(val) => onChange("assetStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}

      />
      <DynamicSelectField

        label={"Asset Activity"}
        placeholder={"Asset Activity"}
        options={
          assetActivity?.map(({ value, label, color }) => ({
            value,
            label,
            icon: (
              <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
            ),
          }))}
        value={filters?.assetActivity}
        onChange={(val) => onChange("assetActivity", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
    </div>
  );
};

export default FilterComponent;
