import moment from "moment";
import {
  ASSET_ACTIVITY,
  ASSET_STATUS,
  EDIT_FORM_SECTION,
  FORM_SECTION,
  ISSUE_STATUS,
  ISSUE_TYPE,
  JOB_STATUS,
} from "./Constant";
import { toast } from "react-toastify";
import { jobStatus } from "./DropDownMasters";

export const timeRanges = [
  { value: 0, label: "12:00 am" },
  { value: 1800, label: "12:30 am" },
  { value: 3600, label: "01:00 am" },
  { value: 5400, label: "01:30 am" },
  { value: 7200, label: "02:00 am" },
  { value: 9000, label: "02:30 am" },
  { value: 10800, label: "03:00 am" },
  { value: 12600, label: "03:30 am" },
  { value: 14400, label: "04:00 am" },
  { value: 16200, label: "04:30 am" },
  { value: 18000, label: "05:00 am" },
  { value: 19800, label: "05:30 am" },
  { value: 21600, label: "06:00 am" },
  { value: 23400, label: "06:30 am" },
  { value: 25200, label: "07:00 am" },
  { value: 27000, label: "07:30 am" },
  { value: 28800, label: "08:00 am" },
  { value: 30600, label: "08:30 am" },
  { value: 32400, label: "09:00 am" },
  { value: 34200, label: "09:30 am" },
  { value: 36000, label: "10:00 am" },
  { value: 37800, label: "10:30 am" },
  { value: 39600, label: "11:00 am" },
  { value: 41400, label: "11:30 am" },
  { value: 43200, label: "12:00 pm" },
  { value: 45000, label: "12:30 pm" },
  { value: 46800, label: "01:00 pm" },
  { value: 48600, label: "01:30 pm" },
  { value: 50400, label: "02:00 pm" },
  { value: 52200, label: "02:30 pm" },
  { value: 54000, label: "03:00 pm" },
  { value: 55800, label: "03:30 pm" },
  { value: 57600, label: "04:00 pm" },
  { value: 59400, label: "04:30 pm" },
  { value: 61200, label: "05:00 pm" },
  { value: 63000, label: "05:30 pm" },
  { value: 64800, label: "06:00 pm" },
  { value: 66600, label: "06:30 pm" },
  { value: 68400, label: "07:00 pm" },
  { value: 70200, label: "07:30 pm" },
  { value: 72000, label: "08:00 pm" },
  { value: 73800, label: "08:30 pm" },
  { value: 75600, label: "09:00 pm" },
  { value: 77400, label: "09:30 pm" },
  { value: 79200, label: "10:00 pm" },
  { value: 81000, label: "10:30 pm" },
  { value: 82800, label: "11:00 pm" },
  { value: 84600, label: "11:30 pm" },
  { value: 86400, label: "12:00 am" },
];

export const dotColors = (type) => {
  switch (type) {
    case ISSUE_TYPE.BREAKDOWN:
    case ISSUE_TYPE.CAT_A_FAILURE:
    case JOB_STATUS.PENDING:
    case ASSET_STATUS.OUT_OF_ORDER:
    case ISSUE_STATUS.UNRESOLVED:
    case ASSET_ACTIVITY.DISCONNECTED:
      return "#E03E3E"; //red

    case ISSUE_TYPE.CAT_C_FAILURE:
    case ISSUE_TYPE.PLANNED_MAINTENANCE:
      return "#6C35E6"; //purple

    case ISSUE_TYPE.CAT_B_FAILURE:
    case JOB_STATUS.PAUSED:
    case ASSET_ACTIVITY.BATTERY_DISCONNECTED:
      return "#FFBA01"; //Yellow

    case JOB_STATUS.INPROGRESS:
      return "#2291FF"; // Blue

    case JOB_STATUS.ENDED:
    case JOB_STATUS.CLOSED:
    case ASSET_STATUS.OPERABLE:
    case ASSET_ACTIVITY.ACTIVE:
    case ISSUE_STATUS.RESOLVED:
      return "#06AF7B"; //Green

    case ASSET_ACTIVITY.PARKED:
      return "#939FB7"; //Grey

    case JOB_STATUS.REJECTED:
      return "#C18152"; //Brown

    case JOB_STATUS.DELETED:
      return "#1A1A1A"; //Black
    default:
      return "";
  }
};

export const convertSecondsToTime = (seconds) => {
  if (!seconds) return "-";

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let timeString = "";
  if (hours >= 0) {
    timeString += `${hours}h `;
  }
  if (minutes > 0 || hours > 0) {
    timeString += `${minutes}m`;
  }

  return timeString;
};

export const formatDateTime = (dateString) => {
  if (!dateString) return "";
  return moment(dateString)
    .tz("Pacific/Auckland")
    .format("hh:mm A, DD/MM/YYYY");
};

export const formatDateTime2 = (dateString) => {
  if (!dateString) return "";
  return moment(dateString)
    .tz("Pacific/Auckland")
    .format("DD/MM/YYYY, hh:mm A");
};

export const getValuesFromObj = (data) => {
  if (typeof data === 'string') {
    return data;
  }

  if (!Array.isArray(data)) {
    return data?.value;
  }
  if (Array.isArray(data)) {
    return data?.map((item) => item.value).join(",") || "";
  }
};

export const formatQuery = (query) =>
  query.trim().toLowerCase().replace(/\s+/g, "");

export const showToast = (isSuccess, msg) => {
  const options = {
    position: "bottom-right",
    autoClose: 6000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
  };

  if (isSuccess) {
    toast.success(msg, options);
  } else {
    toast.error(msg, options);
  }
};

export const formateDataForSaving = (data) => {
  const formattedData = {};
  Object.keys(data).forEach((key) => {
    if (Array.isArray(data[key])) {
      formattedData[key] = data[key].length
        ? data[key].map((item) => {
          if (typeof item === "object" && item !== null && item.value) {
            return item.value;
          }
          return item;
        })
        : [];
    } else if (typeof data[key] === "object" && data[key] !== null) {
      formattedData[key] = data[key].value;
    } else {
      formattedData[key] = data[key];
    }
  });
  return formattedData;
};

export const calculateTimeDifference = (startTime, endTime) => {
  if (!startTime) return "";
  if (!endTime) return "00:00 hr";


  const start = moment(startTime); // Parse start time
  const end = moment(endTime); // Parse end time

  if (start.isAfter(end)) {
    return "INVALID";
  }
  // Calculate difference in minutes
  const duration = moment.duration(end.diff(start));

  // Get hours and minutes from the duration
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  // Format the result as "00:00"
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(2, "0")} hr`;
};

export const getFailedChecks = (checkPoints) => {
  // Validate input
  if (!Array.isArray(checkPoints) || !checkPoints) {
    return "-";
  }

  // Extract categories and count occurrences
  const categoryCount = checkPoints.reduce((prev, curr) => {
    if (curr.category) {
      prev[curr.category] = (prev[curr.category] || 0) + 1;
    }
    return prev;
  }, {});

  // Build result string
  const categories = ['Category A', 'Category B', 'Category C'];
  const result = categories
    .filter(category => categoryCount[category])
    .map(category => `${category.replace('Category ', 'Cat ')} (${categoryCount[category]})`)
    .join(', ');

  return result || "-";
};




export const validateViewDetails = (input) => {
  // If input is an array and its length is 0, return false
  if (Array.isArray(input)) {
    return input.length > 0;
  }

  // If input is an object, check if all values are null or undefined
  if (typeof input === 'object' && input !== null) {
    const values = Object.values(input);
    return values.some(value => value !== null && value !== undefined && value !== "");
  }

  // Return false for any other cases (if input is neither array nor object)
  return false;
}


export const viewDetailsCondition = (jobStatus, issueType, section) => {



  if (issueType === ISSUE_TYPE.BREAKDOWN || issueType === ISSUE_TYPE.PLANNED_MAINTENANCE) {
    if ((section === FORM_SECTION.GENERAL_INFO && jobStatus === JOB_STATUS.PENDING) || (section === FORM_SECTION.GENERAL_INFO && jobStatus === JOB_STATUS.INPROGRESS) || (section === FORM_SECTION.GENERAL_INFO && jobStatus === JOB_STATUS.PAUSED) || (section === FORM_SECTION.GENERAL_INFO && jobStatus === JOB_STATUS.ENDED)) {
      return true
    }
    if ((section === FORM_SECTION.MECHANIC_SUMMARY && jobStatus === JOB_STATUS.INPROGRESS) || (section === FORM_SECTION.MECHANIC_SUMMARY && jobStatus === JOB_STATUS.PAUSED) || (section === FORM_SECTION.MECHANIC_SUMMARY && jobStatus === JOB_STATUS.ENDED)) {
      return true
    }
    if ((section === FORM_SECTION.SUPERVISOR_SUMMARY && jobStatus === JOB_STATUS.ENDED)) {
      return true
    }

  }

  if (issueType === ISSUE_TYPE.CAT_A_FAILURE || issueType === ISSUE_TYPE.CAT_B_FAILURE || issueType === ISSUE_TYPE.CAT_C_FAILURE) {

    if ((section === FORM_SECTION.GENERAL_INFO && jobStatus === JOB_STATUS.PENDING) || (section === FORM_SECTION.GENERAL_INFO && jobStatus === JOB_STATUS.INPROGRESS) || (section === FORM_SECTION.GENERAL_INFO && jobStatus === JOB_STATUS.PAUSED) || (section === FORM_SECTION.GENERAL_INFO && jobStatus === JOB_STATUS.ENDED)) {
      return true
    }
    if ((section === FORM_SECTION.MECHANIC_SUMMARY && jobStatus === JOB_STATUS.INPROGRESS) || (section === FORM_SECTION.MECHANIC_SUMMARY && jobStatus === JOB_STATUS.PAUSED) || (section === FORM_SECTION.MECHANIC_SUMMARY && jobStatus === JOB_STATUS.ENDED)) {
      return true
    }

    if ((section === FORM_SECTION.FAILED_CHECK && jobStatus === JOB_STATUS.PAUSED) || (section === FORM_SECTION.FAILED_CHECK && jobStatus === JOB_STATUS.ENDED)) {
      return true
    }

    if ((section === FORM_SECTION.SUPERVISOR_SUMMARY && jobStatus === JOB_STATUS.ENDED)) {
      return true
    }

  }
  return false;
}

export const bindInitialFormData = (detailData) => {
  const failedCheckPoints = detailData?.preStartCheckInfo?.failedCheckPoints?.map((checkpoint, index) => ({
    category: checkpoint?.category || "",
    checkpointNo: checkpoint?.checkpointNo || "",
    checkPoint: checkpoint?.checkPoint || "",
    operatorNotes: checkpoint?.operatorNotes || "",
    issueStatus: checkpoint?.issueStatus || "",
    correctiveActions: checkpoint?.correctiveActions || "",
  })) || [];

  return {
    generalInfo: {
      equipment: detailData?.generalInfo?.equipment,
      refNo: detailData?.generalInfo?.refNo,
      issueType: detailData?.generalInfo?.issueType,
      jobStatus: jobStatus?.find((js) => js.value == detailData?.generalInfo?.jobStatus),
      assetStatus: detailData?.generalInfo?.assetStatus,
      scheduledFor: detailData?.generalInfo?.scheduledFor,
    },
    mechanicServiceSummary: {
      WONumber: detailData?.mechanicServiceSummary?.WONumber,
      estimatedJobDuration: {
        endTime: detailData?.mechanicServiceSummary?.estimatedJobDuration?.endTime,
      },
      issueStatus: detailData?.mechanicServiceSummary?.issueStatus,
      correctiveActions: detailData?.mechanicServiceSummary?.correctiveActions,
      photos: "",
    },

    preStartCheckInfo: {
      failedCheckPoints,
    },

    supervisorInfo: {
      notes: detailData?.supervisorInfo?.notes || "",
      signature: detailData?.supervisorInfo?.signature || "",
      firstName: detailData?.supervisorInfo?.firstName || "",
      lastName: detailData?.supervisorInfo?.lastName || "",
      reviewedAt: detailData?.supervisorInfo?.reviewedAt || "",
    },
  };


};

export const isWithin24Hours = (archivedAt) => {
  if(!archivedAt) return false;
  const now = moment(); 
  const archivedTime = moment(archivedAt); 
  const diffInHours = now.diff(archivedTime, 'hours'); 

  return diffInHours <= 24; 
};


