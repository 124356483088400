import { CallWithAuth } from "action/apiActions";
import { GET_EQUIMENTS, GET_DELAY_TYPE, GET_DELAY_SUB_TYPE, DELETE_DELAY, DELETE_SHIFT_NOTES, ADD_DAILY_SHIFT_REPORT, DELETE_DAILY_SHIFT_REPORT, GEt_ALL_USER_WITH_FILTER, SHIFT_DAILY_REPORT_PDF } from "action/apiPath";
import axios from "axios";
import moment from "moment";

// Cache object to store data and timestamps
const cache = {};
const CACHE_DURATION = 5 * 60 * 1000; // 5 minutes in milliseconds

const getFromCache = (key) => {
    const cachedData = cache[key];
    if (cachedData && (Date.now() - cachedData.timestamp) < CACHE_DURATION) {
        return cachedData.data;
    }
    return null;
};

const setCache = (key, data) => {
    cache[key] = {
        data,
        timestamp: Date.now(),
    };
};

export const fetchEquipments = async () => {
    const cacheKey = 'equipments';
    const cachedData = getFromCache(cacheKey);
    if (cachedData) return cachedData;

    try {
        const response = await CallWithAuth("GET", "api/equipments");
        if (response.res.status === 201) {
            const data = response.res.data.data;
            const equipmentList = Object.keys(data).reduce((prev, curr) =>
                [...prev, ...(data[curr] || [])],
                []
            ).map(d => ({ ...d, value: d._id, label: d.Unit })) || [];
            if (equipmentList)
                setCache(cacheKey, equipmentList);
            return equipmentList;
        } else {
            throw new Error('Error fetching equipments');
        }
    } catch (error) {
        throw new Error(`Error fetching equipments: ${error.message}`);
    }
};




export const deleteShiftNotes = async (noteId) => {
    try {
        const response = await CallWithAuth("DELETE", DELETE_SHIFT_NOTES(noteId));
        if (response.res.status === 202) {
            return { success: true, message: "Note Deleted successfully" };
        } else {
            return { success: false, message: "Error occurred while Deleting note" };
        }
    } catch (error) {
        return { success: false, message: `Error occurred: ${error.message}` };
    }
};


export const addDailyReport = async(reqBody)=>{
    try {
        const response = await CallWithAuth("POST", ADD_DAILY_SHIFT_REPORT,reqBody);
        if (response?.res?.status === 201) {
            return true
        } else {
           return false
        }
    } catch (error) {
       throw error
    }
}

export const editDailyReport = async(reqBody)=>{
    try {
        const response = await CallWithAuth("PUT", ADD_DAILY_SHIFT_REPORT,reqBody);
        if (response?.res?.status === 202) {
            return true
        } else {
           return false
        }
    } catch (error) {
       throw error
    }
}


export const deleteShiftReport = async (dailyReportId) => {
    try {
        const response = await CallWithAuth("DELETE", DELETE_DAILY_SHIFT_REPORT(dailyReportId));
        if (response.res.status === 202) {
            return true
        } else {
            return false;
        }
    } catch (error) {
       throw error
    }
};

export const fetchSupervisorList = async (userType) => {
    const cacheKey = `userList_${userType}`;
    const cachedData = getFromCache(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    let payload = {
        key: "",
        userType: userType,
        PAGE_NUMBER: -1,
    };

    try {
        const response = await CallWithAuth("POST", GEt_ALL_USER_WITH_FILTER, payload);
        const { status, data } = response.res.data;
        if (status === 200) {
            setCache(cacheKey, data.result);
            return data.result;
        } else {
            throw new Error(`Error fetching user list: ${data.message}`);
        }
    } catch (error) {
        throw new Error(`Error fetching user list: ${error.message}`);
    }
};

export const generateDailyReportPdf = async (
    selectedDate,
    shiftType,
    reportId,
    reportNumber
  ) => {
    try {
      const requestData = {
        date: moment(selectedDate).format("YYYY-MM-DD"),
        shiftType: shiftType,
        reportId: reportId,
      };
  
      const headers = {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "multipart/form-data",
      };
  
      const responseType = "arraybuffer"; // Ensure response type is arraybuffer for PDF
  
      const pdfResponse = await axios.post(
        SHIFT_DAILY_REPORT_PDF,
        requestData,
        { headers, responseType }
      );
  
      if (pdfResponse.status === 200) {
        const blob = new Blob([pdfResponse?.data], { type: "application/pdf" });
        const url = window.URL.createObjectURL(blob);
  
        // Create a link element for download
        const link = document.createElement("a");
        link.href = url;
        link.download = `Daily-Report_${selectedDate}_${reportNumber}.pdf`;
  
        document.body.appendChild(link);
        link.click();
        link.remove();
        setTimeout(() => {
          window.URL.revokeObjectURL(url);
        }, 1000);
      } else {
        throw new Error("An error occurred while generating daily report pdf");
      }
    } catch (error) {
      throw new Error("An error occurred while generating daily report pdf",error.message);
    }
  };