import DynamicSelectField from "pages/Notifications/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import styles from "../Filter/Filter.module.css";
import {
  duration,
  modules,
  notificationType,
  type,
} from "pages/Notifications/utils/Constant";
import { IoCloseSharp } from "react-icons/io5";
const Filter = ({ filters, onChange , handleClose,isEmptyFilter,clearFilters }) => {
  const commonFieldStyles = {
    background: "#FFFFFF",
    // border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };

  const valueStyle = {
    font: "normal normal 600 12px/22px Open Sans",
    color: "#576B8D",
    background: "#FFFFFF",
    width: "130px",
  };

  const optionLabelStyle = {
    font: "normal normal normal 12px/18px Open Sans",
    color: "#1A1A1A",
  };


  return (
    <div className={`mb-4 ${styles.container}`}>
      <div className="px-5 py-3">
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ width: "100%", gap: "10px" }}
        >
          <div>
            {" "}
            <DynamicSelectField
              placeholder={"Type"}
              options={notificationType}
              value={filters.type}
              onChange={(val) => onChange("type", val)}
              style={commonFieldStyles}
              valueStyle={valueStyle}
              optionLabelStyle={optionLabelStyle}
              wrapperStyle={{ minHeight: "0px" }}
              isMulti={true}
              selectAll={true}
            />
          </div>
          <div>
            {" "}
            <DynamicSelectField
              placeholder={"Module"}
              options={modules}
              value={filters.module}
              onChange={(val) => onChange("module", val)}
              style={commonFieldStyles}
              valueStyle={{ ...valueStyle, width: "160px" }}
              isMulti={true}
              selectAll={true}
              wrapperStyle={{ minHeight: "0px" }}
              optionLabelStyle={optionLabelStyle}
            />
          </div>
          <div className="">
            {" "}
            <DynamicSelectField
              placeholder={"Today"}
              options={duration}
              value={filters.duration}
              onChange={(val) => onChange("duration", val)}
              style={commonFieldStyles}
              valueStyle={valueStyle}
              wrapperStyle={{ minHeight: "0px" }}
              optionLabelStyle={optionLabelStyle}
            />
          </div>

          <div
            className="pl-4 d-flex align-items-center justify-content-between"
            style={{ gap: "8px" }}
          >
            <div
              className={styles.clearFilters}
              style={{
                cursor: isEmptyFilter() ? "not-allowed" : "pointer",
                pointerEvents: isEmptyFilter() ? "none" : "auto",
                background: isEmptyFilter() ? "#E1E4EA" : "#FFFFFF",
                color: isEmptyFilter() ? "#8190AD" : "#186FD9",
              }}
              onClick={clearFilters}
            >
              Clear Filters
            </div>
            <div onClick={handleClose} style={{cursor:"pointer"}}>
              <IoCloseSharp
                style={{ marginBottom: "4px", width: "20px", height: "20px" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filter;
