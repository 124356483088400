// @ts-nocheck
import { useEffect, useState } from "react";
import { initialPrestartcheckDefectCount } from "../SummaryWidget/constant";
import SummaryWidget from "../SummaryWidget";
import CategoryWidget from "../CategoryWidget";
import { PreCheckEvaluationResult } from "pages/Pre-StartCheck/utils/NZMine/enums";
import {
  PrestartCheckForTable,
} from "pages/Pre-StartCheck/utils/NZMine/types";
import { dummy_Licences } from "pages/Pre-StartCheck/utils/NZMine/DummayData";
import {
  getCategoryName,
  getDeptname,
  getFailedCategories,
} from "pages/Pre-StartCheck/utils/NZMine/helper";
import { values } from "lodash";
import { tempPreProcessing } from "pages/Pre-StartCheck/utils/Helper";
import { toast } from "react-toastify";
import { Image } from "react-bootstrap";
import ArrowDown from '../../../../assets/images/PreStartCheck/arrow-white.svg';
import { usePreStartContext } from "pages/Pre-StartCheck/Context/Context";



const PreStartSummary = () => {
  const {
    selectedDate,
    loading,
    filteredResults,
    showCharts,
    setShowCharts,
  } = usePreStartContext();

  const departments = [];
  const licenses = dummy_Licences;


  const [categoryData, setCategoryData] = useState<
    { data: number[]; name: string }[]
  >([]);

  const [summary, setSummary] = useState({
    ...initialPrestartcheckDefectCount,
  });

  const handleChange = async (
    prestartchecks
  ) => {
    const summaryCounts = {
      [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: 0,
      [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: 0,
      [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: 0,
      [PreCheckEvaluationResult.PASS]: 0,
      [PreCheckEvaluationResult.INCOMPLETE]: 0,
    };;

    const prestartCountForCategory: {
      name: string;
      data: typeof initialPrestartcheckDefectCount;
    }[] = [];

    const updatedPrestartchecks = prestartchecks?.length ?
      prestartchecks?.map((prestartcheck) => {
        summaryCounts[prestartcheck.status] += 1;
        const categoryName = getCategoryName(prestartcheck.truck?.type, licenses);

        const categoryIndex = prestartCountForCategory.findIndex(
          (category) => category.name === categoryName
        );
        // If the category doesn't exist, create it and add to prestartCountForCategory
        if (categoryIndex === -1) {
          prestartCountForCategory.push({
            name: categoryName,
            data: { ...initialPrestartcheckDefectCount },
          });
        }

        // Update data in prestartCountForCategory
        const updatedCategoryIndex = prestartCountForCategory.findIndex(
          (cat) => cat.name === categoryName
        );
        prestartCountForCategory[updatedCategoryIndex].data[
          prestartcheck.status
        ]++;

        const prestartcheckDepartment: { name: string; code: number } =
          getDeptname(prestartcheck?.truck?.type, departments, licenses);
        const defectCategories: string = getFailedCategories(
          prestartcheck.checkpoints
        );
        return {
          ...prestartcheck,
          assetID: prestartcheck.truck?.name,
          failedChecks: defectCategories,
          supervisorSignature: prestartcheck.supervisor?.signedName,
          department: prestartcheckDepartment.name,
          deptCode: prestartcheckDepartment.code,
          category: getCategoryName(prestartcheck.truck?.type, licenses),
        };
      }) : {};

    const updatedCategoryData: {
      data: number[];
      name: string;
    }[] = prestartCountForCategory.map((category) => ({
      ...category,
      data: values(category.data),
    }));

    setSummary(summaryCounts);
    setCategoryData(updatedCategoryData);
    //setData(updatedPrestartchecks);
  };

  const fetch = async () => {
    try {
      if (!Array.isArray(filteredResults) || filteredResults.length <= 0) {
        return;
      }
      const tempData = await Promise.all(
        filteredResults?.map((d) => tempPreProcessing(d))
      );
      await handleChange(tempData);
    } catch (err: any) {
      console.log(err);
      toast.error(err?.message);
    }
  };

  useEffect(() => {
    fetch();
  }, [filteredResults]);

  return (
    <>
      {showCharts ? (
        <div className="d-flex overflow-scroll" style={{ width: '100%' }}>
          <div className="flex-grow-0">
            <SummaryWidget summary={summary} showLabel={false} />
          </div>
          <div className="flex-grow-1" style={{ width: '50%' }}>
            <CategoryWidget
              closeWidgets={() => setShowCharts(false)}
              data={categoryData}
            />
          </div>
        </div>
      ) : (
        <div
          className="widget-container px-3 d-flex justify-content-between align-items-center"
          style={{
            width: '100%',
            height: '45px',
            background: '#F7F7F7',
          }}
          onClick={() => setShowCharts(true)}
        >
          <p className="text-2 fw-bold">VIEW SUMMARY</p>
          <div
            className="d-flex mr-1 align-items-center justify-content-center"
            style={{
              width: '22px',
              height: '16px',
              backgroundColor: '#2B2D2F',
              cursor: "pointer"
            }}
          >
            <Image src={ArrowDown} alt="down" />
          </div>
        </div>
      )}
    </>
  );

};

export default PreStartSummary;
