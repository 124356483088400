

import { PreCheckEvaluationResult } from 'pages/Pre-StartCheck/utils/NZMine/enums';
import { FaCheckCircle, FaExclamationTriangle, FaTimesCircle, FaCircle, FaQuestionCircle } from 'react-icons/fa';

// Define colors for each defect type
export const DefectColor: Record<PreCheckEvaluationResult, string> = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: '#F44242',
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: '#F8A200',
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: '#4C1A90',
  [PreCheckEvaluationResult.PASS]: '#3EA884',
  [PreCheckEvaluationResult.INCOMPLETE]: '#6F7070',
};

export const initialPrestartcheckDefectCount = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: 0,
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: 0,
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: 0,
  [PreCheckEvaluationResult.PASS]: 0,
  [PreCheckEvaluationResult.INCOMPLETE]: 0,
};

// Define labels for each defect type
export const prestartcheckLabelsWithoutIncomplete: Record<PreCheckEvaluationResult, string> = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: 'Cat A Failure',
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: 'Cat B Failure',
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: 'Cat C Failure',
  [PreCheckEvaluationResult.PASS]: 'Passed',
  [PreCheckEvaluationResult.INCOMPLETE]: 'Incomplete', 
};

// Define icons for each defect type
export const categoryDefects = [
  {
    Icon: FaExclamationTriangle, 
    name: PreCheckEvaluationResult.CATEGORY_A_FAILURE,
  },
  {
    Icon: FaExclamationTriangle,
    name: PreCheckEvaluationResult.CATEGORY_B_FAILURE,
  },
  {
    Icon: FaExclamationTriangle,
    name: PreCheckEvaluationResult.CATEGORY_C_FAILURE,
  },
  {
    Icon: FaCheckCircle,
    name: PreCheckEvaluationResult.PASS,
  },
  {
    Icon: FaQuestionCircle,
    name: PreCheckEvaluationResult.INCOMPLETE,
  },
];

// Define labels for each defect type
// export const PreCheckEvaluationResultLabels: Record<PreCheckEvaluationResult, string> = {
//   [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: 'Category A Failure',
//   [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: 'Category B Failure',
//   [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: 'Category C Failure',
//   [PreCheckEvaluationResult.PASS]: 'Passed',
//   [PreCheckEvaluationResult.INCOMPLETE]: 'Incomplete',
// };

export const PreCheckEvaluationResultLabels = {
  [PreCheckEvaluationResult.CATEGORY_A_FAILURE]: "Cat A",
  [PreCheckEvaluationResult.CATEGORY_B_FAILURE]: "Cat B",
  [PreCheckEvaluationResult.CATEGORY_C_FAILURE]: "Cat C",
  [PreCheckEvaluationResult.PASS]: PreCheckEvaluationResult.PASS,
  [PreCheckEvaluationResult.INCOMPLETE]: PreCheckEvaluationResult.INCOMPLETE,
};