import React, { useState } from 'react';
import './style.css';
import AuthLayout from '../../components/AuthLayout';
import { Helmet } from 'react-helmet';
import NavBar from './Component/NavBar/NavBar';
import Styles from './Maintenance.module.css';
import { ExcavatorLoadTrackerProvider } from './SubComponent/ExcavatorLoadTracker/context/context';
import ExcavatorLoadTracker from './SubComponent/ExcavatorLoadTracker';
import { DumpCycleTrackerProvider } from './SubComponent/DumpCycleTracker/context/context';
import DumpCycleTracker from './SubComponent/DumpCycleTracker';

export default function Dashboard() {
  const [selectedTab, setSelectedTab] = useState("dump");

  return (
    <AuthLayout pageName="Dump Cycle Tracker">
      <Helmet>
        <title>Dump Cycle Tracker | iMOS</title>,
      </Helmet>

      <div
        className={`right_sidebar_body d-flex flex-column ${Styles.main}`}
        id="main"
      >
        <div className={`mb-0 px-5 py-1 mt-5 border-bottom`}>
          <NavBar onTabChange={(type) => setSelectedTab(type)} />
        </div>

        {selectedTab === "dump" ? (
          <DumpCycleTrackerProvider>
            <DumpCycleTracker />
          </DumpCycleTrackerProvider>
        ) : (
          <ExcavatorLoadTrackerProvider>
            <ExcavatorLoadTracker />
          </ExcavatorLoadTrackerProvider>
        )}
      </div>

    </AuthLayout>
  );
}
