import React from "react";
import styles from "./LoadTrackerTable.module.css";
import { useExcavatorLoadTrackerContext } from "../context/context";
import { formatDate, getFilteredLoads, getIntervals } from "./helpers";

const LoadTrackerTable = () => {
  const { data, searchQuery, getExcavatorData, mainContainer } =
    useExcavatorLoadTrackerContext();
  const excavator = getExcavatorData();

  return (
    <div className="w-100 position-relative d-flex flex-column">
      <main
        ref={mainContainer}
        style={{
          position: "relative",
        }}
      >
        <table
          style={{ backgroundColor: "#fff" }}
          className={styles["load-table"]}
        >
          <thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "7.5%" }}>Load</th>
              <th style={{ width: "18%" }}>Cycle Duration</th>
              <th style={{ width: "8.5%" }}>Source</th>
              <th style={{ width: "7%" }}>Truck</th>
              <th style={{ width: "8.5%" }}>Material</th>
              <th style={{ width: "9%" }}>Dump</th>
              <th style={{ width: "10%" }}>Operator</th>
            </tr>
          </thead>
        </table>
      </main>
      <main
        ref={mainContainer}
        style={{
          position: "relative",
          height: "100%",
          overflowY: "scroll",
        }}
      >
        <table
          style={{ backgroundColor: "#fff" }}
          className={styles["load-table"]}
        >
          <thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "7.5%" }}></th>
              <th style={{ width: "18%" }}> </th>
              <th style={{ width: "8.5%" }}></th>
              <th style={{ width: "7%" }}></th>
              <th style={{ width: "8.5%" }}></th>
              <th style={{ width: "9%" }}></th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {getIntervals(data, excavator).map((interval, i) => (
              <React.Fragment key={i}>
                <tr style={{ borderBottom: "none" }} key={`first_${i}`}>
                  <th colSpan={8} style={{ padding: "1.5rem 0 1.2rem 4rem" }}>
                    <span
                      className="px-4 py-2"
                      style={{
                        background: "#707070",
                        width: "max-content",
                        padding: "2px 3px",
                        borderRadius: "1.25rem",
                        backgroundColor: "#00214d",
                        color: "white",
                        font: "normal normal bold 12px/17px Open Sans",
                        fontSize: "1rem",
                      }}
                    >
                      {interval.formattedInterval}
                    </span>
                    <span
                      className="px-4 py-2"
                      style={{
                        background: "#707070",
                        width: "max-content",
                        padding: "2px 3px",
                        borderRadius: "1.25rem",
                        marginLeft: "15px",
                        backgroundColor:
                          getFilteredLoads(interval.loads, searchQuery) >=
                            interval.target
                            ? "#ADE5C0"
                            : "#F2B5B5",
                        color: "black",
                        font: "normal normal bold 12px/17px Open Sans",
                        fontSize: "1rem",
                      }}
                    >
                      {getFilteredLoads(interval.loads, searchQuery)} /{" "}
                      {interval.target ?? '-'}
                    </span>
                  </th>
                </tr>

                {interval.loads
                  ?.filter(
                    (load) =>
                      load.operatorName
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      load.sourceArea
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      load.dumpArea
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      load.material
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase()) ||
                      load.truckName
                        ?.toLowerCase()
                        .includes(searchQuery.toLowerCase())
                  )
                  .map((load, j) => (
                    <tr
                      key={`${i}_${j}`}
                      style={{ fontWeight: "400", fontSize: "1.1rem" }}
                    >
                      <th>Load {load.id}</th>
                      <td>
                        {formatDate(load.actualStartTime)} -{" "}
                        {formatDate(load.actualEndTime, "00:00 AM")}
                      </td>
                      <td>
                        {load.sourceArea ? `Area ${load.sourceArea}` : "-"}
                      </td>
                      <td>{load.truckName || "-"}</td>
                      <td>{load.material || "-"}</td>
                      <td>{load.dumpArea || "-"}</td>
                      <td>{load.operatorName || "-"}</td>
                    </tr>
                  ))}
                {interval.loads?.filter(
                  (load) =>
                    load.operatorName
                      ?.toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    load.sourceArea
                      ?.toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    load.dumpArea
                      ?.toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    load.material
                      ?.toLowerCase()
                      .includes(searchQuery.toLowerCase()) ||
                    load.truckName
                      ?.toLowerCase()
                      .includes(searchQuery.toLowerCase())
                ).length === 0 ? (
                  <tr
                    key={`last_${i}`}
                    style={{ fontWeight: "400", fontSize: "1.1rem" }}
                  >
                    <td></td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </main>
      <div className={styles.footer}>
        <p>
          Displaying{" "}
          {excavator
            ? excavator.loads?.filter(
              (load) =>
                load.operatorName
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                load.sourceArea
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                load.dumpArea
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                load.material
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase()) ||
                load.truckName
                  ?.toLowerCase()
                  .includes(searchQuery.toLowerCase())
            ).length
            : 0}
          /{excavator ? excavator.loads.length : 0} Loads
        </p>
      </div>
    </div>
  );
};

export default LoadTrackerTable;
