import React from 'react'
import Header from './Header/Header'
import { useDumpCycleTrackerContext } from './context/context'
import { Riple } from 'react-loading-indicators';
import Table from './Table/Table';

const DumpCycleTracker = () => {
const { loading } = useDumpCycleTrackerContext();

  return (
    <div
    style={{
      overflowY: "hidden",
      display: "flex",
      flexDirection: "column",
      height: "100%",
    }}
  >
    <Header />
    {loading ? (
      <div
        style={{ height: "100%" }}
        className="d-flex align-items-center justify-content-center"
      >
        <Riple color="#317bcc" size="medium" text="" textColor="" />
      </div>
    ) : (
      <Table />
    )}
  </div>
  )
}

export default DumpCycleTracker