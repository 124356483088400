import React from "react";
import truck from "../../../../../assets/svgs/ExcavatorLoadTracking/Truck.svg";
import clock from "../../../../../assets/svgs/ExcavatorLoadTracking/Clock.svg";
import styles from "./LoadTracker.module.css";
import { useExcavatorLoadTrackerContext } from "../context/context";
import moment from "moment";

const Header = () => {
  const { getExcavatorData, data } = useExcavatorLoadTrackerContext();
  const excavator = getExcavatorData();

  const calculateAverageWaitTime = () => {
    if (!excavator?.loads?.length) return "-";

    const validLoads = excavator.loads.filter(
      (load) => moment(load.startTime).isValid() && moment(load.endTime).isValid()
    );

    if (validLoads.length < 1) return "00h:00m"; // No wait time if 1 or fewer valid loads

    const totalWaitTimeMinutes = validLoads.reduce((total, load, index, loads) => {
      if (index === 0) return moment(load.startTime).diff(moment(data.shiftStartTime), "minutes");
      const prevLoad = loads[index - 1];
      return total + moment(load.startTime).diff(moment(prevLoad.endTime), "minutes");
    }, 0);

    const averageWaitTimeMinutes = totalWaitTimeMinutes / (validLoads.length - 1);
    const duration = moment.duration(averageWaitTimeMinutes, 'minutes');
    const hours = Math.floor(duration.asHours());
    const minutes = duration.minutes();

    const averageWaitTime = `${hours
      .toString()
      .padStart(2, "0")}h:${minutes
        .toString()
        .padStart(2, "0")}m `;

    return averageWaitTime;
  };


  return (
    <div className="pb-4 d-flex flex-column border-bottom">
      <div className="d-flex flex-row justify-content-between align-items-center mb-3">
        <div className="d-flex flex-row align-items-center">
          <img src={truck} alt="Truck Icon" />
          <p className={styles["load-tracker-label"]}>Total Loads</p>
        </div>

        <p className={styles["load-tracker-value"]}>
          {excavator ? excavator.loads.length : "-"}
        </p>
      </div>

      <div className="d-flex flex-row justify-content-between align-items-center">
        <div className="d-flex flex-row align-items-center">
          <img src={clock} alt="Truck Icon" />
          <p className={styles["load-tracker-label"]}>Average Wait Time</p>
        </div>

        <p className={styles["load-tracker-value"]}>
          {calculateAverageWaitTime()}
        </p>
      </div>
    </div>
  );
};

export default Header;
