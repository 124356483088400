import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchEquipments, deleteShiftNotes, fetchSupervisorList } from '../../../utils/apiService';
// import { filterData, formatQuery } from '../utils/helpers/searchHelper';
// import { filterEquipments, isEmptyFilter } from '../utils/helpers/filterHelper';
import { InitialFilters } from 'pages/ShiftNotes/utils/constant';
import { showToast } from 'pages/ShiftNotes/utils/helper';
import { CallWithAuth, CallWithAuthFormData } from 'action/apiActions';
import axios from 'axios';
import moment from 'moment-timezone'
import useAuth from 'hooks/useAuth';
const ShiftReportContext = createContext();


export const ShiftReportProvider = ({ children }) => {
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const [selectedDate, setSelectedDate] = useState(
        queryParams.get("date")
            ? moment.tz(queryParams.get("date"), "YYYY-MM-DD", "Pacific/Auckland").toDate()
            : new Date()
    );
    const [selectedShift, setSelectedShift] = useState({ day: undefined, night: undefined });
    const [shiftType, setShiftType] = useState(queryParams.get("type") ? queryParams.get("type") : 'day');
    const [shiftOptions, setShiftOptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const { auth } = useAuth();
    const timeoutRef = useRef(null);
    const [searchQuery, setSearchQuery] = useState('')
    const [shiftReportData, setShiftReportData] = useState([]);
    const [openFlyer, setOpenFlyer] = useState("");
    const [filters, setFilters] = useState(InitialFilters)

    const [equipmentList, setEquipmentList] = useState([])
    const [refreshPage, setRefreshPage] = useState(false)
    const [filteredResults, setFilteredResults] = useState([]);
    const [supervisorList, setSupervisorList] = useState([]);
    const [shiftSuperVisor, setShiftSupervisor] = useState({ day: undefined, night: undefined });

    useEffect(() => {
        const dateFromQuery = queryParams.get("date");
        const shiftTypeFromQuery = queryParams.get("type")
        const formattedSelectedDate = moment(selectedDate).format("YYYY-MM-DD");

        if (dateFromQuery && formattedSelectedDate !== dateFromQuery) {
            const newSelectedDate = moment.tz(dateFromQuery, "YYYY-MM-DD", "Pacific/Auckland").toDate();
            setSelectedDate(newSelectedDate);
        }
        if (shiftTypeFromQuery && shiftType !== shiftTypeFromQuery) {
            setShiftType(shiftTypeFromQuery);
        }
    }, [location.search]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [equipmentList] = await Promise.all([
                    fetchEquipments(),
                ]);
                setEquipmentList(equipmentList);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        const fetchSuperVisorData = async () => {
            try {
                const [supervisorList] = await Promise.all([
                    fetchSupervisorList(),
                ]);
                setSupervisorList(supervisorList);
            } catch (error) {
                console.log(error);
            }
        };

        fetchSuperVisorData();
    }, []);

    useEffect(() => {
        const source = axios.CancelToken.source();
        const cktkn = source.token;

        // Helper function to navigate with query parameters
        const navigateWithDate = () => {
            queryParams.set("date", moment(selectedDate).format("YYYY-MM-DD"));
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        };

        // Helper function to fetch shifts data
        const fetchShiftsData = async () => {
            try {
                setLoading(true);
                navigateWithDate(); // Update the navigation with the selected date

                const shiftResponse = await CallWithAuthFormData(
                    "POST",
                    "/api/getShiftsForDashboard?new=true",
                    {
                        userId: auth?.userData?._id,
                        date: moment(selectedDate).format("YYYY-MM-DD"),
                    },
                    {},
                    cktkn
                );

                const shiftsData = shiftResponse?.res?.data?.data || {};
                processShiftData(shiftsData);
            } catch (error) {
                handleFetchError(error);
            }
        };

        // Helper function to process fetched shift data
        const processShiftData = (shiftsData) => {
            setShiftOptions({
                day: shiftsData.day,
                night: shiftsData.night,
            });

            setSelectedShift({
                day: shiftsData?.day?.[0]?.value,
                night: shiftsData?.night?.[0]?.value,
            });

            setShiftSupervisor({
                day: shiftsData?.day?.[0]?.shiftSupervisor?._id,
                night: shiftsData?.night?.[0]?.shiftSupervisor?._id,
            })

            // Check if the selected shift type is valid
            if (!shiftsData[shiftType]?.[0]?.value) {
                setShiftReportData([])
                setFilteredResults([]);
            }

            setLoading(false); // Ensure loading is false after processing
        };

        // Helper function to handle fetch errors
        const handleFetchError = (error) => {
            setLoading(false); // Ensure loading is false on error
        };

        // Fetch shifts data if user is authenticated and a date is selected
        if (auth?.userData?._id && selectedDate) {
            fetchShiftsData();
        }

        // Cleanup function
        return () => {
            source.cancel('Operation canceled by the user.');
        };
    }, [selectedDate, auth]);

    useEffect(() => {
        const source = axios.CancelToken.source();

        // Helper function to fetch data
        const fetchData = async (dontLoad = false) => {
            if (!dontLoad) setLoading(true);
            try {
                const shiftReportResponse = await CallWithAuth(
                    "GET",
                    `/api/daily-Report?date=${moment(selectedDate).format("YYYY-MM-DD")}&shiftType=${shiftType == 'day' ? "Day" : "Night"}`,
                    {},
                    source.token
                );
                const data = shiftReportResponse?.res?.data;
                if (!dontLoad && data) setLoading(false);
                if (shiftReportResponse?.res?.status === 200 && shiftReportResponse?.status) {
                    setShiftReportData(data);
                    setFilteredResults(data?.dailyReports);
                }
                setAutoRefreshTimeout();
                // If successful, start the timeout for auto-refresh

            } catch (error) {
                handleError(error, dontLoad);
            }
        };

        // Helper function for error handling and retry logic
        const handleError = (error, dontLoad) => {
            if (!dontLoad) setLoading(false);
            console.error("Error fetching performance data:", error);

            // Set retry with timeout on error
            setAutoRefreshTimeout();
        };

        // Helper function to manage auto-refresh
        const setAutoRefreshTimeout = () => {
            timeoutRef.current = setTimeout(() => {
                if (selectedDate) fetchData(true);
            }, 5000);
        };

        // Initial Navigation
        // if (selectedDate) {
        //     queryParams.set("date",  moment(selectedDate).format("YYYY-MM-DD"));
        //     navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        // }

        if (shiftType) {
            queryParams.set("type", shiftType);
            navigate(`${location.pathname}?${queryParams.toString()}`, { replace: true });
        }

        // Initial Data Fetch
        if (selectedDate) fetchData();
        else {
            setShiftReportData([]);
            setFilteredResults([]);
        }

        // Cleanup on unmount
        return () => {
            source.cancel("Operation canceled by the user.");
            clearTimeout(timeoutRef.current);
        };
    }, [selectedDate, shiftType, refreshPage]);



    const searchShiftReportData = (searchQuery) => {
        const reportData = shiftReportData?.dailyReports
        setSearchQuery(searchQuery);
        if (!searchQuery.trim()) {
            setFilteredResults(reportData);
            return;
        }

        const lowerQuery = searchQuery?.toLowerCase().replace(/\s+/g, "");

        const results = reportData?.filter((item) => {
            const nameMatch = item.reportedBy
                .toLowerCase()
                .replace(/\s+/g, "")
                .includes(lowerQuery);

            //   const operatorMatch = item.operatorLoads.some((load) =>
            //     load.operatorName.toLowerCase().replace(/\s+/g, "").includes(lowerQuery)
            //   );

            return nameMatch;
        });

        setFilteredResults(results);
    };



    return (
        <ShiftReportContext.Provider value={{
            loading,
            setLoading,
            selectedDate,
            setSelectedDate,
            selectedShift,
            setSelectedShift,
            shiftType,
            setShiftType,
            openFlyer,
            setOpenFlyer,
            filters,
            setFilters,
            searchQuery,
            setSearchQuery,
            filteredResults,
            equipmentList,
            supervisorList,
            shiftOptions,
            shiftReportData,
            setRefreshPage,
            refreshPage,
            searchShiftReportData,
            shiftSuperVisor
        }}>
            {children}
        </ShiftReportContext.Provider>
    );
};

export const useShiftReportContext = () => {
    return useContext(ShiftReportContext);
};