import { GoDotFill } from "react-icons/go";

import { usePreStartContext } from "pages/Pre-StartCheck/Context/Context";
import {
  ISSUE_TYPE,
  PreStartHeader,
  statusMap,
} from "pages/Pre-StartCheck/utils/Constant";
import { convertDataToGroup, dotColors } from "pages/Pre-StartCheck/utils/Helper";
import DynamicTable from "pages/Pre-StartCheck/Component/Table/Table";
import { useEffect, useState } from "react";
import PreStartReport from "../PreStartReport/PreStartReport";
import {
  ISSUE_STATUS,
  JOB_STATUS,
} from "pages/ShiftMaintenance/utils/Constant";
import { useLocation, useNavigate } from "react-router-dom";

const PreStartTable = () => {
  const {
    selectedDate,
    loading,
    filteredResults,
    preStartData,
    showCharts,
  } = usePreStartContext();
    const location = useLocation();
    const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);

  const headerStyle = {
    paddingLeft: 12,
    font: "normal normal bold 11px/18px Open Sans",
    color: "#1A1A1A",
  };
  const headers = [
    {
      label: PreStartHeader.ASSET_ID,
      key: "assetId",
      style: {
        width: "1%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.RESULT,
      key: "result",
      style: {
        width: "13%",
        ...headerStyle,
        paddingLeft: 20,
      },
    },
    {
      label: PreStartHeader.OPERATOR,
      key: "operator",
      style: {
        width: "12%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.PRE_START_REF_NO,
      key: "preStartRefNo",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.REVIEWED_BY,
      key: "reviewedBy",
      style: {
        width: "13%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.OPERATOR_NOTIFICATION,
      key: "operatorNotification",
      style: {
        width: "13%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.ENGINE_HOURS,
      key: "engineHours",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.ASSET_ACTIVITY,
      key: "assetActivity",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.JOB_REF_NO,
      key: "jobRefNo",
      style: {
        width: "10%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.JOB_STATUS,
      key: "jobStatus",
      style: {
        width: "14%",
        ...headerStyle,
      },
    },
    {
      label: PreStartHeader.ACTIONS,
      key: "actionMenu",
      style: {
        width: "0%",
        ...headerStyle,
      },
    },
  ];

  const rowHeaderBadgeStyle = {
    font: "normal normal bold 12px/17px Open Sans",
    color: "#1A1A1A",
    background: "#EFF3FA 0% 0% no-repeat padding-box",
    borderRadius: "15px",
    padding: "6px 10px 6px 10px",
  };

  const totalFilteredPscData = filteredResults?.length ? filteredResults?.length : 0


  const totalPscData = preStartData?.length ? preStartData?.length : 0

  const [selectedId, setSelectedId] = useState("");
  const [viewReportModal, setViewReportModal] = useState(false);
  const [convertedData, setConvertedData] = useState([])

  const handleViewReport = (id) => {
    if (id) {
         queryParams.set("id",id);
            navigate(`${location.pathname}?${queryParams.toString()}`, {
              replace: true,
            });
      setSelectedId(id);
      setViewReportModal(true);
    }

  };

  useEffect(() => {
    if (filteredResults) {
      const groupData = convertDataToGroup(filteredResults);
      setConvertedData(groupData)
    }
    else {
      setConvertedData([])
    }
  }, [filteredResults]);




  const handleCloseViewReport = () => setViewReportModal(false);

  const processedData = convertedData?.length
    ?
    convertedData?.map((group) => ({
      groupName: group?.name, // Use `name` as the group name
      groupData: group?.data?.map((pre) => ({
        rowHeaderStyle: { rowHeaderBadgeStyle },
        rowHeader: group?.name,
        // Assign group name as the row header
        rows: [
          {
            value: {
              id: pre?._id,
              assetId: pre?.equipment?.Unit || "-",
              result: pre?.status ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(statusMap[pre?.status]),
                      paddingBottom: "2px",
                    }}
                  />
                  {statusMap[pre?.status]}
                </span>
              ) : (
                "-"
              ),
              operator: pre?.operator?.firstName
                ? `${pre?.operator?.firstName} ${pre?.operator?.lastName}`
                : "-",
              preStartRefNo: pre?.refNo || "-",
              reviewedBy: pre?.supervisor?.firstName
                ? `${pre?.supervisor?.firstName || ""}  ${pre?.supervisor?.lastName || ""
                }`
                : "-" || "-",
              operatorNotification:
                statusMap[pre?.status] === ISSUE_TYPE.CAT_B_FAILURE ? (
                  pre?.notificationBySupervisor?.notifiedBy ? (
                    <span>
                      {" "}
                      <GoDotFill
                        fontSize={19.5}
                        style={{
                          color: "#06AF7B",
                          paddingBottom: "2px",
                        }}
                      />
                      Sent
                    </span>
                  ) : (
                    <span>
                      {" "}
                      <GoDotFill
                        fontSize={19.5}
                        style={{
                          color: "#E03E3E",
                          paddingBottom: "2px",
                        }}
                      />
                      Pending
                    </span>
                  )
                ) : (
                  "-"
                ),
              engineHours: pre?.engineHours || "-",
              assetActivity: pre?.assetActivity ? (
                <span>
                  <GoDotFill
                    fontSize={19.5}
                    style={{
                      color: dotColors(pre?.assetActivity),
                      paddingBottom: "2px",
                    }}
                  />
                  {pre?.assetActivity}
                </span>
              ) : (
                "-"
              ),
              jobRefNo: (
                <span style={{ textDecoration: pre?.job ? "underline" : "", color: pre?.job ? "#186FD9" : "" }}>
                  {pre?.job
                    ? pre?.job?.WONumber
                      ? pre?.job?.WONumber
                      : pre?.job?.refNo
                    : "-"}
                </span>
              ),
              jobStatus: pre?.job?.jobStatus || "-",
            },
            rowStyle: {
              font: "normal normal normal 13px/19px Open Sans",
              color: "#1A1A1A",
            },
            onClick: () => handleViewReport(pre?._id),
            actionMenu: {
              options: [
                {
                  name: "View Report",
                  icon: "",
                  onClick: () => handleViewReport(pre?._id),
                },
                {
                  name: "Download",
                  icon: "",
                  onClick: () => { },
                },
                {
                  name: "History",
                  icon: "",
                  onClick: () => { },
                },
              ],
            },
          },
        ],
        actionMenu: {
          options: [],
        },
      })),
    }))
    : [];

  return (
    <>
      {viewReportModal && (
        <PreStartReport date={selectedDate} closeModal={handleCloseViewReport} id={selectedId} />
      )}

      <DynamicTable
      id="pscTable"
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={true}
        rowHeader={false}
        footer={`Displaying  ${totalFilteredPscData}/${totalPscData} Pre-Start Checks`}
      />
    </>
  );
};

export default PreStartTable;
