import Flyer from "pages/ShiftMaintenance/Component/Flyers/Flyer";
import { REQUESTED_FLYER } from "pages/ShiftMaintenance/utils/Constant";
import { useState } from "react";
import RejectJobComponent from "./RejectJobComponent";
import { RejectJobData } from "pages/ShiftMaintenance/utils/ApiServices";
import { showToast } from "pages/ShiftMaintenance/utils/helper";
import { useRequestedContext } from "../Context/Context";

const RejectJob = ({ data }) => {
  const { openFlyer, setOpenFlyer, setRefreshPage } =
    useRequestedContext();

  const initialFormData = {
    equipment: data?.generalInfo?.equipment,
    refNo: data?.generalInfo?.refNo,
    issueType: data?.generalInfo?.issueType,
    notesAndComments: ""
  }
  const [formData, setFormData] = useState(initialFormData);

  const handleRejectJob = async () => {

    const formattedData = {
      notesAndComments: formData?.notesAndComments
    }
    const approveJobResponse = await RejectJobData(formattedData, data?.jobId);
    if (approveJobResponse) {
      showToast(true, "Job reject successfully");
      setOpenFlyer("");
      setRefreshPage(true);
    } else {
      showToast(false, "An error occurred while rejecting job");
    }
  };

  return (
    <>
      {openFlyer === REQUESTED_FLYER.JOB_REJECT && (
        <Flyer
          isOpen={openFlyer === REQUESTED_FLYER.JOB_REJECT}
          onClose={() => {
            setOpenFlyer("");
          }}
          title={"Job Rejection Form"}
          containerStyle={{
            zIndex: "100",
            right: 0,
            bottom: 0,
            top: "unset",
            height: "calc(100vh - 9.25rem)",
          }}
          headerHeight="60px"
          headerClassName={"pt-3"}
          hideBorderBottom={true}
          tabs={[
            {
              title: "",
              rightActionButtons: [
                {
                  label: "Submit",
                  onClick: async () => handleRejectJob(),
                },
              ],
              component: (
                <RejectJobComponent
                  formData={formData}
                  setFormData={setFormData}
                />
              ),
            },
          ]}
        />
      )}
    </>
  );
};

export default RejectJob;
