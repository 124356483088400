import moment from "moment";
import styles from "../ViewDetails.module.css";

const LabelValue = ({ label, value }) => (
  <div className="d-flex align-items-start justify-content-between">
    <div className={styles.label}>{label}</div>
    <div className={styles.value}>{value || "-"}</div>
  </div>
);

const ReviewedByValue = ({ firstName, lastName, reviewedAt }) => {
  const name = firstName ? `${firstName} ${lastName || ""}` : "";
  const formattedDate = reviewedAt
    ? moment(reviewedAt).format("DD/MM/YYYY, hh:mm A")
    : "-";
  return (
    <LabelValue
      label="Reviewed By"
      value={name ? `${name}, ${formattedDate}` : formattedDate}
    />
  );
};



const SupervisorSummary = ({ supervisorData }) => {
  return (
    <div className="d-flex flex-column" style={{ gap: "16px" }}>
      <LabelValue label="Notes & Comments" value={supervisorData?.notes} />
      <ReviewedByValue
        firstName={supervisorData?.firstName}
        lastName={supervisorData?.lastName}
        reviewedAt={supervisorData?.reviewedAt}
      />
      <LabelValue
        label="Signature"
        value={
          <embed
            style={{ maxHeight: "40px", maxWidth: "170px" }}
            src={supervisorData?.signature}
            alt="sign"
          />
        }
      />
    </div>
  );
};

export default SupervisorSummary;
