import React, { useState } from "react";
import styles from "../Alerts/Alerts.module.css";
import NotificationToast from "../NotificationToast/NotificationToast";
import useNotificationContext from "hooks/useNotificationContext";

const Alerts = () => {
  const [collapsed, setCollapsed] = useState(true); // State to manage collapse status
  const {
    getAlerts,
    getInformation,
    getNotifications,
    clearAllAlerts,
    clearAllInformation,
    setShowNotificationSummary,
    clearAllNotifications,
  } = useNotificationContext();

  const alerts = getAlerts(),
    information = getInformation(),
    notifications = getNotifications();

  const handleShowLess = () => {
    setCollapsed(true); // Collapse the cards
  };

  const handleExpand = () => {
    console.log(notifications.length > 1);
    if (notifications.length > 1) {
      setCollapsed(false); // Expand the cards
    } else {
      clearAllNotifications();
      setShowNotificationSummary(true);
    }
  };

  return (
    notifications.length !== 0 && (
      <div className={`px-4 ${styles.alertContainer}`}>
        {!collapsed ? (
          <div className="px-3" style={{ height: "99vh", backdropFilter: 'blur(15px)' }}>
            {alerts.length !== 0 && (
              <div className="py-4">
                <div className="d-flex align-items-center justify-content-between">
                  <div className={styles.alertTitle}>Alerts</div>
                  <div
                    className="py-3 d-flex align-items-center justify-content-between"
                    style={{ gap: "10px" }}
                  >
                    <div
                      className={styles.showLessBtn}
                      onClick={handleShowLess}
                    >
                      Show Less
                    </div>
                    <div
                      className={styles.clearAllBtn}
                      onClick={clearAllAlerts}
                    >
                      Clear All
                    </div>
                  </div>
                </div>
                <NotificationToast data={alerts} isAlert={true} />
              </div>
            )}

            {information.length !== 0 && (
              <div className="">
                <div className="d-flex align-items-center justify-content-between">
                  <div className={styles.alertTitle}>Information</div>
                  <div
                    className="py-3 d-flex align-items-center justify-content-between"
                    style={{ gap: "10px" }}
                  >
                    {alerts.length === 0 && (
                      <div
                        className={styles.showLessBtn}
                        onClick={handleShowLess}
                      >
                        Show Less
                      </div>
                    )}
                    <div
                      className={styles.clearAllBtn}
                      onClick={clearAllInformation}
                    >
                      Clear All
                    </div>
                  </div>
                </div>
                <NotificationToast data={information} isAlert={false} />
              </div>
            )}
          </div>
        ) : (
          <div className="pb-3 pl-3" onClick={handleExpand} style={{ position: "relative", cursor: "pointer", right: 5 }}>
            <NotificationToast
              isCancelable={!collapsed}
              data={[notifications[0]]}
              isAlert={notifications[0].type === "alert"}
              isCloseVisible={notifications.length === 1}
              isOnClickDisabled={notifications.length > 1}
            />
            {notifications.length > 2 && (
              <div
                className={styles.shadow}
                style={{
                  width: "400px",
                  height: "80px",
                  top: "15px",
                  left: "25px",
                  position: "absolute",
                }}
              ></div>
            )}
            {notifications.length > 1 && (
              <div
                className={styles.shadow}
                style={{
                  width: "400px",
                  height: "80px",
                  top: "7.5px",
                  left: "17.5px",
                  position: "absolute",
                }}
              ></div>
            )}

          </div>
        )}
      </div>
    )
  );
};

export default Alerts;
