export const assetCategory = [
  { value: "Excavator", label: "Excavator" },
  { value: "Truck", label: "Haul Trucks" },
]

export const shiftAssignment = [
  { value: "assigned", label: "Assigned-Ongoing" },
  { value: "Unassigned", label: "Unassigned" },
]

export const preStartStatus = [
  { value: "Complete", label: "Complete" },
  { value: "Incomplete", label: "Incomplete" },
]

export const preStartResult = [
  { value: "Cat A Failure", label: "Cat A Failure", color: "#E03E3E" },
  { value: "Cat B Failure", label: "Cat B Failure", color: "#FFBA01" },
  { value: "Cat C Failure", label: "Cat C Failure", color: "#6C35E6" },
  { value: "Passed", label: "Passed", color: "#06AF7B" },
]

export const assetStatus = [
  { value: "Out of Order", label: "Out of Order", color: "#E03E3E" },
  { value: "Operational", label: "Operational", color: "#06AF7B" },
];

export const assetActivity = [
  { value: "Active", label: "Active", color: "#06AF7B" },
  { value: "Parked", label: "Parked", color: "#939FB7" },
  { value: "Battery Disconnected", label: "Battery Disconnected", color: "#FFA701" },
  { value: "Disconnected", label: "Disconnected", color: "#E03E3E" },
]

