import React from 'react'
import { Dropdown, Image } from 'react-bootstrap';

const Legend = ({ onClick }) => {
    return (
        <div className='position-relative'>
        <Dropdown className="d-flex">
            <Dropdown.Toggle
                style={{ height: "2.5rem", width: "6rem" }}
                bsPrefix="custom-toggle"
                id=""
            >
                Legend
            </Dropdown.Toggle>

            <Dropdown.Menu bsPrefix="dropdown-menu custom-menu2 right-0">
                <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                >
                    <div
                        style={{
                            background: "#95C365 0% 0% no-repeat padding-box",
                        }}
                        className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                        Target Achieved
                    </div>
                </Dropdown.Item>
                <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                >
                    <div
                        style={{
                            background: "#ED7C7F 0% 0% no-repeat padding-box",
                        }}
                        className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                        Below Target
                    </div>
                </Dropdown.Item>
                <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                >
                    <Image
                        src={require("../../../../assets/images/not-available.png")}
                        height={18}
                        width={18}
                        style={{ marginRight: 7 }}
                    />
                    <div className="dashboard__header__bar__label">
                        {/* Not operating */}
                        Breakdown
                    </div>
                </Dropdown.Item>
                <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                >
                    <Image
                        src={require("../../../../assets/images/alert.png")}
                        height={16}
                        width={18}
                        style={{ marginRight: 7 }}
                    />
                    <div className="dashboard__header__bar__label">
                        Falling behind
                    </div>
                </Dropdown.Item>
                <Dropdown.Item
                    className="d-flex align-items-center justify-content-start"
                    disabled
                >
                    <div
                        style={{
                            background: "#BFD4F9 0% 0% no-repeat padding-box",
                        }}
                        className="dashboard__header__bar"
                    />
                    <div className="dashboard__header__bar__label">
                        Ongoing cycle{" "}
                        <span style={{ fontSize: "10px" }}>
                            (on hourly results)
                        </span>
                    </div>
                </Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </div>
    )
}

export default Legend