export const dummy_Licences = [
    {
        "_id": "6491213291c6cf03df3c0b77",
        "name": "Excavator",
        "code": 2,
        "__v": 0,
        "departmentCode": 800
    },
    {
        "_id": "64d462eefba89e600cd101b3",
        "name": "Misc",
        "code": 0,
        "__v": 0,
        "departmentCode": 600
    },
    {
        "_id": "668b172735e826e4871b9422",
        "name": "Truck",
        "code": 6,
        "__v": 0,
        "departmentCode": 600
    },
]