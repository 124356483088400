import ApprovedTable from "./ApprovedTable/ApprovedTable";
import { ApprovedProvider } from "./Context/Context";
import Header from "./Header/Header";

const Approved = ({ }) => {
  return (
    <>
      <ApprovedProvider>
        <Header />
        <ApprovedTable />
      </ApprovedProvider>
    </>
  );
};

export default Approved;
