import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt } from 'react-icons/fa';
import styles from './DateTimeSelect.module.css';
import { IoIosArrowDown } from 'react-icons/io';
import { IoCalendarOutline } from 'react-icons/io5';

const DateTimeSelect = ({
    label,
    value,
    placeholder = "DD/MM/YYYY",
    dateFormat = "yyyy/MM/dd",
    onDateChange,
    containerStyle = {},
    className = "",
    iconColor = "#1a73e8",
    isDisabled = false,
    labelStyle
}) => {

    const handleChange = (date) => {
        if (onDateChange) {
            onDateChange(new Date(date));
        }
    };

    return (
        <div className='d-flex align-items-center '>
            {label && <div style={{width:"20%",...labelStyle}} >{label}</div>}
            <div className={styles.datePickerContainer + ` pl-3 ${className}`} style={{
                background: isDisabled ? "#E1E4EA" : "transparent",
                ...containerStyle}}>
                <DatePicker
                    selected={value}
                    onChange={handleChange}
                    dateFormat={dateFormat}
                    placeholderText={placeholder}
                    className={ isDisabled? styles.customInputDisabled :styles.customInput}
                  
                />
                {/* <FaCalendarAlt className={styles.calendarIcon} style={{ color: iconColor }} /> */}
                <IoCalendarOutline className={styles.calendarIcon} style={{ color: iconColor }} />
            </div>
        </div>
    );
};

export default DateTimeSelect;
