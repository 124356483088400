import { CallWithAuth } from "action/apiActions";
import axios from "axios";
import useAuth from "hooks/useAuth";
import { ArchivedInitialFilters } from "pages/ShiftMaintenance/utils/Constant";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { formatQuery, getValuesFromObj } from "pages/ShiftMaintenance/utils/helper";
import moment from "moment";

const ArchivedContext = createContext();

export const ArchivedProvider = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [loading, setLoading] = useState(true);
  const { auth } = useAuth();
  const timeoutRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [archivedData, setArchivedData] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [openFlyer, setOpenFlyer] = useState("");
  const [filters, setFilters] = useState(ArchivedInitialFilters);
  //   const filtersRef = useRef(ArchivedInitialFilters);
  //   const searchQueryRef = useRef("");
  const [refreshPage, setRefreshPage] = useState(false);
  const [sortBy, setSortBy] = useState("archivedOn");

  useEffect(() => {
    queryParams.set("sortBy", sortBy);
    const sortByFromQuery = queryParams.get("sortBy");
    if (sortByFromQuery && sortBy !== sortByFromQuery) {
      setSortBy(sortByFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const source = axios.CancelToken.source();

    // Helper function to fetch data
    const fetchData = async (dontLoad = false) => {
      if (!dontLoad) setLoading(true);
      try {
        // Construct the query parameters
        const queryParams = new URLSearchParams({
          type: 'archived',
          sortBy,
          assetCategory: getValuesFromObj(filters?.assetCategory),
          date: filters?.date ? moment(filters?.date).format("DD/MM/YYYY") : "",
          dateType: getValuesFromObj(filters?.jobType),
          delayType: getValuesFromObj(filters?.delayType),
          issueType: getValuesFromObj(filters?.issueType),
          jobStatus: getValuesFromObj(filters?.jobStatus),
          issueStatus: getValuesFromObj(filters?.issueStatus),
          page:0
        }).toString();

        // Call the API with the constructed query string
        const jobResponse = await CallWithAuth(
          "GET",
          `/api/job?${queryParams}`,
          {},
          source.token
        );

        const data = jobResponse?.res?.data;
        //const data = archivedDummyData;

        if (!dontLoad && data) setLoading(false);
        if (jobResponse?.res?.status) {
          setArchivedData(data);
          setFilteredResults(data);
        }


        setAutoRefreshTimeout();
        // If successful, start the timeout for auto-refresh
      } catch (error) {
        handleError(error, dontLoad);
      }
    };

    // Helper function for error handling and retry logic
    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error("Error fetching job data:", error);

      // Set retry with timeout on error
      setAutoRefreshTimeout();
    };

    // Helper function to manage auto-refresh
    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        if (sortBy) fetchData(true);
      }, 5000);
    };

    // Initial Navigation
    if (sortBy) {
      queryParams.set("sortBy", sortBy);
      navigate(`${location.pathname}?${queryParams.toString()}`, {
        replace: true,
      });
    }

    // Initial Data Fetch
    if (sortBy) fetchData();
    else {
      setArchivedData([]);
      setFilteredResults([]);
    }

    // Cleanup on unmount
    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [sortBy, filters, refreshPage]);
  // 
  const onApplyFilter = (filterObj, wantToClear = false) => {
    setFilters(filterObj);
  };

  const searchArchivedData = (searchQuery) => {
    setSearchQuery(searchQuery);
    if (!searchQuery.trim()) {
      setFilteredResults(archivedData);
      return;
    }
    const formattedSearchQuery = formatQuery(searchQuery);

    const filteredData = archivedData?.map(day => ({
      ...day,
      jobs: day.jobs.filter(job => {
        // Define specific fields to search within
        const searchableFields = {
          assetId: job.assetId,
          jobType: job.issueType,
          addedBy: job.addedBy,
          jobRefNo: job?.jobRefNo,
          delayType: job?.delayType
        };

        // Check if any searchable field contains the formatted search query
        return Object.values(searchableFields).some(value =>
          value && formatQuery(String(value)).includes(formattedSearchQuery)
        );
      })
    })).filter(day => day.jobs.length > 0);

    setFilteredResults(filteredData);
  };




  return (
    <ArchivedContext.Provider
      value={{
        loading,
        setLoading,
        openFlyer,
        sortBy,
        setSortBy,
        setOpenFlyer,
        filters,
        setFilters,
        searchQuery,
        setSearchQuery,
        searchArchivedData,
        onApplyFilter,
        archivedData,
        filteredResults,
        refreshPage,
        setRefreshPage,
      }}
    >
      {children}
    </ArchivedContext.Provider>
  );
};

export const useArchivedContext = () => {
  return useContext(ArchivedContext);
};
