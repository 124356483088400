import TextInputField from "pages/ShiftMaintenance/Component/TextInputField";
import styles from "../Signature/Signature.module.css";
import TextAreaField from "pages/ShiftMaintenance/Component/GeneralFields/TextAreaField";
import { IoCloseSharp } from "react-icons/io5";
import ImageUploader from "pages/ShiftMaintenance/Component/ImageUploader/ImageUploader";
import { useState } from "react";
import ConfirmAlert from "pages/ShiftMaintenance/Component/ConfirmAlert/ConfirmAlert";
import { ReviewJob } from "pages/ShiftMaintenance/utils/ApiServices";
import { showToast } from "pages/ShiftMaintenance/utils/helper";
import { useApprovedContext } from "../Context/Context";
import { toast } from "react-toastify";

const Signature = ({
  jobId,
  handleClose,
  editFormData,
  handleCloseJob
}) => {

  const { refreshPage, setRefreshPage, setOpenFlyer } = useApprovedContext();

  const [data, setData] = useState({
    firstName: editFormData?.supervisorInfo?.firstName,
    lastName: editFormData?.supervisorInfo?.lastName,
    notes: editFormData?.supervisorInfo?.notes,
    signature: editFormData?.supervisorInfo?.signature,
    file: editFormData?.signature,
  });

  const [signatureAndCloseConfirmModal, setSignatureConfirmModal] = useState({
    show: false,
    title: "",
    message: "",
    nid: "",
  });

  const [closeSuggestionModal, setCloseSuggestionModal] = useState({
    show: false,
    title: "",
    message: "",
    nid: "",
  });


  const handleCloseJobModal = () => {
    setSignatureConfirmModal({
      show: true,
      title: "Sign & Close Job",
      message: confirmSubmitAndCloseMessage(),
      wantToDelete: true,
      jid: jobId,
    });
  }

  const handleSuggestionCloseJobModal = () => {
    setSignatureConfirmModal({
      show: true,
      title: "Close Job",
      message: suggestionCloseMessage(),
      wantToDelete: true,
      jid: jobId,
    });
  }

  const confirmSubmitAndCloseMessage = () => (
    <div
      className="d-flex flex-column"
      style={{
        font: "normal normal normal 14px/20px Open Sans",
        color: "#000000",
      }}
    >
      <span>
        Are you sure to Sign & close this job?
        Note, closed jobs will move to the archives section.
      </span>
    </div>
  );

  const suggestionCloseMessage = () => (
    <div
      className="d-flex flex-column"
      style={{
        font: "normal normal normal 14px/20px Open Sans",
        color: "#000000",
      }}
    >
      <span>
        Would close this job?
        Note, closed jobs will move to the archives section.
      </span>
    </div>
  );

  const SubmitAndClose = async () => {
    handleCloseJobModal();
  }

  const Submit = async () => {
    const reviewJobResponse = await handleReviewJob();
    if (reviewJobResponse) {
      showToast(true, "Job reviewed successfully");
      setOpenFlyer("")
      handleSuggestionCloseJobModal();
    }
    else {
      showToast(false, "Error while review job.");
    }
  }

  const handleReviewJob = async () => {
    const formData = new FormData();
    formData.append("firstName", data?.firstName);
    formData.append("lastName", data?.lastName);
    //formData.append("signature",  data?.signature);
    formData.append("notes", data?.notes);
    formData.append("files", file);
    try {
      const isSigned = await ReviewJob(formData, jobId);

      if (isSigned) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const [file, setFile] = useState("");

  return (
    <>
      <div
        id="summary-report"
        className={`modal fade show ${styles.modalTop}`}
        tabIndex="-1"
        role="dialog"
      >
        <div
          id="modalDialogTop"
          className={`modal-dialog modal-dialog-centered ${styles.modalTopArea}`}
          role="document"
        >
          <div className={`modal-content px-5 ${styles.modalContent}`}>
            <div
              className={`modal-header d-flex align-items-center  justify-content-between ${styles.modalHeader}`}
            >
              <div className={styles.title}>Add Notes & Signature</div>
              <div onClick={handleClose} className={styles.close}>
                <IoCloseSharp color="black" fontSize={17.5} />
              </div>
            </div>
            <div className={`modal-body p-0 pt-3 pb-5 ${styles.modalBody}`}>
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ width: "100%" }}
              >
                <div className="d-flex flex-column" style={{ width: "48%" }}>
                  <div className={`pb-2 ${styles.label}`}>First Name*</div>
                  <div>
                    {" "}
                    <TextInputField
                      label=""
                      required={true}
                      placeholder=""
                      style={{
                        background: "transparent",
                        border: "1px solid #E1E8F4",
                        borderRadius: "4px",
                        width: "300px",
                        height: "40px",
                        font: "normal normal 600 14px/19px Open Sans",
                        color: "#5F6672",
                        opacity: 1,
                      }}

                      value={data?.firstName}
                      onChange={(v) =>
                        setData({ ...data, firstName: v })
                      }
                    />
                  </div>
                </div>
                <div className="d-flex flex-column" style={{ width: "48%" }}>
                  <div className={`pb-2 ${styles.label}`}>Last Name*</div>
                  <div>
                    <TextInputField
                      label=""
                      required={true}
                      placeholder=""
                      style={{
                        background: "transparent",
                        border: "1px solid #E1E8F4",
                        borderRadius: "4px",
                        width: "300px",
                        height: "40px",
                        font: "normal normal 600 14px/19px Open Sans",
                        color: "#5F6672",
                        opacity: 1,
                      }}
                      value={data?.lastName}
                      onChange={(v) =>
                        setData({ ...data, lastName: v })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="pt-2">
                <div className={`pb-2 ${styles.label}`}>Notes & Comments</div>
                <TextAreaField
                  wrapperStyle={{ flexGrow: "1", minHeight: "8.5rem" }}
                  style={{
                    height: "100%",
                    border: "1px solid #E1E8F4",
                    borderRadius: "4px",
                  }}
                  value={data?.notes}
                  onChange={(v) =>
                    setData({ ...data, notes: v })
                  }
                />
              </div>
              <div className="pt-2">
                <div className={`pb-2 ${styles.label}`}>Add Signature</div>
                <div>
                  <div className="w-100">
                    <ImageUploader
                      selectedImage={data?.signature}
                      setSelectedImage={(imageData) =>
                        setData({ ...data, signature: imageData })
                      }
                      originalFileName="supervisor_signature"
                      setFile={(file) => {
                        setFile(file)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-4 d-flex align-items-center justify-content-between">
                <div
                  className={styles.submitClose}
                  style={{
                    color: editFormData?.supervisorInfo?.signature
                      ? "#186FD9"
                      : "#8190AD",
                    cursor: editFormData?.supervisorInfo?.signature
                      ? "pointer"
                      : "not-allowed",
                    pointerEvents: editFormData?.supervisorInfo?.signature
                      ? "auto"
                      : "none",
                  }}
                  onClick={SubmitAndClose}
                >
                  Submit & Close Job
                </div>
                <div
                  className={styles.submit}
                  style={{
                    color: data?.signature
                      ? "#FFFFFF"
                      : "#8190AD",
                    background: data?.signature
                      ? "#186FD9"
                      : "#E1E4EA",
                    cursor: data?.signature
                      ? "pointer"
                      : "not-allowed",
                    pointerEvents: data?.signature
                      ? "auto"
                      : "none",
                  }}
                  onClick={Submit}
                >
                  Submit
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {signatureAndCloseConfirmModal.show && (
        <ConfirmAlert
          title={signatureAndCloseConfirmModal.title}
          message={signatureAndCloseConfirmModal.message}
          onClose={() => {
            setSignatureConfirmModal({
              show: false,
              title: "",
              message: "",
              jid: "",
            });
            handleClose()
            setOpenFlyer("")
          }}
          onConfirm={async () => {
            try {
              await handleCloseJob(jobId);
              setSignatureConfirmModal({
                show: false,
                title: "",
                message: "",
                jid: "",
              });
              setRefreshPage(!refreshPage)
              handleClose()
              setOpenFlyer("")
            } catch (error) {
              toast.error(error.message);
            }
          }}
        />
      )}

      {closeSuggestionModal.show && (
        <ConfirmAlert
          title={closeSuggestionModal.title}
          message={closeSuggestionModal.message}
          onClose={() => {

            setCloseSuggestionModal({
              show: false,
              title: "",
              message: "",
              jid: "",
            });
            handleClose()
          }}
          onConfirm={async () => {
            try {
              await handleCloseJob(jobId);
              setOpenFlyer("")
              setRefreshPage(!refreshPage)
              handleClose()
              setCloseSuggestionModal({
                show: false,
                title: "",
                message: "",
                jid: "",
              });
            } catch (error) {
              toast.error(error.message);
            }
          }}
        />
      )}


    </>
  );
};

export default Signature;
