import ExportButton from "pages/Maintenance/Component/ExportButton/ExportButton";
import Legend from "pages/Maintenance/Component/Legend/Legend";
import React from "react";
import { useDumpCycleTrackerContext } from "../context/context";
import EquipmentButton from "pages/Maintenance/Component/EquipmentButton/EquipmentButton";
import Searchbar from "pages/Maintenance/Component/Searchbar/Searchbar";
import Datepicker from "pages/Maintenance/Component/Datepicker/Datepicker";
import DayNightSwitch from "pages/Maintenance/Component/DayNightSwitch/DayNightSwitch";
import { generatePdf, getCSVData } from "./helpers";
import SelectField from "pages/ShiftPlanning/components/GeneralFields/SelectField";

const Header = () => {
  const {
    getExcavators,
    selectedExcavator,
    setSelectedExcavator,
    selectedTruck,
    setSelectedTruck,
    getTrucks,
    search,
    setSearch,
    date,
    setDate,
    shift,
    setShift,
    data,
    getTruckData,
    mainContainer,
    shiftOptions,
    loading,
    setSelectedShift,
  } = useDumpCycleTrackerContext();

  const truck = getTruckData();

  const excavators = getExcavators(),
    trucks = getTrucks();
  return (
    <div className="d-flex flex-column align-items-center p-5 border-bottom">
      <div className="d-flex flex-row justify-content-between w-100">
        <div className="d-flex flex-row gap-4 justify-content-start">
          {excavators.map((e, idx) => (
            <EquipmentButton
              isSelected={idx === selectedExcavator}
              label={e.excavatorName}
              onClick={() => {
                setSelectedTruck(0);
                setSelectedExcavator(idx);
              }}
              key={idx}
            />
          ))}
        </div>

        <div className="d-flex flex-row gap-4 justify-content-end">
          <ExportButton
            onClick={() => generatePdf(mainContainer, truck)}
            fileName={
              "Dump_Cycle_Tracker_Data " +
              truck?.excavatorName +
              "_" +
              truck?.truckName
            }
            data={data ? getCSVData(truck) : []}
          />
          <Legend />
        </div>
      </div>

      <div className="d-flex flex-row justify-content-between w-100 mt-4">
        <div className="d-flex flex-row gap-4 justify-content-start">
          {trucks.map((e, idx) => (
            <EquipmentButton
              isSelected={idx === selectedTruck}
              label={e.truckName}
              onClick={() => setSelectedTruck(idx)}
              key={idx}
            />
          ))}
        </div>

        <div className="d-flex flex-row gap-4 justify-content-end align-items-center">
          <Searchbar search={search} onChange={(e) => setSearch(e)} />
          <Datepicker date={date} onChange={(e) => setDate(e)} />
          <DayNightSwitch value={shift} onChange={(e) => setShift(e)} />
          <SelectField
            noPlaceHolder={true}
            options={shiftOptions?.[shift]}
            value="1"
            className={"mr-2"}
            isDisabled={!shiftOptions?.[shift]?.length || loading}
            style={{
              height: "30px",
              width: "160px",
              marginTop: 2.5,
              border: "1px solid #F1F1F1",
              borderRadius: "15px",
              paddingLeft: "10px",
              marginLeft: "10px",
              font: "normal normal 600 11px/14px Open Sans",
              color: "#000000",
              cursor:
                !shiftOptions?.[shift]?.length || loading
                  ? "not-allowed"
                  : "pointer",
            }}
            onChange={(v) =>
              setSelectedShift((prev) => ({ ...prev, [shift]: v }))
            }
            mainContainerStyle={{ marginBottom: "0px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
