
import styles from "../Header/Header.module.css";


// import Filter from "../Filter/Filter";
import filter from "../../../../assets/images/filter.png";
import download from "../../../../assets/svgs/shift-maintenance/download.svg";
import { FLEET_FLYER, fleetSortOptions } from "pages/Fleet/utils/Constant";
import { useFleetContext } from "pages/Fleet/Context/Context";
import RadioButton from "pages/Fleet/Component/RadioButton/RadioButton";
import Search from "pages/Fleet/Component/GeneralFields/Search/Search";
import Filter from "../Filter/Filter";

const Header = () => {
  const {
    searchQuery,
    sortBy,
    setSortBy,
    openFlyer,
    setOpenFlyer,
    searchFleetData
  } = useFleetContext();


  const handleFilter = () => {
    setOpenFlyer(FLEET_FLYER.FILTER);
  };

  return (
    <div className="mx-5 py-4 border-bottom d-flex align-items-center justify-content-between">
      <RadioButton
        radioLabel={"Sort By:"}
        options={fleetSortOptions}
        value={sortBy}
        onChange={(v) => {
          setSortBy(v);
        }}
        isDisabled={false}
        id="sortOptions"
      />

      <div
        className="d-flex justify-content-between align-items-center"
        style={{ gap: "10px" }}
      >
        <Search
          query={searchQuery}
          onChange={(value) => searchFleetData(value)}
        />

        <div
          style={{
            userSelect: "none",
            backgroundColor:
              openFlyer === FLEET_FLYER.FILTER
                ? // ||
                // filters.noteType ||
                // filters.equipments.length > 0 ||
                // filters.startTime ||
                // filters.endTime
                "#E2ECFC"
                : "transparent",
          }}
          className={styles.filterBtn}
          onClick={() => handleFilter()}
        >
          <img src={filter} alt="filter" />
          <span>Filter</span>
        </div>

        {openFlyer === FLEET_FLYER.FILTER && (
          <Filter openFlyer={openFlyer} setOpenFlyer={setOpenFlyer} />
        )}


      </div>
    </div>
  );
};

export default Header;
