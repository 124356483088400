import moment from "moment";
import DateAndTimeSelect from "pages/ShiftMaintenance/Component/GeneralFields/DateAndTimeSelect/DateAndTimeSelect";
import DynamicSelectField from "pages/ShiftMaintenance/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import RadioButton from "pages/ShiftMaintenance/Component/RadioButton/RadioButton";
import TextInputField from "pages/ShiftMaintenance/Component/TextInputField";
import { ISSUE_TYPE, JOB_STATUS } from "pages/ShiftMaintenance/utils/Constant";
import {
  assetStatus,
  jobStatus,
} from "pages/ShiftMaintenance/utils/DropDownMasters";
import { MdCircle } from "react-icons/md";

const EditGeneralInfo = ({ detailData, editFormData, handleOnChange }) => {
  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  const handleDateChange = (v) => {
    const isoDate = moment(v).toISOString();
    handleOnChange(
      "generalInfo.scheduledFor",
      isoDate
    );
  };

  return (
    <div>
      <DynamicSelectField
        label={"Asset ID"}
        placeholder={""}
        options={jobStatus}
        value={editFormData?.generalInfo?.equipment}
        // onChange={(val) => handleOnChange("generalInfo.jobStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        wrapperStyle={{ marginTop: "10px" }}
        isDisabled={true}
      />

      <TextInputField
        label="Job Ref No"
        placeholder=""
        style={{
          background: "#E1E4EA",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          width: "335px",
          height: "40px",
          font: "normal normal 600 14px/19px Open Sans",
          color: "#5F6672",
          opacity: 1,
        }}
        labelStyle={labelStyle}
        isDisabled={true}
        value={editFormData?.generalInfo?.refNo}
      // onChange={(v) => handleOnChange("refNo", v)}
      />
      <TextInputField
        label="Issue/Job Type"
        placeholder=""
        style={{
          background: "#E1E4EA",
          border: "1px solid #E1E8F4",
          borderRadius: "4px",
          width: "335px",
          height: "40px",
          font: "normal normal 600 14px/19px Open Sans",
          color: "#5F6672",
          opacity: 1,
        }}
        labelStyle={labelStyle}
        isDisabled={true}
        value={editFormData?.generalInfo?.issueType}
        dotIcon={true}
      // onChange={(v) => handleOnChange("issueType", v)}
      />

      <DynamicSelectField
        label={"JobStatus"}
        placeholder={"job status"}
        options={jobStatus?.filter((js) => {
          if (detailData?.generalInfo?.jobStatus === JOB_STATUS.PENDING) {
            if (js?.value === JOB_STATUS.PENDING)
              return true;
            return false;
          }
          if ([JOB_STATUS.PAUSED, JOB_STATUS.INPROGRESS, JOB_STATUS.ENDED].includes(detailData?.generalInfo?.jobStatus)) {
            if (js?.value === JOB_STATUS.INPROGRESS || js?.value === JOB_STATUS.PAUSED || js?.value === JOB_STATUS.ENDED)
              return true;
            return false;
          }
          return false;
        })?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={editFormData?.generalInfo?.jobStatus}
        onChange={(val) => handleOnChange("generalInfo.jobStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        wrapperStyle={{ marginTop: "10px" }}
      />

      <RadioButton
        radioLabel={"Asset Status"}
        options={assetStatus}
        value={editFormData?.generalInfo?.assetStatus}
        onChange={(v) => {
          handleOnChange("generalInfo.assetStatus", v);
        }}
        isDisabled={false}
        id="assetStatus_general"
        radioLabelStyle={labelStyle}
        className={"px-5 py-4"}
        optionMainContainer={{
          marginLeft: "102px",
        }}
        optionStyle={valueStyle}
      />

      {(detailData?.generalInfo?.issueType !== ISSUE_TYPE.BREAKDOWN) && (
        <DateAndTimeSelect
          // required
          label="Scheduled For"
          value={
            editFormData?.generalInfo?.scheduledFor
              ? new Date(editFormData?.generalInfo?.scheduledFor)
              : null
          }
          onChange={handleDateChange}
          // error={showError && !formData?.startTime}
          // errorMessage="Please select shift start time"
          id="scheduledFor"
          wrapperStyle={{ marginTop: "1.5rem" }}
          labelStyle={labelStyle}
          isDisabled={!(detailData?.generalInfo?.issueType ===
            ISSUE_TYPE.PLANNED_MAINTENANCE || detailData?.generalInfo?.issueType ===
            ISSUE_TYPE.CAT_C_FAILURE)}
        />
      )}
    </div>
  );
};

export default EditGeneralInfo;
