import { IoCloseSharp } from "react-icons/io5";
import styles from "../UpdateAssetStatus/UpdateAssetStatus.module.css";
import RadioButton from "pages/Fleet/Component/RadioButton/RadioButton";
import { assetStatus } from "pages/Fleet/utils/Constant";
import { UpdateAssetStatusData } from "pages/Fleet/utils/ApiService";
import { showToast } from "pages/Fleet/utils/Helper";
import { useState } from "react";
import { useFleetContext } from "pages/Fleet/Context/Context";

const UpdateAssetStatus = ({ handleClose,
  assetData }) => {

  const {
    setRefreshPage,
    setOpenFlyer
  } = useFleetContext();

  const [selectedStatus, setSelectedStatus] = useState(assetData?.assetStatus);

  const handleUpdateStatus = async () => {
    const reqBody = {
      "assetStatus": selectedStatus
    }
    const updateResponse = await UpdateAssetStatusData(assetData?.assetId, reqBody);
    if (updateResponse) {
      showToast(true, "Asset status updated successfully");
      setRefreshPage()
      setOpenFlyer()
      handleClose()

    } else {
      showToast(false, "An error occurred while updating asset status");
    }
  }

  return (
    <>
      <div
        id="summary-report"
        className={`modal fade show ${styles.modalTop}`}
        tabIndex="-1"
        role="dialog"
      >
        <div
          id="modalDialogTop"
          className={`modal-dialog modal-dialog-centered ${styles.modalTopArea}`}
          role="document"
        >
          <div className={`modal-content px-5 ${styles.modalContent}`}>
            <div
              className={`modal-header d-flex align-items-center  justify-content-between ${styles.modalHeader}`}
            >
              <div className={styles.title}>Update Asset Status</div>
              <div onClick={handleClose} className={styles.close}>
                <IoCloseSharp color="black" fontSize={17.5} />
              </div>
            </div>
            <div className={`modal-body p-0 pt-3 pb-5 ${styles.modalBody}`}>
              <div className="pt-3" style={{ marginLeft: "-17px" }}>
                <RadioButton
                  radioLabel={""}
                  options={assetStatus}
                  value={selectedStatus}
                  onChange={(v) => {
                    setSelectedStatus(v);
                  }}
                  isDisabled={false}
                  id="updateAsset"
                  optionStyle={{
                    font: "normal normal 600 14px/19px Open Sans",
                    color: "#1A1A1A",
                  }}
                />
              </div>
              <div
                className="d-flex justify-content-end align-items-center"
                style={{ marginTop: "65px" }}
              >
                <div
                  className={styles.saveChanges}
                  style={{
                    background:
                      assetData?.assetStatus !== selectedStatus
                        ? "#186FD9"
                        : "#E1E4EA",
                    color:
                      assetData?.assetStatus !== selectedStatus
                        ? "#FFFFFF"
                        : "#8190AD",
                    cursor: assetData?.assetStatus !== selectedStatus
                      ? "pointer" : "not-allowed",
                    pointerEvents: assetData?.assetStatus !== selectedStatus
                      ? "auto" : "none"
                  }}
                  onClick={handleUpdateStatus}
                >
                  Save Changes
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateAssetStatus;
