import { useState } from "react";
import NavBar from "./Component/NavBar/NavBar";
import AuthLayout from "../../components/AuthLayout";
import Requested from "./SubComponent/Requested/Requested";
import Approved from "./SubComponent/Approved/Approved";
import Archived from "./SubComponent/Archived/Archived";

const ShiftMaintenance = () => {
  const [selectedTab, setSelectedTab] = useState("requested");

  return (
    <AuthLayout pageName="Workshop Jobs">
      <div
        className="right_sidebar_body d-flex flex-column"
        id="main"
        style={{ backgroundColor: "#FFFFFF" }}
      >
        <div className={`mb-0 px-5  mt-5 border-bottom`}>
          <NavBar onTabChange={(type) => setSelectedTab(type)} />
        </div>

        {selectedTab === "requested" ? (
          <Requested />
        ) : selectedTab === "approved" ? (
          <Approved />
        ) : (
          <Archived />
        )}
      </div>
    </AuthLayout>
  );
};

export default ShiftMaintenance;
