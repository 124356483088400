import DynamicTable from "pages/ShiftNotes/Component/Table/Table";
import React, { useState } from "react";
import ConfirmAlert from "pages/ShiftNotes/Component/ConfirmAlert/ConfirmAlert";
import { useShiftNotesContext } from "pages/ShiftNotes/Context/context";
import EditShiftNotes from "../EditShiftNotes/EditShiftNotes";
import ViewNotes from "../ViewNotes/ViewNotes";

import moment from "moment-timezone";
import { trimLongString } from "pages/ShiftNotes/utils/helpers/helper";
import History from "pages/ShiftNotes/SubComponent/History/History";

const confirmMessage = (note) => <div
  className="d-flex flex-column"
  style={{ lineHeight: "2rem" }}
>
  <span>
    Are you sure you want to delete the following note ?
  </span>
  <span style={{ font: "normal normal bold 14px/20px Open Sans", color: "#000000" }}>{note.note}</span>
</div>


function ShiftNotesTable() {
  const {
    loading,
    openFlyer,
    setOpenFlyer,
    filteredResults,
    shiftNotesData,
    handleDeleteShiftNotes
  } = useShiftNotesContext()
  const [historyData, setHistoryData] = useState([]);
  const [editShiftNotesData, setEditShiftNotesData] = useState({})
  const [viewData, setViewData] = useState({})
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: "",
    message: "",
    nid: ""
  });

  

  const handleOpenHistory = (data) => {
    setHistoryData(data);
    setOpenFlyer("NOTES_HISTORY");
  };

  const handleOpenEdit = (data) => {
    setEditShiftNotesData(data);
    setOpenFlyer("NOTES_EDIT");
  };

  const handleViewNotes = (data) => {
    setViewData(data);
    setOpenFlyer("NOTES_VIEW");
  }



  const headers = [
    {
      label: "Added On",
      key: "addedOn",
      style: { width: "15%", padding: "0 0 14px 12px ", font: "normal normal bold 12px/18px Open Sans", color: '#1A1A1A', borderBottom: "1px solid #E1E8F4" },
    },
    {
      label: "Note Type",
      key: "noteType",
      style: { width: "10%", padding: "0 0 14px 12px ", font: "normal normal bold 12px/18px Open Sans", color: '#1A1A1A', borderBottom: "1px solid #E1E8F4" },
    },
    {
      label: "Asset(s)",
      key: "asset",
      style: { width: "17.5%", padding: "0 0 14px 12px ", font: "normal normal bold 12px/18px Open Sans", color: '#1A1A1A', borderBottom: "1px solid #E1E8F4" },
    },
    {
      label: "Notes",
      key: "note",
      style: { width: "27.5%", padding: "0 0 14px 12px ", font: "normal normal bold 12px/18px Open Sans", color: '#1A1A1A', borderBottom: "1px solid #E1E8F4" },
    },
    {
      label: "Added By",
      key: "addedBy",
      style: { width: "25%", padding: "0 0 14px 12px ", font: "normal normal bold 12px/18px Open Sans", color: '#1A1A1A', borderBottom: "1px solid #E1E8F4" },
    },
    {
      label: "Actions",
      key: "actionMenu",
      style: { width: "5%", padding: "0 0 14px 4px ", font: "normal normal bold 12px/18px Open Sans", color: '#1A1A1A', borderBottom: "1px solid #E1E8F4" },
    },
  ];

  const processedData = [{
    groupName: "Notes",
    groupData: [
      {
        rowHeader: "Notes",
        rows: filteredResults?.notes?.map((note, index) => ({
          value: {
            shiftNoteId: note.shiftNoteId,
            addedOn: moment(note.addedOn).tz("Pacific/Auckland").format("hh:mm A, DD/MM/YYYY"),
            noteType: note.noteType,
            addedBy: note.addedBy,
            note: trimLongString(note?.note),
            asset: trimLongString(note?.assets?.join(", ")) || "N/A"
          },
          onClick: () => handleViewNotes(note),
          actionMenu: {
            options: [
              {
                name: "View",
                icon: "",
                onClick: () => handleViewNotes(note),

              },
              {
                name: "Edit",
                icon: "",
                onClick: () => handleOpenEdit(note),
              },
              {
                name: "Delete",
                icon: "",
                onClick: () =>
                  setConfirmModal({
                    show: true,
                    title: "Delete Note",
                    message: confirmMessage(note),
                    wantToDelete: true,
                    nid: note.shiftNoteId,
                  }),
              },
              {
                name: "Edit History",
                icon: "",
                onClick: () => handleOpenHistory(note.history),
              },
            ],
          },
        })),
      },
    ],
  }]


  return (
    <>
      {openFlyer === "NOTES_VIEW" && <ViewNotes data={viewData} />}
      {openFlyer === "NOTES_EDIT" && <EditShiftNotes data={editShiftNotesData} />}

      {openFlyer === "NOTES_HISTORY" && (
        <History
          handleOnClose={() => setOpenFlyer("")}
          historyData={historyData}
        />
      )}

      {confirmModal.show && (
        <ConfirmAlert
          title={confirmModal.title}
          message={confirmModal.message}
         
          onClose={() => {
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              nid: '',
            });
          }}
          onConfirm={() => {
            handleDeleteShiftNotes(confirmModal.nid);
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              nid: ''
            });
          }}
        />
      )}

      <DynamicTable
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={false}
        rowHeader={false}
        footer={`Displaying ${filteredResults?.notes ? filteredResults.notes.length : 0}/${shiftNotesData?.notes ? shiftNotesData.notes.length : 0 } Shift Notes`}
      />
    </>
  );
}

export default ShiftNotesTable;
