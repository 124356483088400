import styles from "./NotificationTab.module.css";
import flag from "../../../../assets/svgs/notifications/flag.svg";
import hollowFlag from "../../../../assets/svgs/notifications/hollowFlag.svg";
import archiveIcon from "../../../../assets/svgs/notifications/archiveIcon.svg";
import deleteIcon from "../../../../assets/svgs/notifications/deleteIcon.svg";
import ActionMenu from "pages/Notifications/Component/ActionMenu/ActionMenu";
import hoverAction from "../../../../assets/svgs/notifications/hoverAction.svg";
import { useState } from "react";
import { formateDate, getTimeFromDate } from "pages/Notifications/utils/Helper";
import { GoDotFill } from "react-icons/go";
import { Tab } from "pages/Notifications/utils/Constant";
import { Riple } from "react-loading-indicators";
import {
  changeFlatStatus,
  deleteNotification,
  MarkNotificationAsRead,
  markNotificationAsUnread,
  MoveNotificationToArchive,
  MoveNotificationToInbox,
  remindLater,
} from "pages/Notifications/utils/ApiServices";
import useNotificationContext from "hooks/useNotificationContext";

const NotificationTab = ({
  data,
  selectedTab,
  loading,
  setRefreshPage,
  searchQuery,
}) => {
  const { setUnreadNotifications } = useNotificationContext();
  const [hoveredAction, setHoveredAction] = useState(null);

  const handleMouseEnter = (id) => {
    setHoveredAction(id);
  };

  const handleMouseLeave = () => {
    setHoveredAction(null);
  };

  const titleColor = (isSeen, type) => {
    if (!isSeen) {
      if (type === "alert") {
        return "#9B0202";
      } else if (type === "info") {
        return "#034AAC";
      } else {
        return "#1A1A1A";
      }
    }
  };

  const optionMenu = [
    hoveredAction &&
    !data.find((notification) => notification._id === hoveredAction)?.isSeen
      ? {
          name: "Mark as Read",
          onClick: async () => {
            try {
              await MarkNotificationAsRead(hoveredAction);
              setUnreadNotifications((prev) => prev - 1);
              setRefreshPage((prev) => !prev);
            } catch (e) {
              console.log(e);
            }
          },
        }
      : {
          name: "Mark as Unread",
          onClick: async () => {
            try {
              await markNotificationAsUnread(hoveredAction);
              setUnreadNotifications((prev) => prev + 1);
              setRefreshPage((prev) => !prev);
            } catch (e) {
              console.log(e);
            }
          },
        },
    selectedTab === Tab.INBOX && {
      name: "Remind Later (15 min)",
      onClick: async () => {
        try {
          await remindLater(hoveredAction, "15_MINUTES");
        } catch (e) {
          console.log(e);
        }
      },
    },
    selectedTab === Tab.INBOX && {
      name: "Remind Later (1 hour)",
      onClick: async () => {
        try {
          await remindLater(hoveredAction, "1_HOUR");
        } catch (e) {
          console.log(e);
        }
      },
    },
    selectedTab === Tab.ARCHIVE && {
      name: "Move to Inbox",
      onClick: async () => {
        try {
          await MoveNotificationToInbox(hoveredAction);
          setRefreshPage((prev) => !prev);
        } catch (e) {
          console.log(e);
        }
      },
    },
  ].filter(Boolean);

  return (
    <>
      <div className={`d-flex flex-column ${styles.boxContainer}`}>
        {loading ? (
          <div
            style={{
              height: "100%",
              width: "100%",
              position: "fixed",
              left: 0,
              opacity: 0.25,
            }}
            className="d-flex align-items-center justify-content-center"
          >
            <Riple color="#317bcc" size="medium" text="" textColor="" />
          </div>
        ) : (
          data.map(
            (notification) =>
              notification.title
                .toLowerCase()
                .includes(searchQuery.toLowerCase()) && (
                <>
                  <div
                    key={notification._id}
                    className={`py-4 ${styles.notificationItem}`}
                    onMouseEnter={() => handleMouseEnter(notification._id)}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="d-flex align-items-center justify-content-between w-100">
                      {!notification?.isSeen && (
                        <div className="pl-3 pb-3">
                          <GoDotFill
                            style={{
                              color: titleColor(
                                notification?.isSeen,
                                notification?.type
                              ),
                              marginBottom: "5px",
                              width: "13px",
                            }}
                          />
                        </div>
                      )}
                      <div
                        className={` ${
                          !notification?.isSeen ? "px-2" : "px-5"
                        } pb-3 ${styles.notificationTitle}`}
                        style={{
                          width: "75%",
                        }}
                      >
                        <span
                          style={{
                            color: titleColor(
                              notification?.isSeen,
                              notification?.type
                            ),
                          }}
                        >
                          {notification.title}
                        </span>
                      </div>

                      <div
                        className=" px-5  d-flex align-items-center justify-content-between"
                        style={{ gap: "15px" }}
                      >
                        <div>
                          <img
                            src={notification?.isFlagged ? flag : hollowFlag}
                            alt="hollowFlag"
                            style={{
                              width: "25px",
                              height: "25px",
                              cursor: "pointer",
                              visibility:
                                hoveredAction === notification._id || notification?.isFlagged
                                  ? "visible"
                                  : "hidden",
                              opacity:
                                hoveredAction === notification._id || notification?.isFlagged ? 1 : 0,
                              transition: "opacity 0.3s ease",
                            }}
                            onClick={async () => {
                              try {
                                await changeFlatStatus(
                                  notification._id,
                                  notification?.isFlagged ? "UNFLAG" : "FLAG"
                                );
                                setRefreshPage((prev) => !prev);
                              } catch (e) {
                                console.log(e);
                              }
                            }}
                          />
                        </div>

                        <div
                          id="action_icons"
                          className="d-flex align-items-center"
                          style={{
                            gap: "15px",
                            visibility:
                              hoveredAction === notification._id
                                ? "visible"
                                : "hidden",
                            opacity: hoveredAction === notification._id ? 1 : 0,
                            transition: "opacity 0.3s ease",
                          }}
                        >
                          {selectedTab === Tab.INBOX && (
                            <div>
                              <img
                                src={archiveIcon}
                                alt="archiveIcon"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  try {
                                    await MoveNotificationToArchive(
                                      notification._id
                                    );
                                    setRefreshPage((prev) => !prev);
                                  } catch (e) {
                                    console.log(e);
                                  }
                                }}
                              />
                            </div>
                          )}

                          {selectedTab === Tab.ARCHIVE && (
                            <div>
                              <img
                                src={deleteIcon}
                                alt="deleteIcon"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  cursor: "pointer",
                                }}
                                onClick={async () => {
                                  try {
                                    await deleteNotification(notification._id);
                                    setRefreshPage((prev) => !prev);
                                  } catch (e) {
                                    console.log(e);
                                  }
                                }}
                              />
                            </div>
                          )}

                          <div>
                            <ActionMenu
                              options={optionMenu}
                              icon={
                                <img
                                  src={hoverAction}
                                  alt="archiveIcon"
                                  style={{ width: "25px", height: "23px" }}
                                />
                              }
                              optionsContainerStyle={{
                                font: "normal normal normal 12px/18px Open Sans",
                                color: "#1A1A1A",
                                width: "170px",
                                right: "30%",
                                top: "28px",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className=" px-5 d-flex align-items-end justify-content-between w-100">
                      <div
                        className={`d-flex flex-column ${styles.notification}`}
                        style={{ width: "80%" }}
                      >
                        Description - {notification.description}
                      </div>
                      <div
                        className={`d-flex flex-column ${styles.notificationTime}`}
                        style={{ width: "12%" }}
                      >
                        <span>{getTimeFromDate(notification.createdAt)}</span>
                        <span>{formateDate(notification.createdAt)}</span>
                      </div>
                    </div>
                  </div>
                  <div
                    className=""
                    style={{
                      border: "1px solid hsla(218, 46%, 92%, 1)",
                      opacity: "1",
                    }}
                  />
                </>
              )
          )
        )}
      </div>
    </>
  );
};

export default NotificationTab;
