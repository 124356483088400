import React, { useState } from "react";
import { useDumpCycleTrackerContext } from "../context/context";
import Modal from "../Modal/Modal";
import {
  formatDate,
  getFilteredLoads,
  getHourMinuteDifference,
  getIntervals,
} from "./helpers";

const Table = () => {
  const { mainContainer, data, getTruckData, search } =
    useDumpCycleTrackerContext();
  const [selectedUnit, setSelectedUnit] = useState(null);

  const truck = getTruckData();

  return (
    <>
      <main
        ref={mainContainer}
        style={{
          position: "relative",
        }}
      >
        <table
          style={{ backgroundColor: "#fff" }}
          className="shift-history-table"
        >
          <thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "7.5%" }}>Load</th>
              <th style={{ width: "18%" }}>Cycle Duration</th>
              <th style={{ width: "8.5%" }}>Source</th>
              <th style={{ width: "7%" }}>Excavator</th>
              <th style={{ width: "8.5%" }}>Material</th>
              <th style={{ width: "9%" }}>Dump</th>
              <th style={{ width: "10%" }}>Operator</th>
            </tr>
          </thead>
        </table>

        {selectedUnit && (
          <Modal
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
          />
        )}
      </main>
      <main
        ref={mainContainer}
        style={{
          position: "relative",
          maxHeight: "calc(100% - 17.5rem)",
          overflowY: "scroll",
        }}
      >
        <table
          style={{ backgroundColor: "#fff" }}
          className="shift-history-table"
        >
          <thead>
            <tr style={{ fontWeight: "600" }}>
              <th style={{ width: "7.5%" }}></th>
              <th style={{ width: "18%" }}> </th>
              <th style={{ width: "8.5%" }}></th>
              <th style={{ width: "7%" }}></th>
              <th style={{ width: "8.5%" }}></th>
              <th style={{ width: "9%" }}></th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          <tbody>
            {getIntervals(data, truck).map((interval, i) => (
              <>
                <tr style={{ borderBottom: "none" }} key={`first_${i}`}>
                  <th colSpan={8} style={{ padding: "1.5rem 0 1.2rem 4rem" }}>
                    <span
                      className="px-4 py-2"
                      style={{
                        background: "#707070",
                        width: "max-content",
                        padding: "2px 3px",
                        borderRadius: "1.25rem",
                        backgroundColor: "#00214d",
                        color: "white",
                        font: "normal normal bold 12px/17px Open Sans",
                        fontSize: "1rem",
                      }}
                    >
                      {interval.formattedInterval}
                    </span>
                    <span
                      className="px-4 py-2"
                      style={{
                        background: "#707070",
                        width: "max-content",
                        padding: "2px 3px",
                        borderRadius: "1.25rem",
                        marginLeft: "15px",
                        backgroundColor:
                          getFilteredLoads(interval.loads) >=
                            interval.target || !interval.target
                            ? "#ADE5C0"
                            : "#F2B5B5",
                        color: "black",
                        font: "normal normal bold 12px/17px Open Sans",
                        fontSize: "1rem",
                      }}
                    >
                      {getFilteredLoads(interval.loads)} /{" "}
                      {interval.target ?? "-"}
                    </span>
                  </th>
                </tr>

                {interval.loads
                  ?.filter(
                    (load) =>
                      load.operatorName
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      load.sourceAtStart
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      load.dumpArea
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      load.material
                        ?.toLowerCase()
                        .includes(search.toLowerCase()) ||
                      load.truckName
                        ?.toLowerCase()
                        .includes(search.toLowerCase())
                  )
                  .map((load, j) => (
                    <tr
                      hover={1}
                      key={`${i}_${j}`}
                      onClick={() => setSelectedUnit(load)}
                      style={{ fontWeight: "400", fontSize: "1.1rem" }}
                    >
                      <th>Load {load.id}</th>
                      <td>
                        {formatDate(load.startTime)} -{" "}
                        {formatDate(load.endTime, "00:00 AM")} (
                        {load.startTime && load.endTime
                          ? getHourMinuteDifference(
                            new Date(load.startTime),
                            new Date(load.endTime)
                          )
                          : "00:00 hrs"}
                        )
                      </td>
                      <td>
                        {load.sourceAtStart
                          ? `Area ${load.sourceAtStart}`
                          : "-"}
                      </td>
                      <td>{load.excavatorName || "-"}</td>
                      <td>{load.material || "-"}</td>
                      <td>{load.dumpArea || "-"}</td>
                      <td>{load.operatorName || "-"}</td>
                    </tr>
                  ))}
                {interval.loads?.filter(
                  (load) =>
                    load.operatorName
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    load.sourceAtStart
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    load.dumpArea
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    load.material
                      ?.toLowerCase()
                      .includes(search.toLowerCase()) ||
                    load.truckName?.toLowerCase().includes(search.toLowerCase())
                ).length === 0 ? (
                  <tr
                    hover={1}
                    key={`last_${i}`}
                    style={{ fontWeight: "400", fontSize: "1.1rem" }}
                  >
                    <td></td>
                  </tr>
                ) : (
                  <tr></tr>
                )}
              </>
            ))}
          </tbody>
        </table>

        {selectedUnit && (
          <Modal
            selectedUnit={selectedUnit}
            setSelectedUnit={setSelectedUnit}
          />
        )}
      </main>
    </>
  );
};

export default Table;
