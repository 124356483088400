import { dummyReportData } from "../../../utils/ReportDummyData";
import DynamicTable from "pages/ShiftNotes/Component/Table/Table";
import moment from "moment";
import ViewShiftReport from "../ViewShiftReport/ViewShiftReport";
import { useEffect, useRef, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { LuHistory } from "react-icons/lu";
import { LiaDownloadSolid } from "react-icons/lia";
import { RiDeleteBin6Line } from "react-icons/ri";
import ConfirmAlert from "pages/ShiftNotes/Component/ConfirmAlert/ConfirmAlert";
import History from "../History/History";
import { useShiftReportContext } from "../Context/Context";
import { showToast } from "pages/DelayAndActivity/utils/helper";
import { deleteShiftReport, generateDailyReportPdf } from "pages/ShiftNotes/utils/apiService";
import AddEditShiftReport from "../AddEditShiftReport/AddEditShiftReport";
import { CSVLink } from "react-csv";

const ShiftReportTable = () => {
  const {
    loading,
    filteredResults,
    shiftReportData,
    setRefreshPage,
    refreshPage,
    setLoading,
    selectedDate,
    shiftType,
  } = useShiftReportContext();

  const [viewShiftReport, setViewShiftReport] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [viewReportData, setViewReportData] = useState({});
  const [shiftReportEditData, setShiftReportEditData] = useState({});
  const [showShiftReportEditModal, setShowShiftReportEditModal] =
    useState(false);
  // const csvLinkRef = useRef();
  // const [csvData, setCSVData] = useState(null);
  // const [csvFilename, setCSVFilename] = useState(null);

  const headers = [
    {
      label: "Shift Ref No.",
      key: "refNumber",
      style: {
        width: "10%",
        padding: "0 0 14px 12px ",
        font: "normal normal 600 12px/18px Open Sans",
        color: "#707070",
        borderBottom: "1px solid #E1E8F4",
      },
    },
    {
      label: "Added On",
      key: "addedOn",
      style: {
        width: "20%",
        padding: "0 0 14px 12px ",
        font: "normal normal 600 12px/18px Open Sans",
        color: "#707070",
        borderBottom: "1px solid #E1E8F4",
      },
    },
    {
      label: "Shift Type",
      key: "shiftType",
      style: {
        width: "50%",
        padding: "0 0 14px 12px ",
        font: "normal normal 600 12px/18px Open Sans",
        color: "#707070",
        borderBottom: "1px solid #E1E8F4",
      },
    },
    {
      label: "Created by",
      key: "createdBy",
      style: {
        width: "15%",
        padding: "0 0 14px 12px ",
        font: "normal normal 600 12px/18px Open Sans",
        color: "#707070",
        borderBottom: "1px solid #E1E8F4",
      },
    },
    {
      label: "Actions",
      key: "actionMenu",
      style: {
        width: "5%",
        padding: "0 0 14px 4px ",
        font: "normal normal 600 12px/18px Open Sans",
        color: "#707070",
        borderBottom: "1px solid #E1E8F4",
      },
    },
  ];
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: "",
    message: "",
    sid: "",
  });

  const confirmMessage = (note) => (
    <div className="d-flex flex-column" style={{ lineHeight: "2rem" }}>
      <span
        style={{
          font: "normal normal normal 14px/20px Open Sans",
          color: "#000000",
        }}
      >
        Are you sure you want to delete this report? This cannot be undone.
      </span>
    </div>
  );
  const handleOpenHistory = (data) => {
    const historyData = data?.map((entry) => ({
      updatedAt: entry.updatedAt,
      updatedBy: entry.updatedBy,
      changes: entry.changes.map((change) => ({
        key: `${change.key}:`,
        from: change.from,
        to: change.to,
      })),
    }));

    setHistoryData(historyData);
    setShowHistory(true);
  };

  const handleCloseViewModal = () => setViewShiftReport(false);
  const handleShowViewModal = (dailyReports) => {
    setViewShiftReport(true);
    setViewReportData(dailyReports);
  };

  const handleOpenEdit = (data) => {
    setShiftReportEditData(data);
    setShowShiftReportEditModal(true);
  };

  const handleCloseEditModal = () => setShowShiftReportEditModal(false);

  const handleDeleteShiftReport = async (dailyReportId) => {
    try {
      const deleteReportResponse = await deleteShiftReport(dailyReportId);
      if (deleteReportResponse) {
        showToast(true, "Daily shift report deleted successfully!");
        setConfirmModal({
          show: false,
          title: "",
          message: "",
          sid: "",
        });
        setRefreshPage(!refreshPage);
      } else {
        throw new Error("Failed to delete shift daily report");
      }
    } catch (error) {
      showToast(
        false,
        "An error occurred while deleting shift daily report. Please try again."
      );
    }
  };




  // const handleDownloadCSV = (report) => {
  //   const data = getSingleCSVData(report);
  //   const filename = `Shift_Report_${report?.reportNo}`;
  //   setCSVData(data);
  //   setCSVFilename(filename);
  // };

  // useEffect(() => {
  //   if (csvData && csvFilename) {
  //     csvLinkRef.current.link.click();
  //   }
  // }, [csvData, csvFilename]);

  // const getSingleCSVData = (report) => {
  //   return [{
  //     shiftRefNumber: report?.reportNo,
  //     addedOn: moment(report.date)
  //       .tz("Pacific/Auckland")
  //       .format("hh:mm A, DD/MM/YYYY"),
  //     shiftType: report.shiftType === "day" ? "Day" : "Night",
  //     createdBy: report.reportedBy,
  //   }];
  // };


  const generateShiftDailyReportPdf = async (report) => {
    setLoading(true);
    try {
      const pdfResponse = await generateDailyReportPdf(
        selectedDate,
        shiftType,
        report?.id,
        report?.reportNo
      );
      if (pdfResponse) {
        showToast(true, "Pdf generated successfully..!");
      }
    } catch (error) {
      showToast(false, error.message);
    }
    setLoading(false);
  }


  const processedData = [
    {
      groupName: "Shift Report",
      groupData: [
        {
          rowHeader: "Shift Report",
          rows: filteredResults?.map((report, index) => ({
            value: {
              id: report?.id,
              refNumber: report?.reportNo, //report.refNumber,
              addedOn: moment(report.createdAt)
                .tz("Pacific/Auckland")
                .format("hh:mm A, DD/MM/YYYY"),
              shiftType: report.shiftType,
              createdBy: report.reportedBy,
            },
            onClick: () => handleShowViewModal(report),
            actionMenu: {
              options: [
                {
                  name: "Edit",
                  icon: <FiEdit2 color={"#386DFF"} size={16} />,
                  onClick: () => handleOpenEdit(report),
                },
                {
                  name: "View Edit History",
                  icon: <LuHistory color={"#386DFF"} size={16} />,
                  onClick: () => handleOpenHistory(report?.history),
                },
                {
                  name: "Download",

                  icon: <LiaDownloadSolid color={"#386DFF"} size={18} />,
                  onClick: () => generateShiftDailyReportPdf(report),
                },
                {
                  name: "Delete",
                  icon: <RiDeleteBin6Line color={"#386DFF"} size={16} />,
                  onClick: () =>
                    setConfirmModal({
                      show: true,
                      title: "Delete Daily Shift Report",
                      message: confirmMessage(report),
                      wantToDelete: true,
                      sid: report.id,
                    }),
                },
              ],
            },
          })),
        },
      ],
    },
  ];

  return (
    <>
      {viewShiftReport && (
        <ViewShiftReport
          reportData={viewReportData}
          handleCloseViewModal={handleCloseViewModal}
        />
      )}

      {confirmModal.show && (
        <ConfirmAlert
          title={confirmModal.title}
          message={confirmModal.message}
          cancelButton={true}
          deleteLabel="Delete"
          onClose={() => {
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              sid: "",
            });
          }}
          onConfirm={() => {
            handleDeleteShiftReport(confirmModal.sid);
            setConfirmModal({
              show: false,
              title: "",
              message: "",
              sid: "",
            });
          }}
        />
      )}

      {showHistory && (
        <History
          handleOnClose={() => setShowHistory(false)}
          historyData={historyData}
        />
      )}

      <DynamicTable
        id="shift-daily-report"
        loading={loading}
        data={processedData}
        headers={headers}
        groupBadge={false}
        rowHeader={false}
        footer={""}
        actionMenuContainerStyle={{
          borderRadius: "15px",
          width: "159px",
          height: "135px",
          paddingTop: "6px",
          top: "25px",
        }}
        actionMenuStyle={{
          font: "normal normal normal 11px/15px Open Sans",
          color: "#313131",
        }}
      />

      {showShiftReportEditModal && (
        <AddEditShiftReport
          handleCloseModal={handleCloseEditModal}
          shiftReportData={shiftReportEditData}
          isModalEdit={true}
        />
      )}

      {/* {csvData && csvFilename && (
        <CSVLink data={csvData} filename={csvFilename} ref={csvLinkRef} />
      )} */}
    </>
  );
};

export default ShiftReportTable;
