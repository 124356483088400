import LineChart from "pages/KPIDashboard/Component/LineChart/LineChart";
import Style from "../Delays/Delays.module.css";
import ToggleButtons from "pages/KPIDashboard/Component/ToggleButton/ToggleButton";
import SelectField from "pages/KPIDashboard/Component/GeneralFields/SelectField/SelectField";
import Badge from "pages/KPIDashboard/Component/Badge/Badge";
import CircularPieChart from "pages/KPIDashboard/Component/CircularPieChart/CircularPieChart";
import DelaysSectionViewDetails from "./DelaysSectionViewDetails/DelaysSectionViewDetails";
import { useEffect, useRef, useState } from "react";
import DelayTypes from "./DelayTypes/DelayTypes";
import {
  convertSecondsToTime,
  processHourlyGraphCategories,
  processMonthlyGraphCategories,
  showToast,
} from "pages/KPIDashboard/Utils/helper";
import {
  DELAY_TYPE_COLOR_GRADIENT,
  TYPES,
  SHIFT_TYPE,
} from "pages/KPIDashboard/Utils/constant";
import { useKPIDashboardContext } from "pages/KPIDashboard/Context/Context";
import { DailyDelays, DailyMonthlyDelays } from "pages/KPIDashboard/DummyData";
import moment from "moment";
import DelaysTypeSection from "./DelayTypeSection/DelaysDailySection";
import DelayIcon from "../../../../assets/svgs/kpi-dashboard/delay.svg";
import { fetchDashboardDelays } from "pages/KPIDashboard/Utils/apiService";
import axios from "axios";
import { Riple } from "react-loading-indicators";
const Delays = () => {
  const { selectedDate } = useKPIDashboardContext();
  const timeoutRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const [coordinates, setCoordinates] = useState({});
  const [showDetails, setShowDetails] = useState(false);
  const [shiftType, setShiftType] = useState(SHIFT_TYPE.DAY);
  const [type, setType] = useState(TYPES.DAILY);
  const [equipmentType, setEquipmentType] = useState(1);
  const [cardContent, setCardContent] = useState({});
  const [delayData, setDelayData] = useState({});
  const [delayGraphData, setDelayGraphData] = useState({});
  const [xAxisCategories, setXAxisCategories] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [loading, setLoading] = useState(false);

  const TYPES_DATA = [
    { name: "Daily", value: 1 },
    { name: "Monthly", value: 2 },
  ];

  const SHIFT_TYPES_DATA = [
    { name: "Day", value: 1 },
    { name: "Night", value: 2 },
  ];

  const EQUIPMENT_TYPE = [
    { label: "Truck", value: 1 },
    { label: "Excavator", value: 2 },
  ];

  const handleClose = () => {
    setShowDetails(false);
    setIsOpen(false);
  };

  useEffect(() => {
    const source = axios.CancelToken.source();

    // Helper function to fetch delays data
    const fetchData = async (dontLoad = false) => {
      if (!dontLoad) setLoading(true);
      try {
        const delaysData = await fetchDashboardDelays(
          moment(selectedDate).format("YYYY-MM-DD"),
          type,
          shiftType,
          equipmentType,
          source
        );

        if (delaysData) {
          handleDataProcessing(delaysData);
        } else {
          resetDelayData();
        }

        if (!dontLoad) setLoading(false);
        setAutoRefreshTimeout(); // Schedule auto-refresh
      } catch (error) {
        handleError(error, dontLoad);
      }
    };

    // Helper function to process and set data
    const handleDataProcessing = (delaysData) => {
      if (type === TYPES.DAILY) {
        setDelayData(delaysData);
        setDelayGraphData(delaysData?.hourlyData);
        setXAxisCategories(
          processHourlyGraphCategories(delaysData?.hourlyData)
        );

        const resultArray = Object.keys(delaysData?.hourlyData).map((time) => {
          return delaysData?.hourlyData[time]?.totalDelay || 0;
        });

        setSeriesData([resultArray]);
      } else {
        setDelayData(delaysData);
        setDelayGraphData(delaysData?.dailyData);
        setXAxisCategories(
          processMonthlyGraphCategories(delaysData?.dailyData)
        );

        const resultArray = Object.keys(delaysData?.dailyData).map((time) => {
          return delaysData?.dailyData[time]?.totalDelay || 0;
        });

        setSeriesData([resultArray]);
      }
    };

    // Helper function to reset delay data
    const resetDelayData = () => {
      setDelayData({});
      setDelayGraphData([]);
      setXAxisCategories([]);
      setSeriesData([]);
    };

    // Helper function for error handling
    const handleError = (error, dontLoad) => {
      if (!dontLoad) setLoading(false);
      console.error(`Error fetching delays for ${type}:`, error);
      showToast(`An error occurred while getting ${type} delays`);
      setAutoRefreshTimeout(); // Schedule auto-refresh on error
    };

    // Helper function to manage auto-refresh
    const setAutoRefreshTimeout = () => {
      timeoutRef.current = setTimeout(() => {
        fetchData(true); // Fetch without loading indicator
      }, 10000);
    };

    // Initial data fetch
    if (selectedDate && shiftType && type && equipmentType) {
      fetchData();
    } else {
      resetDelayData();
      setLoading(false);
    }

    // Cleanup on unmount
    return () => {
      source.cancel("Operation canceled by the user.");
      clearTimeout(timeoutRef.current);
    };
  }, [selectedDate, type, shiftType, equipmentType]);

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     console.log("outside");

  //     const chartHoverElement = document.getElementById("popup");
  //     if (
  //       chartHoverElement &&
  //       !chartHoverElement.contains(event.target) &&
  //       !document.getElementById("popup").contains(event.target)
  //     ) {
  //       setIsOpen(false);
  //     }
  //   };

  //   document.addEventListener("mousedown", handleClickOutside);
  //   return () => {
  //     document.removeEventListener("mousedown", handleClickOutside);
  //   };
  // }, []);
  const handleCloseCard = () => setIsOpen(false);

  const handlePointClick = (params) => {
    const coordinate = {
      x: params.event.offsetX,
      y: params.event.offsetY,
    };

    if (!delayGraphData || !params || !params.name) {
      return;
    }

    let cardObject = null;

    if (type === TYPES.DAILY) {
      const key = params.name;
      const data = delayGraphData?.[key];
      if (data) {
        cardObject = {
          time: key,
          ...data,
        };
      }
    } else {
      const matchingKey = Object.keys(delayGraphData).find((key) => {
        return moment(key).format("DD") === String(params.name);
      });

      if (matchingKey) {
        const data = delayGraphData?.[matchingKey];
        cardObject = {
          time: matchingKey,
          ...data,
        };
      }
    }

    if (cardObject) {
      setCardContent(cardObject);
    }
    setCoordinates(coordinate);
    setIsOpen(true);
  };

  const totalDelayPieChartData = delayData?.totalByDelayTypes
    ? Object.values(delayData.totalByDelayTypes)
    : [];

  const colorGradient = delayData?.totalByDelayTypes
    ? Object.keys(delayData?.totalByDelayTypes).map((key) => {
        const gradient = DELAY_TYPE_COLOR_GRADIENT.find(
          (item) => item.id === key
        );
        return gradient || { id: key, colors: ["#CCCCCC", "#CCCCCC"] }; // Default color if no match
      })
    : [];

  return (
    <>
      <div
        className={`d-flex align-items-center justify-content-between px-4 py-4 ${Style.container}`}
      >
        <div
          className="d-flex pt-4 flex-column"
          style={{ width: "calc(100% - 280px)" }}
        >
          <div className="d-flex  align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <img
                style={{ height: "1.5rem", width: "1.5rem" }}
                src={DelayIcon}
                alt="sun"
              />
              <div
                className="ml-1"
                style={{
                  font: "normal normal 600 15px/19px Open Sans",
                  color: "#000000",
                  textTransform: "capitalize",
                }}
              >
                Delays
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-between mr-5">
              {type === TYPES.DAILY && (
                <ToggleButtons
                  selectedValue={shiftType === SHIFT_TYPE.DAY ? 1 : 2}
                  onOptionClick={(v) => {
                    setShiftType(v === 1 ? SHIFT_TYPE.DAY : SHIFT_TYPE.NIGHT);
                  }}
                  style={{ height: 24.5 }}
                  options={SHIFT_TYPES_DATA}
                />
              )}

              <SelectField
                noPlaceHolder={true}
                options={EQUIPMENT_TYPE}
                value={equipmentType}
                className="ml-3"
                style={getSelectFieldStyle()}
                onChange={(v) => {
                  setEquipmentType(v);
                }}
                mainContainerStyle={{ marginBottom: "0px" }}
              />
              <ToggleButtons
                selectedValue={type === TYPES.DAILY ? 1 : 2}
                onOptionClick={(v) => {
                  setType(v === 1 ? TYPES.DAILY : TYPES.MONTHLY);
                }}
                style={{ height: 24.5 }}
                options={TYPES_DATA}
                activeBackgroundColor={"#2B2D2F"}
                activeColor={"#FFFFFF"}
              />
            </div>
          </div>
          <div
            className="mt-3 mb-4"
            style={{
              border: "1px solid #ebebeb",
              opacity: "0.4",
              width: "98%",
            }}
          ></div>
          {loading ? (
            <div
              style={{ height: "300px", width: "100%", left: 0, zIndex: 1000 }}
              className="d-flex align-items-center justify-content-center"
            >
              <Riple color="#317bcc" size="medium" text="" textColor="" />
            </div>
          ) : (
            <div>
              <LineChart
                gridOptions={{
                  left: "3%",
                  top: "7.5%",
                }}
                yAxisLabel="Delay Duration"
                formatter={convertSecondsToTime}
                data={seriesData}
                categories={xAxisCategories}
                setCoordinates={setCoordinates}
                setIsOpen={setIsOpen}
                handlePointClick={handlePointClick}
                isChartClickable={true}
              />
            </div>
          )}
        </div>
        {loading ? (
          <div style={{ width: 280 }}></div>
        ) : (
          <div className="d-flex flex-column">
            <div className="d-flex align-items-center justify-content-between">
              <div className={`px-4 py-4 ${Style.legendContainer}`}>
                <div className=" d-flex align-items-center justify-content-between">
                  <div>
                    <span className={Style.legendDelay}>Delays </span>
                    <span className={Style.legendTotalLabel}>
                      (Shift Totals)
                    </span>
                  </div>

                  <div>
                    <Badge
                      value={`${convertSecondsToTime(
                        delayData?.totalDelay
                      )} Total`}
                      containerClass={"py-2"}
                      containerStyle={{
                        font: "normal normal 600 11px/15px Open Sans",
                        color: "#386DFF",
                        background: "#E8F2FD",
                        borderRadius: 15,
                        width: "98px",
                      }}
                    />
                  </div>
                </div>
                <div
                  className="mt-3 mb-2"
                  style={{ border: "1px solid #ebebeb", opacity: "0.4" }}
                ></div>

                <div>
                  <CircularPieChart
                    pieChartData={totalDelayPieChartData}
                    gradients={colorGradient}
                    lineWidth={18}
                    width={100}
                    height={115}
                    containerStyle={{ marginLeft: "70px" }}
                  />
                </div>
                <DelayTypes delayTypesData={delayData?.totalByDelayTypes} />
              </div>
            </div>
          </div>
        )}
      </div>

      {isOpen && (
        <div id="popup">
          <DelaysTypeSection
            coordinates={coordinates}
            setShowDetails={setShowDetails}
            cardContent={cardContent}
            handleCloseCard={handleCloseCard}
            type={type}
          />
        </div>
      )}

      {showDetails && (
        <DelaysSectionViewDetails
          handleClose={handleClose}
          detailData={cardContent}
          selectedDate={
            type === TYPES.DAILY
              ? selectedDate
              : cardContent
              ? cardContent.time
              : selectedDate
          }
          equipmentType={equipmentType}
          type={type}
        />
      )}
    </>
  );
};
const getSelectFieldStyle = (isLoading, options) => ({
  height: "24.5px",
  width: "160px",
  marginTop: 2.5,
  border: "1px solid #F0F0F0",
  borderRadius: "30px",
  paddingLeft: "10px",
  font: "normal normal 600 11px/15px Open Sans",
  color: "#1A1A1A",
  // cursor: !options?.length || isLoading ? "not-allowed" : "pointer",
});

export default Delays;
