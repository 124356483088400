
import AuthLayout from "../../components/AuthLayout";
import { FleetProvider } from "./Context/Context";
import FleetTable from "./SubComponent/FleetTable/FleetTable";
import Header from "./SubComponent/Header/Header";

const Fleet = () => {
  return (
    <AuthLayout pageName="Fleet">
      <div
        className="right_sidebar_body d-flex flex-column"
        id="main"
        style={{ backgroundColor: "#FFFFFF" }}
      >
      <FleetProvider>
      <Header />
      <FleetTable />
      </FleetProvider>
        
      </div>
    </AuthLayout>
  );
};

export default Fleet;
