import DynamicSelectField from "pages/Fleet/Component/GeneralFields/DynamicSelectField/DynamicSelectField";
import {
  assetActivity,
  assetCategory,
  assetStatus,
  preStartResult,
  preStartStatus,
  shiftAssignment,
} from "pages/Fleet/utils/DropDownMasters";
import { MdCircle } from "react-icons/md";

const FilterComponent = ({ filters, onChange }) => {
  const commonFieldStyles = {
    background: "#FFFFFF",
    border: "1px solid #E1E8F4",
    borderRadius: "4px",
    marginTop: "10px",
  };
  const labelStyle = {
    font: "normal normal normal 14px/17px Open Sans",
    color: "#1A1A1A",
  };
  const valueStyle = {
    font: "normal normal 600 14px/19px Open Sans",
    color: "#1A1A1A",
  };

  return (
    <div className="px-5">
      <DynamicSelectField
        label={"Asset Category"}
        placeholder={"Asset Category"}
        options={assetCategory}
        value={filters.assetCategory}
        onChange={(val) => onChange("assetCategory", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
      <DynamicSelectField
        label={"Shift Assignment"}
        placeholder={"Shift Assignment"}
        options={shiftAssignment}
        value={filters?.shiftAssignment}
        onChange={(val) => onChange("shiftAssignment", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />

      <DynamicSelectField
        label={"Pre-Start Status"}
        placeholder={"Pre-Start Status"}
        options={preStartStatus}
        value={filters?.preStartStatus}
        onChange={(val) => onChange("preStartStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
      <DynamicSelectField
        label={"Pre-Start Result"}
        placeholder={"Pre-Start Result"}
        options={preStartResult?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={filters?.issueType}
        onChange={(val) => onChange("issueType", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />

      <DynamicSelectField
        isSearchable={true}
        label={"Asset Status"}
        placeholder={"Asset Status"}
        options={assetStatus?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={filters?.assetStatus}
        onChange={(val) => onChange("assetStatus", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />

      <DynamicSelectField
        isSearchable={true}
        label={"Asset Activity"}
        placeholder={"Asset Activity"}
        options={assetActivity?.map(({ value, label, color }) => ({
          value,
          label,
          icon: (
            <MdCircle style={{ marginLeft: 8 }} size={12.5} color={color} />
          ),
        }))}
        value={filters?.assetActivity}
        onChange={(val) => onChange("assetActivity", val)}
        style={commonFieldStyles}
        labelStyle={labelStyle}
        valueStyle={valueStyle}
        isMulti={true}
        selectAll={true}
      />
    </div>
  );
};

export default FilterComponent;
