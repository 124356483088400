import styles from "../Header/Header.module.css";

// import Filter from "../Filter/Filter";
import filter from "../../../../assets/images/filter.png";
import {
  PRE_START_FLYER,
  PreStartSortOptions,
  SHIFT_TYPES,
} from "pages/Pre-StartCheck/utils/Constant";
import { usePreStartContext } from "pages/Pre-StartCheck/Context/Context";
import RadioButton from "pages/Pre-StartCheck/Component/RadioButton/RadioButton";
import Search from "pages/Pre-StartCheck/Component/GeneralFields/Search/Search";
import DateTimeSelect from "pages/Pre-StartCheck/Component/GeneralFields/DateTimeSelect/DateTimeSelect";
import ToggleButtons from "pages/Pre-StartCheck/Component/ToggleButton/ToggleButton";
import SelectField from "pages/Pre-StartCheck/Component/GeneralFields/SelectField/SelectField";
import download from "../../../../assets/svgs/shift-maintenance/download.svg";
import Filter from "../Filter/Filter";
import PreStartSummary from "../PreStartSummary/PreStartSummary";


const Header = () => {
  const {
    setShiftType,
    shiftType,
    selectedDate,
    setSelectedDate,
    searchQuery,
    sortBy,
    setSortBy,
    shiftData,
    setShiftData,
    openFlyer,
    setOpenFlyer,
    searchPreStartData,
    shiftOptions,
    setSelectedShift,
    selectedShift,
    loading,
  } = usePreStartContext();




  const handleFilter = () => {
    setOpenFlyer(PRE_START_FLYER.FILTER);
  };

  return (
    <>
      <div className="px-5 py-4 d-flex align-items-center justify-content-between">
        <div className="d-flex justify-content-between align-items-center">
          <DateTimeSelect
            value={selectedDate}
            onDateChange={(date) => {
              setSelectedDate(date);
              setOpenFlyer("");
            }}
            className={`${styles.dateTimeSelect} `}
            iconColor="#6B7B8E"
            containerStyle={{
              border: "1px solid #E1E8F4",
            }}
          />

          <ToggleButtons
            selectedValue={shiftType === "day" ? 1 : 2}
            onOptionClick={(v) => {
              setShiftType(v === 1 ? "day" : "night");
              setOpenFlyer("");
              setShiftData(shiftOptions?.[shiftType]?.[0]?.value)
            }}
            style={{ height: 27.5 }}
            options={SHIFT_TYPES}
          />

          <SelectField
            noPlaceHolder={true}
            options={shiftOptions?.[shiftType]}
            value={selectedShift?.[shiftType]}
            className="ml-3"
            isDisabled={!shiftOptions?.[shiftType]?.length || loading}
            style={getSelectFieldStyle(loading, shiftOptions?.[shiftType])}
            onChange={(v) => {
              setSelectedShift((prev) => ({ ...prev, [shiftType]: v }));
              setShiftData(v)
              setOpenFlyer("");
            }}
            mainContainerStyle={{ marginBottom: "0px" }}
          />

        </div>
        <img className={styles.downloadIcon} src={download} alt="download" />
      </div>
      <PreStartSummary />
      <div className="px-5 py-4 border-bottom d-flex align-items-center justify-content-between">
        <RadioButton
          radioLabel={"Sort By:"}
          options={PreStartSortOptions}
          value={sortBy}
          onChange={(v) => {
            setSortBy(v);
          }}
          isDisabled={false}
          id="sortOptions"
        />

        <div
          className="d-flex justify-content-between align-items-center"
          style={{ gap: "10px" }}
        >
          <Search
            query={searchQuery}
            onChange={(value) => searchPreStartData(value)}
          />

          <div
            style={{
              userSelect: "none",
              backgroundColor:
                openFlyer === PRE_START_FLYER.FILTER
                  ? "#E2ECFC"
                  : "transparent",
            }}
            className={styles.filterBtn}
            onClick={() => handleFilter()}
          >
            <img src={filter} alt="filter" />
            <span>Filter</span>
          </div>

          {openFlyer === PRE_START_FLYER.FILTER && <Filter />}
        </div>
      </div>
    </>
  );
};

const getSelectFieldStyle = (isLoading, options) => ({
  height: "30px",
  width: "160px",
  marginTop: 2.5,
  border: "1px solid #E1E8F4",
  borderRadius: "15px",
  font: "normal normal 600 12px/14px Open Sans",
  paddingLeft: "10px",
  color: "#000000",
  cursor: !options?.length || isLoading ? "not-allowed" : "pointer",
});

export default Header;
